import styled from "styled-components";

import InputField from "../../../components/InputField";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";
import { theme } from "../../../util/theme";
import { Grid } from "@material-ui/core";

export const SectionWrapper = styled.section`
  box-sizing: border-box;
  margin: 0 auto;
  width: 100%;
  max-width: 1200px;
`;

export const Section1 = styled(Grid)`
  box-sizing: border-box;
  height: auto;
  align-items: center;
  position: relative;
  margin-top: 100px;
`;

export const ContactWrapper = styled(Grid)`
  box-sizing: border-box;
  height: 391px;
  // width: 50rem;
  background-color: rgba(50, 197, 255, 0.06);
  padding: 3rem;
  position: relative;
  hr {
    box-sizing: border-box;
    height: 1px;
    width: 72px;
    border: 4px solid #034962;
    margin-top: 20px;
    margin-bottom: 40px;
  }

  @media screen and (max-width: 600px) {
    margin-left: 1rem;
    margin-right: 1rem;
    padding: 3rem 1rem;
    height: auto;
  }
`;

export const ContactBox = styled(Grid)``;

export const SectionTitle = styled.h2`
  box-sizing: border-box;
  height: 32px;
  color: #034962;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
`;

export const SectionSubtitle = styled.h3`
  box-sizing: border-box;
  height: 32px;
  color: #034962;
  font-family: "Nunito Sans";
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
`;

export const ContactInfo = styled.div`
  margin-top: 10px;
  display: flex;
  align-items: center;
  img {
    width: 20px;
    height: 20px;
  }
`;

export const ContactText = styled.div`
  margin-left: 20px;
  color: #1c4576;
`;

export const ContactInputField = styled(InputField)`
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: transparent !important;
  color: #fff !important;

  .MuiFilledInput-root {
    border: 1px solid !important;
    border-color: transparent !important;
  }
  .MuiFilledInput-input {
    color: #fff;
    background-color: transparent;
  }
  .MuiFormLabel-root {
    color: #5a8aa1;
  }

  .Mui-focused {
    color: #fff !important;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    // -webkit-box-shadow: 0 0 0 30px #274472 inset !important;
    -webkit-text-fill-color: #fff !important;
  }
`;

export const ContactTextAreaField = styled(InputField)`
  border-radius: 3px;
  .MuiFormLabel-root {
    color: #5a8aa1;
  }
  .MuiFilledInput-root:hover {
    border-color: #fff !important;
  }
  .MuiFilledInput-input {
    color: #fff;
    background-color: transparent;
  }
`;

export const ErrorContainer = styled.div`
  padding-left: 20px;
  position: relative;
  top: -7px;
  color: #f95725;
  font-size: 12px;
`;

export const FormContainer = styled(Grid)`
  background: #274472;
  h5 {
    font-weight: bold;
    font-size: 24px;
    color: #ffffff;
    margin-bottom: 20px;
  }
  input {
    // margin-bottom: 20px;
  }
`;

export const FormWrapper = styled.form`
  margin: 3rem;
  @media screen and (max-width: 783px) {
    margin: 3rem 1rem;
  }
`;

export const ButtonWrapper = styled.div`
  margin-top: 10px;
  margin-bottom: 30px;
  text-align: center;
  display: flex;
  justify-content: center;
`;

export const DropdownCon = styled.div`
  .MuiNativeSelect-root {
    color: #fff !important;
  }
  .MuiFormLabel-root {
    color: #5a8aa1;
  }
  .MuiNativeSelect-select:not([multiple]) option,
  .MuiNativeSelect-select:not([multiple]) optgroup {
    background-color: #274472;
  }
`;

export const SubmitButton = styled(LoadingButton)`
  &.MuiButtonBase-root {
    border: none !important;
    background-color: ${theme.color.primary} !important;
    color: #fff !important;
  }
`;
