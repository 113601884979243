import { Box, TextField, Grid } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { PrevBtn, SubmitButton } from '../../../../components/Forms/ConfirmVerificationModal/style'
import { CustomDialog, CustomDialogTitle } from './style'



const BlacklistModal = ({toggleModal, isOpen, toggleFn, vendorId, loading}) => {
    const [reason, setReason] = useState("")
    const handleChange = (e) => {
        setReason(e.target.value)
    }
    const handleBlacklist = () => {
       toggleFn({vendorId, status: 4, description: reason})
    }
    return (
        <CustomDialog open={isOpen} onClose={() => toggleModal(false)}>
           <CustomDialogTitle>Kindly state your reason for suspending</CustomDialogTitle> 
           <TextField
           id="outlined-textarea"
            value={reason}
            InputProps={{
                disableUnderline: true,
              }}
            onChange={handleChange}
            label="Reason"
            placeholder="Type here"
            multiline
            rows={15}
            variant="outlined"
            style={{ marginTop: 20 }}
           />
           <Box ml={1} mt={3}>
             <Grid container direction="row" justify="flex-start" alignItems="center" spacing={2}>
                <PrevBtn onClick={() => toggleModal(false)} variant="outlined">Cancel</PrevBtn> 
                <SubmitButton disabled={loading} onClick={handleBlacklist} style={{background: loading ? "#cbd4da" : "red"}} variant="contained" color="primary">Suspend</SubmitButton> 
             </Grid>
           </Box>
        </CustomDialog>
    )
}

export default BlacklistModal
