/* eslint-disable no-unused-expressions */
/* eslint-disable no-alert */
/* eslint-disable no-undef */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useForm } from "react-hook-form";
import { Container } from "@material-ui/core";

import { Navbar, Footer } from "../../../components";
import InputField from "../../../components/InputField";
import {
  ButtonLabel,
  ContentWrapper,
  AuthSubtext,
  LoginButton,
  LoginInfoWrapper,
  PasswordResetButton,
  TermsAndPrivacy,
  Wrapper,
  AuthText,
} from "../style";
import { loginUser } from "../../../redux/reducer/userReducer";
import { RedirectHelper } from "../util";
import useAlert from "../../../hooks/useAlert";
import { LandingNavbarStatic } from "../../../components/HomepageHeader/style";

const withLogin = (WrappedComponent) => ({
  forgotPasswordLink, dashboardLink, loginRoute = "/login",
}) => {
  const width = "100%";
  const margin = "0 0 8px 0";
  const textDecoration = "none";
  const color = "#2AA96D";
  const textAlign = "center";

  const history = useHistory();
  const dispatch = useDispatch();
  const { register, handleSubmit } = useForm();
  const { loading } = useSelector((state) => state.user);

  const { showAlert, Toast } = useAlert();

  const onSubmit = (data) => {
    dispatch(loginUser(data))
      .then((res) => {
        if (res.error) {
          showAlert({ severity: "error", message: res.payload });
          return;
        }

        const isAdmin = res.payload.data.user.userType === 2;
        const isVendor = res.payload.data.user.userType === 1;
        let reRoutePath = "/404";

        if (isAdmin) {
          reRoutePath = "/admin/dashboard/overview";
        }

        if (isVendor) {
          reRoutePath = "/vendor/overview"
        }

        history.push(reRoutePath);
      });
  };

  useEffect(() => {
    RedirectHelper(loginRoute, dashboardLink, history);
  }, [dashboardLink, history, loginRoute]);

  return (
    <div>
      <LandingNavbarStatic>
        <Navbar />
      </LandingNavbarStatic>
      <Container>
        <ContentWrapper>
          <Wrapper>
            <AuthText>Login</AuthText>
            <AuthSubtext style={{ textAlign, marginBottom: "30px" }}>
              Fill in the fields below correctly
            </AuthSubtext>
            <Toast />
            <form onSubmit={handleSubmit(onSubmit)}>
              <InputField
                type="email"
                label="Email address"
                name="email"
                style={{ width, margin }}
                inputRef={register({
                  required: "Invalid email address",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                })}
              />
              <InputField
                type="password"
                label="Password"
                name="password"
                style={{ width, margin }}
                inputRef={register({
                  required:
                    "Password is required",
                })}
              />
              <LoginInfoWrapper>
                <PasswordResetButton>
                  <Link to={forgotPasswordLink} style={{ textDecoration }}>
                    <ButtonLabel>Reset password</ButtonLabel>
                  </Link>
                </PasswordResetButton>
                <LoginButton type="submit" disabled={loading === "pending"}>
                  {loading === "pending" ? "Please Wait..." : "Login"}
                </LoginButton>
              </LoginInfoWrapper>
            </form>

            <TermsAndPrivacy>
              Logging in indicates that you've read and agree to our{" "}
              <Link to="/terms-and-conditions" style={{ textDecoration, color }}>
                Terms
              </Link>{" "}
              and have read our{" "}
              <Link to="/terms-and-conditions" style={{ textDecoration, color }}>
                Privacy Policy
              </Link>
            </TermsAndPrivacy>
            <WrappedComponent />
          </Wrapper>
        </ContentWrapper>
      </Container>
      <Footer />
    </div>
  );
};

export default withLogin;
