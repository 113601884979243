// material ui component
import { FormControlLabel, Typography } from "@material-ui/core";
import styled from "styled-components"



export const SectionCard = styled.div`
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #ffffff !important;
  box-shadow: 0 2px 4px 0 #cbd4da;
  margin-bottom: 24px;
  padding-top: 20px !important;
  padding-bottom: 35px !important;
`;
export const SectionContent = styled.div`
  background-color: #ffffff !important;
  padding: 24px 0 20px 0;
  border: 1px solid #cbd4da;
  border-radius: 3px;
`;
export const SecTitleText = styled(Typography)`
  font-size: 16px !important;
  line-height: 24px !important;
  color: #1E2B37;
  ${props => !props.noPadding ? 'padding-left: 20px;' : 'padding-left: 0px;'}
`;

export const SecSubtitleText = styled(Typography)`
  font-size: 14px !important;
  line-height: 20px  !important;
  color: #637381;
  ${props => !props.noPadding ? 'padding-left: 20px;' : 'padding-left: 0px;'}
`;

export const InputStyledContainer = styled.div`
  background: #FCFCFD;
  border: 1px solid #C9D4DB;
  padding: 20px !important;

  .MuiFilledInput-input {
    padding: 27px 12px 10px !important;
  }
`;