import Landing from "./Landing";
import ForgotPassword from "../Admin/ForgotPassword";
import AdminPasswordReset from "../Admin/PasswordReset";
import ProfileSetup from "../Admin/ProfileSetup";
import Login from "../Vendor/Login";
import SignUp from "../Vendor/SignUp";
import VendorForgotPassword from "../Vendor/ForgotPassword";
import NewPassword from "../Common/NewPasswordComponent";
import VendorPasswordReset from "../Vendor/PasswordReset";
import AccountVerificationComponent from "../Vendor/AccountVerificationComponent";
import About from "./About";
import TenderDetail from "../Vendor/TenderDetails";
import Resources from "./Resources";
import SupplierList from "./SupplierList";
import SupplierDetail from "./SupplierDetail";
import TenderList from "./TenderList";
import AwardedContracts from "./AwardedContracts";
import NewsAndBulletins from "./NewsAndBulletins";
import SingleTenderDetails from "./TenderDetails";
import Contact from "./Contact";
import NewsPage from "./NewsPage";
import AwardedContractDetails from "./ContractDetail";
import TermsAndConditions from "../Vendor/TermsAndConditions";

const LandingPageRoutes = [
  {
    exact: true,
    path: "/",
    component: Landing,
  },
  {
    exact: true,
    path: "/login",
    component: Login,
  },
  {
    exact: true,
    path: "/verify-account",
    component: AccountVerificationComponent,
  },
  {
    exact: true,
    path: "/reset-password",
    component: NewPassword,
  },
  {
    exact: true,
    path: "/create-account",
    component: SignUp,
  },
  {
    exact: true,
    path: "/terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    exact: true,
    path: "/vendor/forgot-password",
    component: VendorForgotPassword,
  },
  {
    exact: true,
    path: "/admin/forgot-password",
    component: ForgotPassword,
  },
  {
    exact: true,
    path: "/admin/password-sent",
    component: AdminPasswordReset,
  },
  {
    exact: true,
    path: "/profile-setup",
    component: ProfileSetup,
  },
  {
    exact: true,
    path: "/vendor/password-sent",
    component: VendorPasswordReset,
  },
  {
    exact: true,
    path: "/vendor/tenders/:id",
    component: TenderDetail,
  },
  {
    exact: true,
    path: "/supplier",
    component: SupplierList,
  },
  {
    exact: true,
    path: "/supplier/:id",
    component: SupplierDetail,
  },
  {
    exact: true,
    path: "/resources",
    component: Resources,
  },
  {
    exact: true,
    path: "/ocds-portal/tenders-list",
    component: TenderList,
  },
  {
    exact: true,
    path: "/ocds-portal/tenders-list/:id",
    component: SingleTenderDetails,
  },
  {
    exact: true,
    path: "/ocds-portal/awarded-contracts",
    component: AwardedContracts,
  },
  {
    exact: true,
    path: "/ocds-portal/awarded-contracts/:id",
    component: AwardedContractDetails,
  },
  {
    exact: true,
    path: "/about",
    component: About,
  },
  {
    exact: true,
    path: "/contact",
    component: Contact,
  },
  {
    exact: true,
    path: "/news-and-bulletins",
    component: NewsAndBulletins,
  },
  {
    exact: true,
    path: "/news/:id",
    component: NewsPage,
  },
]

export default LandingPageRoutes
