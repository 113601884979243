import React, { useState, useContext } from "react";
import { SectionContainer, SectionHeader, SectionDateInfo, Title } from "../style";
// import { NoteDetail, Radio, FormControlLabel } from "./style";
import { NoteDetail } from "./style";
import { useQuery } from "react-query";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import ToastContext from "../../../../util/toastContext";
import DetailItem from "../../../../components/DetailItem";
import { parseISO, format } from "date-fns";
import moment from 'moment';
import Loader from "../../../../components/Common/Loader";
import PaymentRequiredNotice from "./PaymentRequiredNotice";
// import Box from "@material-ui/core/Box";
// import RadioGroup from "@material-ui/core/RadioGroup";
// import FormControl from "@material-ui/core/FormControl";
// import FormLabel from "@material-ui/core/FormLabel";
import OnlinePayment from "./OnlinePayment";
import OfflinePayment from "./OfflinePayment";
import DocumentList from "./DocumentsList";
import BidQuotation from "./BidQuotation"
import PlaceBid from "./PlaceBid";
import PaymentStatus from "./PaymentStatus";
import useBidPaymentStatus from "../useBidPaymentStatus";

const formatDate = (date) => format(parseISO(date), "dd/MM/yyyy");

const isAnyEqualTo = (val, arr) => arr.some((arrVal) => arrVal === val);



// const RadioTitle = ({ className, ...props }) => <Title as="legend" secondary bottomSpacing="12px" {...props} />;

const paymentTypes = [
  {
    value: "online",
    label: "Online Payment",
    component: OnlinePayment,
  },
  {
    value: "offline",
    label: "Offline Payment",
    component: OfflinePayment,
  },
];

export default function BidDocuments({ procurementQueryStatus, procurementPlan, bidQueryStatus, bid, tenderId }) {
  const [paymentType, setPaymentType] = useState("online");
  const [bidDataInfo, setBidDataInfo] = useState({
    quotation: "",
    completionNumber: "",
    completionPeriod: 1,
  })
  const {
    query: paymentStatusQuery,
    paymentInfo,
    // hasAttemptedPayment,
    hasUserPaid,
    isAwaitingVerification,
  } = useBidPaymentStatus({
    tenderId,
    queryProps: {
      onSuccess(paymentInfo) {
        if (paymentInfo.paymentStatus !== "PENDING" && !!paymentInfo.paymentMethod) {
          setPaymentType(paymentInfo.paymentMethod.toLowerCase());
        }
      },
    },
  });
  const onError = (error) => {
    showAlert({
      severity: "error",
      message: error.message,
      durationInMs: 3000,
    });
  };
  const updateBidDataInfo = (key) => (value) => {
    setBidDataInfo((prev) => ({
      ...prev,
      [key]: value
    }))

  }
  const { status, data: documents = [] } = useQuery(["getVendorDocuments", tenderId], vendorsAPI.getBidInvitationDocuments, {
    enabled: hasUserPaid,
    onError,
  });

  const { status: getDocumentsStatus, data: uploadedDocuments = [] } = useQuery(
    ["getBidDocuments", tenderId],
    vendorsAPI.getBidsDocument,
    {
      enabled: bid?.bidStatus === "PROCESSING",
      onError,
    }
  );

  const { showAlert } = useContext(ToastContext);

  const queryStatuses = [procurementQueryStatus, bidQueryStatus, paymentStatusQuery.status, getDocumentsStatus, status];

  const isLoading = isAnyEqualTo("loading", queryStatuses);
  const hasError = isAnyEqualTo("error", queryStatuses);

  const selectedPaymentType = paymentTypes.find((type) => paymentType === type.value);

  const price = procurementPlan?.tenderPreparation?.fee ?? 0;

  const getDaysUntilExpiry = (dateString) => {
    const now = new Date();
    const date = parseISO(dateString);
    if (now >= date) { return "Submission Closed"; }
    else {
      const distanceFromNow = moment(dateString).fromNow()
      return `${distanceFromNow}`;
    }
  };

  if (isLoading)
    return (
      <SectionContainer>
        <Loader />
      </SectionContainer>
    );

  return (
    <SectionContainer>
      {!hasError ? (
        <React.Fragment>
          <SectionHeader>
            <Title>Bidding Documents</Title>
            <SectionDateInfo offsetMargin>
              <DetailItem label="Submission Date" text={formatDate(bid?.expiryDate)} />
              <p className="expiry-info">{getDaysUntilExpiry(bid?.expiryDate)}</p>
            </SectionDateInfo>
          </SectionHeader>
          <NoteDetail label="Note" text={bid?.description} />
          {/* <Box mb={4.25}>
            <FormControl component="fieldset">
              <FormLabel component={RadioTitle}>Select Payment Type</FormLabel>
              <RadioGroup
                row
                aria-disabled={hasUserPaid}
                aria-label="Payment type"
                name="paymentType"
                value={paymentType}
                onChange={(e) => setPaymentType(e.target.value)}
              >
                {paymentTypes.map((type) => (
                  <FormControlLabel
                    active={paymentType === type.value}
                    disabled={hasUserPaid || (hasAttemptedPayment && paymentType !== type.value)}
                    value={type.value}
                    control={<Radio />}
                    label={type.label}
                  />
                ))}
              </RadioGroup>
            </FormControl>
          </Box> */}
          {hasUserPaid && <PaymentStatus paymentInfo={paymentInfo} />}
          {(!hasUserPaid || isAwaitingVerification) && (
            <selectedPaymentType.component refetch={paymentStatusQuery.refetch} paymentInfo={paymentInfo} tenderId={tenderId} />
          )} 
          {(!hasUserPaid || isAwaitingVerification) && <PaymentRequiredNotice price={price} />}

          {hasUserPaid && <DocumentList documents={documents} />}
          {hasUserPaid && bid.bidStatus === "NOTSTARTED" && <BidQuotation
            previewMode
            handleCompletionPeriod={updateBidDataInfo("completionPeriod")}
            handleCompletionNumber={updateBidDataInfo("completionNumber")}
            handleQuotation={updateBidDataInfo("quotation")}
          />}
          {bid.bidStatus === "PROCESSING" && <DocumentList variant="vendor" documents={{ documents: uploadedDocuments }} />}
          {hasUserPaid && bid.bidStatus === "NOTSTARTED" && <PlaceBid bid={bid} tenderId={tenderId} expiryDate={bid?.expiryDate} bidData={bidDataInfo} />}
        </React.Fragment>
      ) : null}
    </SectionContainer>
  );
}
