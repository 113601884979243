import React, { useState, useEffect } from 'react'
import { useQuery } from "react-query";
import { PDFDownloadLink } from '@react-pdf/renderer';
import { Link, useLocation } from "react-router-dom";
// material UI
import {
  Container,
  // Grid,
  SvgIcon,
  Box,
  Button,
  useMediaQuery,
  // TablePagination,
} from '@material-ui/core';
import { makeStyles } from "@material-ui/styles";
// assets
import { ReactComponent as CopySvg } from "../../../assets/copy.svg";
import { ReactComponent as PdfSvg } from "../../../assets/file_pdf.svg";
import { ReactComponent as PrintPdf } from "../../../assets/print.svg";
// custom styled
import { BreadCrumblabel, BreadcrumbActive, PaginationWrapper } from './style';
// components
import InputField from '../../../components/InputField'
import Loader from "../../../components/Common/Loader";
import withHeaderFooterHOC from '../HOC/withHeaderFooterHOC'
import CustomSearchTable from "../../../components/SearchTable/SupplierSearchTable";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import CustomSupplierList from "../../../components/SupplierListCard/CustomSupplierListTable"
// import SupplierListCard from '../../../components/SupplierListCard';
import SupplierListPDF from "./SupplierListPDF"
import { supplierList } from './mock';
import vendorsAPI from '../../../redux/api/vendorsAPI';


const headLabel = [
  {
    id: "CompanyName",
    label: "COMPANY NAME"
  },
  {
    id: "Category",
    label: "CATEGORY"
  },
  {
    id: "Competence",
    label: "COMPETENCE"
  },
  // {
  //   id: "Website",
  //   label: "WEBSITE"
  // },
  {
    id: "IncorporationYear",
    label: "YEAR OF INC"
  },
  {
    id: "CacRegistrationNumber",
    label: "CAC REG NO"
  },
  {
    id: "Status",
    label: "STATUS"
  },
]
const SupplierList = () => {
  const width = "100%";
  const marginTop = "40px";
  const classes = useStyles();
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const linkStyle = { textDecoration: "none", color: "inherit" };
  const [filters, setFilters] = useState({
    Search: "",
    Sort: "",
    Filter: "",
    FilterBy: "",
  })
  const { pathname } = useLocation();
  const handleCopyTable = () => {
    var urlField = document.getElementById("supplier");

    // create a Range object
    var range = document.createRange();
    // set the Node to select the "range"
    range.selectNode(urlField);
    // add the Range to the set of window selections
    window.getSelection().addRange(range);

    // execute 'copy', can't 'cut' in this case
    // navigator.clipboard.writeText
    document.execCommand("copy");
  }
  const { data: apiData, isLoading, status } = useQuery(
    [
      "getWebVendorList",
      {
        pageSize: rowsPerPage,
        pageNumber: pageNumber + 1,
        ...filters,
      }
    ],
    vendorsAPI.getWebVendorList,
  )

  const responseData = apiData?.data || [];
  const meta = apiData?.meta || {};
  const emptyRows =
    pageNumber > 0 ? Math.max(0, (1 + pageNumber) * rowsPerPage - meta?.pagination?.totalEntries) : 0

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const handleChangePage = (event, pageNumber) => {
    setPageNumber(pageNumber);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const renderSupplierTable = () => {
    return (
      <div id="supplier">
        <CustomSearchTable
          rows={responseData}
          pageNumber={pageNumber}
          rowsPerPage={rowsPerPage}
          showPadding={false}
          totalNumberOfPages={meta?.pagination?.totalEntries}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
          handleSortRequest={updateFilters("Sort")}
          columns={headLabel}
        >
          <CustomSupplierList
            emptyRows={emptyRows}
            column={headLabel}
            suppliers={responseData}
            urlLink={`${pathname}`}
          />
        </CustomSearchTable>
      </div>
    )
  }
  return (
    <div>
      <Container>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <BreadCrumblabel>
            <Link style={linkStyle} to="/">
              Home
            </Link>{" "} &nbsp;
            <BreadcrumbActive>/{" Suppliers Catalogue"}</BreadcrumbActive>
          </BreadCrumblabel>
        </Box>
        <AdminSearchFilter normalSearch>
          <>
            <InputField
              style={{ width, height: "100%" }}
              type="text"
              label="Search ID, Title"
              placeholder="Completion of resettlement scheme"
              setValue={updateFilters("Search")}
              required
            />
          </>
        </AdminSearchFilter>
      </Container>
      <Container style={{ marginTop }}>
        <Box display="flex">
          <Box mr={2}>
            <Button
              classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
              startIcon={
                <SvgIcon component={CopySvg} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />
              }
              disabled={isLoading}
              onClick={handleCopyTable}
            >
              Copy
            </Button>
          </Box>
          <Box mr={2}>
            <PDFDownloadLink
              fileName={`Supplier-List-${Date.now()}`}
              document={<SupplierListPDF suppliers={responseData} />}
              style={{ textDecoration: 'none' }}
            >
              {({ loading, ...rest }) => {
                return (
                <Button
                  classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                  startIcon={
                    <SvgIcon component={PdfSvg} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />
                  }
                  disabled={isLoading}
                >
                  {loading ? "Downloading" : "Download PDF"}
                </Button>
              )}}
            </PDFDownloadLink>
          </Box>
          <Box >
            <Button
              classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
              startIcon={
                <SvgIcon component={PrintPdf} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />
              }
              disabled={isLoading}
              onClick={() => window.print()}
            >
              Print
            </Button>
          </Box>
        </Box>
      </Container>
      <Container style={{ marginTop, overflowX: "scroll" }}>
        {status === "loading" ? (
          <div style={{ width: "100%" }}>
            <Loader feedback="Fetching Supplier Lists" />
          </div>
        ) : renderSupplierTable()}
      </Container>
    </div>
  )
}


const useStyles = makeStyles((theme) => ({
  btnStartIcon: {
    margin: 0,
  },
  downLoadBtn: {
    border: "1px solid #E5E5EA",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    textTransform: "none",
  },
}));

export default withHeaderFooterHOC(SupplierList)
