import React, { useMemo, useState } from "react";
import { Box } from "@material-ui/core";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import { useQuery } from "react-query";
import SearchTable, { StyledTableCell } from "../../../components/SearchTable";
import { CardWrapper, HoverTableStyle } from "../../../util/theme";
import {
  CardContentTitle,
  CardContentValue,
  CardContentValueDesc,
  CardContentValueFlag,
  PageContainer,
  PageTitle,
  PageTitleContainer,
  StyleTableCellDesc,
  StyleTableCellId,
  TableStatusContainer,
} from "../../Common/style";
import TableEmptyContent from "../../Common/TableEmptyContent";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import withVendorDashboard from "../HOC/withVendorDashboard";
import { useSelector } from "react-redux";
import { transformContractStatus, designatedPath } from "../../../util/transforms/contract";
import { format } from "date-fns";
import { formatCurrency } from "../../../util/formatCurrency";

export const pickStatus = (_status) => {
  const status = _status.toLowerCase();
  switch (_status) {
    case "UNSIGNED":
      return (
        <TableStatusContainer textcolor="#637381" bgcolor="#CBD4DA">
          {status}
        </TableStatusContainer>
      );
    case "ASSIGNED":
      return (
        <TableStatusContainer textcolor="#FF8D26" bgcolor="#FFE2C5">
          {status}
        </TableStatusContainer>
      );
    case "ACCEPTED":
      return (
        <TableStatusContainer textcolor="#2AA96D" bgcolor="#C1EEFF">
          {status}
        </TableStatusContainer>
      );
    case "SIGNED":
      return (
        <TableStatusContainer textcolor="#50B83C" bgcolor="#D6EFD2">
          {status}
        </TableStatusContainer>
      );
    case "REJECTED":
      return (
        <TableStatusContainer textcolor="#F21500" bgcolor="#FFEAD9">
          {status}
        </TableStatusContainer>
      );

    default:
      return (
        <TableStatusContainer textcolor="#F21500" bgcolor="#FFEAD9">
          {status}
        </TableStatusContainer>
      );
  }
};

const ContractTableBody = ({ history, data }) =>
  data.map((eachContract) => (
    <HoverTableStyle key={eachContract.id} onClick={() => designatedPath(eachContract, history, eachContract.id)}>
      <StyledTableCell>
        <StyleTableCellId>{eachContract?.referenceId}</StyleTableCellId>
        <StyleTableCellDesc>{eachContract?.title}</StyleTableCellDesc>
      </StyledTableCell>
      <StyledTableCell>{eachContract?.category}</StyledTableCell>
      <StyledTableCell>{pickStatus(transformContractStatus(eachContract))}</StyledTableCell>
      <StyledTableCell>
        {(eachContract?.startDate && format(new Date(eachContract?.startDate), "dd, MMM, yyyy")) || "N/A"}
      </StyledTableCell>
      <StyledTableCell>{!!eachContract?.estimatedValue ? formatCurrency(eachContract?.estimatedValue, true) : 0}</StyledTableCell>
    </HoverTableStyle>
  ));

const VendorContract = () => {
  const history = useHistory();

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };
  const {
    user: { UserId },
  } = useSelector((state) => state.user);

  const queryKey = useMemo(() => ["vendorContracts", pagination, UserId], [pagination, UserId]);

  const vendorContract = useQuery(queryKey, vendorsAPI.getVendorContracts);
  const contractSummary = useQuery(["vendorContractSummary", UserId], vendorsAPI.getVendorContractSummary);

  return (
    <PageContainer>
      <PageTitleContainer>
        <PageTitle>Contracts</PageTitle>
      </PageTitleContainer>

      {contractSummary?.isLoading && <Skeleton variant="rect" width={300} height={125} />}
      {!contractSummary?.isLoading && (
        <CardWrapper>
          <CardContentTitle>Total Contracts</CardContentTitle>
          <CardContentValue>{contractSummary?.data?.total || 0}</CardContentValue>
          <CardContentValueDesc>All active and inactive</CardContentValueDesc>
          <Box display="flex" justifyContent="space-between">
            <CardContentValueFlag textcolor="#2AA96D" bgcolor="#C1EEFF">
              {contractSummary.data?.signed || 0} Signed
            </CardContentValueFlag>
            <CardContentValueFlag textcolor="#637381" bgcolor="#CBD4DA">
              {contractSummary.data?.unsigned || 0} Unsigned
            </CardContentValueFlag>
            <CardContentValueFlag textcolor="#108043" bgcolor="#D6EFD2">{`${contractSummary.data?.awarded || 0
              } Awarded`}</CardContentValueFlag>
            <CardContentValueFlag textcolor="#FFFFFF" bgcolor="#72a3ed">{`${contractSummary.data?.assigned || 0
              } Assigned`}</CardContentValueFlag>
            <CardContentValueFlag textcolor="#BF6712" bgcolor="#FFE2C5">{`${contractSummary.data?.pending || 0
              } Pending`}</CardContentValueFlag>
            <CardContentValueFlag textcolor="#DD5E47" bgcolor="#FFC8BE">{`${contractSummary.data?.rejected || 0
              } Rejected`}</CardContentValueFlag>
          </Box>
        </CardWrapper>
      )}

      {/*APIs did not make a provision for it so I commented out temporarily*/}

      {/*<AdminSearchFilter normalSearch={true}>*/}
      {/*  <Box display="flex" justifyContent="space-between">*/}
      {/*    <InputField label="Search ID. Title" style={{ width: "100%" }} defaultValue="Completion of resettlement scheme" />*/}
      {/*    <Dropdown label="By Process" values={[{ label: "All", value: 0 }]} style={{ width: "100%" }} />*/}
      {/*    <Dropdown label="By Bid Status" values={[{ label: "All", value: 0 }]} style={{ width: "100%" }} />*/}
      {/*    <InputField type="date" defaultValue={format(new Date(), "yyyy-MM-dd")} label="Expiry Date" style={{ width: "100%" }} />*/}
      {/*  </Box>*/}
      {/*  <SearchButton>Search</SearchButton>*/}
      {/*</AdminSearchFilter>*/}

      <SearchTable
        columns={["REF ID / TITLE", "CATEGORY", "CONTRACT STATUS", "AWARDED DATE", "VALUE"]}
        page={pagination.pageNumber}
        setPage={updatePagination("pageNumber")}
        rowsPerPage={pagination.pageSize}
        setRowsPerPage={updatePagination("pageSize")}
        loading={vendorContract.isLoading}
        totalNumberOfPages={vendorContract.data?.pagination?.totalEntries}
        rows={vendorContract.data?.contracts}
      >
        {vendorContract.data?.contracts.length > 0 ? (
          <ContractTableBody history={history} data={vendorContract.data.contracts} />
        ) : (
          <TableEmptyContent noColSpan={5} textDisplayed="No Contracts" />
        )}
      </SearchTable>
    </PageContainer>
  );
};

export default withVendorDashboard(VendorContract);
