import React, { useContext, useState, useEffect, useRef } from "react";
import { Drawer, Box, IconButton, Button, Typography, Grid, Avatar } from "@material-ui/core";
import makeStyles from "@material-ui/core/styles/makeStyles";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import { Link } from "react-router-dom";
import { Close as CloseIcon } from "@material-ui/icons";

import usersAPI from "../../redux/api/usersAPI";
import vendorsAPI from "../../redux/api/vendorsAPI";
import certificateImg from "../../assets/certificate.jpg";
import ProfileFormField from "./ProfileFormField";
import ToastContext from "../../util/toastContext";
import NoImage from "../../assets/no_image.png";
import { Title } from "../../pages/Vendor/BidDetails/style";
import ResetPassword from "./ResetPassword";
import { useForm } from "react-hook-form";
import { SaveButton } from "../Forms/Common/style";

export default function VendorProfile({ vendor, open, onClose }) {
  const useFormUtils = useForm();
  const inputRef = useRef(null);
  const { handleSubmit } = useFormUtils;
  const { data: vendorProfileData, isLoading, isSuccess } = useQuery(
    ["getVendorProfile", { userId: vendor.id }],
    usersAPI.getVendorProfile,
    {
      enabled: !!vendor.id,
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const { data: vendorDetails } = useQuery(
    ["getVendorDetails", vendor.id],
    vendorsAPI.getVendorDetails,
    {
      enabled: !!vendor.id,
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  )

  const [updateVendorProfile, updateVendorProfileQuery] = useMutation(usersAPI.updateVendorProfile, {
    onSuccess: () => {
      showAlert({
        message: "Your profile was updated successfully",
        severity: "success",
      });
    },
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedImage, setUploadedImage] = useState(vendorProfileData?.profilePicture?.url || null);
  const { businessInfoContainer, card, profileInfo, profileInfoContainer, profileImageContainer, classValue, paper, header, vendorName, certificate } = useStyles();

  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    if (isSuccess) {
      setUploadedImage(vendorProfileData?.profilePicture?.url)
    }
  }, [isSuccess, vendorProfileData])

  const handleSubmitForm = (data) => {
    const formData = new FormData();

    Object.keys(data).forEach((el) => {
      if (el === "currentPassword") {
        // Did this to avoid cases where the browser autofill current password input
        formData.append(el, data[el])
        if (data.newPassword === "" || data.confirmNewPassword === "") {
          formData.append("currentPassword", "");
        }
      } else if (!!data[el]) {
        formData.append(el, data[el]);
      }
    });

    submitForm(formData);
  };


  const submitForm = (form) => {
    form.append("profilePicture", selectedFile);
    try {
      updateVendorProfile({ userId: vendor.id, data: form });
    } catch (error) {
      showAlert({
        message: error.message,
        severity: "error",
      });
    }
  };

  const handleImageUpload = (file) => {
    const reader = new FileReader();
    reader.addEventListener("load", function () {
      // convert image file to base64 string
      setUploadedImage(reader.result);
    }, false);

    if (file) {
      reader.readAsDataURL(file);
    }
    setSelectedFile(file)
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={onClose}
      classes={{ paper }}
    >
      <Box className={header} display="flex" justifyContent="space-between" alignItems="center">
        <Title>Profile</Title>
        <Button onClick={onClose} variant="outlined" color="inherit" style={{ textTransform: "capitalize" }}>Close</Button>
      </Box>

      <Box display="flex" className={profileInfoContainer}>
        {isLoading ? (
          <Skeleton height="176px" width="176px" variant="circle" col />
        ) : (
          <div className={profileImageContainer}>
            <Avatar
              className="profileImage"
              alt="User" src={uploadedImage || NoImage}
              onClick={() => inputRef.current?.click()}
              style={{ cursor: "pointer" }}
            />

            <input
              type="file"
              hidden
              ref={inputRef}
              onChange={(e) => handleImageUpload(e.target.files[0])}
            />
          </div>
        )}
        <Box display="flex" flex={1}>
          <Box ml={4} flex={1}>
            <Box className={vendorName} mb={2}>
              <Typography component="h4">{vendorDetails?.companyName}</Typography>
              <Typography component="p" variant="body1">
                {vendorDetails?.city}, {vendorDetails?.state}
              </Typography>
              <Button variant="outlined" color="primary" className="upgradeButton" component={Link} to="/vendor-registration/categories">
                Upgrade Class
              </Button>
            </Box>
            <Box className={profileInfo}>
              <div>
                <p>Contract Value:</p>
                <h4>
                  &#8358;{vendorProfileData?.contractMinValue?.toLocaleString()} - &#8358;
                  {vendorProfileData?.contractMaxValue?.toLocaleString()}
                </h4>
              </div>
              <div>
                <p>Service Fee:</p>
                <h4>&#8358;{vendorProfileData?.fee?.toLocaleString()}</h4>
              </div>
              <div>
                <p>Date Joined:</p>
                <h4>{moment(vendorProfileData?.dateJoined).format("DD, MMMM, yyyy")}</h4>
              </div>
            </Box>
          </Box>
          <Box>
            <p>Class</p>
            <Box display="flex" alignItems="center" justifyContent="center" className={classValue}>
              <Box display="flex" alignItems="center" justifyContent="center" style={{ height: 100, width: 100, backgroundColor: "rgba(0, 176, 241, 0.0735632)" }}>
                <h4 >{vendorProfileData?.grade?.split(" ")[1]}</h4>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box className={businessInfoContainer}>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box className={profileInfo}>
              <div>
                <p>Area of Competence:</p>
                <h4>
                  Above &#8358;{vendorProfileData?.contractMinValue?.toLocaleString()} - &#8358;
                  {vendorProfileData?.areaOfCompetence}
                </h4>
              </div>
              <div>
                <p>Type of Organisation:</p>
                <h4>{vendorProfileData?.organizationType}</h4>
              </div>
              <div>
                <p>Business  Name:</p>
                <h4>{vendorProfileData?.companyName}</h4>
              </div>
              <div>
                <p>Registration Number:</p>
                <h4>{vendorProfileData?.registrationNumber}</h4>
              </div>
              <div>
                <p>Registration Date:</p>
                <h4>{moment(vendorProfileData?.dateJoined).format("DD, MMMM, yyyy")}</h4>
              </div>
              <div>
                <p>Last renewal:</p>
                <h4>{!!vendorProfileData?.lastRenewalDate && moment(vendorProfileData?.lastRenewalDate).format("DD, MMMM, yyyy")}</h4>
              </div>
            </Box>
          </Grid>
          <Grid className={card} item xs={6} style={{ padding: 30 }}>
            <Box className={vendorName}>
              <Typography component="h4">Business Address</Typography>
              <Typography component="p" variant="body1">
                {vendorDetails?.addressLine1}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box className={businessInfoContainer}>
        <Box className={`${card} ${vendorName}`}>
          <Typography component="h4">Password</Typography>
          <Box mt={1}>
            <ResetPassword user={vendor} onSubmitForm={submitForm} useFormUtils={useFormUtils} />
          </Box>

        </Box>
      </Box>

      <Box className={businessInfoContainer}>
        <Box className={certificate}>
          {vendorProfileData?.certificate ? (
            <Button
              variant="text"
              href={vendorProfileData?.certificate}
              style={{ color: "white", textTransform: "capitalize", fontWeight: 500 }}
              target="_blank"
            >Click to view certificate
            </Button>
          ) : (
            <Button
              variant="text"
              style={{ color: "white", textTransform: "capitalize", fontWeight: 500 }}
            >Vendor does not have certificate
            </Button>
          )}
        </Box>
      </Box>
      <Box display="flex" justifyContent="flex-end" mr={5} mb={5}>
        <SaveButton
          loading={updateVendorProfileQuery.isLoading}
          onClick={handleSubmit(handleSubmitForm)}>
          Save
        </SaveButton>
      </Box>
    </Drawer>
  );
}

const useStyles = makeStyles((theme) => ({
  card: {
    border: "1px solid #EBEBEB",
    borderRadius: "3px",
    padding: 30,
  },
  vendorName: {
    "& h4": {
      fontWeight: 800,
      color: "#424F5C"
    },
    "& p": {
      color: "#424F5C",

    },
    "& .upgradeButton": {
      fontWeight: 600,
      borderRadius: 50,
      backgroundColor: "white",
      marginTop: theme.spacing(1),
      textTransform: "capitalize"
    },
  },
  certificate: {
    minHeight: 100,
    backgroundImage: `linear-gradient(180deg, rgba(0,0,0, .5), rgba(0,0,0, .5) 30%),url(${certificateImg})`,
    borderRadius: 3,
    backgroundColor: "#e5e9ec",
    backgroundSize: "cover",
    backgroundRepeat: "no-repeat",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
  },
  paper: {
    maxWidth: 900,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  businessInfoContainer: {
    padding: theme.spacing(4, 5),

  },
  profileInfo: {
    fontSize: 16,
    color: "#424F5C",
    flex: 1,
    "& div": {
      width: "100%",
      display: "flex",
      "&:not(:last-child)": {
        marginBottom: 10,
      },
      "& p": {
        flex: 1
      },
      "& h4": {
        flex: 1,
        fontWeight: 800
      },
    },
  },
  header: {
    padding: theme.spacing(2, 5),
  },
  profileInfoContainer: {
    padding: theme.spacing(4, 5),
    backgroundColor: "#F3F6F8"
  },
  classValue: {
    background: "white",
    width: 120,
    height: 120,
    border: "1px solid #EBEBEB",
    fontWeight: 600,
    fontSize: 64,
    lineHeight: "87px",

    color: "#2AA96D",
  },
  profileImageContainer: {
    position: "relative",
    width: 176,
    height: 176,

    "& .profileImage": {
      position: "absolute",
      top: 0,
      left: 0,
      zIndex: 1,
      backgroundColor: "#E4E9EC",
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },
    '& input[type="file"] ': {
      position: "absolute",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      borderColor: "transparent",
      opacity: 0,
      zIndex: 2,
      display: "none"
    }
  }
}));
