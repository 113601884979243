import React, { useState } from "react";
import { useQuery } from "react-query";
import vendorsAPI from "../../redux/api/vendorsAPI";
import SearchTable from "../SearchTable";
import PaymentTable from "./PaymentTable";

const VendorPaymentRenewalTable = () => {

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const { data, isLoading, refetch } = useQuery(
    [
      "renewalPaymentProofs",
      {
        PageNumber: pagination.pageNumber,
        PageSize: pagination.pageSize,
        PaymentType: 3,
        Status: 1,
      },
    ],
    vendorsAPI.getRenewalPaymentProofs
  );

  return (
    <SearchTable
    rows={data?.data}
    columns={["name", "payment", ""]}
    page={pagination.pageNumber}
    setPage={updatePagination("pageNumber")}
    rowsPerPage={pagination.pageSize}
    setRowsPerPage={updatePagination("pageSize")}
    loading={isLoading}
    totalNumberOfPages={data?.meta?.pagination?.totalEntries}
  >
    <PaymentTable refetch={refetch} vendors={data?.data} />
  </SearchTable>
  );
};

export default VendorPaymentRenewalTable;
