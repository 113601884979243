import React, { useState, useEffect } from "react";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { TitleContainer, PageTitle, AddButton, CirclePlusIconWrapper } from "../../../Common/style";
import { ContentWrapper } from "../../Procurement/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import { Box, capitalize } from "@material-ui/core";
import { SearchButton, SearchInput, StyledStatus } from "./style";
import AddPermissionDrawer from "./AddPermissionDrawer";
import { useQuery } from "react-query";
import adminAPI from "../../../../redux/api/adminAPI";
import Loader from "../../../../components/Common/Loader";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import AccessControl from "../../../../components/AccessControl";
import SearchTable, { StyledTableRow, StyledTableCell } from "../../../../components/SearchTable";
import { formatCurrency } from "../../../../util/formatCurrency";
import moment from "moment";
import { theme } from "../../../../util/theme";

function Permission() {
  const [showDrawer, setShowDrawer] = useState(false);
  const [makeRequest, setMakeRequest] = useState(true);
  const [makeRolesRequest, setMakeRolesRequest] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [searchText, setSearchText] = useState("");

  const { data: { data: roles = [] } = {} } = useQuery(["roles", { Search: searchValue }], adminAPI.getRoles, {
    enabled: makeRolesRequest,
    onSuccess: () => {
      setMakeRolesRequest(false);
    },
    onError: () => {
      setMakeRolesRequest(false);
    },
  });

  const { isFetching, data: { data = [] } = {}, isLoading } = useQuery(
    ["getRolesThreshholds", { Search: searchValue }],
    adminAPI.getRolesThreshholds,
    {
      enabled: makeRequest,
      onSuccess: () => {
        setMakeRequest(false);
      },
      onError: () => {
        setMakeRequest(false);
      },
    }
  );

  const { data: categories = [] } = useQuery({
    queryFn: adminAPI.getProcurementCategories,
    queryKey: ["categories"],
  });

  useEffect(() => {
    setMakeRequest(true);
  }, [searchValue]);

  const breadcrumbLinks = [
    {
      title: "Home",
      url: "/admin/dashboard/overview",
    },
    {
      title: "Administration",
    },
  ];

  const handleClickSearch = () => {
    setSearchValue(searchText);
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Permissions</PageTitle>
          <Breadcrumb values={breadcrumbLinks} />
        </div>
        <AccessControl allowedRoles={Roles.KWPPA}>
          <AddButton onClick={() => setShowDrawer(true)}>
            <CirclePlusIconWrapper />
            Set Threshold
          </AddButton>
        </AccessControl>
      </TitleContainer>
      <Box mb={3.125}>
        <AdminSearchFilter title="Filter" normalSearch>
          <Box as="form" display="flex" alignItems="center">
            <SearchInput type="text" label="Search" setValue={(value) => setSearchText(value)} />
            <SearchButton onClick={handleClickSearch} loading={isFetching} disabled={isFetching}>
              Search
            </SearchButton>
          </Box>
        </AdminSearchFilter>
      </Box>
      {isLoading && <Loader />}
      {!isLoading && (
        <SearchTable columns={["S/N", "Role", "Category", "Lower threshold limit", "Upper threshold limit", "CREATION DATE", "EXPIRY DATE", "STATUS"]}>
          {data.map(({ role, category, lowerLimitThreshold, upperLimitThreshold, expiryDate, status, createdAt }, index) => (
            <StyledTableRow key={index}>
              <StyledTableCell>{index + 1}</StyledTableCell>
              <StyledTableCell>{role}</StyledTableCell>
              <StyledTableCell>{category?.name}</StyledTableCell>
              <StyledTableCell>{formatCurrency(lowerLimitThreshold, true)}</StyledTableCell>
              <StyledTableCell>{formatCurrency(upperLimitThreshold, true)}</StyledTableCell>
              <StyledTableCell>{createdAt ? moment(createdAt).format('MMM DD, yyyy') : 'N/A'}</StyledTableCell>
              <StyledTableCell>{expiryDate ? moment(expiryDate).format('MMM DD, yyyy') : 'N/A'}</StyledTableCell>
              <StyledTableCell style={{ textAlign: 'center' }} >
                <StyledStatus style={status && status === 'Active' ? {
                  backgroundColor: theme.color.tags.approved.background,
                  border: `2px solid ${theme.color.tags.approved.background}`,
                  color: theme.color.tags.approved.text
                } : status && status === 'InActive' ? {
                  backgroundColor: theme.color.tags.rejected.background,
                  border: `2px solid ${theme.color.tags.rejected.background}`,
                  color: theme.color.tags.rejected.text
                } : {}
                }>{status ? capitalize(status) : 'N/A'}</StyledStatus></StyledTableCell>
            </StyledTableRow>
          ))}
        </SearchTable>
      )}
      <AddPermissionDrawer roles={roles} categories={categories?.data} show={showDrawer} onClose={() => setShowDrawer(false)} />
    </ContentWrapper>
  );
}

export default withAdminDashboard(Permission);
