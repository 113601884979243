import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Chip, Box, Button } from "@material-ui/core";
import { theme } from '../../../../util/theme';
import { VendorUpgradeRequestRowStyle, StyledTableCell } from '../style';
import EmptyTableBody from "../../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../../components/EmptyTableBody/style";


const statusColors = {
  PENDING: theme.color.tags.pending,
  APPROVED: theme.color.tags.approved,
  REJECTED: theme.color.tags.rejected,
  SUSPENDED: theme.color.tags.rejected
}

const UploadedVendorTable = ({ requests = [], column, emptyRows }) => {
  const history = useHistory();
  const {pathname} = useLocation;

  console.log("vendorListData ", requests);

  return requests.length ? (
    <React.Fragment>
      {requests.map((request) => {
        const link = `${pathname}/${request?.userId}`
        return (
          <VendorUpgradeRequestRowStyle
            key={request?.userId}
            onClick={() => history.push(link)}
          >
            <StyledTableCell>
              <Box style={{ width: 200 }} >
                <p>
                  {request?.email}
                </p>
              </Box>
            </StyledTableCell>
            <StyledTableCell >
              {request?.currentCategory?.toLowerCase()}
            </StyledTableCell>
            <StyledTableCell >
              {request?.regPaymentDate?.toLowerCase() || "---"}
            </StyledTableCell>
            <StyledTableCell style={{ textAlign: "left" }}>
              {request?.status?.toLowerCase() || "---"}
            </StyledTableCell>
            <StyledTableCell>
              <Chip
                label={request?.status || "UNKNOWN"}
                style={{
                  background: statusColors[request?.status?.toUpperCase()] || statusColors.SUSPENDED,
                  // color: statusColors[request?.status?.toUpperCase()].text
                }}
              />
            </StyledTableCell>
            <StyledTableCell align="right">
              <Button color="error" variant="text" style={{ textTransform: "none", color: theme.color.danger }}>Delete</Button>

            </StyledTableCell>
          </VendorUpgradeRequestRowStyle>
        )
      })}
      {emptyRows > 0 && (
        <VendorUpgradeRequestRowStyle style={{ height: 50 * emptyRows }}>
          <StyledTableCell style={{ backgroundColor: "#fff" }} colSpan={column.length} />
        </VendorUpgradeRequestRowStyle>
      )}
    </React.Fragment>
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>No Vendor Upgrade Request yet</EmptyTableSubTitle>
    </EmptyTableBody>
  )
}

export default UploadedVendorTable;
