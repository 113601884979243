import React from "react";
import { Grid, Container } from "@material-ui/core";
import { useQuery } from "react-query";

import {
  ProjectInfoSection,
  ProjectInfoContainer,
  ProjectInfoSectionWrapper,
  ProjectContent,
  ProjectContentValue,
  ProjectContentLabel,
} from "./style";
import procurementAPI from "../../../redux/api/procurementAPI";

const ProjectSummary = () => {
  const { data: projectSummaryResponse } = useQuery(["getProjectSummary"], procurementAPI.getProjectSummary);
  const projectSummaryResponseData = projectSummaryResponse?.data || {};

  return (
    <Container>
      <ProjectInfoSectionWrapper>
        <ProjectInfoSection>
          <ProjectInfoContainer>
            <Grid container spacing={3} style={{ height: "100%" }}>
              <ProjectContent item xs={12} sm={4}>
                <ProjectContentValue>{projectSummaryResponseData.completedProjectCount}+</ProjectContentValue>
                <ProjectContentLabel>Completed Projects</ProjectContentLabel>
              </ProjectContent>
              <ProjectContent item xs={12} sm={4}>
                <ProjectContentValue>{projectSummaryResponseData.staffCount}+</ProjectContentValue>
                <ProjectContentLabel>Staff Capacity</ProjectContentLabel>
              </ProjectContent>
              <ProjectContent item xs={12} sm={4}>
                <ProjectContentValue>2005</ProjectContentValue>
                <ProjectContentLabel>Established</ProjectContentLabel>
              </ProjectContent>
            </Grid>
          </ProjectInfoContainer>
        </ProjectInfoSection>
      </ProjectInfoSectionWrapper>
    </Container>
  );
};

export default ProjectSummary;
