import { InputField } from "../../../../components";
import styled from "styled-components";
import { withStyles } from "@material-ui/styles";
import { ButtonBase } from "@material-ui/core";
import { DefaultWidthCardWrapper } from "../../../../util/theme";

export const SearchInput = styled(InputField).attrs(() => ({
  style: {
    width: 260,
    marginRight: 23,
  },
}))``;

export const SearchButton = withStyles(() => ({
  root: {
    backgroundColor: "#2AA96D",
    padding: "10px 22px",
    color: "white",
    borderRadius: 3,
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.15)",
    fontSize: 14,
    lineHeight: 1.43,
    textAlign: "center",
    fontWeight: 600,
  },
}))(ButtonBase);

export const PermissionCardWrapper = styled(DefaultWidthCardWrapper)`
  border: 1px solid #c9d4db;
  padding: 20px;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

export const StyledStatus = styled.div`
  background: #EDF9F0;
  border: 2px solid #FFFFFF;
  border-radius: 100px;
  padding: 5px 8px
`;

export const PermissionName = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
`;

export const PermissionCount = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #637381;
  margin-bottom: 15px;
`;

export const PermissionDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #637381;
  margin-bottom: 20px;
  flex: 1;
`;

export const PermissionUser = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #212b36;
`;
