import React from "react";
import { Grid, Box, Typography, Tooltip } from "@material-ui/core";
import moment from "moment";

import { ContractDetailTabText } from "./style";
import { formatCurrency } from "../../../util/formatCurrency";

const AwardedCompanyTab = ({ contractOCDS }) => {
  const awardedCompany = contractOCDS.bidders.filter((bid) => bid.isRecommended)[0];

  console.log("awardedCompany ", awardedCompany, contractOCDS);

  const details = [
    { title: "Date of Award", value: moment(contractOCDS.awardedDate).format("DD MMMM, yyyy") || "Not Applicable" },
    { title: "Project Title", value: contractOCDS.title || "Not Applicable" },
    { title: "Project Description", value: contractOCDS.projectDescription || "Not Applicable", maxLength: 120 },
    // { title: "Budget Description	", value: contractOCDS.budgetDescription || "Not Applicable" },
    // { title: "Award Remark/Criteria	", value: awardedCompany?.comment || "Not Applicable" },
    { title: "Contract Sum", value: formatCurrency(contractOCDS.contractAmount, true) || "Not Applicable" },
    { title: "Contract Duration", value: contractOCDS.contractDuration || "Not Applicable" },
    { title: "Contractor", value: contractOCDS.awardedContractor || "Not Applicable" },
  ];

  return (
    <>
    <Box style={{ background: "#e4e9ec", padding: 20 }}>
      <Typography variant="h6" style={{ color: "#5F7483" }}>
        {contractOCDS?.title}
      </Typography>
    </Box>
      <Box>
        {details.map((detail, i) => (
          <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
            <Grid item xs={3}>
              <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
                {detail.title}
              </ContractDetailTabText>
            </Grid>
            <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
              {detail.maxLength ? (
                <Tooltip title={detail.value} aria-label="add">
                  <ContractDetailTabText>
                    {detail.value.length > detail.maxLength ? `${detail.value.substr(0, detail.maxLength)}...` : detail.value}
                  </ContractDetailTabText>
                </Tooltip>
              ) : (
                <ContractDetailTabText>{detail.value}</ContractDetailTabText>
              )}
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default AwardedCompanyTab;
