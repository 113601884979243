/* eslint-disable no-undef */
import React, { useState, useEffect, useContext } from "react";
import { useSelector } from "react-redux";
import AddNewDocuments from "./AddNewDocuments";
import DocumentGroups from "./DocumentGroups";
import NavigationButtons from "../../NavigationButtons";
import {
  postDocuments, getDocumentGroups, getDocuments, deleteDocuments,
} from "../../../redux/reducer/vendorReducer";
import withVendorRegLoader from "../../../pages/Common/HOC/withVendorRegLoader";
import ToastContext from "../../../util/toastContext";

const Form = ({
  dispatch, userId,
}) => {
  const [openModal, setOpenModal] = useState(false);
  const [activeGroup, setActiveGroup] = useState("PPA");
  const [documents, setDocuments] = useState({});
  const { documentsGroups, vendorDocuments } = useSelector((state) => state.vendor);
  const { showAlert } = useContext(ToastContext);

  useEffect(() => {
    const newObj = {};

    documentsGroups.forEach((obj) => {
      newObj[obj.id] = [...vendorDocuments.filter((doc) => doc.vendorDocumentTypeId === obj.id)];
    });

    setDocuments(newObj);
  }, [documentsGroups, vendorDocuments]);

  const toggleDrawer = (value) => {
    setOpenModal((previous) => !previous);
    setActiveGroup(value);
  };

  const handleDelete = (id) => {
    dispatch(deleteDocuments({ userId, id }))
      .then((res) => {
        if (res.error) {
          return showAlert({ severity: "error", message: res.payload });
        }
        showAlert({ severity: "success", message: res.payload.message });
      });
  };

  const canProceed = Object.values(documents).reduce((acc, docs, index) => {
    if ((index === 0 || index === 1 || index === 3) && docs.length === 0) {
      acc = false
    }
    return acc;
  }, true)

  const makeRequest = (file, group) => {
    const formData = new FormData();
    formData.append("vendorDocumentTypeId", group);
    formData.append("file", file[0]);
    formData.append("name", file[0]?.name || "");
    formData.append("description", file[0]?.description || "");

    return dispatch(postDocuments({ data: formData, userId }))
      .then((res) => {
        if (res.error) {
          return showAlert({ severity: "error", message: res.payload });
        }
        showAlert({ severity: "success", message: "Documents uploaded successfully" });
        toggleDrawer(group);
      });
  };

  return (
    <>
      <DocumentGroups
        handleDelete={handleDelete}
        documents={documents}
        toggleDrawer={toggleDrawer}
        documentGroups={documentsGroups}
      />
      {
        openModal
        && <AddNewDocuments
          isOpen={openModal}
          toggleDrawer={toggleDrawer}
          activeGroup={activeGroup}
          documents={documents}
          setDocuments={setDocuments}
          makeRequest={makeRequest}
          description={documentsGroups.find((document) => document.id === activeGroup).title}
        />
      }

      {canProceed === false ? <div style={{ marginTop: 100 }}></div> : <NavigationButtons />}
    </>
  );
};

const DocumentSubmission = () => {
  const { user: { UserId } } = useSelector((state) => state.user);

  return withVendorRegLoader(Form)({
    title: "Documents Submission",
    apis: [getDocumentGroups(), getDocuments(UserId)],
    userId: UserId,
    conditioners: ["documentsGroups", "vendorDocuments"],
  });
};

export default DocumentSubmission;
