import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useHistory, matchPath } from "react-router-dom";
import LogoutIcon from "@material-ui/icons/ExitToApp";

import { MenuItemWrapper, MenuItem, LogoutWrapper, CustomMenuItemWrapper, MenuChildrenWrapper } from "./style";
import { menus } from "./mock";
import { logoutFn } from "../../util/logout";

const Sidebar = () => {
  const { pathname } = useLocation();
  const history = useHistory();
  const [active, setActive] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    const page = pathname.split("/");

    menus.forEach((menu) => {
      if (menu.children.map(el => el.route).includes(page[page.length - 1])) {
        return setActive(menu.title);
      }
    });
  }, [pathname]);

  const CustomMenuItem = ({ to, title, children, icon, check }) => {
    const _to = children.length ? `${to}/${children[0].route}` : to;
    const matchRoute = matchPath(pathname, { path: check, exact: false });
    const routeIsActive = Boolean(matchRoute);

    return (
      <>
        <CustomMenuItemWrapper active={routeIsActive.toString()}>
          {icon}
          <MenuItem
            to={_to}
            onClick={() => setActive(title)}
            active={routeIsActive.toString()}
          >
            {title}
          </MenuItem>
        </CustomMenuItemWrapper>
        {children.length > 0 &&
          (active === title ? (
            <MenuChildrenWrapper>
              {children.map((child, i) => (
                <MenuItem to={`${to}/${child.route}`} active={pathname.includes(child.route).toString()} key={`${child.route}-${i}`}>
                  {child.name}
                </MenuItem>
              ))}
            </MenuChildrenWrapper>
          ) : null)}
      </>
    )
  };

  return (
    <>
      <MenuItemWrapper>
        {menus.map((menu, i) => (
          <CustomMenuItem {...menu} key={`${menu.title}-${i}`} />
        ))}
      </MenuItemWrapper>
      <LogoutWrapper
        onClick={() => {
          logoutFn(dispatch);
          return history.push("/login");
        }}
      >
        <LogoutIcon />
        <MenuItem to="#">Logout</MenuItem>
      </LogoutWrapper>
    </>
  );
};

export default Sidebar;
