import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import React, { useState } from "react";
import { YearPickerWrapper } from "./style";

const YearPicker = ({ label, id, style, handleChange, ...rest }) => {
  const [selectedYear, setSelectedYear] = useState(rest.value);

  const handleYearChange = (year) => {
    setSelectedYear(year);
    handleChange && handleChange(year);
  };
  return (
    <YearPickerWrapper>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          views={["year"]}
          placeholder="YYYY"
          style={style}
          label={label}
          id={id}
          value={selectedYear}
          InputLabelProps={{
            shrink: true
          }}
          onChange={handleYearChange}
          variant="inline"
          orientation="portrait"
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </YearPickerWrapper>
  );
};

export default YearPicker;
