import React, { useState } from "react";
import moment from "moment";
import { Box } from "@material-ui/core";
import PropTypes from "prop-types";
import ChatMessage from "../ChatMessage";
import FileProgress from "../FileProgress/FileProgress";
import { ChatBoxWrapper, FileLabel } from "./style";
import CommentInput from "../CommentInput/CommentInput";

const ChatBox = ({ messages, onSubmit, onUploadFile }) => {
  const [comment, setComment] = useState("");

  const sortedMessage = () =>
    messages
      .map((message) => {
        return {
          ...message,
          createdAt: moment(message.createdAt).valueOf(),
        };
      })
      .sort(({ createdAt: aCreatedAt }, { createdAt: bCreatedAt }) => aCreatedAt - bCreatedAt);

  // eslint-disable-next-line no-underscore-dangle
  const _onSubmit = async () => {
    const prevComment = comment;
    setComment("");

    // The onSubmit function should return a true or false based on whether the request was successful or not
    const stayWiped = await onSubmit(comment);
    if (!stayWiped) {
      setComment(prevComment);
    }
  };

  const renderMessage = (node, index) => {
    switch (node.type) {
      case "message":
        return (
          <Box key={`message-${index}`} mb={1.5}>
            <ChatMessage key={`message-${index}`} author={node.author || "No name"} date={node.createdAt} status={node.status}>
              {node.message}
            </ChatMessage>
          </Box>
        );
      case "COMMENT":
        return (
          <Box key={`message-${index}`} mb={1.5}>
            <ChatMessage key={`message-${index}`} author={node.fullName || "No name"} date={node.createdAt} status={node.status}>
              {node.body}
            </ChatMessage>
          </Box>
        );
      case "file":
        return (
          <Box key={`file-${index}`} display="inline-flex" flexDirection="column" mb={1} ml={6.875} width={"100%"} maxWidth={500}>
            <FileLabel>{node.fileLabel}</FileLabel>
            <FileProgress fileName={node.file.name} fileSize={node.file.size} progress={30} />
          </Box>
        );
      default:
        throw new Error(`Unknown node type: ${node.type}`);
    }
  };

  return (
    <ChatBoxWrapper>
      {sortedMessage().map(renderMessage)}
      <Box mt={3.75}>
        <CommentInput value={comment} onChange={setComment} onSubmit={_onSubmit} onUploadFile={onUploadFile} />
      </Box>
    </ChatBoxWrapper>
  );
};

ChatBox.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(["message", "file"]),
      author: PropTypes.string.isRequired,
      message: PropTypes.string.isRequired,
      date: PropTypes.string,
      file: PropTypes.shape({
        name: PropTypes.string,
        size: PropTypes.number,
        progress: PropTypes.number,
      }),
      fileLabel: PropTypes.string,
    })
  ),
  onSubmit: PropTypes.func.isRequired,
  onUploadFile: PropTypes.func.isRequired,
};

export default ChatBox;
