import styled from "styled-components";
import { Button } from "@material-ui/core";
import { FormSubtitle } from "../../../util/theme";
import { ReactComponent as FolderDownIcon } from "../../../assets/folder_down_icon.svg";
import { ReactComponent as InfoIcon } from "../../../assets/info_icon.svg";
import { ReactComponent as ForwardArrowIcon } from "../../../assets/forward_arrow.svg";
import { ReactComponent as CardPaymentIcon } from "../../../assets/credit_card_icon.svg";
import { SaveButton } from "../../../components/Forms/Common/style";

export const DownloadButton = styled(Button)`
  box-sizing: border-box !important;
  height: 53px;
  width: 320px;
  border: 1px solid #DFE3E8 !important;
  border-radius: 3px !important;
  background-color: #FFFFFF !important;
  justify-content: space-between !important;
  margin-bottom: 10px !important;
`;

export const Text = styled(FormSubtitle)`
  font-weight: 800 !important;
`;

export const InfoIconStyle = styled(InfoIcon)`

`;

export const FolderIcon = styled(FolderDownIcon)`
  background-color: #E3D0FF;
  padding: 9px;
  border-radius: 3px;
`;

export const ForwardIcon = styled(ForwardArrowIcon)`
  width: 20px;
  fill: ${({ iconColor }) => iconColor};
`;

export const DocumentWrapper = styled.div`
  border-radius: 3px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #DFE3E8;
  background-color: #FFFFFF;
  padding: 8.5px 15px 8.5px 8.5px;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const CardIcon = styled(CardPaymentIcon)`
  background-color: #FCF1CD;
  padding: 9px;
  width: 22px;
  fill: #eec200;
  border-radius: 3px;
`;

export const WrapContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const WrapPaymentContent = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const RegisterButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;
`;

export const RegisterButton = styled(SaveButton)`
  height: 41px;
  width: 132px;
`;
