import styled from 'styled-components'
import { StyledTableRow } from "../SearchTable";
import { theme } from "../../util/theme";

export const VendorTableStyle = styled(StyledTableRow)`
  &:hover {
    border-left: 5px solid transparent;
    border-left-color: ${theme.color.primary};
    cursor: pointer;
  }
`;

export const DrawerWrapper = styled.div`
width: 485px;
padding: 30px;
display: flex;
flex-direction: column;
height: 100vh;
justify-content: space-between;

.bold{
   color: ${theme.color.textMain};
   font-weight: 700; 
}
`;

export const VendorDetails = styled.div`
display: flex;
align-items: center;
margin-top: 20px;

.avatar {
    width: 20px;
    height: 20px;
    background: ${theme.color.primary};
    border-radius: 50%;
    margin-right: 5px;
    color: white;
    font-size: 14px;
    padding: 10px;

}

`;