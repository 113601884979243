import React, { useState, useEffect } from "react";
import { Box, TableRow, TableCell } from "@material-ui/core";
import { format } from "date-fns";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import moment from "moment";

import {
  CardContentTitle,
  CardContentValue,
  CardContentValueDesc,
  CardContentValueFlag,
  PageContainer,
  PageTitle,
  PageTitleContainer,
  StyleTableCellDesc,
  StyleTableCellId,
  TableStatusContainer,
} from "../../Common/style";
import { CardWrapper, HoverTableStyle } from "../../../util/theme";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import { Dropdown, InputField } from "../../../components";
import SearchTable, { StyledTableCell } from "../../../components/SearchTable";
import TableEmptyContent from "../../Common/TableEmptyContent";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import procurementAPI from "../../../redux/api/procurementAPI";
import useAlert from "../../../hooks/useAlert";
import withVendorDashboard from "../HOC/withVendorDashboard";
import Loader from "../../../components/Common/Loader";

export const pickStatus = (status) => {
  switch (status.toUpperCase()) {
    case "INTERESTED":
      return (
        <TableStatusContainer textcolor="#707070" bgcolor="#ebeff2">
          {status}
        </TableStatusContainer>
      );
    case "RECOMMENDED":
      return (
        <TableStatusContainer textcolor="#118043" bgcolor="#D6EFD2">
          {status}
        </TableStatusContainer>
      );
    case "NOTRECOMMENDED":
      return (
        <TableStatusContainer textcolor="#DD5E47" bgcolor="#FFC8BE">
          NOT RECOMMENDED
        </TableStatusContainer>
      );
    case "EXPIRED":
    case "REJECTED":
      return (
        <TableStatusContainer textcolor="#DD5E47" bgcolor="#FFC8BE">
          {status}
        </TableStatusContainer>
      );
    case "PROCESSING":
      return (
        <TableStatusContainer textcolor="#BF6712" bgcolor="#FFE2C5">
          {status}
        </TableStatusContainer>
      );
    case "SHORTLISTED":
      return (
        <TableStatusContainer textcolor="#FFFFFF" bgcolor="#72a3ed">
          {status}
        </TableStatusContainer>
      );
    default:
      return (
        <TableStatusContainer textcolor="#637381" bgcolor="#CBD4DA">
          NOT STARTED
        </TableStatusContainer>
      );
  }
};

const VendorBid = () => {
  const [title, setTitle] = useState(null);
  const [process, setProcess] = useState(null);
  const [status, setStatus] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);

  const { user } = useSelector((state) => state);

  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10);
  // eslint-disable-next-line
  const [pageNumber, setPageNumber] = useState(1);

  const { showAlert } = useAlert();

  const payload = {
    title,
    process,
    status,
    expiryDate,
    pageNumber,
    pageSize,
    vendorId: user.user.UserId,
  };

  const {
    status: typesStatus,
    // eslint-disable-next-line
    data: data = [],
  } = useQuery("procurementTypes", procurementAPI.getAllProcurementProcessTypes);

  const queryKey = ["getAllBids", payload];

  const { status: errorStatus, error, data: vendorData, isLoading: isVendorDataLoading } = useQuery(
    queryKey,
    vendorsAPI.getBids,
    {
      enabled: !!payload?.vendorId,
    }
  );


  const { status: bidErrorStatus, data: bidData = [], error: summaryError } = useQuery(
    ["bidSummaryResponse", user.user.UserId],
    vendorsAPI?.fetchVendorBidSummary,
    {
      enabled: !!payload.vendorId,
    }
  );

  useEffect(() => {
    if (bidErrorStatus === "error" || typesStatus === "error" || errorStatus === "error") {
      showAlert({
        severity: "error",
        message: summaryError?.message || error?.message,
        durationInMs: 3000,
      });
    }
  }, [bidErrorStatus, summaryError, showAlert, typesStatus, errorStatus, error]);

  const history = useHistory();

  const BidTableBody = ({ history: historyData }) =>
    vendorData?.data?.map((eachBid) => {
      return (
        <HoverTableStyle key={eachBid.id} onClick={() => historyData.push(`/vendor/bids/${eachBid.procurementId}`)}>
          <StyledTableCell>
            <StyleTableCellId>{eachBid?.packageNumber || "DT-101"}</StyleTableCellId>
            <StyleTableCellDesc>{eachBid?.title || "Demo Title N/A"}</StyleTableCellDesc>
          </StyledTableCell>
          <StyledTableCell>{eachBid?.process || "N/A"}</StyledTableCell>
          <StyledTableCell>{pickStatus(eachBid?.bidStatus || "N/A")}</StyledTableCell>
          <StyledTableCell>
            {(eachBid.expiryDate && moment(eachBid.expiryDate).format("DD, MMMM, yyyy")) || "N/A"}
          </StyledTableCell>
          <StyledTableCell>N{eachBid?.value ? eachBid?.value : "/A"}</StyledTableCell>
        </HoverTableStyle>
      );
    });

  const loader = () => {
    if (isVendorDataLoading) {
      return (
        <TableRow>
          <TableCell colSpan={5}>
            <Loader />
          </TableCell>
        </TableRow>
      );
    }
    if (vendorData?.data?.length === 0) {
      return <TableEmptyContent noColSpan={5} textDisplayed="No Bid Activities" />;
    }
  };

  const mappedProcessTypes = data
    .map((item) => ({
      label: item.name,
      value: item.description,
    }))
    .filter((item) => item.label && item.value);

  const VendorBidSummaryCard = () => {
    return (
      <Box>
        <CardContentTitle>Total Bids</CardContentTitle>
        <CardContentValue>{bidData.total || 0}</CardContentValue>
        <CardContentValueDesc>All active and inactive</CardContentValueDesc>
        <Box display="flex" justifyContent="space-between">
          <CardContentValueFlag textcolor="#108043" bgcolor="#D6EFD2">{`${
            bidData.approved || 0
          } Recommended`}</CardContentValueFlag>
          <CardContentValueFlag textcolor="#BF6712" bgcolor="#FFE2C5">{`${
            bidData.processing || 0
          } Processing`}</CardContentValueFlag>
          <CardContentValueFlag textcolor="#FFFFFF" bgcolor="#72a3ed">{`${
            bidData.shortlisted || 0
          } Shortlisted`}</CardContentValueFlag>
          <CardContentValueFlag textcolor="#DD5E47" bgcolor="#FFC8BE">{`${bidData.rejected || 0} Rejected`}</CardContentValueFlag>
          <CardContentValueFlag textcolor="#637381" bgcolor="#CBD4DA">{`${
            bidData.notStarted || 0
          } Not Started`}</CardContentValueFlag>
        </Box>
      </Box>
    );
  };

  return (
    <PageContainer>
      <PageTitleContainer>
        <PageTitle>Bids</PageTitle>
      </PageTitleContainer>

      <CardWrapper>
        <VendorBidSummaryCard />
      </CardWrapper>

      <AdminSearchFilter normalSearch={true}>
        <Box display="flex" justifyContent="space-between">
          <InputField onBlur={(e) => setTitle(e.target.value)} label="Search ID. Title" style={{ width: "100%" }} />

          <Dropdown
            name="byProcess"
            label="By Process"
            placeHolder="test"
            values={[
              {
                label: "Select a Process",
                value: "",
              },
              ...mappedProcessTypes,
            ]}
            style={{ width: "100%" }}
            onChange={(e) => {
              setProcess(e);
            }}
          />
          <Dropdown
            label="By Bid Status"
            values={[
              {
                label: "Select a Bid Status",
                value: null,
              },
              {
                label: "Recommended",
                value: 1,
              },
              { label: "Evaluated", value: 2 },
              { label: "Rejected", value: 3 },
              { label: "Processing", value: 4 },
              { label: "Not Started", value: 5 },
              { label: "Short listed", value: 8 },
            ]}
            onChange={(e) => {
              setStatus(e);
            }}
            style={{ width: "100%" }}
          />
          <InputField
            type="date"
            onBlur={(e) => setExpiryDate(e.target.value)}
            defaultValue={format(new Date(), "yyyy-MM-dd")}
            label="Expiry Date"
            style={{ width: "100%" }}
          />
        </Box>
      </AdminSearchFilter>

      <SearchTable
        rows={vendorData?.data?.length}
        columns={["REF ID/TITLE", "PROCESS", "BID STATUS", "EXPIRY DATE", "VALUE"]}
        tableHeaderText={`Showing ${vendorData?.data?.length} Results ` || "Showing 0 Result"}
        page={pageNumber}
        setPage={setPageNumber}
        rowsPerPage={pageSize}
        setRowsPerPage={setPageSize}
        totalNumberOfPages={vendorData?.meta?.pagination?.totalEntries}
      >
        {vendorData?.data?.length > 0 && <BidTableBody history={history} />}
        {loader()}
      </SearchTable>
    </PageContainer>
  );
};

export default withVendorDashboard(VendorBid);
