import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const BreadcrumbLabel = styled(Typography)`
  height: 16px;
  margin-top: 35px !important;
  margin-bottom: 35px !important;
  color: #adb4bb;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  flex-direction: row;
`;

export const BreadcrumbActive = styled.span`
  color: #6d7278;
  font-size: 14px !important;
  font-weight: 600;
  margin-left: 8px !important;
`;

export const PaginationWrapper = styled.div`
  box-sizing: border-box;
  height: 51px;
  border: 1px solid #e5e9ec;
  background-color: #ffffff;
  margin-bottom: 40px;
`;

export const UnstyledLink = styled(Link)`
  text-decoration: none;
`;

export const ClearButton = styled.button`
  background-color: transparent;
  color: #2AA96D;
  font-size: 16px;
  text-decoration: underline;
  margin-left: auto;
  border: none;
  text-align: right;
  width: 100%;
  cursor: pointer;
`;