import React from "react";
import { Grid } from "@material-ui/core";

import InputField from "../InputField";
import { ErrorContainer } from "../Forms/Common/style";

export default function ResetPassword({ useFormUtils }) {
    const { register, errors, getValues } = useFormUtils;
    const onRenderError = (error) => <ErrorContainer style={{ color: "red" }}> {error && error.message} </ErrorContainer>;
    // const classes = useStyles();

    const width = "100%";

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
                <InputField
                    type="password"
                    label="Current Password"
                    name="currentPassword"
                    auto
                    style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
                    inputRef={register}
                />
            </Grid>
            <Grid item xs={12} sm={4}>
                <InputField
                    type="password"
                    label="New Password"
                    name="newPassword"
                    style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
                    inputRef={register({
                        pattern: {
                            value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{8,}$/,
                            message:
                                "Password must contain at least (1) lower case, (1) capital letter, (1) numeric number and a minimum of 8 characters",
                        },
                    })
                    }
                />
                {onRenderError(errors.newPassword)}
            </Grid>
            <Grid item xs={12} sm={4}>
                <InputField
                    type="password"
                    label="Confirm New Password"
                    name="confirmNewPassword"
                    style={{ width, margin: 0, marginBottom: 15, padding: 0 }}
                    inputRef={register({
                        validate: (value) => {
                            if (value === getValues().newPassword) {
                                return true;
                            }
                            return "The passwords do not match";
                        },
                    })
                    }
                />
                {onRenderError(errors.confirmNewPassword)}
            </Grid>
        </Grid>
    );
}
