import React from "react";
import { Grid, Box, Typography, Tooltip } from "@material-ui/core";
import moment from "moment";

import { ContractDetailTabText } from "./style";
import { formatCurrency } from "../../../util/formatCurrency";

const ContractDetailTab = ({ contractOCDS }) => {
  const contractsDate = !!contractOCDS.tenderPeriodStartDate && !!contractOCDS.tenderPeriodEndDate;

  const details = [
    { title: "Project Title", value: contractOCDS?.title || "Not Applicable" },
    { title: "Project Description", value: contractOCDS.projectDescription || "Not Applicable", maxLength: 120 },
    // { title: "Budget Description", value: contractOCDS?.budgetDescription || "Not Applicable" },
    { title: "Project Year", value: (contractOCDS.budgetYear) || "Not Applicable" },
    { title: "Budget Source", value: (contractOCDS.budgetSource) || "Not Applicable" },
    { title: "Budget Amount	", value: contractOCDS.budgetAmount ? formatCurrency(contractOCDS.budgetAmount, true) : "Not Applicable" },
    { title: "Procurement Method	", value: contractOCDS?.procurementMethod || "Not Applicable" }, // depends on busgdget desc
    // {
    //   title: "Tender Period	",
    //   value: contractsDate ? `${moment(contractOCDS.tenderPeriodStartDate).format("DD MMMM, yyyy")} to ${moment(contractOCDS.tenderPeriodEndDate).format(
    //     "DD MMMM, yyyy"
    //   )} ` : "Not Applicable"
    // },
    { title: "Procuring Entity / SubSector	", value: contractOCDS?.ministryName || "Not Applicable" },
    { title: "Procurement Category	", value: contractOCDS.procurementCategory || "Not Applicable" },

    // { title: "Bid Solicitation Date	", value: contractOCDS.bidSolicitationDate ? moment(contractOCDS.bidSolicitationDate).format("DD MMMM, yyyy") : "Not Applicable" },
    // { title: "Bid Opening Date		", value: contractOCDS.bidOpeningDate ? moment(contractOCDS.bidOpeningDate).format("DD MMMM, yyyy") : "Not Applicable" },
  ];

  return (
    <>
      <Box style={{ background: "#e4e9ec", padding: 20 }}>
        <Typography variant="h6" style={{ color: "#5F7483" }}>
          {contractOCDS?.title}
        </Typography>
      </Box>
      {details.map((detail, i) => (
        <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
          <Grid item xs={3}>
            <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
              {detail.title}
            </ContractDetailTabText>
          </Grid>
          <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
            {detail.maxLength ? (
              <Tooltip title={detail.value} aria-label="add">
                <ContractDetailTabText>
                  {detail.value.length > detail.maxLength ? `${detail.value.substr(0, detail.maxLength)}...` : detail.value}
                </ContractDetailTabText>
              </Tooltip>
            ) : (
              <ContractDetailTabText>{detail.value}</ContractDetailTabText>
            )}
          </Grid>
        </Grid>
      ))}
    </>
  );
};

export default ContractDetailTab;
