import React from "react";
import DocumentUploadField from "../../../../../components/DocumentUploadField";
import { TitleContainer, BorderedBox } from "./style";

const LetterOfIntentUpload = ({ documents, setDocuments, disabled }) => {
  const removeFile = (file) => {
    setDocuments([]);
  };

  const addFiles = (newDocuments) => {
    setDocuments(newDocuments);
  };

  return (
    <div>
      <TitleContainer>Upload Letter of Intent</TitleContainer>

      <BorderedBox>
        <DocumentUploadField disabled={disabled} addFiles={addFiles} removeFile={removeFile} files={documents} multiple={false} />
      </BorderedBox>
    </div>
  );
};

export default LetterOfIntentUpload;
