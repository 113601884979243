import Drawer from "@material-ui/core/Drawer";
import Tab from "@material-ui/core/Tab";
import Tabs from "@material-ui/core/Tabs";
import React, { useState } from "react";
import { ErrorContainer, SaveButton } from "../Forms/Common/style";
import {
  ButtonContainer, DashboardCancelButton, DashboardDrawerWrapper, DashboardFormTitle, TabDivider,
} from "./style";
import AddNewMinistry from "../AddNewMinistry";
import BulkUpload from "../BulkUpload";

const DashboardDrawer = ({ openModal, toggleDrawer }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  const renderButton = ({ TextLabel, handleClick, disabled }) => (
    <ButtonContainer>
      <DashboardCancelButton onClick={() => toggleDrawer()}>Close</DashboardCancelButton>
      <SaveButton disabled={disabled} type="submit" onClick={(e) => handleClick(e)} style={{ width: "122px" }}>
        {TextLabel}
      </SaveButton>
    </ButtonContainer>
  );

  const renderTabContent = (tabIndex) => {
    switch (tabIndex) {
      case 1:
        return <BulkUpload RenderButton={renderButton} isMinistry={true} />;

      default:
        return <AddNewMinistry onRenderError={onRenderError} handleCancel={() => toggleDrawer()}/>;
    }
  };

  return (
    <>
      <Drawer anchor="right" open={openModal}>
        <DashboardDrawerWrapper>
          <DashboardFormTitle> Add new ministry</DashboardFormTitle>
          <Tabs value={value} onChange={handleChange}>
            <Tab label="Single" />
            <Tab label="Bulk upload" />
          </Tabs>
          <TabDivider />
          {renderTabContent(value)}
        </DashboardDrawerWrapper>
      </Drawer>
    </>
  );
};

export default DashboardDrawer;
