import React from "react";
import { Grid } from "@material-ui/core";

import { TitleContainer } from "../../style";
import { PageTitle, PageWrapper } from "../../../../Common/style";
import { ReactComponent as CheckIcon } from "../../../../../assets/checkIcon.svg";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import {
  BoldCardContent,
  CardBody,
  CardContent,
  CardLabel,
  CardSection,
  Divider,
  SerialText,
  TopSection,
} from "../../../PlanDetails/style";
import Tag from "../../../../../components/Tag";
import { Quotation, ContractDocument, CheckedIconWrapper, ContractDocumentSection } from "./style";
import FileProgress from "../../../../../components/FileProgress/FileProgress";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import { CardWrapper } from "../../../../../util/theme";
import { useQuery } from "react-query";
import { useParams } from "react-router-dom";
import procurementAPI from "../../../../../redux/api/procurementAPI";
import { openFileUrlInNewWindow } from "../../../../Common/util";
import RenderComponent from "../../../../../components/RenderComponent";
import UploadContractDocument from "../../GeneralPlanDetails/UploadContractDocument";
import AccessControl from "../../../../../components/AccessControl";
import { Roles } from "../../../../../components/AccessControl/UserRoles";

const svgProps = {
  width: 14,
  height: 14,
  fill: "white",
};

const LegalDocumentSigning = () => {
  const { procurementId } = useParams();
  const { data: unsignedContractForMOJ } = useQuery(
    ["mojUnsignedContractDoc", { procurementId, objectType: 5 }],
    procurementAPI.getVendorSignedContract
  );
  const { data: signedContract } = useQuery(["mojSignedContractDoc", { procurementId }], procurementAPI.getVendorSignedContract);
  const { data: recommendedVendor } = useQuery(["ContractRecommendedVendor", procurementId], procurementAPI.getRecommendedVendor);

  const GeneralPlanLinks = [
    { url: "#", title: "Home" },
    { url: "#", title: "Plans" },
  ];

  const formatValue = (value) => {
    if (value) {
      return "Yes"
    }
    else {
      return "No"
    }
  }

  return (
    <PageWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Contract Signing</PageTitle>
          <Breadcrumb values={GeneralPlanLinks} />
        </div>
      </TitleContainer>
      <CardWrapper width={"100%"}>
        <TopSection>
          <Tag text="Recommended" color="green" size={"large"} />
          <SerialText>{recommendedVendor?.companyName || "N/A"}</SerialText>
        </TopSection>
        <Divider />
        <CardSection style={{ marginTop: 20 }}>
          <Quotation>
            <div>
              <CardLabel>Quotation</CardLabel>
              <BoldCardContent>{recommendedVendor?.quotation?.toLocaleString() || "N/A"}</BoldCardContent>
            </div>
            <p>{recommendedVendor?.documentCount || 0} Attachments</p>
          </Quotation>
        </CardSection>
        <Divider />
        <CardBody>
          <CardSection>
            <CardLabel>Tax Clearance</CardLabel>
            <CardContent>{formatValue(recommendedVendor?.taxClearance)}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>KSIR Card</CardLabel>
            <CardContent>{formatValue(recommendedVendor?.dsirCard)}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Company Memorandum</CardLabel>
            <CardContent>{formatValue(recommendedVendor?.companyMemo)}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Company Profile</CardLabel>
            <CardContent>{formatValue(recommendedVendor?.companyProfile)}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Past Jobs</CardLabel>
            <CardContent>{formatValue(recommendedVendor?.pastJobs)}</CardContent>
          </CardSection>
        </CardBody>
        <div style={{ marginTop: 20 }}>
          <BoldCardContent style={{ marginBottom: 10 }}>Contract Documents</BoldCardContent>
          <ContractDocumentSection
            style={{
              marginBottom: 20,
            }}
          >
            <CardSection>
              <BoldCardContent style={{ marginBottom: 10 }}> Unsigned Contracts</BoldCardContent>
              <ContractDocument>
                <RenderComponent condition={unsignedContractForMOJ?.length > 0}>
                  <Grid container spacing={2}>
                    {unsignedContractForMOJ?.map((eachDoc, index) => (
                      <Grid item xs={4} key={index}>
                        <FileProgress
                          hasEyes
                          onClickEyes={() => openFileUrlInNewWindow(eachDoc?.file?.secure_url)}
                          fileName={eachDoc?.name}
                          fileSize={eachDoc?.file?.bytes}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RenderComponent>
                <RenderComponent condition={unsignedContractForMOJ?.length === 0}>
                  <CardLabel>Awaiting contract draft from Ministry of Justice</CardLabel>
                </RenderComponent>
                <CheckedIconWrapper isSigned={unsignedContractForMOJ?.length > 0}>
                  <CheckIcon {...svgProps} />
                </CheckedIconWrapper>
              </ContractDocument>
            </CardSection>
          </ContractDocumentSection>
          <ContractDocumentSection>
            <CardSection>
              <BoldCardContent style={{ marginBottom: 20 }}>Signed Contract</BoldCardContent>
              <ContractDocument>
                <RenderComponent condition={signedContract?.length > 0}>
                  <Grid container spacing={2}>
                    {signedContract?.map((eachDoc, index) => (
                      <Grid item xs={4} key={index}>
                        <FileProgress
                          hasEyes
                          onClickEyes={() => openFileUrlInNewWindow(eachDoc?.file?.secure_url)}
                          fileName={eachDoc?.name}
                          fileSize={eachDoc?.file?.bytes}
                        />
                      </Grid>
                    ))}
                  </Grid>
                </RenderComponent>
                <RenderComponent condition={signedContract?.length === 0}>
                  <CardLabel>Awaiting signed contract document from Vendor</CardLabel>
                </RenderComponent>
                <CheckedIconWrapper isSigned={signedContract?.length > 0}>
                  <CheckIcon {...svgProps} />
                </CheckedIconWrapper>
              </ContractDocument>
            </CardSection>
          </ContractDocumentSection>
        </div>
      </CardWrapper>

      <AccessControl allowedRoles={[Roles.MINISTRY_OF_JUSTICE]}>
        {unsignedContractForMOJ?.length > 1 || !unsignedContractForMOJ ?
          <div /> :
          <UploadContractDocument />
        }
      </AccessControl>
    </PageWrapper>
  );
};
export default withAdminDashboard(LegalDocumentSigning);
