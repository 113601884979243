import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { ProcurementInformationContainer } from "../BidDetails/BidInfo/style";

export const ReportCardContainer = styled(ProcurementInformationContainer)`
  background: ${({ bgcolor }) => (bgcolor === "true" ? "#2AA96D" : "#ffffff")};

  p:not(.position-content) {
    color: ${({ bgcolor }) => (bgcolor === "true" ? "#ffff" : "#000000")};
  }
`;

export const ReportCardTitle = styled(Typography)`
  color: #1E2B37;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 22px !important;
`;

export const ReportCardSubTitle = styled(Typography)`
  color: #1E2B37;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const ReportLineSeparator = styled.hr`
  box-sizing: border-box;
  height: 1px;
  border: 1px solid #CBD4DA !important;
  margin: 20px 0 !important;
`;

export const BulletContainer = styled(Typography)`
  box-sizing: border-box;
  height: 42px;
  width: 42px;
  border-radius: 50%;
  border: 1px solid #637381 !important;
  background-color: #FFFFFF;
  padding: 10px 10px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px !important;
`;

export const StatusBanner = styled(Typography)`
  border-radius: 20px;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor} !important;
  text-align: center;
  font-size: 12px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px !important;
  padding: 0 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 22px;
`;
