import React, { useContext, useState } from "react";
import { Box, Grid, CardContent, Typography } from "@material-ui/core";
import { ContentWrapper } from "./style";
import { useParams } from "react-router-dom";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import SearchTable from "../../../../../../components/SearchTable";
import ToastContext from "../../../../../../util/toastContext";
import { GeneralTitlePage } from "../../SpecificProcurementNotice/style";
import { useQuery } from "react-query";
import { SummaryCard } from "./style";
import StaffTable from "./EvaluationTable";
import { getStaff } from "../../../../../../redux/reducer/adminReducer";
import EvaluationTab from "./EvaluationTab";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";

const TAB_VALUES = {
  PENDING: "pending",
  REVIEWED: "reviewed"
};

const FinancialReviews = () => {
  const [tabValue, setTabValue] = useState(TAB_VALUES.PENDING);
  const { showAlert } = useContext(ToastContext);
  const routeParams = useParams();
  const { procurementId }  = routeParams;

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const { data: financialEvaluationStat = {} } = useQuery({
    queryFn: receiptOfBiddersResponseApi.getFinancialEvalutaionStat,
    queryKey: [
      "technical-evaluation-stat",
      { procurementId },
    ],
    config: {
      onError: (error) => showAlert({
        severity: "error",
        message: error?.message,
      })
    }
  });

  const { data: staffData = {}, isLoading } = useQuery({
    queryFn: receiptOfBiddersResponseApi.getFinancialEvalutaion,
    queryKey: [
      "financial-evaluation",
      {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        procurementId,
        status: tabValue
      },
    ],
    config: {
      onError: (error) => showAlert({
        severity: "error",
        message: error?.message,
      })
    }
  });

  const rows = staffData?.data ?? [];

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const renderSummaryCards = () => {
    return (
      <Grid container spacing={2}>
        {getCardContents(financialEvaluationStat).map((item, index) => (
          <Grid item key={index} style={{minWidth: 150}}>
            <SummaryCard elevation={0}>
              <CardContent style={{ padding: "16px" }}>
                <Typography className="title" variant="body2" gutterBottom>{item.title}</Typography>
                <Typography className="count" variant="h6">{item.count}</Typography>
              </CardContent>
            </SummaryCard>
          </Grid>
        ))}
      </Grid>
    )
  }

  return (
    <ContentWrapper>
      <Box mt={3}>
        <GeneralTitlePage>Financial reviews</GeneralTitlePage>
        <Breadcrumb values={getBreadcrumbs(routeParams)} />
      </Box>

      <Box my={3}>
        {renderSummaryCards()}
      </Box>

      <SearchTable
        tabs={
          <EvaluationTab
            value={tabValue}
            handleChange={handleTabChange}
            tabs={[
              { value: TAB_VALUES.PENDING, label: "Pending Review" },
              { value: TAB_VALUES.REVIEWED, label: "Reviewed" },
            ]}
          />
        }
        rows={rows}
        columns={["name", "quotation", "score", "remarks", "Date", ""]}
        tableAPI={getStaff}
        page={pagination.pageNumber}
        setPage={updatePagination("pageNumber")}
        rowsPerPage={pagination.pageSize}
        setRowsPerPage={updatePagination("pageSize")}
        loading={isLoading}
        totalNumberOfPages={staffData?.pagination?.totalEntries}
        empty={staffData?.pagination?.totalEntries === 0}
        emptyMessage="No financial review for any vendor"
      >
        <StaffTable staff={rows}/>
      </SearchTable>
    </ContentWrapper>
  );
};

const getBreadcrumbs = ({ activityName, activityId,  procurementId }) => ([
  {
    url: "/dashboard/overview",
    title: "Home",
  },
  {
    url: `/admin/procurement/plans`,
    title: "Plans",
  },
  {
    url: `/admin/procurement/activity/${activityName}/${activityId}/${procurementId}`,
    title: "Plan Details",
  },
  {
    url: `#`,
    title: "Financial review",
  },
]);

const getCardContents = data => ([
  {
    title: "Pending Review",
    count: data.totalPending
  },
  {
    title: "Reviewed",
    count: data.totalReviewed
  }
]);

export default FinancialReviews;
