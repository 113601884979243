import React, { useState, useContext } from "react";
import { Grid, Box, Tab, Button, makeStyles, Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";
import { useDropzone } from "react-dropzone";
import { saveAs } from 'file-saver';
import { useMutation, queryCache } from "react-query";

import { PageTitle } from "../../Common/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import withAwardsNTenders from "../../Homepage/HOC/withAwardsNTenders";
import withAdminDashboard from "../HOC/withAdminDashboard";
import AccessControl from "../../../components/AccessControl";
import { Roles } from "../../../components/AccessControl/UserRoles";
import { ContentWrapper, AddButton, TitleContainer } from "../Procurement/style";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogTitleWrapper,
  SubmitButton,
  PrevBtn
} from "../../../components/Forms/ConfirmVerificationModal/style";
import { DropzoneArea } from "../../../components/DocumentUploadField/style";
import FileError from "../../../components/FileUploadField/FileError";
import { MaxFileSize } from "../../../util/constants";
import FileProgress from "../../../components/FileProgress/FileProgress";
import { PageTabs } from "../../../util/theme";
import contractsAPI from "../../../redux/api/contractAPI";
import ToastContext from "../../../util/toastContext";


const CONTRACT_BULK_UPLOAD_FORMAT = `Tender Package Number,MinistryCode,Procuring Entity,Project Title/Description,Year,Procurement Category,Selection Method,Contractor Name,Contract Sum,Date of Award,Contract Duration`;
const BIDDER_BULK_UPLOAD_FORMAT = `Tender Package Number,Bidder,Bid Price`;

const useStyles = makeStyles({
  tabWrapper: {
    textTransform: "none",
  },
  root: {
    minWidth: 90,
  },
});

const OCDS = () => {
  return withAwardsNTenders(null)({ title: "contracts", isAdminPortal: true, })
};

const AdminOCDS = () => {
  const { showAlert } = useContext(ToastContext);
  const [openUpload, setOpenUpload] = useState(false);
  const [contractFile, setContractFile] = useState(null);
  const [biddersFile, setBiddersFile] = useState(null);
  const [tabValue, setTabValue] = useState(0);
  const classes = useStyles();
  const fileMaxSize = MaxFileSize;
  const GeneralPlanLinks = [{ url: "/admin/dashboard/overview", title: "Overview" }];

  const contractDropzone = useDropzone({
    onDrop: (files) => {
      setContractFile(files[0]);
      if (!biddersFile) {
        setTabValue(1);
      }
    },
    multiple: false,
    validator: fileSizeValidator
  });

  const bidderDropzone = useDropzone({
    onDrop: (files) => {
      setBiddersFile(files[0]);
    },
    multiple: false,
    validator: fileSizeValidator
  });

  const handleCloseModal = () => {
    setContractFile(null);
    setTabValue(0);
    setOpenUpload(false);
  };

  const [uploadOCDS, uploadOCDSQuery] = useMutation(
    contractsAPI.uploadOCDS,
    {
      onSuccess: () => {
        showAlert({
          message: "Upload was successful",
          severity: "success",
        });
        queryCache.invalidateQueries("tenderAndAwardRequest", {
          exact: false,
          throwOnError: true,
          refetchActive: true,
          refetchInactive: false
        })
        handleCloseModal();
        // refetch();
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      classes: { root: classes.root, wrapper: classes.tabWrapper },
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function fileSizeValidator(file) {
    if (file.size > fileMaxSize) {
      return {
        code: "tooLarge",
        message: `File size is larger than ${fileMaxSize / 1000}kb`
      };
    }

    return null
  };

  const saveFile = () => {
    const data = tabValue === 0 ? [CONTRACT_BULK_UPLOAD_FORMAT] : [BIDDER_BULK_UPLOAD_FORMAT];
    const name = tabValue === 0 ? "Contract_Upload_Format" : "Bidder_Upload_Format";

    const csvFile = new File(data, `${name}.csv`, { type: 'text/csv;charset=utf-8' });
    saveAs(csvFile);
  };

  const handleChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const handleUpload = async () => {
    const fd = new FormData();

    fd.set("Contract", contractFile);
    fd.set("Bidder", biddersFile);

    try {
      uploadOCDS({
        file: fd,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>OCDS</PageTitle>
          <Breadcrumb values={GeneralPlanLinks} />
        </div>

        <AccessControl allowedRoles={[Roles.KWPPA]}>
          <AddButton onClick={() => setOpenUpload(true)}>
            Upload New
          </AddButton>
        </AccessControl>
      </TitleContainer>
      <OCDS />
      <CustomDialog scroll="body" onClose={handleCloseModal} open={openUpload}>
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>Upload OCDS</CustomDialogTitle>
        </CustomDialogTitleWrapper>
        <CustomDialogContent>
          <PageTabs value={tabValue} onChange={handleChange} aria-label="upload tabs">
            <Tab label="Tender/Contract" {...a11yProps(0)} />
            <Tab label="Bidders" {...a11yProps(1)} disabled={!contractFile} />
          </PageTabs>
          <Collapse in={tabValue === 0}>
            <Box mb={3}>
              <Button
                variant="text"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={saveFile}
              ><b>Download Tender/Contracts sample file here</b></Button>
            </Box>
            <Alert severity="info">
              <b>Info:</b> You agree to have thoroughly gone through the draft and agree that the information are valid and correct?
            </Alert>
            <Box mt={3}>
              {contractFile ? (
                <FileProgress
                  progress={100}
                  fileName={contractFile.name}
                  fileSize={contractFile.size}
                  hasCancel
                  onCancel={() => setContractFile(null)}
                />
              ) : (
                <DropzoneArea {...contractDropzone.getRootProps()}>
                  <input {...contractDropzone.getInputProps()} />
                  <div>
                    <p>Upload or drop file here</p>
                    <span style={{ fontSize: ".9em" }}>{"File size should not exceed 5,000kb (5mb)"}</span>
                    <FileError fileRejections={contractDropzone.fileRejections} />
                  </div>
                </DropzoneArea>
              )}
            </Box>
          </Collapse>
          <Collapse in={tabValue === 1}>
            <Box mb={3}>
              <Button
                variant="text"
                color="primary"
                style={{ textTransform: "none" }}
                onClick={saveFile}
              ><b>Download Bidders sample file here</b></Button>
            </Box>
            <Alert severity="info">
              <b>Info:</b> You agree to have thoroughly gone through the draft and agree that the information are valid and correct?
            </Alert>
            <Box mt={3} mb={2}>
              {biddersFile ? (
                <FileProgress
                  progress={100}
                  fileName={biddersFile.name}
                  fileSize={biddersFile.size}
                  hasCancel
                  onCancel={() => setBiddersFile(null)}
                />
              ) : (
                <DropzoneArea {...bidderDropzone.getRootProps()}>
                  <input {...bidderDropzone.getInputProps()} />
                  <div>
                    <p>Upload or drop file here</p>
                    <span style={{ fontSize: ".9em" }}>{"File size should not exceed 5,000kb (5mb)"}</span>
                    <FileError fileRejections={bidderDropzone.fileRejections} />
                  </div>
                </DropzoneArea>
              )}
            </Box>
          </Collapse>
        </CustomDialogContent>

        <AccessControl allowedRoles={[Roles.KWPPA]}>
          <Box>
            <SubmitButton
              onClick={handleUpload}
              style={{ marginRight: 16 }}
              disabled={uploadOCDSQuery.isLoading || !contractFile || !biddersFile}
            >{uploadOCDSQuery.isLoading ? "Uploading..." : "Upload New"}</SubmitButton>
            <PrevBtn onClick={() => {
              setOpenUpload(false);
              setContractFile(null);
            }}>Cancel</PrevBtn>
          </Box>
        </AccessControl>
      </CustomDialog>
    </ContentWrapper>
  )
}

export default withAdminDashboard(AdminOCDS);
