import { Box, Typography, Button, IconButton } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useQuery, useMutation } from "react-query";
import moment from "moment";
import { Dropdown, InputField } from "../../../components";
import AdminSearchFilter from "../../../components/AdminSearchFilter";
import SearchTable, { StyledTableCell } from "../../../components/SearchTable";
import { HoverTableStyle } from "../../../util/theme";
import { PageContainer, PageTitle, PageTitleContainer, StyleTableCellDesc, StyleTableCellId } from "../../Common/style";
import TableEmptyContent from "../../Common/TableEmptyContent";
import procurementAPI from "../../../redux/api/procurementAPI";
import useAlert from "../../../hooks/useAlert";
import MinistryDropdown from "../../../components/MinistryDropdown";
import withVendorDashboard from "../HOC/withVendorDashboard";
import Tag from "../../../components/Tag";
import { formatCurrency } from "../../../util/formatCurrency";
import { ExpiryNotice } from "./style";
import { ReactComponent as Info } from "../../../assets/round_caution.svg";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import ConfirmVerificationModal from "../../../components/Forms/ConfirmVerificationModal";
import { useSelector } from "react-redux";
import usersAPI from "../../../redux/api/usersAPI";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import paymentAPI from "../../../redux/api/paymentAPI";



const ProcurementTableBody = ({ history: historyData, notices = [] }) => {
  const renderNoticeStatus = (date) => {
    const isOpened = moment() < moment(date);
    return <Tag text={isOpened ? "Opened" : "Closed"} color={isOpened ? "grey_green" : "grey_dark"} rounded size="small" />;
  };

  return notices.map((notice) => (
    <HoverTableStyle
      key={notice.id}
      onClick={() => {
        if (notice.tenderingStage === "Single Stage tendering") {
          return historyData.push(`/vendor/bids/${notice?.tenderId}`);
        }
        return historyData.push(`/vendor/procurement/${notice?.id}`);
      }}
    >
      <StyledTableCell>
        <StyleTableCellId>{notice?.packageNumber}</StyleTableCellId>
        <StyleTableCellDesc>{notice?.tenderName || "Not Available"}</StyleTableCellDesc>
      </StyledTableCell>
      <StyledTableCell>{notice?.type}</StyledTableCell>
      <StyledTableCell>{renderNoticeStatus(notice?.expiryDate)}</StyledTableCell>
      <StyledTableCell>{notice?.ministry}</StyledTableCell>
      <StyledTableCell> {moment(notice?.expiryDate).format("DD, MMMM, yyyy")}</StyledTableCell>
      <StyledTableCell>{formatCurrency(notice?.budget, true)}</StyledTableCell>
    </HoverTableStyle>
  ));
};

const SpecialNotice = () => {
  const [title, setTitle] = useState(null);
  const [status, setStatus] = useState(null);
  const [ministry, setMinistry] = useState(null);
  const [expiryDate, setExpiryDate] = useState(null);
  const [isOpen, toggleModal] = useState(false);
  const [showNotice, setShowNotice] = useState(true);
  const [isPaymentDue, setIsPaymentDue] = useState(false);
  const [isPaymentExpired, setIsPaymentExpired] = useState(false);

  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const txRef = params.get("reference");

  const [ updatePayment ] = useMutation(paymentAPI.updatePayment)

  const {
    user: { UserId },
  } = useSelector((state) => state.user);

  // eslint-disable-next-line
  const [pageSize, setPageSize] = useState(10);
  // eslint-disable-next-line
  const [pageNumber, setPageNumber] = useState(1);

  const payload = {
    title,
    status,
    ministry,
    expiryDate,
    pageNumber,
    pageSize,
  };
  const queryKey = ["AllProcurement", payload];
  const { status: errorStatus, error: noticesError, data, isLoading } = useQuery(
    queryKey,
    procurementAPI.fetchAllProcumentNotice
  );
  const { status: categoryErrorStatus, error: categoriesError } = useQuery(
    "allCategoryTypes",
    procurementAPI.getAllProcurementCategoryTypes
  );

  const { data: paymentData, refetch } = useQuery(["vendorPaymentData", UserId], vendorsAPI.getVendorLastPayment, {
    onSuccess: (data) => {
      // if(data !== null){
        let dayDiff = moment(data?.expiryDate).diff(moment().startOf("day"), "days");
        console.log({dayDiff})
        if (dayDiff <= 60) {
          setIsPaymentDue(true);
        }
        if (dayDiff <= 0) {
          setIsPaymentExpired(true);
        }
      // }
    },
  });

  useEffect(() => {
    if(!!txRef) updatePayment({transactionId: txRef})
    refetch();
    // eslint-disable-next-line
  }, [txRef])


  const { data: vendorProfileData } = useQuery(["getVendorProfile", { userId: UserId }], usersAPI.getVendorProfile, {
    enabled: !!UserId,
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  const loader = (loading) => {
    if (loading) {
      return <TableEmptyContent noColSpan={5} textDisplayed="Loading..." />;
    }
    if (data?.notices?.length === 0) {
      return <TableEmptyContent noColSpan={5} textDisplayed="No Procurement Activities" />;
    }
  };

  const { showAlert } = useAlert();
  const history = useHistory();

  useEffect(() => {
    if (errorStatus === "error") {
      showAlert({
        severity: "error",
        message: noticesError.message,
        durationInMs: 3000,
      });
    }

    if (categoryErrorStatus === "error") {
      showAlert({
        severity: "error",
        message: categoriesError.message,
        durationInMs: 3000,
      });
    }
  }, [errorStatus, showAlert, categoryErrorStatus, categoriesError, noticesError]);

  return (
    <PageContainer>
      {showNotice && isPaymentDue && (
        <React.Fragment>
          <ExpiryNotice>
            <Box mr={1}>
              <Info />
            </Box>
            <Box>
              {isPaymentExpired ? (
                <Typography variant="subtitle1">
                  Your payment plan has expired please
                  <strong>{' '}renew immediately</strong>
                </Typography>
              ) : (
                <Typography variant="subtitle1">
                  Your payment plan will expire in{" "}
                  <strong>{`${moment(paymentData?.expiryDate).diff(moment().startOf("day"), "days")} days`}</strong>
                </Typography>
              )}
            </Box>
            <Box mr={2}>
              <Button
                onClick={() => toggleModal(!isOpen)}
                style={{ color: "white", border: "1px solid white" }}
                variant="outlined"
              >
                Proceed to payment
              </Button>
            </Box>
            <Box>
              <IconButton style={{ color: "white" }} onClick={() => setShowNotice(!showNotice)}>
                <CancelOutlinedIcon />
              </IconButton>
            </Box>
          </ExpiryNotice>
        </React.Fragment>
      )}
      <ConfirmVerificationModal refetchPayStatus={refetch} isRenewal isOpen={isOpen} toggleModal={toggleModal} vendorProfileData={vendorProfileData || {}} />
      <PageTitleContainer>
        <PageTitle>Special Procurement Notice</PageTitle>
      </PageTitleContainer>

      <AdminSearchFilter normalSearch={true}>
        <Box display="grid" gridTemplateColumns="repeat(4, 1fr)" gridColumnGap="30px">
          <InputField label="Search ID. Title" value={title} setValue={setTitle} style={{ width: "100%" }} />
          <Dropdown
            label="Status"
            onChange={setStatus}
            values={[
              {
                label: "All",
                value: "",
              },
              {
                label: "Open",
                value: "open",
              },
              {
                label: "Closed",
                value: "closed",
              },
            ]}
            style={{ width: "100%" }}
          />

          <MinistryDropdown useReactQuery onChange={setMinistry} />
          <InputField type="date" value={expiryDate} setValue={setExpiryDate} label="Expiry Date" style={{ width: "100%" }} />
        </Box>
      </AdminSearchFilter>

      <SearchTable
        columns={["REF ID / TITLE", "TYPE", "STATUS", "MINISTRY", "EXPIRY DATE", "VALUE"]}
        page={pageNumber}
        setPage={setPageNumber}
        rowsPerPage={pageSize}
        setRowsPerPage={setPageSize}
        loading={isLoading}
        rows={data?.notices}
        totalNumberOfPages={data?.pagination.totalEntries}
      >
        <ProcurementTableBody history={history} notices={data?.notices} />
        {loader(isLoading)}
      </SearchTable>
    </PageContainer>
  );
};

export default withVendorDashboard(SpecialNotice);
