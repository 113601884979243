import MuiAccordion from "@material-ui/core/Accordion";
import MuiAccordionDetails from "@material-ui/core/AccordionDetails";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import styled from "styled-components";
import { theme } from "../../util/theme";

export const Accordion = styled(MuiAccordion)`
  margin-bottom: 15px;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.09) !important;
  max-width: 855px;

  .MuiAccordionSummary-root.Mui-expanded {
    color: ${theme.color.primary};
  }

  .MuiAccordionDetails-root {
    padding: 20px 0;
  }

  .MuiAccordionSummary-content.Mui-expanded {
    margin: 0;
  }

  .MuiCollapse-wrapper {
    display: flex;
    margin-bottom: 15px;
  }
`;

export const AccordionSummary = styled(MuiAccordionSummary)`
  color: #212b36;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;

  .MuiAccordionSummary-content {
    margin: 20px 0;
    border-radius: 3px;
    background-color: #ffffff;
    align-items: center;
  }

  .MuiAccordionSummary-root.Mui-expanded {
    min-height: 64px;
    color: blue;
  }
`;

export const AccordionDetails = styled(MuiAccordionDetails)`
  border-radius: 3px;
  background-color: #ffffff;
  width: 96%;
  margin: 0 auto;
  border-top: 1px solid #e5e9ec;

  p {
    color: #637381;
    font-size: 14px;
  }
`;

export const AccordionExpandMoreIcon = styled(ExpandMoreIcon)`
  color: "#2AA96D";
`;

export const AccordionDescription = styled(Typography)`
  .MuiTypography-body1 {
    height: 21px;
    color: #212b36;
    font-size: 14px !important;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 21px;
  }
`;
