import React from 'react';
import { useHistory } from 'react-router-dom';
import { Chip } from "@material-ui/core";
import { theme } from '../../../../util/theme';
import { VendorUpgradeRequestRowStyle, StyledTableCell } from '../style';
import EmptyTableBody from "../../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../../components/EmptyTableBody/style";
import { separateNumByComma } from "../../../../util/truncateValue"


// const statusColors = {
//   PENDING: theme.color.tags.pending,
//   APPROVED: theme.color.tags.approved,
//   REJECTED: theme.color.tags.rejected,
//   SUSPENDED: theme.color.tags.rejected
// }

const VendorUpgradeRequestTable = ({ requests = [], column, emptyRows }) => {
  const history = useHistory()

  return requests.length ? (
    <React.Fragment>
      {requests.map((request) => {
        const link = `/admin/vendorsList/vendors/vendor-upgrade-request/${request?.userId}`
        return (
          <VendorUpgradeRequestRowStyle
            key={request?.userId}
            onClick={() => history.push(link)}
          >
            <StyledTableCell component="th" scope="row">
              <p style={{ width: 200 }}>
                {request?.companyName?.toLowerCase()}
              </p>
            </StyledTableCell>
            <StyledTableCell >
              {request?.currentCategory?.toLowerCase()}
            </StyledTableCell>
            <StyledTableCell >
              {request?.newCategory?.toLowerCase()}
            </StyledTableCell>
            {/* <StyledTableCell>
              <Chip
                label={request?.status || "UNKNOWN"}
                style={{
                  background: statusColors[request?.status?.toUpperCase()].background || statusColors.SUSPENDED.background,
                  color: statusColors[request?.status?.toUpperCase()].text
                }}
              />
            </StyledTableCell> */}
            <StyledTableCell style={{ textAlign: "left" }}>
              {separateNumByComma(request?.amount)}
            </StyledTableCell>
          </VendorUpgradeRequestRowStyle>
        )
      })}
      {emptyRows > 0 && (
        <VendorUpgradeRequestRowStyle style={{ height: 50 * emptyRows }}>
          <StyledTableCell style={{ backgroundColor: "#fff" }} colSpan={column.length} />
        </VendorUpgradeRequestRowStyle>
      )}
    </React.Fragment>
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>No Vendor Upgrade Request yet</EmptyTableSubTitle>
    </EmptyTableBody>
  )
}

export default VendorUpgradeRequestTable
