import styled from "styled-components";
import { FormTitle, FormSubtitle } from "../../util/theme";

export const CardLabelWrapper = styled(FormTitle)`
  margin: 0 0 10px 0 !important;
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #5F7483
`;

export const CardNumberText = styled(FormTitle)`
  margin: 0 !important;
  font-size: 26px !important;
  letter-spacing: 0;
  line-height: 32px !important;
  font-weight: 600 !important;
  color: #1E2B37
`;

export const CardInfoText = styled(FormSubtitle)`
  margin: 0 0 10px 0 !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
`;

export const DescriptionSection = styled.div`
  margin: 0;
`;

export const CardIconContainer = styled.div`
  border-radius: 50%;
  height: 45px;
  width: 45px;
  display: flex;
  background-color: rgba(3, 173, 235, 0.13);
  align-items: center;
  justify-content: center;
`;
