import React, { useState, useRef } from "react";
import { useHistory, matchPath } from "react-router-dom";
import {
  Container,
  IconButton,
  useMediaQuery,
  Button,
  Popper,
  ClickAwayListener,
  MenuItem,
  Grow,
  MenuList,
  Paper,
  Box
} from "@material-ui/core";
import {
  MenuOutlined,
  HomeOutlined,
  InfoOutlined,
  MenuBookOutlined,
  LocalShippingOutlined,
  BlurOnOutlined,
  ContactsOutlined,
  VerifiedUserOutlined,
  FingerprintOutlined,
} from "@material-ui/icons";
import { makeStyles } from "@material-ui/core/styles";

import {
  LandingNavbarContainer,
  LandingNavbarStatic,
  Spacer,
  MenuLink,
  VendorLoginButton,
  MobileNavLink,
  MobileNavStyle,
} from "./style";
import logoImg from "../../assets/kwara_logo.svg";

const HomepageNavBar = () => {
  const isSmallScreen = useMediaQuery("(max-width:600px)");
  const [isMobileNavVisible, setisMobileNavVisible] = useState(null);
  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);
  const classes = useStyles();

  const handleClickOCDSPortal = (event) => {
    setOpen((prevOpen) => !prevOpen);
  };

  const routes = [
    { icon: HomeOutlined, name: "Home", path: "/" },
    { icon: InfoOutlined, name: "About", path: "/about" },
    { icon: MenuBookOutlined, name: "News & bulletin", path: "/news-and-bulletins" },
    { icon: BlurOnOutlined, name: "Resources", path: "/resources" },
    { icon: LocalShippingOutlined, name: "Supplier", path: "/supplier" },
    {
      icon: VerifiedUserOutlined,
      name: "OCDS Portal",
      path: "/ocds-portal",
      exact: false,
      onClick: handleClickOCDSPortal,
    },
    { icon: ContactsOutlined, name: " Contact Us", path: "/contact" },
  ];

  function drawerClickEventHandler(event) {
    setisMobileNavVisible((state) => !state);
  }

  function renderMobileNav() {
    return (
      <MobileNavStyle data-isvisible={isMobileNavVisible} onClick={drawerClickEventHandler}>
        <MobileNavLink id="modal-card" onClick={(e) => e.stopPropagation()}>
          <img src={logoImg} alt="Logo" />
          {routes.map((route) => {
            const Icon = !!route.icon ? route.icon : null;
            const matchRoute = matchPath(pathname, {
              path: route.path,
              exact: route.path === "/" ? true : false,
            });
            const routeIsActive = Boolean(matchRoute);
            if (route.onClick) {
              return (
                <>
                  <MenuLink to={route.path} style={{ textTransform: "capitalize" }} activemenu={routeIsActive.toString()}>
                    {!!Icon && <Icon />}
                    {route.name}
                  </MenuLink>
                  <Box ml={3}>
                    <p style={{ padding: 3 }}>
                      <MenuLink to="/ocds-portal/awarded-contracts" style={{ textTransform: "capitalize" }} activemenu={Boolean(
                        matchPath(pathname, {
                          path: "/ocds-portal/awarded-contracts",
                          exact: false,
                        })
                      ).toString()}>

                        Awarded Contracts
                      </MenuLink>
                    </p>
                    <p style={{ padding: 3 }}>
                      <MenuLink to="/ocds-portal/tenders-list" style={{ textTransform: "capitalize" }} activemenu={Boolean(
                        matchPath(pathname, {
                          path: "/ocds-portal/tenders-list",
                          exact: false,
                        })
                      ).toString()}>
                        Tenders
                      </MenuLink>
                    </p>
                  </Box>
                </>
              );
            }

            return (
              <MenuLink to={route.path} style={{ textTransform: "capitalize" }} activemenu={(pathname === route.path).toString()}>
                {!!Icon && <Icon />}
                {route.name}
              </MenuLink>
            );
          })}
          <MenuLink to="/login" activemenu={true}>
            <FingerprintOutlined />
            Login
          </MenuLink>
        </MobileNavLink>
      </MobileNavStyle>
    );
  }

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      handleClose();
    }
  }

  const {
    location: { pathname },
    push,
  } = useHistory();
  return (
    <>
      <LandingNavbarStatic>
        <div style={{ background: "#fff" }}>
          <Container>
            {isSmallScreen && (
              <IconButton onClick={drawerClickEventHandler} style={{ margin: ".5rem 0" }}>
                <MenuOutlined />
              </IconButton>
            )}
            <LandingNavbarContainer>
              {/* <Logo /> */}
              <img src={logoImg} alt="Logo" />
              <Spacer />
              {routes.map((route) => {
                const matchRoute = matchPath(pathname, {
                  path: route.path,
                  exact: route.path === "/" ? true : false,
                });
                const routeIsActive = Boolean(matchRoute);
                if (route.onClick) {
                  return (
                    <MenuLink
                      onClick={route.onClick}
                      ref={anchorRef}
                      aria-controls={open ? "menu-list-grow" : undefined}
                      aria-haspopup="true"
                      disableRipple
                      style={{ color: routeIsActive && "#2AA96D" }}
                      classes={{ root: classes.root }}
                      component={Button}
                      activemenu={`${routeIsActive}`}
                    >
                      {route.name}
                    </MenuLink>
                  );
                }
                return (
                  <MenuLink to={route.path} activemenu={`${routeIsActive}`}>
                    {route.name}
                  </MenuLink>
                );
              })}
              <VendorLoginButton onClick={() => push("/login")}>Login</VendorLoginButton>
            </LandingNavbarContainer>
          </Container>
        </div>    {renderMobileNav()}
      </LandingNavbarStatic>
      <Popper open={open} anchorEl={anchorRef.current} role={undefined} transition disablePortal style={{ zIndex: 105 }}>
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
            <Paper style={{ background: "#fff" }} classes={{ root: classes.paperRoot }}>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={open} id="menu-list-grow" onKeyDown={handleListKeyDown}>
                  <MenuItem>
                    <MenuLink
                      to="/ocds-portal/awarded-contracts"
                      activemenu={`${Boolean(
                        matchPath(pathname, {
                          path: "/ocds-portal/awarded-contracts",
                          exact: false,
                        })
                      )}`}
                      style={{ width: "100%", color: "inherit" }}
                    >
                      Awarded Contracts
                    </MenuLink>
                  </MenuItem>
                  <MenuItem>
                    <MenuLink
                      to="/ocds-portal/tenders-list"
                      style={{ width: "100%" }}
                      activemenu={`${Boolean(
                        matchPath(pathname, {
                          path: "/ocds-portal/tenders-list",
                          exact: false,
                        })
                      )}`}
                    >
                      Tenders
                    </MenuLink>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    textTransform: "none",
    padding: 0,
    "&:hover": {
      background: "transparent",
    },
  },
  paperRoot: { background: "#fff", zIndex: 100 },
  paper: {
    marginRight: theme.spacing(2),
  },
}));

export default HomepageNavBar;
