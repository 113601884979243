import React, { useState } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import { useParams } from "react-router-dom";
import { BidInfoTitle, InfoContainer, ProcurementInformationContainer } from "../BidDetails/BidInfo/style";
import { ProjectDocumentContainer } from "./style";
import { Box, Grid } from "@material-ui/core";
import DocumentDownload from "../../../components/DocumentDownload";
import { Skeleton } from "@material-ui/lab";
import GridFileUpload from "../../../components/GridFileUpload";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";

const ProjectReportSection = () => {
  const { projectId } = useParams();

  const [uploadImageFileNames, setUploadImageFileNames] = useState([]);

  const projectDocQueryKeys = ["projectDocUpload", projectId, 2];

  const projectReportDoc = useQuery({
    queryKey: projectDocQueryKeys,
    queryFn: vendorsAPI.getProjectReportDoc
  });


  const [uploadReportDocuments, uploadReportDocumentsQuery] = useMutation(
    vendorsAPI.uploadProjectReportDoc,
    {
      onSuccess: (data) => {
        queryCache.cancelQueries(projectDocQueryKeys);
        queryCache.setQueryData(projectDocQueryKeys, (prevData) => [...data, ...prevData]);
        setUploadImageFileNames([]);
      }
    }
  );

  const onClickUpload = () => {
    uploadReportDocuments({
      projectId,
      files: uploadImageFileNames
    });
  };

  if (projectReportDoc.isLoading) {
    return (
      <Skeleton
        variant='rect'
        height='200px'
        width='100%'
      />
    );
  }

  if (projectReportDoc?.data.length === 0) {
    return (
      <Box marginTop="20px">
        <ProcurementInformationContainer>
          <BidInfoTitle>Upload Project Report</BidInfoTitle>
          <GridFileUpload
            filesNames={uploadImageFileNames}
            setFilesNames={setUploadImageFileNames}
            multiple
            noMargin
          />
          <Box mt={3} mr={0.5}>
            <Grid
              container
              spacing={2}
              justify='flex-end'
            >
              <LoadingButton
                color='#2AA96D'
                marginLeft='15px'
                loading={uploadReportDocumentsQuery.isLoading}
                onClick={onClickUpload}
              >
                Submit
              </LoadingButton>
            </Grid>
          </Box>
        </ProcurementInformationContainer>
      </Box>
    );
  }

  return (
    <InfoContainer>
      <ProjectDocumentContainer>
        <BidInfoTitle>Project Report Documents</BidInfoTitle>
        <Grid container spacing={2}>
          {projectReportDoc?.data?.map((eachDoc) => (
            <Grid item xs={4} key={eachDoc.name}>
              <DocumentDownload file={eachDoc}/>
            </Grid>
          ))}
        </Grid>
      </ProjectDocumentContainer>
    </InfoContainer>
  );
};

export default ProjectReportSection;
