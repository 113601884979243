import React from "react";
import { Grid } from "@material-ui/core";
import Skeleton from "@material-ui/lab/Skeleton";
import { CardInfoText, CardLabelWrapper, CardNumberText, CardStatus, CardWrapper, StatusContainer, SummaryCards } from "./style";

const SummaryCard = ({ title, count, description, statuses, ...props }) => (
  <CardWrapper {...props}>
    <CardLabelWrapper>{title}</CardLabelWrapper>
    <CardNumberText>{count}</CardNumberText>
    <CardInfoText>{description}</CardInfoText>
    <CardStatus>
      {statuses.map(({ text, bgColor, textColor }) => (
        <StatusContainer key={text} bgcolor={bgColor} textcolor={textColor}>
          {text}
        </StatusContainer>
      ))}
    </CardStatus>
  </CardWrapper>
);

const SummaryCardsComponent = ({ filterPlans, activeCategory, loading = false, summary = {} }) => (
  <SummaryCards>
    <Grid container spacing={2} alignItems="center">
      {loading ? (
        Array.from({ length: 4 }).map((_, idx) => (
          <Grid item key={idx}>
            <Skeleton width={230} height={150} variant="rect" />
          </Grid>
        ))
      ) : (
        <>
          <Grid item xs={3}>
            <SummaryCard
              onClick={() => filterPlans("Works")}
              title={"Tender for Works"}
              count={summary.works?.total || 0}
              description={"Total ongoing plans"}
              statuses={[
                {
                  text: `${summary.works?.approved || 0}  Approved`,
                  bgColor: "rgba(0,176,241,0.13)",
                  textColor: "#2AA96D",
                },
                {
                  text: `${summary.works?.draft || 0} Draft`,
                  bgColor: "#DFE3E9",
                  textColor: "#4D5762",
                },
                {
                  text: `${summary.works?.review || 0} Review`,
                  bgColor: "#FFEAD9",
                  textColor: "#FF8D26",
                },
              ]}
              activeCategory={activeCategory === "Works"}
            />
          </Grid>
          <Grid item xs={3}>
            <SummaryCard
              onClick={() => filterPlans("Goods")}
              title={"Tender for Goods"}
              count={summary.goods?.total || 0}
              description={"Total ongoing plans"}
              statuses={[
                {
                  text: `${summary.goods?.approved || 0}  Approved`,
                  bgColor: "rgba(0,176,241,0.13)",
                  textColor: "#2AA96D",
                },
                {
                  text: `${summary.goods?.draft || 0} Draft`,
                  bgColor: "#DFE3E9",
                  textColor: "#4D5762",
                },
                {
                  text: `${summary.goods?.review || 0} Review`,
                  bgColor: "#FFEAD9",
                  textColor: "#FF8D26",
                },
              ]}
              activeCategory={activeCategory === "Goods"}
            />
          </Grid>
          <Grid item xs={3}>
            <SummaryCard
              onClick={() => filterPlans("Consultation")}
              title={"Tender for Consultation"}
              count={summary.consultancy?.total || 0}
              description={"Total ongoing plans"}
              statuses={[
                {
                  text: `${summary.consultancy?.approved || 0}  Approved`,
                  bgColor: "rgba(0,176,241,0.13)",
                  textColor: "#2AA96D",
                },
                {
                  text: `${summary.consultancy?.draft || 0} Draft`,
                  bgColor: "#DFE3E9",
                  textColor: "#4D5762",
                },
                {
                  text: `${summary.consultancy?.review || 0} Review`,
                  bgColor: "#FFEAD9",
                  textColor: "#FF8D26",
                },
              ]}
              activeCategory={activeCategory === "Consultation"}
            />
          </Grid>
          <Grid item xs={3}>
            <SummaryCard
              onClick={() => filterPlans("Non Consultation")}
              title={"Tender for Non Consultation"}
              count={summary.nonConsultancy?.total || 0}
              description={"Total ongoing plans"}
              statuses={[
                {
                  text: `${summary.nonConsultancy?.approved || 0}  Approved`,
                  bgColor: "rgba(0,176,241,0.13)",
                  textColor: "#2AA96D",
                },
                {
                  text: `${summary.nonConsultancy?.draft || 0} Draft`,
                  bgColor: "#DFE3E9",
                  textColor: "#4D5762",
                },
                {
                  text: `${summary.nonConsultancy?.review || 0} Review`,
                  bgColor: "#FFEAD9",
                  textColor: "#FF8D26",
                },
              ]}
              activeCategory={activeCategory === "Non Consultation"}
            />
          </Grid>
        </>
      )}
    </Grid>
  </SummaryCards>
);

export default SummaryCardsComponent;
