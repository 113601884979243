import { Box } from "@material-ui/core";
import React, { useContext, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import CheckIcon from "@material-ui/icons/Check";

import { GeneralTitlePage } from "../../../../../../components/AddGeneralPlan/style";
import { CardNumberText } from "../../../../../../components/AdminCard/style";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogTitleWrapper,
  ApplicationDates,
  SubmitButton,
  PrevBtn,
} from "../../../../../../components/Forms/ConfirmVerificationModal/style";
import TopStatisticsBar from "../../../../../../components/TopStatisticsBar";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import { ContentWrapper } from "../../../../Administration/Staff/style";
import { CardLabel } from "../../../../PlanDetails/style";
import ApprovalModal from "./ApprovalModal";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import EvaluatedVendorsTable from "../../../../../../components/EvaluatedVendorsTable/EvaluatedVendorsTable";
import { useMutation, useQuery } from "react-query";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import ToastContext from "../../../../../../util/toastContext";
import procurementAPI from "../../../../../../redux/api/procurementAPI";
import AccessControl from "../../../../../../components/AccessControl";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../../../hooks/useUserDetails";

const baseVendorObject = {
  data: {
    responseSummary: {
      total: 0,
      pending: 0,
      evaluated: 0,
    },
    evaluatedVendors: [],
  },
  pagination: {
    totalEntries: 0,
  },
};

const EvaluatedVendors = () => {
  const { activityId, procurementId } = useParams();
  const [open, setOpen] = React.useState(false);
  const [openEndorsementModal, setOpenEndorsementModal] = React.useState(false);
  const [openForwardModal, setOpenForwardModal] = React.useState(false);
  const { role: userRole } = useRoles();
  const [uploadDocument, setUploadDocument] = React.useState([]);
  const { showAlert } = useContext(ToastContext);

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const { data: evaluationStat } = useQuery({
    queryKey: ["evaluation-stat", { procurementId }],
    queryFn: receiptOfBiddersResponseApi.getEvaluationStat,
  });

  const biddersResponse = useQuery({
    queryKey: [
      "biddersResponse",
      {
        Status: 2,
        PageSize: pagination.pageSize,
        PageNumber: pagination.pageNumber,
        id: procurementId,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.evaluatedBids,
  });

  const documents = useQuery({
    queryKey: [
      "approvalMemo",
      {
        activityId,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.getApprovalDocument,
  });

  const activityDetails = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
  });

  const vendorEndorsementQuery = useQuery({
    queryKey: [
      "vendorEndorsement",
      {
        procurementId,
      },
    ],
    queryFn: procurementAPI.getVendorEndorsements,
  });

  const [endorseVendor, endorseVendorQuery] = useMutation(procurementAPI.endorseVendor, {
    onSuccess: () => {
      showAlert({
        message: `Recommended vendor was ${openEndorsementModal ? "endorsed" : "forwarded"} successfully`,
        severity: "success"
      });
      setOpenEndorsementModal(false);
      setOpenForwardModal(false);
      vendorEndorsementQuery.refetch();
    },
    onError: (e) => {
      showAlert({
        message: e.message,
        severity: "error"
      });
    }
  });

  const [sendForRecommendation, sendForRecommendationQuery] = useMutation(receiptOfBiddersResponseApi.sendForRecommendation, {
    onError: (e) => {
      showAlert({
        message: e.message,
        severity: "error"
      });
    }
  });

  const {
    data: { responseSummary, evaluatedVendors },
    pagination: apiPagination,
  } = Object.assign(baseVendorObject, biddersResponse.data);

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleEndorseVendor = () => {
    endorseVendor({
      procurementId
    })
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderLeftContent = () => (
    <Box>
      <CardLabel>Total Bidders</CardLabel>
      <CardNumberText>{evaluationStat?.financial?.total}</CardNumberText>
    </Box>
  );

  const renderCenterContent = () => {
    const { procurementPlanActivityStatus } = activityDetails.data || {};

    const isAwaiting = procurementPlanActivityStatus === "Awaiting";
    const isApproved = procurementPlanActivityStatus === "Approved";

    const ApprovementStages = [
      {
        text: "Endorse Recomended Vendor",
        onClick: () => setOpenEndorsementModal(true),
        allowedRoles: [Roles.PERMANENT_SECRETARY, Roles.ACCOUNTING_OFFICERS],
        hasEndorse: !!checkEndorsementStatus(vendorEndorsementQuery?.data, userRole, "endorsed")?.role
      },
      {
        text: "Endorse Recomended Vendor",
        onClick: () => setOpenEndorsementModal(true),
        allowedRoles: [Roles.COMMISSIONER],
        isAwaitingTurn: !checkEndorsementStatus(vendorEndorsementQuery?.data, [Roles.PERMANENT_SECRETARY, Roles.ACCOUNTING_OFFICERS], "endorsed")?.role,
        isReadyTurn: !!checkEndorsementStatus(vendorEndorsementQuery?.data, [Roles.PERMANENT_SECRETARY, Roles.ACCOUNTING_OFFICERS], "endorsed")?.role,
        hasEndorse: !!checkEndorsementStatus(vendorEndorsementQuery?.data, userRole, "endorsed")?.role
      },
      {
        text: "Endorse Recomended Vendor",
        onClick: () => setOpenEndorsementModal(true),
        allowedRoles: [Roles.KWPPA],
        isAwaitingTurn: !checkEndorsementStatus(vendorEndorsementQuery?.data, Roles.COMMISSIONER, "endorsed")?.role,
        isReadyTurn: !!checkEndorsementStatus(vendorEndorsementQuery?.data, Roles.COMMISSIONER, "endorsed")?.role,
        hasEndorse: !!checkEndorsementStatus(vendorEndorsementQuery?.data, userRole, "endorsed")?.role
      },
      {
        text: "Forward Recomended Vendor",
        onClick: () => setOpenForwardModal(true),
        allowedRoles: [Roles.COMMISSIONER],
        action: "forwarded",
        isAwaitingTurn: !checkEndorsementStatus(vendorEndorsementQuery?.data, Roles.KWPPA, "endorsed")?.role,
        isReadyTurn: !!checkEndorsementStatus(vendorEndorsementQuery?.data, Roles.KWPPA, "endorsed")?.role,
        hasEndorse: !!checkEndorsementStatus(vendorEndorsementQuery?.data, userRole, "forwarded")?.role
      }
    ];

    return (
      <>
        <Box>
          <CardLabel style={{ color: "#FF8D26" }}>Pending Review</CardLabel>
          <CardNumberText>{evaluationStat?.financial.totalPending}</CardNumberText>
        </Box>
        <Box marginLeft="55px">
          <CardLabel style={{ color: "#219653" }}>Evaluated</CardLabel>
          <CardNumberText>{evaluationStat?.financial.totalEvaluated}</CardNumberText>
        </Box>
        {/* {(isApproved || isAwaiting) && (
          <Box ml="auto" mr="auto">
            <ApprovalDocumentLink href={documents.data?.pop()?.file?.url} target="_blank">
              View approval memo
            </ApprovalDocumentLink>
          </Box>
        )} */}
        <AccessControl allowedRoles={[Roles.MINISTERIAL_TENDERS_BOARD]}>
          {!isApproved && (
            <Box ml="auto">
              <LoadingButton
                onClick={handleClickOpen}
                endIcon={isAwaiting ? null : <CheckIcon width="10px" />}
                color="#2AA96D"
                disabled={isAwaiting}
              >
                {isAwaiting ? "Waiting for approval" : "Send for Recommended Vendor For Approval"}
              </LoadingButton>
            </Box>
          )}
        </AccessControl>
        {isAwaiting && (
          <Box ml="auto">
            {ApprovementStages.map(el => (
              <AccessControl allowedRoles={el.allowedRoles}>
                <LoadingButton
                  onClick={el.onClick}
                  color="#2AA96D"
                  disabled={el.hasEndorse || el.isAwaitingTurn}
                >
                  {el.hasEndorse
                    ? el.action === "forwarded"
                      ? "Vendor Forwarded"
                      : "Vendor Endorsed"
                    : el.isAwaitingTurn
                      ? el.action === "forward"
                        ? "Awaiting to forward vendor"
                        : "Awaiting turn to endorse vendor"
                      : el.text}
                </LoadingButton>
              </AccessControl>
            ))}
          </Box>
        )}
      </>
    );
  };

  const recommend = async () => {
    try {
      await sendForRecommendation({
        File: uploadDocument[0],
        activityId,
      });

      handleClose();

      showAlert({
        message: "Succcessfully uploaded recommend document",
        severity: "success",
        durationInMs: 3000,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
        durationInMs: 3000,
      });
    }
  };

  const topBarStatus = useMemo(() => {
    const { procurementPlanActivityStatus } = activityDetails.data || {};

    if (procurementPlanActivityStatus === "Awaiting") {
      return "pending";
    } else if (procurementPlanActivityStatus === "Approved") {
      return "approved";
    } else {
      return null;
    }
  }, [activityDetails.data]);

  return (
    <ContentWrapper>
      <Box mt={3}>
        <GeneralTitlePage>Evaluated Vendors</GeneralTitlePage>
        <Breadcrumb
          values={[
            {
              url: "/dashboard/overview",
              title: "Home",
            },
            {
              url: `/admin/procurement/plans`,
              title: "Plans",
            },
            {
              url: `/admin/procurement/plan-details/${procurementId}`,
              title: "Plan Details",
            },
            {
              url: `/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}`,
              title: "Receipt of bidders response",
            },
            {
              url: `#`,
              title: "Evaluated Vendors",
            },
          ]}
        />
      </Box>

      <Box marginTop="43px">
        <TopStatisticsBar
          leftContent={renderLeftContent()}
          hasApproveStatus={documents.data?.length > 0}
          centerContent={renderCenterContent()}
          isLoading={documents.isLoading}
          status={topBarStatus}
        />
      </Box>

      <EvaluatedVendorsTable
        isLoading={biddersResponse.isLoading}
        vendors={evaluatedVendors}
        pageNumber={pagination.pageNumber}
        pageSize={pagination.pageSize}
        setPageNumber={updatePagination("pageNumber")}
        setPageSize={updatePagination("pageSize")}
        totalPages={apiPagination.totalEntries}
      />
      <ApprovalModal
        btnText="Send For Recommended Vendor For Approval"
        filesNames={uploadDocument}
        setFilesNames={setUploadDocument}
        handleClose={handleClose}
        open={open}
        onClickButton={recommend}
        isLoading={sendForRecommendationQuery.isLoading}
        cardTitle="Send For Approval"
        docInfo="Upload Evaluation document here"
      />
      <CustomDialog scroll="body" onClose={() => setOpenEndorsementModal(false)} open={openEndorsementModal}>
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>Endorse Recommended Vendor</CustomDialogTitle>
        </CustomDialogTitleWrapper>
        <CustomDialogContent>
          <ApplicationDates>Are you sure you want to endorse this recommended vendor?</ApplicationDates>
        </CustomDialogContent>
        <Grid container justify="space-between">
          <div />
          <div>
            <PrevBtn onClose={() => setOpenEndorsementModal(false)}>Cancel</PrevBtn>
            <SubmitButton
              onClick={handleEndorseVendor}
              disabled={endorseVendorQuery.isLoading}

            >
              {endorseVendorQuery.isLoading ? "Endorsing Vendor..." : "Endorse Vendor"}
            </SubmitButton>
          </div>
        </Grid>
      </CustomDialog>
      <CustomDialog scroll="body" onClose={() => setOpenForwardModal(false)} open={openForwardModal}>
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>Forward Recommended Vendor</CustomDialogTitle>
        </CustomDialogTitleWrapper>
        <CustomDialogContent>
          <ApplicationDates>Are you sure you want to forward this recommended vendor?</ApplicationDates>
        </CustomDialogContent>
        <Grid container justify="space-between">
          <div />
          <div>
            <PrevBtn onClose={() => setOpenForwardModal(false)}>Cancel</PrevBtn>
            <SubmitButton
              onClick={handleEndorseVendor}
              disabled={endorseVendorQuery.isLoading}
            >
              {endorseVendorQuery.isLoading ? "Forwarding Vendor..." : "Forward Vendor"}
            </SubmitButton>
          </div>
        </Grid>
      </CustomDialog>
    </ContentWrapper>
  );
};

function checkEndorsementStatus(endorsements, role, action) {
  const endorsedStage = endorsements?.filter(el => {
    const endorsementRole = el.role?.split(" ").join("_").toLowerCase();
    const isRole = Array.isArray(role)
      ? role.includes(endorsementRole)
      : endorsementRole === role;

    const stage = isRole && el.action?.toLowerCase() === action;
    return stage;
  })[0];

  return endorsedStage;
};

export default EvaluatedVendors;
