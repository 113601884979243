export const policies = [
    {
      id: "1",
      title: "Information and Sharing disclosure",
      list: [
        {
          id: "1.1",
          content: `The information that we collect about you is critical to our business. 
            We respect and value this information and do not want to jeopardize the 
            flow of information on the Site. Therefore, 
            we are not in the business of selling this information to other websites or businesses. 
            There are very limited circumstances where we do share some of your information and this 
            is outlined immediately below. `,
        },
        {
          id: "1.2",
          content: `We provide the information to trusted partners who work on behalf of or with us under confidentiality 
          agreements. These companies may use your personal information to help us communicate with you about our offers
          and our marketing partners. However, these companies do not have any independent right to share this information.  `,
        },
        {
          id: "1.3",
          content: `We respond to subpoenas, court orders, or legal process, or to establish or exercise our legal 
          rights or defend against legal claims;  `,
        },
        {
          id: "1.4",
          content: `We believe it is necessary to share information in order to investigate, prevent, or take action 
          regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety
           of any person, violations of our terms of use, or as otherwise required by law; and `,
        },
        {
          id: "1.5",
          content: `We transfer information about you if we are acquired by or merged with another company. In this event, 
          we will notify you before information about you is transferred and becomes subject to a different privacy policy.`,
        },
        {
          id: "1.6",
          content: `We do not intend to sell, share, or rent information obtained on this Site other than as discussed in this privacy policy`,
        },
      ],
    },
    {
      id: "2",
      title: "Confidentiality and security",
      list: [
        {
          id: "2.1",
          content: `Your Account Information with us is password-protected. 
            In certain areas we use industry-standard SSL-encryption 
            to protect data transmissions.  `,
        },
      ],
    },
    {
      id: "3",
      title: "Correction/updating of personal information",
      list: [
        {
          id: "3.1",
          content: `If you provide personally identifiable information changes (such as an email address or zip code), 
          the app will endeavor to provide a way to correct, 
          update or remove your personal date. This can usually be done at the Customer Profile Page `,
        },
      ],
    },
    {
      id: "4",
      title: "Data Storage",
      list: [
        {
          id: "4.1",
          content: `We will only retain personal information on our servers for as long as an account is active.
           Once an account has been deactivated,
           your information is stored on our servers for as long as necessarily obligated by law.`,
        },
      ],
    },
    {
      id: "5",
      title: "Changes to this privacy policy",
      list: [
        {
          id: "5.1",
          content: `We may update this policy. We will notify you about significant changes in the way 
          we treat personal information by sending a notice to the primary 
          e-mail address specified in your account with us or by placing a prominent notice on our Site. `,
        },
      ],
    },
  ];