import React, { useState } from "react";
import { Box } from "@material-ui/core";
import { useParams, useLocation, Link } from "react-router-dom";
import { useQuery } from "react-query";

import receiptOfResponseApi from "../../../../../../redux/api/receiptOfResponseApi";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";
import AccessControl from "../../../../../../components/AccessControl";
import TopStatisticsBar from "../../../../../../components/TopStatisticsBar";
import { ResponseStatsLabel, ResponseStatsValue } from "../style";
import { TopButtons } from "./style";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";


const EvaluationStatisticBar = () => {
    const { pathname } = useLocation();
    const allowedRoles = [Roles.MINISTERIAL_TENDERS_BOARD];
    const [hasBenchmark, setHasBenchmark] = useState(null);

    const { procurementId, vendorId } = useParams();

    const evaluatedVendorScore = useQuery({
        queryKey: [
            'evaluatedVendorScore',
            { procurementId, vendorId }
        ],
        queryFn: receiptOfResponseApi.getEvaluatedVendorScore
    });

    useQuery({
        queryFn: receiptOfBiddersResponseApi.getTechnicalEvaluation,
        queryKey: [
            "technical-evaluation",
            { id: procurementId },
        ],
        config: {
            onSuccess: (data) => {
                setHasBenchmark(data?.benchmark > 0);
            }
        }
    });

    const renderLeftContent = () => {

        return (
            <>
                <ResponseStatsLabel color='#5F7483'>
                    Evaluation Score
                </ResponseStatsLabel>
                <ResponseStatsValue>
                    {evaluatedVendorScore?.data?.totalScore ? `${evaluatedVendorScore?.data?.totalScore.toFixed(2)}%` : "-"}
                </ResponseStatsValue>
            </>
        )
    };

    const renderCenterContent = () => {

        return <>
            <Box mr='auto' />
            {hasBenchmark === true && evaluatedVendorScore?.data?.totalScore === 0 && (
                <AccessControl allowedRoles={allowedRoles}>
                    <TopButtons>
                        <Box mr={2.5}>
                            <LoadingButton
                                color='#219653'
                                component={Link}
                                to={`${pathname}/vendor-evaluation`}
                            >
                                Evaluate Vendor
                            </LoadingButton>
                        </Box>
                    </TopButtons>
                </AccessControl>
            )}
            {evaluatedVendorScore?.data?.totalScore > 0 && (
                <TopButtons>
                    <Box mr={2.5}>
                        <LoadingButton
                            color='#219653'
                            component={Link}
                            to={`${pathname}/vendor-evaluation`}
                        >
                            View Evaluation
                        </LoadingButton>
                    </Box>
                </TopButtons>
            )}
        </>;
    };

    return (
        <TopStatisticsBar
            status={evaluatedVendorScore?.data?.totalScore > 0 ? 'approved' : 'pending'}
            leftContent={renderLeftContent()}
            centerContent={renderCenterContent()}
        />
    );
};

export default EvaluationStatisticBar;
