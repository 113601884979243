import styled from "styled-components";
import Grid from "@material-ui/core/Grid";

export const Wrapper = styled(Grid)`
  min-height: 100vh;
`;

export const SideNavSection = styled.div`
  position: fixed;
  height: 100vh;
  min-width: 250px;
  left: 0;
  top: 0;
  overflow-x: hidden;
  z-index: 100;
`;

export const Caption = styled.div`
  padding: 28.5px;
  padding-top: 16px;
  padding-bottom: 17px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;
