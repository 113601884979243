import styled from "styled-components";
import { Link } from "react-router-dom";
import { theme } from "../../../util/theme";

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;

  p {
    color: white;
    padding: 10px 28.5px;
  }
`;

export const MenuItem = styled(Link)`
  margin-left: 14px;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 22px;
  color: ${({ active }) => (active === "true" ? theme.color.primary : "#ABB4BC")};
  text-decoration: none;
  font-weight: ${({ active }) => (active === "true" ? 800 : 600)};
  text-transform: capitalize;
`;

export const CustomMenuItemWrapper = styled.div`
  display: flex;
  margin: 10px 0;
  padding: 5px 30px;
  align-items: center !important;

  svg {
    width: 25px;
    height: 25px;
    stroke: #abb4bc;
  }

  border-left: 5px solid ${({ active }) => (active === "true" ? "#2AA96D" : "transparent")};

  path {
    stroke: ${({ active }) => (active === "true" ? "#2AA96D" : "#ABB4BC")};
  }
  line {
    stroke: ${({ active }) => (active === "true" ? "#2AA96D" : "#ABB4BC")};
  }
`;

export const LogoutWrapper = styled(CustomMenuItemWrapper)`
  bottom: 0;
  position: absolute;
`;

export const MenuChildrenWrapper = styled.div`
  background-color: #000000;
  display: flex;
  flex-direction: column;

  a {
    padding: 10px 60px;
  }
`;
