import React, { useEffect } from "react";
import { Container, Grid } from "@material-ui/core";
import { useParams } from "react-router-dom";

import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import Hero from "../../../components/Hero";
import {
  FlexContainer,
  LandingTitleText as NewsBulletinText,
  SeparatorLine,
  NewsDateLabel,
  NewsUpdateSection,
  NewsUpdateImageContentLarge,
  LandingComment,
} from "../Landing/style";
import { NewsCardWrapper, RelatedPosts } from "./style";
import { news, mainNews } from "../NewsAndBulletins/mock";
import NewsHeadlinesListCard from "../../../components/NewsHeadlineListCard";

const NewsPage = () => {
  const { id } = useParams();
  const post = news.filter((x) => x.id.toString() === id);
  const isMainNews = id === "main";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Hero title={isMainNews ? mainNews.title : post[0].title} desc={isMainNews ? mainNews.title : post[0].title} />

      <NewsUpdateSection>
        <Container>
          <FlexContainer>
            <div>
              <NewsBulletinText>News</NewsBulletinText>
              <SeparatorLine />
            </div>
          </FlexContainer>
          <Grid container spacing={3} justify="space-between">
            <Grid item md={8} sm={12}>
              <NewsUpdateImageContentLarge url={isMainNews ? mainNews.imgUrl : post[0].imgUrl} />
              <NewsDateLabel>{isMainNews ? mainNews.date : post[0].date}</NewsDateLabel>
              <LandingComment>
                <div dangerouslySetInnerHTML={{ __html: isMainNews ? mainNews.content : post[0].content }} />
              </LandingComment>
            </Grid>
            <Grid item md={4} sm={12}>
              <NewsCardWrapper>
                {news.filter((el) => el.id.toString() !== id).lenght !== 0 && <RelatedPosts>Related Posts</RelatedPosts>}
                {news
                  .filter((el) => el.id.toString() !== id)
                  .slice(0, 3)
                  .map((post) => (
                    <NewsHeadlinesListCard
                      key={post.id}
                      id={post.id}
                      cardImg={post.imgUrl}
                      title={post.title}
                      date={post.date}
                      noContent
                    />
                  ))}
              </NewsCardWrapper>
            </Grid>
          </Grid>
        </Container>
      </NewsUpdateSection>
    </div>
  );
};

export default withHeaderFooterHOC(NewsPage);
