/* eslint-disable no-undef */
/* eslint-disable no-alert */
import React, { useState, useEffect } from "react";

import { ReactComponent as ErrorIcon } from "../../assets/feedback-error.svg";
import { ReactComponent as WarningIcon } from "../../assets/feedback-warning.svg";
import { ReactComponent as SuccessIcon } from "../../assets/feedback-success.svg";
import uploadTemplate from "../../assets/ministry_upload_template.csv";
import { useMutation } from "react-query";

import {
  Wrapper,
  FeedbackWrapper,
  FeedbackIconWrapper,
  FeedbackTitle,
  FeedbackBody,
  ErrorText,
  MessageContents,
  MessageContent,
  MessageContentTitle,
  MessageContentBody,
  SamplefileDownloadButton,
} from "./style";
import FileUploadField from "../FileUploadField";
import procurementAPI from "../../redux/api/procurementAPI";
import { CSVLink } from "react-csv";
import useAlert from "../../hooks/useAlert";

const feedbackVariants = {
  error: {
    icon: <ErrorIcon />,
    style: {
      backgroundColor: "#fbeae5",
      paddingBottom: "40px",
    },
  },
  warning: {
    icon: <WarningIcon />,
    style: {
      backgroundColor: "#fcf1cd",
      paddingBottom: "40px",
    },
  },
  success: {
    icon: <SuccessIcon />,
    style: {
      backgroundColor: "#e3f1df",
      paddingBottom: "18px",
    },
  },
};

const BulkUpload = ({ RenderButton, isMinistry }) => {
  const [files, setFile] = useState([]);
  const { showAlert } = useAlert();
  const [csvError, setCsvError] = useState({
    formatError: false,
    contentError: false,
    uploadError: false
  });
  let fileIsAvailable = files?.length > 0;

  const [bulkUpload, { isSuccess, isLoading, data, reset }] = useMutation(procurementAPI.MDABulkUpload, {
    onSuccess: (data) => {
      if (data?.errors?.failedUpload?.length > 0) {
        setCsvError({ ...csvError, contentError: true });
      }

    },
    onError: (data) => {
      showAlert({ severity: "error", message: data?.message });
      setCsvError({ ...csvError, formatError: true, uploadError: true });
    },
    onSettled: () => {
      if (files?.[0]?.name?.split(".")?.pop() !== "csv") {
        setCsvError({ ...csvError, formatError: true });
      }
    },
  });

  const headers = [
    { label: "Name", key: "name" },
    { label: "Code", key: "code" },
    { label: "Email", key: "email" }
  ];


  let fileLength = files?.length;

  useEffect(() => {
    reset();
    setCsvError({uploadError: false, formatError: false, contentError: false });
  }, [fileLength, setCsvError, reset]);

  const onUpload = (e) => {
    e.preventDefault();
    const fd = new FormData();
    fd.append("File", files[0]);
    bulkUpload({ fd });
  };

  return (
    <form style={{ height: "100%" }}>
      <Wrapper>
        <div>
          <FileUploadField filesNames={files} setFilesNames={setFile} />
          <SamplefileDownloadButton>
            <a href={uploadTemplate} download="ministry_upload_template.csv">
              Click here to download sample file
            </a>
          </SamplefileDownloadButton>

          {csvError.formatError && fileIsAvailable && (
            <Feedback
              variant="error"
              title={csvError.uploadError ? `Network or format error` : `Wrong format`}
              content={<ErrorText>{csvError.uploadError ? `An error occured trying to upload the file, please confirm it matches the sample file pattern and try again.` : `Kindly download and use the format provided`}</ErrorText>}
            />
          )}

          {csvError.contentError && fileIsAvailable && (
            <Feedback
              variant="warning"
              title="Cells with errors"
              content={
                <MessageContents>
                  <MessageContent>
                    <MessageContentTitle>Rows</MessageContentTitle>
                    <MessageContentBody>
                      {data?.errors?.rows?.map((error, index, arr) => `${error}${arr.length - 1 !== index ? `, ` : ``}`)}
                    </MessageContentBody>
                  </MessageContent>
                  <MessageContent>
                    <MessageContentTitle>Insight</MessageContentTitle>
                    <MessageContentBody>
                      {data?.errors?.messages?.map((message, index, arr) => `${message}${arr.length - 1 !== index ? `, ` : ``}`)}
                    </MessageContentBody>
                  </MessageContent>
                </MessageContents>
              }
            />
          )}

          {data?.errors?.failedUpload?.length > 0 && (
            <React.Fragment>
              <CSVLink style={{textDecoration: 'none'}} headers={headers} data={data.errors.failedUpload} filename={"failedUpload.csv"}>
                <SamplefileDownloadButton>Click here to download the file with the wrong entries</SamplefileDownloadButton>
              </CSVLink>
            </React.Fragment>
          )}

          {isSuccess && data?.rowUploadedCount > 0 && (
            <Feedback
              variant="success"
              title="File uploaded successfully"
              content={
                <MessageContents>
                  <MessageContent>
                    <MessageContentTitle>Number of entries</MessageContentTitle>
                    <MessageContentBody>{data?.totalRow}</MessageContentBody>
                  </MessageContent>
                  <MessageContent>
                    <MessageContentTitle>Uploaded</MessageContentTitle>
                    <MessageContentBody>{data?.rowUploadedCount}</MessageContentBody>
                  </MessageContent>
                </MessageContents>
              }
            />
          )}
        </div>
        {!isMinistry ? (
          <RenderButton TextLabel="Send Invite" />
        ) : (
          <RenderButton disabled={!fileIsAvailable} loading={isLoading} handleClick={onUpload} TextLabel="Upload" />
        )}
      </Wrapper>
    </form>
  );
};

const Feedback = ({ variant, title, content }) => (
  <FeedbackWrapper
    paddingBottom={feedbackVariants[variant].style.paddingBottom}
    backgroundColor={feedbackVariants[variant].style.backgroundColor}
  >
    <FeedbackIconWrapper>{feedbackVariants[variant].icon}</FeedbackIconWrapper>
    <FeedbackBody>
      <FeedbackTitle>{title}</FeedbackTitle>
      {content}
    </FeedbackBody>
  </FeedbackWrapper>
);

export default BulkUpload;
