import styled from "styled-components";
import { theme } from "../../util/theme";

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 10px 20.5px 30px 20.5px;
`;

export const FeedbackWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  width: 100%;
  border-radius: 6px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  padding-top: 8px;
  padding-bottom: ${({ paddingBottom }) => paddingBottom};
  padding-left: 12px;
  margin-top: 20px;
`;

export const FeedbackIconWrapper = styled.div`
  width: 28px;
  height: 28px;
  display: flex;
  justify-content: center;
  align-items: center;
  align-items: center;
  margin-right: 12px;
`;

export const FeedbackBody = styled.div``;

export const FeedbackTitle = styled.div`
  height: 24px;
  width: 328px;
  color: #212b36;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: 2px;
`;

export const ErrorText = styled.div`
  height: 20px;
  width: 357px;
  color: #212b36;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 18px;
`;

export const MessageContents = styled.div`
  display: grid;
  grid-gap: 18px;
  margin-top: 25px;
`;

export const MessageContent = styled.div`
  display: grid;
  grid-gap: 5px;
`;

export const MessageContentTitle = styled.div`
  height: 20px;
  width: 145px;
  color: #637381;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const MessageContentBody = styled.div`
  height: 28px;
  width: 359px;
  color: #212b36;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
`;

export const SamplefileDownloadButton = styled.div`
  color: ${theme.color.primary};
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  margin-top: 10px;
  cursor: pointer;

  a {
    text-decoration: none;
  }
`;
