import React, { useContext } from 'react'
import { useParams, Link, useHistory } from 'react-router-dom';
import { useSelector } from "react-redux";
import { useQuery } from 'react-query';
import { Box } from '@material-ui/core';
import Container from "@material-ui/core/Container"
import withAdminDashboard from "../HOC/withAdminDashboard";
import Loader from '../../../components/Common/Loader';
// import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import {
  CategoryUpgradeDetail,
} from '../../../components/CategoryUpgrade';
import { Wrapper, TitleText, BreadCrumblabel, BreadcrumbActive } from './style';
import ToastContext from '../../../util/toastContext';
import vendorsAPI from '../../../redux/api/vendorsAPI';

const VendorUpgradeCategoryDetails = () => {
  const linkStyle = { textDecoration: "none", color: "#2AA96D" };
  const history = useHistory()
  const { showAlert } = useContext(ToastContext);
  const { id } = useParams()
  const { user: { UserId } } = useSelector((state) => state.user);
  const { data: vendorDetailsData } = useQuery(
    ["getVendorDetails", id], vendorsAPI.getVendorDetails,
    {
      enabled: !!id,
      onError: (error) => {
        showAlert({
          severity: "error",
          message: `${error.message} and redirecting back to request list`
        })
        history.push("/admin/vendorsList/vendors/vendor-upgrade-request")
      }
    }
  )
  const { data: vendorUpgradeData, isLoading, isError } = useQuery(
    ["getVendorUpgradeDetails", { vendorId: id }],
    vendorsAPI.getVendorUpgrade,
    {
      enabled: !!id,
      onError: (error) => {
        showAlert({
          severity: "error",
          message: `${error.message} and redirecting back to request list`
        })
        history.push("/admin/vendorsList/vendors/vendor-upgrade-request")
      }
    }
  )
  const vendorStatus = vendorDetailsData?.status === "CLASSUPGRADE"

  return (
    <Container>
      <Box my={3}>
        <>
          <Container maxWidth="sm">
            <Wrapper>
              <TitleText>Upgrade Request Details</TitleText>
              <div style={{ margin: "20px 0" }}>
                <BreadCrumblabel>
                  <Link style={linkStyle} to="/admin/vendorsList/vendors/vendor-upgrade-request">
                    Upgrade List
                  </Link>&nbsp;
                  <BreadcrumbActive>/{" Upgrade Details"}</BreadcrumbActive>
                </BreadCrumblabel>
              </div>
              {isLoading || isError ? <Loader /> :
                <CategoryUpgradeDetail
                  vendorUpgradeData={vendorUpgradeData}
                  vendorId={id}
                  userId={UserId}
                  vendorStatus={vendorStatus}
                />
              }

            </Wrapper>
          </Container>
        </>

      </Box>
    </Container>
  )
}
export default withAdminDashboard(VendorUpgradeCategoryDetails)
