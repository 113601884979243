import styled from "styled-components";
import { Menu } from "@material-ui/core";
import { Link } from "react-router-dom";
import {theme } from "../../../util/theme";

export const CrumbLink = styled(Link)`
  text-decoration: none;
  color: ${theme.color.primary};
  font-weight: 500;
  font-size: 12px !important;
`;

export const MenuList = styled(Menu)`
  .MuiMenu-paper {
    box-shadow: 0 0 5px #e4e4e4;
  }
`;
