import React from "react";
import { Footer, GreyText, ListContent, PageWrapper } from "./style";
import Typography from "@material-ui/core/Typography";
import { Navbar } from "../../../components";
import { Box, Container } from "@material-ui/core";
import { policies } from "./policyData";

const TermsAndConditions = () => {
  return (
    <Box>
      <Navbar />
      <Container>
      <PageWrapper>
        <Typography className="title" variant="h3">
          Privacy Policy
        </Typography>
        <GreyText variant="subtitle1" gutterBottom>
          Last updated September 2nd, 2021
        </GreyText>
        <Box my={5}>
          <GreyText variant="body1">
            The Privacy Policy covers your use of the E-Procurement Solution for Kwara State (“The App”). Please read the policy
            carefully as it affects your rights and liabilities under the law. By accessing this application, you are indicating
            your acceptance of the Privacy Policy, our terms of use and any other applicable policy. If you do not agree to terms
            of the Data Privacy Policy, please do not access and or use the App.{" "}
          </GreyText>
        </Box>
        <Box mb={10}>
        {policies.map((policy, index) => (
          <Box key={index}>
            <Box my={3}>
              <Typography className="title" variant="h6">
                {policy.id}. {policy.title}
              </Typography>
              {policy.list.map((item, _) => (
                <ListContent>
                  <GreyText>{item.id}.</GreyText>
                  <GreyText>{item.content}</GreyText>
                </ListContent>
              ))}
            </Box>
          </Box>
        ))}
        </Box>
        <Footer>
          <GreyText variant="subtitle2"> &#169; Copyright {new Date().getFullYear()} Delta State. All rights reserved</GreyText>
          <GreyText variant="subtitle2">Powered by: Prunedge</GreyText>
        </Footer>
      </PageWrapper>
      </Container>
    </Box>
  );
};

export default TermsAndConditions;
