import React from "react";
import Chip from "@material-ui/core/Chip";
import ClearIcon from "@material-ui/icons/Clear";
import Typography from "@material-ui/core/Typography";

import {
  Wrapper, Header, Filters, FilterTags,
} from "./style";

const AdminSearchFilter = ({
  children,
  toggleSearch,
  advanceSearch = false,
  normalSearch = false,
  title,
  bold,
  noMarginTop,
  noPadding,
  renderHeadingLeft,
}) => (
  <Wrapper style={{ marginTop: noMarginTop ? 0 : null }}>
    <Header>
      {renderHeadingLeft ? (
        renderHeadingLeft()
      ) : (
        <Typography style={{ fontWeight: "bold", fontSize: "16px", padding: "10.5px 0 10.5px 12px" }}>
          {title || "Search"}
        </Typography>
      )}

      {/* {!normalSearch && (
        <AdvanceText onClick={() => toggleSearch()}>{advanceSearch ? "Basic Search" : "Advance Search"}</AdvanceText>
      )} */}
    </Header>
    <Filters noPadding={noPadding}>
      {children}
      {advanceSearch && (
        <FilterTags>
          <Chip label="Olayemi" onDelete={() => { }} color="primary" deleteIcon={<ClearIcon />} />
          <Chip label="Min. of Works" onDelete={() => { }} color="primary" deleteIcon={<ClearIcon />} />
        </FilterTags>
      )}
    </Filters>
  </Wrapper>
);

export default AdminSearchFilter;
