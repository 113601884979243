import styled from "styled-components";
import { Grid, Typography } from "@material-ui/core";

export const UserPlaceHolder = styled.div`
  border-radius: 50%;
  background-color: green;
  height: 20px;
  width: 20px;
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: 20px !important;
  text-align: center !important;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px !important;
`;

export const ActiveStatus = styled(Typography)`
  border: 2px solid #ffffff;
  border-radius: 100px;
  background-color: #d6efd2;
  color: #118043;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 0 10px;
  display: inline-block;
  text-transform: capitalize;
`;

export const ProjectProgressContainer = styled.div`
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  margin-left: 23px !important;
  padding: 20px !important;
`;

export const ProjectDocumentContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #f4fcff;
  padding: 20.5px;
  margin-top: 19.5px;
`;

export const EmptyProjectMilestone = styled.div`
  min-height: 462px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const ProcurementInfoWrapper = styled(Grid)`
  padding-top: 20px;
  padding-bottom: 20px;
`;

export const ProcurementDescription = styled.div`
  h3 {
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    color: #1e2b37;
  }
`;

export const ProcurementInfo = styled(Grid)`
  h4 {
    font-weight: 400;
    fontsize: 16px;
    color: #1e2b37;
  }
  p {
    font-weight: 600;
    font-size: 14px;
    color: #5f7483;
  }
`;

export const ProcurementPublishedDate = styled(Grid)`
  background: #f2f2f2;
  border: 1px solid #c9d4db;
  border-radius: 0px 0px 0px 3px;
  color: #5f7483;
  h6 {
    font-weight: 400;
    fontsize: 13px;
  }
  p {
    font-weight: 600;
    fontsize: 14px;
  }
`;

export const ProcurementInfoHeading = styled(Typography)`
  font-size: 13px;
  line-height: 16px;
  /* identical to box height, or 123% */

  color: #5f7483;
  font-size: 14px;
`;

export const ProcurementInfoSubtext = styled(Typography)`
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  /* identical to box height, or 125% */

  color: #212b36;
`;

export const ViewMoreBtn = styled.span`
  font-size: 15px;
  line-height: 16px;
  /* identical to box height, or 107% */

  color: #2AA96D;
  cursor: pointer;
`;

export const ItemsGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
`;
