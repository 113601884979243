import styled from "styled-components";
import { Typography } from "@material-ui/core";
import DoneIcon from "@material-ui/icons/Done";
import { ProcurementInformationContainer } from "../BidDetails/BidInfo/style";
import { TagColorScheme } from "../../../util/constants";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";

export const UserPlaceHolder = styled.div`
  border-radius: 50%;
  background-color: green;
  height: 20px;
  width: 20px;
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: 20px !important;
  text-align: center !important;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px !important;
`;

export const ActiveStatus = styled(Typography)`
  border: 2px solid #ffffff;
  border-radius: 100px;
  background-color: ${(props) => TagColorScheme[props.status]?.bgColor};
  color: ${(props) => TagColorScheme[props.status]?.textColor};
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px;
  text-align: center;
  padding: 0 10px;
  display: inline-block;
`;

export const ProjectProgressContainer = styled.div`
  box-sizing: border-box;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  margin-left: 23px !important;
  padding: 20px !important;
`;

export const ProjectDocumentContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #f4fcff;
  padding: 20.5px;
  margin-top: 19.5px;
`;

export const EmptyProjectMilestone = styled.div`
  min-height: 462px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  margin-top: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const MarkedCompleteContainer = styled.div`
  padding: 8px 15px;
  border-radius: 3px;
  background-color: #dcf6ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`;

export const MarkedCompleteText = styled(Typography)`
  color: #2AA96D;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 20px !important;
`;

export const MarkDoneIcon = styled(DoneIcon)`
  color: #2AA96D;
  font-size: 14px !important;
  margin-right: 10px;
`;

export const UploadImageContainer = styled(ProcurementInformationContainer)`
  > div {
    border: none !important;
    padding: 0 !important;
  }
`;

export const ImagesContainer = styled.div`
  display: flex;
  img {
    margin-right: 16px !important;
  }
`;

export const TaskTitle = styled.p`
  font-size: 16px;
  color: #1e2b37;
  font-weight: bold;
  margin-bottom: 10px;
`;

export const SectionLabel = styled.p`
  font-size: 13px;
  color: #5f7483;
  ${(props) => props.hasMargin && "margin-bottom: 10px"}
`;

export const TaskDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #637381;
`;

export const SectionDivider = styled.p`
  width: 100%;
  background-color: #cbd4da;
  margin: 20px 0;
  height: 1px;
`;

export const MarkCompleteButton = styled(LoadingButton)`
  &.MuiButtonBase-root {
    color: #2AA96D;
  }
`;

export const DateGrid = styled.div`
  display: grid;
  grid-template-columns: 15px auto;
  grid-column-gap: 10px;
  flex-basis: 25%;
`;

export const UnderReviewIcon = styled.div`
  display: inline-flex;
  height: 40px;
  width: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: #f2994a;
  color: #fff;
`;
