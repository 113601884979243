import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography"
import LoadingButton from "../LoadingButton/LoadingButton";
import { theme } from "../../util/theme";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import Modal from "@material-ui/core/Modal"

export const StyledRadioLabel = styled.div`
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 240px;
  width: 100%;
  min-width: 220px;
  border: 1.5px solid #dfe3e8;
  border-radius: 5px;
  padding: 15px 26px 20px 26px;
  padding-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    background: #f9fafb;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .class {
    margin-bottom: 10px;
    height: 20px;
    width: 78px;
    border-radius: 3px;
    background-color: rgba(3, 173, 235, 0.07);
    .value,
    p {
      height: 20px;
      color: #2AA96D;
      font-family: "Nunito Sans";
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
  }
  .contract-fee {
    .fee {
      height: 20px;
      width: 88px;
      color: #454f5b;
      font-family: "Nunito Sans";
      font-size: 16px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
    .title {
      height: 20px;
      width: 100px;
      color: #919eab;
      font-family: "Nunito Sans";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
  }

  .separator {
    height: 1px;
    width: 28px;
    border-bottom: 1px solid #c4cdd5;
    margin: 10px 10px 0;
  }

  .renewal {
    margin-top: 6px;
    font-style: Regular;
    font-size: 12px;
    line-height: 20px;
    color: #8e9fac;
  }

  .long-separator {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #c4cdd5;
    margin-top: 17px;
  }

  .contract-value {
    margin-top: 20px;
    .value {
      height: 20px;
      width: 115px;
      color: #424f5c;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
    .title {
      height: 20px;
      width: 118px;
      color: #919eab;
      font-family: "Nunito Sans";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
  }
`;
export const StyledTypogaphyText = styled(Typography)`
  &.MuiTypography-root {    
    font-size: 16px;
    line-height: 20px;
  }
`;

export const StyledTypographyBold = styled(Typography)`
&.MuiTypography-root {    
  font-size: 16px;
  line-height: 20px;
  font-weight: 800;
}

`;

export const TextLogo = styled(Typography)`

`;

export const StyledBox = styled(Box)`
  text-decoration: none;
`

export const StyledGrid = styled(Grid)`

`;

export const RejectButton = styled(LoadingButton)`
  margin-left: auto;
  width: 171px;
  border: 1px solid #ebebeb !important;
  border-radius: 8px;
  background-color: ${(props) => (props.disabled ? "#c9d4db !important" : `#F0F5FF !important`)};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
  span {
    color: ${theme.color.primary} !important;
  }
  text-transform: none !important;
  &:hover {
   background-color: ${theme.color.danger} !important;
   color: ${theme.color.white} !important;
  }

  .MuiButton-label {
    &:hover {
      color: ${theme.color.white} !important;
    }
  }
`;

export const PrevBtn = styled.button`
  color: ${theme.color.primary};
  background: #fff;
  box-sizing: border-box;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #2AA96D;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    background: ${theme.color.danger};
    color: ${theme.color.white}
  }
`;

export const SubmitButton = styled(LoadingButton)`
  color: #ffffff;
  box-sizing: border-box;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: ${theme.color.danger};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;

  &:disabled,
  &[disabled]{
  background-color: #c9d4db;
  cursor: not-allowed;
}
`;



export const CustomDialog = styled(Dialog)`
  border-radius: 3px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border: 1px solid #EBEBEB;
  backdrop-filter: blur(3px);
  .MuiDialog-paper {
    padding: 30px 32px 40px;
    min-width: 550px;
  }
`;

export const CustomDialogTitleWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

export const CustomDialogTitle = styled(DialogTitle)`
  padding: 0 !important;
  color: ${theme.color.primary};
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 33px;
`;

export const Caption = styled.p`
  border-radius: 3px;
  background-color: rgba(3, 173, 235, 0.07);
  padding: 10px 15px;
  text-align: left;
  color: #2AA96D;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
`;

export const CustomDialogContent = styled(DialogContent)`
  padding: 20px 0 !important;
  display: grid;
  grid-gap: 20px;
`;

export const CustomDialogActions = styled(DialogActions)`
  
`;

export const StyledModal = styled(Modal)`
  border-radius: 3px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border: 1px solid #EBEBEB;
  backdrop-filter: blur(3px);
  .MuiDialog-paper {
    padding: 30px 32px 40px;
    min-width: 550px;
  }

`
export const ModalBody = styled.div`
  position: absolute;
  top: 140px;
  left: calc(50% - 200px);
  width: 100%;
  max-width: 400px;
  min-height: 200px;
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
`

export const ModalTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #3F5673;
  margin-bottom: 30px;
`

export const ModalDescription = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #3F5673;
  margin-bottom: 20px;
`

export const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr 1fr;
  grid-column-gap: 10px;
  margin-top: auto;
`
