import React, { useContext, useState } from "react";
import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { useMutation, useQuery } from "react-query";

import paymentAPI from "../../../../../redux/api/paymentAPI";
import ToastContext from "../../../../../util/toastContext";
import { SubmitButton } from "../PlaceBid/style";
import { CALLBACK_ROUTES } from "../../../../../util/constants";
import Iframe from "react-iframe";
import { CustomDialog, CustomDialogTitle, CustomDialogTitleWrapper, PrevBtn } from "../../../../../components/Forms/ConfirmVerificationModal/style";


export default function OnlinePayment({ refetch, tenderId }) {
  const { showAlert } = useContext(ToastContext);
  let hostURL = window.location.origin;
  const [paymentIframe, setPaymentIframe] = useState({ display: false, url: "" });
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkPayStatus, setCheckPayStatus] = useState(false);
  const [paymentGatewayFailure, setPaymentGatewayFailure] = useState(false);

  const { isLoading: checkingPay } = useQuery(["verifyBidPayment", tenderId], paymentAPI.getBidPaymentStatus, {
    onSuccess: (data) => {
      if (data.paymentStatus === "PAID") {
        setIsModalOpen(false)
        showAlert({
          severity: "success",
          message: "Payment verified successfully",
          durationInMs: 3000,
        });
      } else {

        showAlert({
          severity: "error",
          message: "Payment couldn't be verified, please try again",
          durationInMs: 3000,
        });
      }
    },
    enabled: checkPayStatus,
    onSettled: () => {
      setCheckPayStatus(false)
      refetch()
    }
  })

  const [invokePaystack, { isLoading: loadingPayStack, error }] = useMutation(paymentAPI.initiatePayment, {
    onSuccess: (data) => {
      setIsModalOpen(true)
      setPaymentIframe({ display: true, url: data.data });
    },
    onError(error) {
      setPaymentGatewayFailure(true);
    },
  });

  const handlePayment = () => {
    invokePaystack({
      callBack: `${hostURL}/${CALLBACK_ROUTES.BID}/${tenderId}`,
      paymentType: 2,
      tenderId,
    });
  };

  return (
    <>
      <CustomDialog scroll="body" open={isModalOpen}>
        {paymentIframe.display && (
          <>
            <Iframe url={paymentIframe.url} width="100%" height="500px" id="payFrame" />
            <Grid style={{ marginTop: "20px" }} container justify="space-between">
              <PrevBtn onClick={() => setIsModalOpen(false)}>Close</PrevBtn>
              <SubmitButton disabled={checkingPay} onClick={() => setCheckPayStatus(true)}>
                I have paid
              </SubmitButton>
            </Grid>
          </>
        )}
      </CustomDialog>
      <CustomDialog
        scroll="body"
        onClose={() => setPaymentGatewayFailure(false)}
        open={paymentGatewayFailure}
      >
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>An error occured</CustomDialogTitle>
        </CustomDialogTitleWrapper>
        <Box my={5}>
          <Alert severity="error">
            <AlertTitle>Payment Gateway Error</AlertTitle>
            {error?.data?.message}
          </Alert>
        </Box>
        <Grid container justify="space-between">
          <PrevBtn onClick={() => {
            setPaymentGatewayFailure(false);
          }}>Close</PrevBtn>
        </Grid>
      </CustomDialog>
      <Box mb={3.75}>
        <SubmitButton disabled={loadingPayStack} onClick={handlePayment}>
          {loadingPayStack ? "Please wait..." : "Pay Now"}
        </SubmitButton>
      </Box>
    </>
  );
}
