import React, { useContext, useState, useEffect } from "react";
import { useQuery } from "react-query";
import Chart from "react-apexcharts";
import withAdminDashboard from "../HOC/withAdminDashboard";
import { PageTitle } from "../../Common/style";
import AdminCard from "../../../components/AdminCard";
import { CardWrapper } from "../../../util/theme";
import { DetailsGrid, PageWrapper, ChartTitleText } from "./style";
import adminAPI from "../../../redux/api/adminAPI";
import { Box, Grid } from "@material-ui/core";
import { barOption } from "../../../util/chart";
import OverviewPiechart from "./OverviewPiechart";
import { formatCurrency } from "../../../util/formatCurrency";
import { Skeleton } from "@material-ui/lab";
import ToastContext from "../../../util/toastContext";
import OverviewFilter from "./OverviewFilter";
import { Roles } from "../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../hooks/useUserDetails";

const AdminOverview = () => {
  const { role, user } = useRoles();
  const limitAccessibleMinistryByRole = [Roles.MODERATOR, Roles.PROCUREMENT_OFFICER, Roles.PERMANENT_SECRETARY].includes(role);
  const isCommissioner = [Roles.COMMISSIONER].includes(role);
  const { showAlert } = useContext(ToastContext);
  const [makeRequest, setMakeRequest] = useState(true);
  const [filterParams, setfilterParams] = useState({
    MinistryId: getMinistryId().ministryId,
    Year: null,
  });

  useEffect(() => {
    setMakeRequest(true);
  }, [filterParams]);
  // const allproject = useQuery("adminAllProject", adminAPI.getAllProject);

  const {
    data: { contractSummary, contractAward, projectStatus, contractCategory = [] } = {},
    isLoading: contractSummaryLoading,
  } = useQuery(["getContractSummary", filterParams], adminAPI.getContractSummary, {
    enabled: makeRequest,
    onSuccess: () => {
      setMakeRequest(false);
    },
    onError: (error) => {
      setMakeRequest(false);
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  const contractCategoryData = contractCategory.map((category) => category.category);
  const contractCategoryValue = contractCategory.map((category) => category.count);

  const contractAwardedLegend = {
    Awarded: {
      percent: contractAward?.awardedPercentage,
      count: contractAward?.awardedCount,
      color: "#FF8D26",
    },
    Reviewed: {
      percent: contractAward?.reviewedPercentage,
      count: contractAward?.reviewedCount,
      color: "#F21500",
    },
    "Awaiting approval": {
      percent: contractAward?.awaitingApprovalPercentage,
      count: contractAward?.awaitingApprovalCount,
      color: "#1D4576",
    },
    "Under development": {
      percent: contractAward?.underDevelopmentPercentage,
      count: contractAward?.underDevelopmentCount,
      color: "#2AA96D",
    },
    Completed: {
      percent: contractAward?.completedPercentage,
      count: contractAward?.completedCount,
      color: "#C9D4DB",
    },
  };

  const projectStatusLegend = {
    Active: {
      percent: projectStatus?.activePercentage,
      count: projectStatus?.activeCount,
      color: "#FF8D26",
    },
    Inactive: {
      percent: projectStatus?.inactivePercentage,
      count: projectStatus?.inactiveCount,
      color: "#1D4576",
    },
    Completed: {
      percent: projectStatus?.completedPercentage,
      count: projectStatus?.completedCount,
      color: "#2AA96D",
    },
  };

  function getMinistryId(MinistryId) {
    let ministryId;
    let showMinistryFilter = true;
    if (isCommissioner) {
      if (!!user.ministry?.id) {
        ministryId = user.ministry.id;
        showMinistryFilter = false;
      } else {
        ministryId = MinistryId;
      }
    } else if (limitAccessibleMinistryByRole) {
      ministryId = user.ministry.id;
      showMinistryFilter = false;
    } else {
      ministryId = MinistryId;
    }
    return {
      ministryId,
      showMinistryFilter,
    };
  }

  return (
    <PageWrapper>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <PageTitle>Dashboard</PageTitle>
        <OverviewFilter
          showMinistryFilter={getMinistryId().showMinistryFilter}
          setfilterParams={(filter) =>
            setfilterParams((state) => ({ ...filter, MinistryId: getMinistryId(filter.MinistryId).ministryId }))
          }
        />
      </div>
      <DetailsGrid container spacing={2}>
        <CardWrapper>
          <AdminCard
            hideIcon
            isLoading={contractSummaryLoading}
            title="Contracts Awarded"
            count={contractSummary?.contractCount || 0}
          />
        </CardWrapper>
        <CardWrapper>
          <AdminCard
            hideIcon
            isLoading={contractSummaryLoading}
            title="Value of Contracts Awarded"
            count={
              <span>
                &#8358;{!!contractSummary?.estimatedValue ? formatCurrency(contractSummary?.estimatedValue?.toFixed(2)) : 0}
              </span>
            }
          />
        </CardWrapper>
        <CardWrapper>
          <AdminCard
            hideIcon
            isLoading={contractSummaryLoading}
            title="Average Time To Award Contract"
            count={
              <span>
                {contractSummary?.averageTime || 0} Day{contractSummary?.averageTime > 1 ? "s" : ""}
              </span>
            }
          />
        </CardWrapper>
      </DetailsGrid>

      <Box mt="30px" mb="30px">
        <Grid container spacing={3}>
          <OverviewPiechart
            title="Tender Package"
            label="Total Tender"
            labels={Object.keys(contractAwardedLegend)}
            legends={contractAwardedLegend}
            isLoading={contractSummaryLoading}
          />
          <OverviewPiechart
            title="Project Status (Awarded Contracts)"
            label="Awarded Contract"
            labels={Object.keys(projectStatusLegend)}
            legends={projectStatusLegend}
            isLoading={contractSummaryLoading}
          />
        </Grid>
      </Box>

      <CardWrapper style={{ width: "100%" }}>
        <ChartTitleText>Contract Awarded By Categories</ChartTitleText>
        {contractSummaryLoading && <Skeleton width="100%" height={320} variant="rect" />}
        {!contractSummaryLoading && (
          <Box>
            <Chart
              options={{
                ...barOption,
                xaxis: {
                  ...barOption.categories,
                  categories: contractCategoryData,
                },
              }}
              series={[
                {
                  name: "ministry",
                  data: contractCategoryValue,
                },
              ]}
              type="bar"
              height={320}
            />
          </Box>
        )}
      </CardWrapper>
    </PageWrapper>
  );
};

export default withAdminDashboard(AdminOverview);
