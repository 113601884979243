import styled from "styled-components";
import { ReactComponent as PdfIcon } from "../../assets/pdf.svg";

export const CardContainer = styled.div`
  box-sizing: border-box;
  height: 163px;
  width: 100%;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: 600px) {
    padding: 12px;
  }

  &:hover {
    border: 1px solid #cbd4da;
    border-radius: 3px;
    background-color: #2aa96d;
    cursor: pointer;
    color: #ffffff;

    span {
      color: #ffffff;
    }

    svg {
      fill: #ffffff;
    }
  }
`;

export const CardTitle = styled.p`
  color: #274472;
  text-transform: uppercase;
  text-decoration: none !important;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
  margin-top: 20px;

  @media screen and (max-width: 600px) {
    font-size: 10px;
  }
`;

export const CardDate = styled.p`
  height: 21px;
  width: 167px;
  color: #adb4bb;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 21px;
  text-align: center;
`;

export const CardPdfIcon = styled(PdfIcon)`
  height: 40px;
  width: 40px;
  fill: ${({ iconcolor }) => iconcolor};
`;
