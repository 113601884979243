import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { theme } from "../../../util/theme";


export const PageWrapper = styled.div`
margin: 49px 0 21px;
.title {
    font-weight: bold;
    color: ${theme.color.textMain}
}
`

export const ListContent = styled.div`
display: flex;
align-items: start;
margin: 10px;

> p:first-child{
    margin-right: 8px;
  }
`;

export const GreyText = styled(Typography)`
color: ${theme.color.textSecondary}
`

export const Footer = styled.div`
display: flex;
justify-content: space-between;
align-items: center;
color: #8E9FAC;
`
