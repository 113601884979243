import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { withStyles } from "@material-ui/core";

export const CommentInputWrapper = styled.div`
  border: 1px solid #c9d4db;
  border-radius: 4px;
  background-color: #ffffff;
  position: relative;

  .text-input {
    min-height: 75px;
    width: 100%;
    padding: 0 5px;
    box-sizing: border-box;
    font-size: 14px;
  }

  .grid {
    background-color: #f8f8f9;
    height: 50px;
    padding: 0 14px 0 8px;
  }
`;

export const ActionButtons = styled.div`
  display: flex;

  button {
    min-width: unset;
  }

  svg {
    width: 20px;
    height: 20px;
  }
`;

export const SendButton = withStyles(() => ({
  root: {
    color: "white",
    backgroundColor: "#2AA96D",
    minWidth: "unset",
    padding: "10px 15px",
    transition: "all .3s",
    opacity: 0.85,

    "&:hover": {
      opacity: 1,
      backgroundColor: "#2AA96D",
    },

    "&:disabled": {
      filter: "brightness(50%)",
    },
  },
}))(Button);

export const SendButtonIcon = styled.img`
  width: 20px;
  height: 20px;
`;
