import { Link } from "react-router-dom";
import styled from "styled-components";

export const ResponseStats = styled.div`
  display: grid;
  grid-template-columns: 220px auto 105px;
  height: 100px;
  background: #FAFBFC;
  border: 1px solid #C9D4DB;
  box-shadow: 0 2px 4px #C9D4DB;
  border-radius: 3px;
  margin-bottom: 30px;
`

export const ResponseStatsLabel = styled.p`
  font-size: 14px;
  line-height: 19px;
  color: ${(props) => props.color};
  margin-bottom: 5px;
`

export const ResponseStatsValue = styled.p`
  font-size: 26px;
  color: #1E2B37;
`

export const TotalResponse = styled.div`
  background-color: white;
  padding-left: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  border-right: 1px solid #C9D4DB;
`

export const ViewAllLink = styled(Link)`
  color: #2AA96D;
  text-decoration: underline;
`

export const ViewAllLinks = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-right: auto;
  height: 100%;
  padding: 24px 0;
  box-sizing: border-box; 
`
