import React from "react";
import { Grid, Container } from "@material-ui/core";
import {
  SectionWrapper,
  Section1,
  QuoteWrapper,
  Quote,
  Image,
  VisionOrMissionTitle,
  VisionOrMissionDescription,
  VisionAndMission,
  VisionOrMission,
  MemberInfo,
  MemberImage,
  MemberCard,
  MemberName,
  MemberRole,
  PeopleSection,
  SectionTitle,
  PeopleSectionsContent,
  PeopleSections,
  OpenQuoteIcon,
  CloseQuoteIcon,
} from "./style";
import Hero from "../../../components/Hero";
import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import { team } from "./mock";
import { HandIcon } from "../Landing/style";

const About = () => (
  <div>
    <Hero
      title="About Us"
      desc="Are you interested in bidding for a contract for your organization or for an individual? Click the link below to register"
    />
    <SectionWrapper>
      <Container>
        <Section1>
          <QuoteWrapper>
            <OpenQuoteIcon>“</OpenQuoteIcon>
            <Quote>
              The Kwara state Public Procurement Agency (the Agency) was established on the 2nd of April, 2017. The objectives of
              the Agency are to ensure probity, accountability and transparency in Public Procurement throughout Kwara state. The
              Agency is therefore charged with the responsibility of formulating Policies and guidelines relating to Procurement
              in Kwara state as well as certifying all Procurements prior to, during and after the awards of Contract.
            </Quote>
            <CloseQuoteIcon>“</CloseQuoteIcon>
          </QuoteWrapper>
          <Image></Image>
        </Section1>
      </Container>
    </SectionWrapper>
    <Container>
      <SectionWrapper>
        <VisionAndMission>
          <Grid container>
            <Grid item xs={12} sm={6} md={6}>
              <VisionOrMission>
                <HandIcon style={{ fontSize: 30 }} />
                <VisionOrMissionTitle>Vision</VisionOrMissionTitle>
                <VisionOrMissionDescription>
                  To be the foremost Public Procurement Regulator in Nigeria, upholding global best practices in Public
                  procurement.
                </VisionOrMissionDescription>
              </VisionOrMission>
            </Grid>
            <Grid item xs={12} sm={6} md={6}>
              <VisionOrMission>
                <HandIcon style={{ fontSize: 30 }} />
                <VisionOrMissionTitle>Mission</VisionOrMissionTitle>
                <VisionOrMissionDescription>
                  To operate a transparent, competitive, sustainable and value-for-money public procurement system that supports
                  the State’s development goals and engenders public confidence.
                </VisionOrMissionDescription>
              </VisionOrMission>
            </Grid>
          </Grid>
        </VisionAndMission>
      </SectionWrapper>
    </Container>
    <Container>
      <SectionWrapper>
        <PeopleSections>
          <SectionTitle>Management Board</SectionTitle>
          <hr />
          <PeopleSectionsContent>
            <PeopleSection>
              <Grid container spacing={3}>
                {team.map((member) => (
                  <MemberCard item xs={6} sm={4} md={3} key={member.id}>
                    <MemberImage style={{ backgroundImage: `url(${member.image})` }}></MemberImage>
                    <MemberInfo>
                      <MemberName>
                        <strong>{member.name}</strong>
                      </MemberName>
                      <MemberRole>{member.position}</MemberRole>
                    </MemberInfo>
                  </MemberCard>
                ))}
              </Grid>
            </PeopleSection>
          </PeopleSectionsContent>
        </PeopleSections>
      </SectionWrapper>
    </Container>
  </div>
);

export default withHeaderFooterHOC(About);
