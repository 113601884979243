import React, { useState }  from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { coreCompetencies, organizationTypes } from "../../../components/Forms/GeneralCompanyDetails/mock";
import { BusinessAddressContainer, StatusButton, RCText, TextLabel, ValueText } from "./style";
import { FlexContainer } from "../../Homepage/Landing/style";
import { Box, Grid } from "@material-ui/core";
import { SaveButton } from "../../../components/Forms/Common/style";
import { useHistory, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import BlacklistModal from "./VendorVerify/BlacklistModal";


const vendorState = {
  PENDING: "Verify vendor",
  APPROVED: "Suspend vendor",
  SUSPENDED: "Activate vendor",
  REJECTED: "Verify vendor"
}

const VendorCompanyDetails = () => {
  const { companyDetails } = useSelector((state) => state.vendor);
  const [isOpen, toggleModal] = useState(false);
  const [status, setStatus] = useState(companyDetails?.status)
  const { coreCompetency, organizationType } = Object.keys(companyDetails).length && companyDetails;
  const history = useHistory();
  const { vendorId } = useParams();
  const { data: vendorDetails } = useQuery(["companyDetails", { vendorId }], vendorsAPI.getVendorsCompanyDetails);

  const [ toggleVendorStatus, {isLoading} ] = useMutation(vendorsAPI.toggleVendorStatus, {
    onSuccess: (data) => {
      toggleModal(false);
      setStatus(data?.status)
    }
  })

  const handleVendorAction = () => {
    if(status === "PENDING" || status ===  "REJECTED"){
      history.push(`/admin/vendors/${vendorId}/${companyDetails?.id}/verify-vendor`)
    }else if(status === "APPROVED") {
     toggleModal(true)
    }else if(status === "SUSPENDED") {
      toggleVendorStatus({status: 2, vendorId})
    }
  }
  return (
    <Box>
      <BlacklistModal toggleModal={toggleModal} isOpen={isOpen} toggleFn={toggleVendorStatus} vendorId={vendorId} loading={isLoading}/>
      <Grid container>
        <Grid item xs={2}>
          <Box display="flex" flexDirection="column" justifyContent="center" height="100%">
            <Box>
              <StatusButton status={status}>{status}</StatusButton>
              <RCText>{companyDetails.cacRegistrationNumber}</RCText>
            </Box>
          </Box>
        </Grid>
        <Grid item style={{ flex: 1 }}>
          <FlexContainer>
            <div>
              <TextLabel>Area of Competence</TextLabel>
              <ValueText>{coreCompetency && coreCompetencies.find((c) => Number(c.value) === coreCompetency).label}</ValueText>
            </div>
            <div>
              <TextLabel>Type of Organization</TextLabel>
              <ValueText>
                {organizationType && organizationTypes.find((o) => Number(o.value) === organizationType).label}
              </ValueText>
            </div>
            <div>
              <TextLabel>Date of Registration</TextLabel>
              <ValueText>
                {(moment(vendorDetails?.createdAt).format("Do MMM, YYYY")) || "N/A"}
              </ValueText>
            </div>
            <div>
              <TextLabel>Capital Contribution By Partners</TextLabel>
              <ValueText>NGN {companyDetails.authorizedShareCapital}</ValueText>
            </div>
          </FlexContainer>
          <Box display="flex" justifyContent="space-between" mt="20px" alignItems="center">
            <BusinessAddressContainer style={{ marginTop: 0 }}>
              <TextLabel>Business Address</TextLabel>

              <ValueText>
                {companyDetails.correspondenceAddress1}, {companyDetails.correspondenceAddress2},{" "}
                {companyDetails.correspondenceCity}, {companyDetails.correspondenceState} State,{" "}
                {companyDetails.correspondenceCountry}
              </ValueText>
            </BusinessAddressContainer>
            <Box flexShrink={0}>
              <SaveButton status={status} onClick={handleVendorAction}>{vendorState[status]}</SaveButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default VendorCompanyDetails;
