import React from 'react';

import styled from "styled-components";


export const FixedActivityTabWrapper = styled.div`
  position: sticky;
  height: max-content;
  top: 80px;
  right: 0;
  @media screen and (max-width: 767px) {
    display: none
  }
`;

const FixedActivityTab = ({ children }) => (
  <FixedActivityTabWrapper>{children}</FixedActivityTabWrapper>
);

export default FixedActivityTab;