import React from "react";
import { Container, Grid } from "@material-ui/core";
import PropTypes from "prop-types";
import { Nav } from "./style";
import Logo from "../../assets/kwara_logo.svg";
import { useHistory } from "react-router-dom";

const Navbar = (props) => {
  const history = useHistory();

  return (
    <Nav>
      <Container>
        <Grid container direction="row" justify="space-between" alignItems="center">
          <img src={Logo} alt="Kwara state logo" className="state_logo" onClick={() => history.push("/")} />
          {props.rightContent}
        </Grid>
      </Container>
    </Nav>
  );
};

Navbar.propTypes = {
  rightContent: PropTypes.node,
};

export default Navbar;
