import styled from "styled-components";
import { FormTitle } from "../../../util/theme";
import { theme } from "../../../util/theme";
import { Checkbox } from "@material-ui/core";



export const Wrapper = styled.div`
  display: block;
  text-align: left;
  width: 100%;
`;

export const TitleText = styled.p`
  margin: 20px 0 5px 0;
  height: 22px;
  color: #445571;
  font-family: "Nunito Sans";
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 22px;
`;

export const RegisterFormTitle = styled(FormTitle)`
  margin-bottom: 0 !important;
`;

export const TermsAndCondition = styled.div`
display: flex;
color: ${theme.color.textColor1};
align-items: center;

> div:first-child{
  margin-right: 10px;
}

.MuiIconButton-colorPrimary {
  color: #C4CDD5;
}

span.Mui-checked {
  color: ${theme.color.primary};
}

.link {
  color: ${theme.color.primary};
  text-decoration: none;
}
`

export const AppCheckBox = styled(Checkbox)`
`
