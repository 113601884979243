import React, { useContext, useEffect } from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useQuery } from "react-query";
import moment from "moment";

import { Link, useHistory, useParams } from "react-router-dom";
import { PageContainer, PageTitle } from "../../Common/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { SearchButton } from "../../../components/Forms/Common/style";
import { InfoContainer } from "../BidDetails/BidInfo/style";
import { EmptyProjectMilestone, ItemsGrid } from "./style";
import { TableSubTextStyle, TableTextStyle } from "../Projects/style";
import MilestoneModal from "./MilestoneModal/index";
import CalendarTimeLine from "../../../components/CalendarTimeline";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import ToastContext from "../../../util/toastContext";
import withVendorDashboard from "../HOC/withVendorDashboard";
import Loader from "../../../components/Common/Loader";
import Skeleton from "@material-ui/lab/Skeleton";
import ProjectProgressBar from "../../Admin/Procurement/Activitiies/ContractSupervision/ProjectProgressBar";
import { HL, InfoCardWrapper, MainText, MainTitle } from "../Procurement/style";

const ProjectInfo = () => {
  const { projectId: id } = useParams();
  const history = useHistory();
  const [open, setOpen] = React.useState(false);
  const { showAlert } = useContext(ToastContext);
  const { data: milestones, isLoading, status, error } = useQuery(["projectMileStones", id], vendorsAPI.getProjectMilestones);
  const { status: projectStatus, error: projectError, isLoading: projectIsLoading, data: project } = useQuery(
    ["project", id],
    vendorsAPI.getProjectDetails
  );

  useEffect(() => {
    if (status === "error") {
      showAlert({
        severity: "error",
        message: error.message,
      });
    }
  }, [status, error, showAlert]);

  useEffect(() => {
    if (projectStatus === "error") {
      showAlert({
        severity: "error",
        message: projectError.message,
      });
    }
  }, [projectStatus, projectError, showAlert]);

  const handleMilestoneClick = (event) => {
    history.push(`/vendor/projects/${id}/milestone/${event.target.id}`);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const renderBreadcrumbLinks = () => [
    { url: "/vendor/projects", title: "Projects", forwardSlash: true },
    { url: `/vendor/projects/${id}`, title: `${project?.code}`, forwardSlash: false }
  ];

  const transformMileStonesToCalendarTime = () => {
    const groups = [];
    const items = [];

    const colorScheme = {
      pending: "#F49342",
      inprogress: "#6CCF81",
      done: "#2AA96D",
    };

    if (milestones?.length > 0) {
      milestones.forEach((milestone) => {
        groups.push({
          id: milestone.id,
          title: milestone.title,
          height: 100,
          status: milestone?.status?.toLowerCase() === "done" ? "done" : "notDone",
        });
        items.push({
          id: milestone.id,
          group: milestone.id,
          title: milestone.status,
          start_time: new Date(milestone.startDate).getTime(),
          end_time: new Date(milestone.endDate).getTime(),
          itemProps: {
            style: {
              backgroundColor: colorScheme[milestone?.status?.toLowerCase()] || colorScheme.pending,
            },
          },
        });
      });
    }

    return { groups, items };
  };

  const renderEmptyMilestoneView = () => (
    <EmptyProjectMilestone>
      <ListAltIcon style={{ fontSize: "100px" }} />
      <TableTextStyle>Project Milestones</TableTextStyle>
      <TableSubTextStyle style={{ width: "345px" }}>
        You currently have no project milestones or task, use the button below to create one
      </TableSubTextStyle>
      <SearchButton onClick={handleOpen} style={{ minWidth: "155px", marginTop: "32.5px" }}>
        Create a milestone
      </SearchButton>
    </EmptyProjectMilestone>
  );

  const renderMilestones = () => {
    const result = transformMileStonesToCalendarTime();

    return milestones?.length === 0 || !milestones ? (
      renderEmptyMilestoneView()
    ) : (
      <CalendarTimeLine groups={result.groups} handleMilestoneClick={handleMilestoneClick} items={result.items} />
    );
  };

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" marginBottom="35px">
        {!projectIsLoading && (
          <Box>
            <PageTitle>{project?.code}</PageTitle>
            <Breadcrumb values={renderBreadcrumbLinks()} />
          </Box>
        )}
        {projectIsLoading && <Skeleton width="200px" height="50px" />}
        {
          project?.percentageCompleted === 100 ? (
            <SearchButton
              component={Link}
              to={`/vendor/projects/${id}/report`}
            >
              Upload Report
            </SearchButton>
          ) : (
            <SearchButton
              onClick={handleOpen}
            >
              Create a milestone
            </SearchButton>
          )
        }
      </Box>
      <InfoContainer>
        <Grid container layout={"row"}>
          <Grid item xs={8}>
            <div>
              <InfoCardWrapper>
                <MainTitle>Procurement Information</MainTitle>
                {isLoading && <Loader />}
                {!isLoading && (
                  <>
                    <MainText mt="5px" fontSize="14px">
                      {project?.ministry?.name}
                    </MainText>
                    <MainTitle mt="43px"> {project?.name}</MainTitle>
                    <MainText mt="20px" fontSize="13px">
                      Project Description
                    </MainText>
                    <MainText mt="10px" fontSize="14px">
                      {project?.description}
                    </MainText>
                    <HL mt="20px" />

                    <ItemsGrid>
                      <div>
                        <MainText mt="20px" fontSize="13px">
                          Budget
                        </MainText>
                        <MainTitle>N{project?.budget?.toLocaleString()}</MainTitle>
                      </div>
                      <div>
                        <MainText mt="20px" fontSize="13px">
                          Procurement ID
                        </MainText>
                        <MainTitle>{project?.code}</MainTitle>
                      </div>
                    </ItemsGrid>

                    <HL mt="20px"/>
                    <ItemsGrid>
                      <div>
                        <MainText mt="20px" fontSize="13px">
                          Procurement Category
                        </MainText>
                        <MainText fw="600" fontSize="14px">
                          {project?.procurementCategory}
                        </MainText>
                      </div>
                      <div>
                        <MainText mt="20px" fontSize="13px">
                          Procurement Method
                        </MainText>
                        <MainText fw="600" fontSize="14px">
                          {project?.procurementMethod}
                        </MainText>
                      </div>
                    </ItemsGrid>

                    <Link
                      to={`/vendor/projects/${id}/details`}
                      style={{
                        textDecoration: 'none'
                      }}
                    >
                      <MainText mt="20px" fontSize="15px" color="#2AA96D" fw="bold">
                        View more
                      </MainText>
                    </Link>
                  </>
                )}
                <div className="topRightCard oneCard">
                  <div>
                    <MainText mt="20px" mr="10px" fontSize="13px">
                      Date Published
                    </MainText>
                    <MainText fw="600" fontSize="14px">
                      {moment(project?.startDate).format("DD/MM/YYYY")}
                    </MainText>
                  </div>
                </div>
              </InfoCardWrapper>
            </div>
          </Grid>
          <Grid item xs={4}>
            <ProjectProgressBar
              marginSet="20px"
              textTitle="Total Tasks"
              totalTask={project?.totalMilestone}
              CompletedTask={project?.totalCompletedMilestone}
              pendingTask={project?.totalPendingMilestone}
              percentageCompleted={project?.percentageCompleted}
              fullWidth
            />
          </Grid>
        </Grid>
      </InfoContainer>
      {
        isLoading ? (
          <center>
            <CircularProgress />
          </center>
        ) : (
          renderMilestones()
        )
      }
      <MilestoneModal open={open} handleClose={handleClose} />
    </PageContainer>
  );
};

export default withVendorDashboard(ProjectInfo);
