import { theme } from "./theme";

export const pieOption = ({ label, labels, colors }) => ({
  labels,
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  fill: {
    colors,
  },
  plotOptions: {
    pie: {
      startAngle: 0,
      expandOnClick: true,
      offsetX: 0,
      offsetY: 0,
      customScale: 1,
      dataLabels: {
        offset: 0,
        minAngleToShowLabel: 10,
      },
      donut: {
        labels: {
          show: true,
          name: {
            show: true,
          },
          value: {
            show: true,
          },
          total: {
            show: true,
            showAlways: true,
            label,
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: 600,
            color: "#373d3f",
            formatter: function (w) {
              return w.globals.seriesTotals.reduce((a, b) => {
                return a + b;
              }, 0);
            },
          },
        },
      },
    },
  },
});

export const barOption = {
  chart: {
    id: "ministry-bar",
  },
  colors: [theme.color.primary],
  yaxis: {
    title: {
      text: "Amount Spent (N Million)",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "12px",
        fontWeight: 600,
        cssClass: "apexcharts-xaxis-title",
      },
    },
  },
  xaxis: {
    position: "bottom",
    title: {
      text: "Ministries",
      offsetX: 0,
      offsetY: 0,
      style: {
        color: undefined,
        fontSize: "12px",
        fontWeight: 600,
        cssClass: "apexcharts-xaxis-title",
      },
    },
  },
  grid: {
    show: true,
    borderColor: "#E5E9EC",
    strokeDashArray: 1,
    yaxis: {
      lines: {
        show: true,
      },
    },
    xaxis: {
      lines: {
        show: true,
      },
    },
  },
};
