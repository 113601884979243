import React, { useEffect, useState } from "react";
import { Container } from "@material-ui/core";
import { Link } from "react-router-dom";

import { awardedContracts } from "./mock";
import withAwardsNTenders from "../HOC/withAwardsNTenders";
import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import { BreadcrumbLabel, BreadcrumbActive } from "../TenderList/style";

const AwardedContracts = () => withAwardsNTenders(null)({ data: awardedContracts, title: "contracts" });

const linkStyle = { textDecoration: "none", color: "inherit" };

const HomeAwardedContracts = ({title}) => {
    return (
        <Container>
            <BreadcrumbLabel>
                <Link style={linkStyle} to="/">
                    Home
                </Link>{" "}
                <BreadcrumbActive> /{title === "tenders" ? "Tenders Lists" : "Awarded Contracts"}</BreadcrumbActive>
            </BreadcrumbLabel>
            <AwardedContracts />
        </Container>
    )
}

export default withHeaderFooterHOC(HomeAwardedContracts);
