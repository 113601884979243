import styled from "styled-components";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";

export const CustomDialog = styled(Dialog)`
  .MuiDialog-paper {
    padding: 30px 32px 40px;
    min-width: 550px;
  }
`;

export const CustomDialogTitleWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

export const CustomDialogTitle = styled(DialogTitle)`
  padding: 0 !important;
  color: #445571;
  font-size: 24px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 32px;
`;

export const Caption = styled.p`
  border-radius: 3px;
  background-color: rgba(3, 173, 235, 0.07);
  padding: 10px 15px;
  text-align: left;
  color: #2AA96D;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 19px;
`;

export const CustomDialogContent = styled(DialogContent)`
  padding: 20px 0 !important;
  display: grid;
  grid-gap: 20px;
`;

export const RegistrationCategoryWrapper = styled.div`
  display: grid;
  grid-gap: 10px;
`;

export const RegistrationCategoryContent = styled.div`
  padding: 15.5px;
  box-sizing: border-box;
  height: 71px;
  width: 100%;
  border: 1px solid #2AA96D;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
`;

export const RegistrationCategoryClass = styled.p`
  border-radius: 3px;
  background-color: rgba(3, 173, 235, 0.07);
  color: #2AA96D;
  font-size: 36px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 36px;
  text-align: center;
  margin-right: 16px;
`;
export const RegistrationCategorySubtitle = styled.p`
  height: 20px;
  color: #454f5b;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 20px;
`;
export const RegistrationCategoryValue = styled.p`
  height: 20px;
  color: #454f5b;
  font-size: 16px;
  font-weight: 800;
  letter-spacing: 0;
  line-height: 20px;
  margin-left: 10px;
`;

export const Subtitle = styled.h3`
  margin: 0;
  padding: 0;
  height: 22px;
  color: #445571;
  font-size: 16px;
  font-weight: bold;
  letter-spacing: 0;
  line-height: 22px;
`;

export const ApplicationPeriod = styled.div`
  display: grid;
  grid-gap: 10px;
`;

export const ApplicationDates = styled.p`
  height: 22px;
  color: #637381;
  font-size: 16px;
  letter-spacing: 0;
  line-height: 22px;
`;

export const Notice = styled.div`
  width: 550px;
  border-radius: 3px;
  background-color: #fcf1cd;
  display: flex;
  padding: 15px;

  p {
    height: 76px;
    width: 482px;
    color: #454f5b;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
    margin-left: 10px;
  }
`;

// 2AA96D

export const Disclaimer = styled.p`
  height: 15px;
  color: #637381;
  font-size: 11px;
  letter-spacing: 0;
  line-height: 15px;
`;

export const SubmitButton = styled.button`
  color: #ffffff;
  box-sizing: border-box;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #2AA96D;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;

  &:disabled,
  &[disabled]{
  background-color: #c9d4db;
  cursor: not-allowed;
}
`;


export const PrevBtn = styled.button`
color: #5F7483;
background: #fff;
box-sizing: border-box;
height: 41px;
padding-left: 20px;
padding-right: 20px;
border: 1px solid #2AA96D;
border-radius: 3px;
box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
font-size: 14px;
font-weight: 600;
letter-spacing: 0;
line-height: 20px;
text-align: center;
cursor: pointer;
margin-right: 10px;
`;


export const PayText = styled.p`
font-weight: normal;
font-size: 16px;
line-height: 22px;
color: ${(props) => props.checked ? `#2AA96D` : `#5F7483`}
`;

export const GatewayCard = styled.div`
background: #FFFFFF;
border: 1px solid #DEE3E9;
border-radius: 3px;
margin: 15px 15px 15px 0;
padding: 20px 40px 15px;
height: 97px;
box-sizing: border-box;
width: 32%;

p {
  color: #5F7483;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  margin-top: 5px;
}
`;

export const OfflinePayCard = styled.div`
background: #FEF1C9;
border-radius: 3px;
padding: 20px;
position: relative;
Margin-bottom: 30px;

.title {
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #424F5C;
  margin-left: 38px;
}

.instruction {
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  color: #424F5C;
  margin-left: 38px;
  margin-bottom: 30px;
}

.icon {
  position: absolute;
  top: 8;
  left: 8;
}

`;

export const ProofBtn = styled.button`
  width: 100%;
  color:#614A0C;
  background: #FEF0C9;
  border: 1px solid #614A0C;
  border-radius: 3px;
  margin-top: 8%;
  padding: 10px;
  font-weight: 700;
`;

