import React from 'react'
import { theme } from '../../util/theme';
import { Card, CardContainer } from './style'


const {
    color: {
      tags: { rejected, blacklist },
    },
  } = theme;

const colors = {
    default: {
        text: '#637381',
        bg: '#ffffff',
        border: '#CBD4DA'
    },
    active: {
        text: '#287D3C',
        bg: '#EDF9F0',
        border: '#5ACA75'
    },
    pending: {
        text: '#FFB321',
        bg: '#FFF3DB',
        border: '#FFC453'
    },
    rejected: {
        text: '#E10000',
        bg: '#f1a99e59',
        border: rejected.background
    },
    suspended: {
        text: blacklist.background,
        bg: '#d0d6db80',
        border: blacklist.background,
    } 
}

const StatisticsCards = ({cards}) => {
    return (
        <CardContainer>
            {cards.map((card, index) => (
                <Card key={index} color={colors[card.type] ?? colors.default}>
                    <div className="title">{card.title}</div>
                    <div className="stat">{card.count}</div>
                    <div className="subTitle">{card.subTitle ?? ''}</div>
                </Card>
            ))}
        </CardContainer>
    )
}

export default StatisticsCards
