import styled from "styled-components";
import { Button, Box } from "@material-ui/core";
import { Delete } from '@material-ui/icons';
import { theme } from "../../../../../../util/theme";

export const FormWrapper = styled.div`
    background-color: white;
    color: red;
    padding: 2rem;
    border: 1px solid #C9D4DB;
    box-shadow: 0px 2px 4px #C9D4DB;
    border-radius: 3px;
    margin-top: 2rem;
    width: 80%;
`

export const FormTitle = styled.p`
    color: #1E2B37;
    font-weight: 600;
    line-height: 24px;
`

export const FormSubTitle = styled.p`
    color: #637381;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
`

export const BenchMarkWrapper = styled.div`
    background-color: #F7F8F9;
    padding: 1rem;
    border-radius: 8px;
    margin-top: 2rem;
`

export const ErrorWrapper = styled.p`
    font-style: italic;
    color: red;
    text-align: center;
    margin-top: 1rem;
`

export const BenmarkLabel = styled.p`
    color: #1E2B37;
    margin-bottom: 1rem;
`

export const CriteriaWrapper = styled.div`
    /* background-color: white; */
    margin-top: 1rem;
`

export const Criteria = styled.div`
    width: 60%;
    margin-right: 1rem;
`

export const MaxScore = styled.div`
   width: 30%;
   margin-right: 1rem;
`

export const FormFieldWrapper = styled.div`
    display: flex;
    margin-bottom: 1rem;
`

export const BtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
`

export const DeleteIcon = styled(Delete)`
    && {
       align-self: center;
       color: #DA1414;
       width: 5%;
        cursor: pointer;
  }
`;

export const AddNewButton = styled(Button)`
    && {
    color: ${theme.color.primary};
    margin-top: 2rem;
  }
`;

export const BackButton = styled(Button)`
    && {
        background-color: #EEFBF5;
    border-radius: 8px;
    color: ${theme.color.primary};
    &:hover {
      background-color: #EEFBF5;
      opacity: 0.7;
    }
  }
`;

export const ProceedButton = styled(Button)`
    && {
        background-color:  ${theme.color.primary};
    border-radius: 8px;
    color: white;
    &:hover {
      background-color:  ${theme.color.primary};
      opacity: 0.8;
    }
    &:disabled {
      background-color: ${theme.color.primary};
      cursor: not-allowed !important;
      opacity: 0.5;
      color: white;
    }
  }
`;

export const LoadingButton = styled(Button)`
    && {
        background-color: ${theme.color.primary};
        border-radius: 8px;
        opacity: 0.5;
        color: #fff;
        padding: 0.5rem;
        min-width: 5rem;
        max-width: 5rem;
        &:disabled {
      color: white;
    }
  }
`;


export const CircularWrapper = styled(Box)`
  && {
    background-color: #fff;
    height: 60vh;
    width: 60vw;
    opacity: 0.7;
    }
    .MuiCircularProgress-colorPrimary {
      text-align: center;
  }
  .MuiCircularProgress-root { 
    left: 45%; 
    position: absolute; 
    top: 50%; 
}
`;




