import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import ErrorSharpIcon from "@material-ui/icons/ErrorSharp";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import { theme } from "../../util/theme";

export const PageGrid = styled.div`
  display: grid;
  grid-template-columns: auto 300px;
  grid-column-gap: 24px;
  margin-top: 60px;
`;

export const FormsHeader = styled.div`
  border: 1px solid #C9D4DB;
  background-color: #E4E9EC;
  padding: 0 20px;
  display: flex;
`;

export const FormTab = styled.div`
  color: #5F7483;
  font-size: 16px;
  font-weight: 600;
  padding: 9px 20px;
  border-bottom: 2px solid ${(props) => (props.isActive ? theme.color.primary : "transparent")};
`;

export const CheckEntryHeader = styled(Typography)`
  color: #212B36;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  margin-bottom: 15px !important;
`;

export const CheckEntryErrorIcon = styled(ErrorSharpIcon)`
  color: #DE3618;
  margin-right: 10px;
`;

export const CheckEntryCheckCircleSharpIcon = styled(CheckCircleSharpIcon)`
  color: rgba(11,176,45,0.6);
  margin-right: 10px;
`;

export const CheckEntryText = styled(Typography)`
  color: #212B36;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const CheckEntryContentWrapper = styled.div`
  display: flex;
  margin-bottom: 15px;
`;
