import { Box, TableCell, TableRow } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useMemo, useState } from "react";
import { useHistory, useParams, Link, useLocation } from "react-router-dom";
import { GeneralTitlePage } from "../../../../../../components/AddGeneralPlan/style";
import { CardNumberText } from "../../../../../../components/AdminCard/style";
import { SaveButton } from "../../../../../../components/Forms/Common/style";
import SearchTable from "../../../../../../components/SearchTable";
import TopStatisticsBar from "../../../../../../components/TopStatisticsBar";
import Breadcrumb from "../../../../../Common/PageElements/Breadcrumb";
import { ContentWrapper } from "../../../../Administration/Staff/style";
import { CardLabel } from "../../../../PlanDetails/style";
import Grid from "@material-ui/core/Grid";
import { ModdedAvatar, TableCellEmail } from "../../ReceiptOfResponse/VendorsTable/style";
import IconButton from "@material-ui/core/IconButton";
import { ReactComponent as RightIcon } from "../../../../../../assets/right_icon.svg";
import { useMutation, useQuery } from "react-query";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import procurementAPI from "../../../../../../redux/api/procurementAPI";
import { useRoles } from "../../../../../../hooks/useUserDetails";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import CheckIcon from "@material-ui/icons/Check";
import FileProgress from "../../../../../../components/FileProgress/FileProgress";
import { openFileUrlInNewWindow } from "../../../../../Common/util";
import AdminSearchFilter from "../../../../../../components/AdminSearchFilter";
import InputField from "../../../../../../components/InputField";
import AccessControl from "../../../../../../components/AccessControl";
import Dropdown from "../../../../../../components/DropdownField";
import ToastContext from "../../../../../../util/toastContext";
import EvaluatedVendorsTable from "../../../../../../components/EvaluatedVendorsTable/EvaluatedVendorsTable";
import UpdateDeadline from "./UpdateDeadline";
import EvaluationTable from "./EvaluationTable";
import adminAPI from "../../../../../../redux/api/adminAPI";

const baseVendorObject = {
  data: {
    responseSummary: {
      total: 0,
      pending: 0,
      evaluated: 0,
    },
    evaluatedVendors: [],
  },
  pagination: {
    totalEntries: 0,
  },
};

const TableBody = ({ procurementId, id, history, vendors = [], canViewVendorDetails }) => {
  const redirectUser = (vendorId) => {
    return history.push(`/admin/procurement/activity/receiptofbiddersresponse/${id}/${procurementId}/evaluation/${vendorId}`);
  };

  return vendors.map(({ companyName, email, userId, registrationGrade }) => {
    return (
      <TableRow key={id}>
        <TableCell>
          <Grid spacing={1} container wrap="nowrap">
            <Box mr={2}>
              <ModdedAvatar size={34} color="#50B83C">
                {companyName?.slice(0, 2)}
              </ModdedAvatar>
            </Box>
            <Grid container direction="column" spacing={1}>
              <span>{companyName}</span>
              <TableCellEmail>{email}</TableCellEmail>
            </Grid>
          </Grid>
        </TableCell>
        <TableCell>{registrationGrade}</TableCell>

        <TableCell align="right">
          {canViewVendorDetails() && (
            <IconButton onClick={() => redirectUser(userId)}>
              <RightIcon fill="#637381" width={18} />
            </IconButton>
          )}
        </TableCell>

      </TableRow>
    );
  });
};

const BidderResponseReceipt = () => {
  const { role, user } = useRoles();
  const [roleThresholdUpperLimit, setRoleThresholdUpperLimit] = useState(0);
  const [roleThresholdLowerLimit, setRoleThresholdLowerLimit] = useState(0);
  const { activityId, procurementId } = useParams();
  const { pathname } = useLocation();
  const history = useHistory();

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const [filters, setFilters] = useState({
    Name: null,
    Status: role === Roles.TENDER_BOARD ? 2 : 4,
  });

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const expiryDate = useQuery({
    queryKey: [
      "expiryDate",
      {
        TenderId: procurementId,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.getExpiryDate,
  });


  const biddersResponse = useQuery({
    queryKey: [
      "biddersResponse",
      {
        Status: filters.Status,
        PageSize: pagination.pageSize,
        PageNumber: pagination.pageNumber,
        id: procurementId,
        Name: filters.Name,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.evaluatedBids,
  });

  const activityDetails = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
  });

  const documents = useQuery({
    queryKey: [
      "approvalMemo",
      {
        activityId,
      },
    ],
    queryFn: receiptOfBiddersResponseApi.getApprovalDocument,
  });

  const { data: evaluationStat } = useQuery({
    queryKey: ["evaluation-stat", { procurementId }],
    queryFn: receiptOfBiddersResponseApi.getEvaluationStat,
  });

  const generalPlanDetails = useQuery({
    queryKey: ["generalPlanDetails", procurementId],
    queryFn: procurementAPI.getPlanDetails
  });

  useQuery(["rolesThreshold", {
    selectedRole: user?.roleId,
    selectedCategory: generalPlanDetails?.data?.procurementCategory?.id
  }],
    adminAPI.getRoleThreshold, {
    onSuccess(data) {
      console.log("data?.data[0] ", data?.data[0]);
      if (data) {
        setRoleThresholdLowerLimit(data?.data[0]?.lowerLimitThreshold);
        setRoleThresholdUpperLimit(data?.data[0]?.upperLimitThreshold);
      }
    },
  });

  // const [needsAmendment, needsAmendmentQuery] = useMutation(receiptOfBiddersResponseApi.evaluationNeedsAmendment, {
  //   throwOnError: true,
  // });

  const [approveEvaluations, approveEvaluationsQuery] = useMutation(receiptOfBiddersResponseApi.approveEvaluations, {
    throwOnError: true,
  });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  const {
    data: { responseSummary, evaluatedVendors },
    pagination: apiPagination,
  } = Object.assign(baseVendorObject, biddersResponse.data);
  const { procurementPlanActivityStatus } = activityDetails.data || {};

  // const topStatsBarKeys = useMemo(() => {
  //   if (biddersResponse.isSuccess && activityDetails.isSuccess) {

  //     let show;

  //     if (role === Roles.TENDER_BOARD) {
  //       show = responseSummary.pending === 0;
  //     } else {
  //       show = true;
  //     }

  //     return {
  //       show,
  //       status: procurementPlanActivityStatus === "Approved" ? "approved" : "pending",
  //     };
  //   }

  //   return {
  //     show: false,
  //     status: null,
  //   };
  // }, [biddersResponse.isSuccess, activityDetails.isSuccess, activityDetails.data, role, responseSummary.pending]);

  const { showAlert } = useContext(ToastContext);

  // const onClickNeedsAmendment = async () => {
  //   try {
  //     await needsAmendment({
  //       procurementId,
  //     });

  //     showAlert({
  //       message: "Successfully sent amendment request",
  //       severity: "success",
  //     });
  //   } catch (e) {
  //     showAlert({
  //       message: e.message,
  //       severity: "error",
  //     });
  //   }
  // };

  const onClickApprove = async () => {
    try {
      await approveEvaluations({
        procurementId,
      });

      showAlert({
        message: "Successfully approved evaluations",
        severity: "success",
      });
      activityDetails.refetch();
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const canApproveTender = () => {
    if (
      roleThresholdUpperLimit >= generalPlanDetails.data?.budget &&
      roleThresholdLowerLimit <= generalPlanDetails.data?.budget
    ) {
      return true;
    } else {
      return false;
    }
  };

  const renderTotalVendors = () => (
    <Box>
      <CardLabel>Total Vendors</CardLabel>
      <CardNumberText>{evaluationStat?.financial?.total}</CardNumberText>
    </Box>
  );

  const renderCenterContent = () => {
    let rightContent;

    if (canApproveTender()) {
      const doc = documents.data?.pop();

      rightContent = (
        <>
          <Box ml="auto" mr={2.5} minWidth="180px">
            <FileProgress
              progress={100}
              fileName={doc?.file?.original_filename}
              fileSize={doc?.file?.bytes}
              hasEyes
              onClickEyes={() => openFileUrlInNewWindow(doc?.file?.url)}
            />
          </Box>
          <Box ml="auto" mr={2.5}>
            <Grid container spacing={2}>
              {/* <Box mr={2}>
                <LoadingButton color="#F2994A" onClick={onClickNeedsAmendment} loading={needsAmendmentQuery.isLoading}>
                  Needs Amendment
                </LoadingButton>
              </Box> */}
              <LoadingButton
                color="#219653"
                loading={approveEvaluationsQuery.isLoading}
                onClick={onClickApprove}
                endIcon={<CheckIcon width="10px" />}
                disabled={evaluationStat?.financial.totalPending > 0 || procurementPlanActivityStatus === "Approved"}
              >
                Approve
              </LoadingButton>
            </Grid>
          </Box>
        </>
      );
    } else {
      rightContent = (
        <SaveButton
          onClick={() =>
            history.push(`/admin/procurement/activity/receiptofbiddersresponse/${activityId}/${procurementId}/evaluated/vendor`)
          }
        >
          View Evaluation
        </SaveButton>
      );
    }

    return (
      <>
        <Grid item>
          <CardLabel style={{ color: "#FF8D26" }}>Pending Review</CardLabel>
          <CardNumberText>{evaluationStat?.financial.totalPending}</CardNumberText>
        </Grid>
        <Grid item style={{ marginRight: "auto" }}>
          <CardLabel style={{ color: "#219653" }}>Evaluated</CardLabel>
          <CardNumberText>{evaluationStat?.financial.totalEvaluated}</CardNumberText>
        </Grid>
        {rightContent}
      </>
    );
  };

  const canViewVendorDetails = () => {
    let currentDate = moment.parseZone().format();
    return moment.parseZone(expiryDate.data).format() <= currentDate;
  };

  return (
    <ContentWrapper>
      <Box mt={2}>
        <GeneralTitlePage>Receipt of Bidders Response</GeneralTitlePage>
        <Breadcrumb
          values={[
            {
              url: "/dashboard/overview",
              title: "Home",
            },
            {
              url: `/admin/procurement/plans`,
              title: "Plans",
            },
            {
              url: `/admin/procurement/plan-details/${procurementId}`,
              title: "Plan Details",
            },
          ]}
        />
      </Box>


      <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
        <Box mb={5.5} mt={2}>
          <UpdateDeadline expiryDate={expiryDate} />
        </Box>
      </AccessControl>



      <Box mb={5.5} mt={2}>
        <TopStatisticsBar
          gridSpacing={2}
          leftContent={renderTotalVendors()}
          status={procurementPlanActivityStatus === "Approved" ? "approved" : "pending"}
          centerContent={renderCenterContent()}
          hasApproveStatus={evaluationStat?.financial?.totalPending === 0}
          isLoading={biddersResponse.isLoading || activityId.isLoading || documents.isLoading}
        />
      </Box>


      <AccessControl allowedRoles={[Roles.TENDER_BOARD, Roles.KWPPA, Roles.COMMISSIONER, Roles.MINISTERIAL_TENDERS_BOARD, Roles.PARASTATALS_TENDERS_BOARD, Roles.GOVERNOR, Roles.PERMANENT_SECRETARY, Roles.ACCOUNTING_OFFICERS]}>
        <EvaluationTable evaluationStat={evaluationStat} />
      </AccessControl>

      <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
        <AdminSearchFilter normalSearch>
          <Grid container justify="space-around" wrap="nowrap">
            <InputField type="text" label="Search name" value={filters.Name} setValue={updateFilters("Name")} />
            <Dropdown
              name="status"
              label="Status"
              values={[
                { label: "Evaluated", value: 2 },
                { label: "Not Evaluated", value: 4 },
                { label: "Recommended", value: 1 },
                { label: "Not Recommended", value: 9 },
              ]}
              initialState={filters.Status}
              onChange={updateFilters("Status")}
            />
          </Grid>
        </AdminSearchFilter>
      </AccessControl>

      <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
        <SearchTable
          columns={["Name", "Registration Class", "Action"]}
          page={pagination.pageNumber}
          setPage={updatePagination("pageNumber")}
          rowsPerPage={pagination.pageSize}
          setRowsPerPage={updatePagination("pageSize")}
          totalNumberOfPages={apiPagination.totalPages}
          loading={biddersResponse.isLoading}
          empty={evaluatedVendors.length === 0}
        >
          {evaluatedVendors.length > 0 && (
            <TableBody
              procurementId={procurementId}
              id={activityId}
              history={history}
              vendors={evaluatedVendors}
              canViewVendorDetails={canViewVendorDetails}
            />
          )}
        </SearchTable>
      </AccessControl>

      {/* <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
        <EvaluatedVendorsTable
          pageNumber={pagination.pageNumber}
          pageSize={pagination.pageSize}
          totalPages={apiPagination.totalEntries}
          isLoading={biddersResponse.isLoading}
          setPageSize={updatePagination("pageSize")}
          setPageNumber={updatePagination("pageNumber")}
          vendors={evaluatedVendors}
        />
      </AccessControl> */}
    </ContentWrapper>
  );
};

export default BidderResponseReceipt;
