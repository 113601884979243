import React, {useState} from "react";
import { Grid, Checkbox } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";

import { Wrapper, TitleText, RegisterFormTitle, TermsAndCondition} from "./styled";
import InputField from "../../InputField";
import { FormSubtitle } from "../../../util/theme";
import { ErrorContainer } from "../Common/style";
import { signupVendor } from "../../../redux/reducer/userReducer";
import { RegisterButtonContainer, RegisterButton } from "../../../pages/Vendor/SignUp/style";
import useAlert from "../../../hooks/useAlert";

const FormHomepage = () => {
  const width = "100%";
  const margin = "15px 0 0 0";

  const { register, handleSubmit, errors } = useForm();
  const { loading, error: apiError } = useSelector((state) => state.user);
  const history = useHistory();
  const [TCchecked, setTCchecked] = useState(false);

  const handleChange = (event) => {
    setTCchecked(event.target.checked);
  };

  const { showAlert, Toast } = useAlert();

  const dispatch = useDispatch();
  const onSubmit = (data) => {
    dispatch(signupVendor(data))
      .then((res) => {
        if (res.error) {
          showAlert({ severity: "error", message: apiError, durationInMs: 10000 });
          return;
        }
        history.push("/verify-account");
      });
  };

  const onRenderError = (error) => <ErrorContainer> {error && error.message} </ErrorContainer>;

  return (
    <Wrapper>
      <RegisterFormTitle> New vendor's registration </RegisterFormTitle>
      <FormSubtitle>
        Fill in the fields below
      </FormSubtitle>
      <Toast />
      <TitleText>Login Information</TitleText>
      <form onSubmit={handleSubmit(onSubmit)}>
        <InputField
          type="email"
          label="Email address"
          name="email"
          style={{ width, margin }}
          inputRef={register({
            required: "Invalid email address",
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
              message: "Invalid email address",
            },
          })}
        />
        {onRenderError(errors.email)}
        <InputField
          type="password"
          label="Create password"
          name="password"
          style={{ width, margin }}
          inputRef={register({
            required:
              "Password must contain atleast (1) lower case, (1) capital letter, (1) numeric number and a minimum of 8 characters",
            pattern: {
              value: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z]).{8,}$/,
              message:
                "Password must contain atleast (1) lower case, (1) capital letter, (1) numeric number and a minimum of 8 characters",
            },
          })}
        />
        {onRenderError(errors.password)}
        <TitleText>User Information</TitleText>
        <Grid container spacing={3}>
          <Grid item xs={6} style={{ paddingTop: "0" }}>
            <InputField
              type="text"
              label="First Name"
              name="firstName"
              style={{ width: "100%", margin }}
              inputRef={register({
                required: "Please supply your first name",
                validate: (value) => (value.length > 2 && value.length < 256)
                  || "Please supply your first name",
              })}
            />
            {onRenderError(errors.firstName)}
          </Grid>
          <Grid item xs={6} style={{ paddingTop: "0" }}>
            <InputField
              type="text"
              label="Last Name"
              name="lastName"
              style={{ width: "100%", margin }}
              inputRef={register({
                required: "Please supply your last name",
                validate: (value) => (value.length > 2 && value.length < 256)
                  || "Please supply your last name",
              })}
            />
            {onRenderError(errors.lastName)}
          </Grid>
        </Grid>
        <TermsAndCondition>
        <Checkbox
        checked={TCchecked}
        onChange={handleChange}
        size="small"
        color="primary"
      />
      <div>I agree to the <Link to="/terms-and-conditions" className="link"> Terms & Conditions</Link></div>
        </TermsAndCondition>
        <RegisterButtonContainer>
          <RegisterButton type="submit" disabled={loading === "pending" || !TCchecked}>{loading === "pending" ? "Loading..." : "Register"}</RegisterButton>
        </RegisterButtonContainer>
      </form>
    </Wrapper>
  );
};

export default FormHomepage;
