import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import styled from "styled-components";
import { ReactComponent as ContractIcon } from "../../../../assets/contract_logo.svg";

export const MainWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  padding: 20.5px !important;
`;

export const TopSummary = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const TopRightSummary = styled.div`
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TopItemsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const TopItems = styled.div`
  margin-top: 20.5px;

  span {
    color: #5f7483;
    font-size: 13px !important;
    letter-spacing: 0;
    line-height: 16px !important;
  }

  p {
    color: #5f7483;
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
  }
`;

export const ContractDetails = styled.div`
  box-sizing: border-box;

  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 20.5px !important;
  margin-top: 30.5px;
`;

export const ContractDetailsTop = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 56px;
`;

export const RecipientWrapper = styled.div`
  box-sizing: border-box;

  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff;
  padding: 20.5px !important;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const AllFilesWrapper = styled.div`
  height: 122px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const HorizontalLine = styled.div`
  height: 1px;
  border: 1px solid #cbd4da;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ContractLogo = styled(ContractIcon)`
  height: 60px;
  width: 60px;
  align-items: center;
  fill: ${({ iconcolor }) => iconcolor};
`;

export const Signature = styled.p`
  height: 34px;
  width: 89px;
  display: flex;
  letter-spacing: 0 !important;
  border-radius: 3px !important;
  background-color: ${({ bgcolor }) => bgcolor};
  margin-right: 10px;

  span {
    height: 24px;
    font-size: 16px;
    font-weight: 600;
    color: ${({ textcolor }) => textcolor};
    margin: auto;
  }
`;

export const EditContractIcon = styled(EditOutlinedIcon)`
  height: 14.97px;
  width: 15px;
  align-items: center;
  margin-right: 10px;
`;

export const EditText = styled.span`
  height: 20px;
  width: 25px;
  color: #5f7483;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-decoration: underline;
`;

export const MainTitle = styled.p`
  height: 24px;
  color: #1e2b37;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 24px;
  margin-top: ${(props) => props.mt};
  margin-bottom: ${(props) => props.mb};
`;
export const ContractDetailTabText = styled.p`
  font-weight: 600;
  font-size: 15px;
  line-height: 20px;
  padding: 20px 20px 10px 15px;
  color: #5f7483;
`;

export const LoaderWrapper = styled.div`
min-height: 100vh;
display: flex;
justify-content: center;
align-items: center;
`
