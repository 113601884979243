import React from "react";
import { formatCurrency } from "../../../util/formatCurrency";

import SecondaryTable from "./SecondaryTable";

const BiddersTab = ({ contractOCDS }) => {
  const secondaryTableHeader = [
    { id: "s/n", title: "#", width: 50, align: "alignCenter", indexed: true },
    { id: "companyName", title: "Company Name", grid: 5 },
    // { id: "amount", title: "Registeration Name", grid: 3 },
    { id: "amount", title: "Bid Amount", grid: 5 },
    // { id: "evalStatus", title: "Evaluation Status", grid: 6 },
  ];

  const tableBody = contractOCDS?.bidders.map((bidder) => ({
    companyName: bidder.companyName,
    regName: bidder.registrationName || "Not Applicable",
    evalComment: bidder.comment || "Not Applicable",
    evalStatus: bidder.status,
    amount: bidder.amount ? formatCurrency(bidder.amount, true) : "Not Applicable"
  }));
  return <SecondaryTable noDataText="Bidders information is either Not Available or Not Applicable for this Procurement due to the Procurement Method" tableBody={tableBody} tableHeader={secondaryTableHeader} />;

};

export default BiddersTab;
