import React, { useContext } from "react";
import { useQuery } from "react-query";

import usersAPI from "../../redux/api/usersAPI";
import VendorProfile from "./VendorProfile";
import ToastContext from "../../util/toastContext";
import StaffProfile from "./StaffProfile";

export default function ProfileDrawer({ onClose, open, user }) {
  const { showAlert } = useContext(ToastContext);

  const { data: userData = {} } = useQuery("getUserInfo", usersAPI.getUser, {
    enabled: !Boolean(user) && open,
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  return !!userData.role || !!user ? (
    userData.role?.toLowerCase() === "vendor" ? (
      <VendorProfile
        vendor={Boolean(user) ? user : userData}
        open={open}
        onClose={onClose}
      />
    ) : (
      <StaffProfile
        staff={Boolean(user) ? user : userData}
        open={open}
        onClose={onClose}
      />
    )
  ) : null;
}
