import React from 'react';
import {
    Box,
    Typography,
    Divider,
    FormControl,
    FormControlLabel,
    RadioGroup,
    Radio,
    FormHelperText,
    makeStyles
} from "@material-ui/core";
import { Controller } from "react-hook-form";
import { theme } from '../../../../../../util/theme';

const bgColor = "#FCFCFD";

const useStyles = makeStyles({
    radio: {
        "&.Mui-checked": {
            color: theme.color.primary,

        }
    }
})

function ConsultingServiceRequirement({ useFormUtils, evaluations, title, isEvaluated }) {
    const { control, errors } = useFormUtils;
    const classes = useStyles();

    const renderHeading = () => (
        <Box p={3}>
            <Typography variant="subtitle1">{title}</Typography>
            <Typography variant="body2" color="textSecondary">
                Please tick the one that suits the vendor
            </Typography>
        </Box>
    );

    const renderMandatoryRequirements = () => (
        <>
            {renderHeading("Mandatory Requirements", "Please tick the one that suits the vendor")}
            <Divider />
            <Box p={3} bgcolor={bgColor}>
                {evaluations.map((field, index) => {
                    const fieldError = errors[field.requirementEvaluationId]?.message;
                    return (
                        <Box mt={index !== 0 ? 3 : 0}>
                            <Typography
                                variant="body2"
                                color="text.secondary"
                                gutterBottom
                            >{field.name}</Typography>
                            <Controller
                                name={field.requirementEvaluationId}
                                control={control}
                                rules={{ required: "Field is required" }}
                                render={({ onChange, value }) =>
                                    <FormControl>
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            value={value}

                                            onChange={e => onChange(e.target.value)}
                                        >
                                            <FormControlLabel value="yes" disabled={isEvaluated} control={<Radio classes={{ colorSecondary: classes.radio }} />} label="Yes" />
                                            <FormControlLabel value="no" disabled={isEvaluated} control={<Radio classes={{ colorSecondary: classes.radio }} />} label="No" />
                                        </RadioGroup>
                                    </FormControl>
                                }
                            />
                            {!!fieldError && (
                                <FormHelperText error>{fieldError}</FormHelperText>
                            )}
                        </Box>
                    )
                })}
            </Box>
        </>
    );

    return (
        <>
            {renderMandatoryRequirements()}
            <Divider />
        </>
    )
}

export default ConsultingServiceRequirement;