import React, { useContext, useState } from "react";
import { Box, Modal, Button } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { queryCache, useMutation } from "react-query";

import { ApprovalBanner, ModalBody, ModalButtons, ModalTitle } from "../../CreateProcurement/style";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";
import { DropzoneArea } from "../../../../components/DocumentUploadField/style";
import FileProgress from "../../../../components/FileProgress/FileProgress";
import ToastContext from "../../../../util/toastContext";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import FileError from "../../../../components/FileUploadField/FileError";

export default function ApproveVerifyVendorModal({ vendorDetailsQueryKey, showApprove, setShowApprove, vendorDetails }) {
  const { showAlert } = useContext(ToastContext);
  const [file, setFile] = useState(null);

  const [approveVendorProfile, approveVendorProfileQuery] = useMutation(vendorsAPI.approveVendorProfile, {
    onSuccess: () => {
      //Refetch query
      queryCache.removeQueries(vendorDetailsQueryKey);

      showAlert({
        message: "Vendor has been approved successfully",
        severity: "success",
      });
      setShowApprove(false);
    },
    onError: (error) => {
      showAlert({
        message: error.message,
        severity: "error",
      });
    },
  });

  const handleApprove = async () => {
    try {
      approveVendorProfile({
        vendorId: vendorDetails.id,
        file,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: (files) => setFile(files[0]),
    multiple: false,
  });

  return (
    <Modal open={showApprove} onClose={() => setShowApprove(false)}>
      <ModalBody>
        <ModalTitle>Certificate Upload</ModalTitle>
        <ApprovalBanner>Upload Approval Certificate</ApprovalBanner>
        <Box mb={2.5}>
          {file ? (
            <FileProgress progress={100} fileName={file.name} fileSize={file.size} />
          ) : (
            <DropzoneArea {...getRootProps()}>
              <input {...getInputProps()} />
              <div>
                <p>Upload or drop file here</p>
                <span style={{ fontSize: ".9em" }}>{"File size should not exceed 5,000kb (5mb)"}</span>
                <FileError fileRejections={fileRejections} />
              </div>
            </DropzoneArea>
          )}
        </Box>
        <ModalButtons>
          <LoadingButton
            style={{
              marginLeft: "0",
            }}
            color="#3BD278"
            disabled={!file}
            onClick={handleApprove}
            loading={approveVendorProfileQuery.isLoading}
          >
            Approve
          </LoadingButton>
          <Button onClick={() => setShowApprove(false)} variant="outlined">
            Cancel
          </Button>
        </ModalButtons>
      </ModalBody>
    </Modal>
  );
}
