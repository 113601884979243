import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import { Provider } from "react-redux";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "react-circular-progressbar/dist/styles.css";
import "react-calendar-timeline/lib/Timeline.css";
import { ThemeProvider } from '@material-ui/styles';
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import store from "./redux/store";
import Theme from "./util/MuiThemeOverides";

ReactDOM.render(
  <React.Fragment>
    <ThemeProvider theme={Theme}>
    <Provider store={store}>
    <App />
   </Provider>
   </ThemeProvider>
  </React.Fragment>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
