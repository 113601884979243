import React, { useEffect, useState, useContext } from "react";
import { useSelector } from "react-redux";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormControl from "@material-ui/core/FormControl";
import {
  CustomRadiosWrapper,
  CustomFormLabel,
  CustomFormlabelSubtitle,
  StyledRadioLabel,
  StyledRadio,
  RadioButtonsWrapper,
} from "./style";
import NavigationButtons from "../../NavigationButtons";
import {
  postVendorRegistrationPlan,
  getVendorRegistrationPlan,
  getGeneralCompanyDetails,
} from "../../../redux/reducer/vendorReducer";
import { formatNumber, separateNumByComma } from "../../../util/truncateValue";
import withVendorRegLoader from "../../../pages/Common/HOC/withVendorRegLoader";
import ToastContext from "../../../util/toastContext";
import { useQuery } from "react-query";
import vendorsAPI from "../../../redux/api/vendorsAPI";


const Form = ({ dispatch, userId }) => {
  const { selectedRegPlan, companyDetails } = useSelector((state) => state.vendor);
  //setState of companyDetails
  const [value, setValue] = useState("");
  const [loading, setLoading] = useState(false);
  const { showAlert } = useContext(ToastContext);

  const { data: registrationPlans } = useQuery(
    [
      "registrationPlans",
      {
        coreCompetency: companyDetails?.coreCompetency,
      },
    ],
    vendorsAPI.getVendorRegistrationPlans, 
    {
      enabled: !!companyDetails?.coreCompetency
    }
  );


  useEffect(() => {
    if (Object.keys(selectedRegPlan).length) setValue(selectedRegPlan.id);
  }, [selectedRegPlan]);

  const handleChange = (event) => {
    setValue(event.target.value);
  };

  const onSubmit = (cb) => () => {
    if (selectedRegPlan.id === value) return cb();
    setLoading(true);

    dispatch(postVendorRegistrationPlan({ registrationPlanId: value, userId })).then((res) => {
      if (!res.error) {
        setLoading(false);
        showAlert({ severity: "success", message: "Registration Plan Saved" });
        return cb();
      }
      showAlert({ severity: "error", message: res.payload });
    });
  };

  return (
    <>
      <CustomRadiosWrapper>
        <CustomRadios registrationPlans={registrationPlans || []} handleChange={handleChange} value={value} />
      </CustomRadiosWrapper>
      <NavigationButtons nextActionHandler={(cb) => onSubmit(cb)} value={loading ? "Please Wait..." : null} />
    </>
  );
};

const RegistrationCategories = () => {
  const {
    user: { UserId },
  } = useSelector((state) => state.user);

  return withVendorRegLoader(Form)({
    title: "General Company Details",
    description: "Fill in the fields below",
    apis: [getVendorRegistrationPlan(UserId), getGeneralCompanyDetails(UserId)],
    userId: UserId,
    conditioners: ["selectedRegPlan", "companyDetails"],
  });
};

const CustomRadios = ({ registrationPlans, handleChange, value }) => (
  <FormControl component="fieldset">
    <CustomFormLabel>Choose Your Registration Type</CustomFormLabel>
    <CustomFormlabelSubtitle>Select a contract category</CustomFormlabelSubtitle>
    <RadioGroup
      defaultValue="class-a"
      aria-label="class"
      name="registration-categories-radios"
      value={value}
      onChange={handleChange}
    >
      <RadioButtonsWrapper>
        {registrationPlans.map((plan) => (
          <FormControlLabel
            key={plan.id}
            value={plan.id}
            control={<StyledRadio color="primary" />}
            label={<CustomLabel {...plan} />}
            labelPlacement="bottom"
          />
        ))}
      </RadioButtonsWrapper>
    </RadioGroup>
  </FormControl>
);

const CustomLabel = ({ grade, fee, contractMaxValue, contractMinValue, anualRenewalFee }) => (
  <StyledRadioLabel>
    <div className="class">
      <p className="value">{grade}</p>
    </div>

    <div className="contract-fee">
      <p className="fee">N{separateNumByComma(fee)}</p>
      <p className="title">Registration Fee</p>
    </div>
    <div className="separator" />
    <div className="renewal">Renew at N{separateNumByComma(anualRenewalFee)} annually</div>
    <div className="long-separator" />
    <div className="contract-value">
      {formatNumber(contractMinValue) === "1B" ? (
        <p className="value">1B and Above</p>
      ) : (
        <p className="value">
          {formatNumber(contractMinValue)} - {formatNumber(contractMaxValue)}
        </p>
      )}
      <p className="title">Contract sum</p>
    </div>
  </StyledRadioLabel>
);

export default RegistrationCategories;
