import React, { useState } from "react";
import { Tab, makeStyles } from "@material-ui/core";
import { useQuery } from "react-query";

import { CardContentWrapper, PageTabs } from "../../../util/theme";
import Loader from "../../../components/Common/Loader";
import { PlanCardContainer, TabContainer } from "../../Admin/Procurement/Activitiies/SpecificProcurementNotice/style";
import ContractDetailTab from "./ContractDetailTab";
import TenderDetailsTab from "./TenderDetailsTab";
import AwardedCompanyTab from "./AwardedCompanyTab";
import ContactBiddersTab from "./ContactBiddersTab";
import EvaluatorsTab from "./EvaluatorsTab";
import BiddersTab from "./BiddersTab";
import contractsAPI from "../../../redux/api/contractAPI";

const useStyles = makeStyles({
  tabWrapper: {
    textTransform: "none",
  },
  root: {
    minWidth: 90,
  },
});

const ContractDetailsCardTable = ({ vendorId }) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const contractsOCDSQuery = useQuery(
    ['contractOCDS', vendorId],
    contractsAPI.getContractOCDS, {
    onError: (error) => {
    }
  });

  const { data: contractOCDS } = contractsOCDSQuery?.data || {};

  if (contractsOCDSQuery.isLoading) {
    return <Loader />;
  };

  return (
    <CardContentWrapper>
      <PlanCardContainer>
        <TabContainer>
          <PageTabs value={value} onChange={handleChange}>
            <Tab
              label="Planning"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 0).toString()}
            />
            <Tab
              label="Tender"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 1).toString()}
            />
            <Tab
              label="Bidders"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 2).toString()}
            />
            <Tab
              label="Award"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 3).toString()}
            />
            {/* <Tab
              label="Contract"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 4).toString()}
            />
            <Tab
              label="Implementation"
              classes={{ root: classes.root, wrapper: classes.tabWrapper }}
              activetab={(value === 5).toString()}
            /> */}
          </PageTabs>
        </TabContainer> 
        {value === 0 && <ContractDetailTab contractOCDS={contractOCDS} />}
        {value === 1 && <TenderDetailsTab contractOCDS={contractOCDS} />}
        {value === 2 && <BiddersTab contractOCDS={contractOCDS} />}
        {value === 3 && <AwardedCompanyTab contractOCDS={contractOCDS} />}
        {/* {value === 4 && <ContactBiddersTab contractOCDS={contractOCDS} />}
        {value === 5 && <EvaluatorsTab contractOCDS={contractOCDS} />} */}
      </PlanCardContainer>
    </CardContentWrapper>
  );
};

export default ContractDetailsCardTable;
