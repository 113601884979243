/* eslint-disable import/no-named-as-default */
/* eslint-disable import/no-named-as-default-member */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-expressions */
import React, { useEffect, useState } from "react";
import { EditorState } from "draft-js";
import { Box } from "@material-ui/core";
import { ContentState } from "draft-js";
import { useMutation, useQuery, queryCache } from "react-query";
import { useParams } from "react-router-dom";
import moment from "moment";
import useAlert from "../../../../../hooks/useAlert";
import procurementApi from "../../../../../redux/api/procurementAPI";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import {
  GeneralButtonContainer,
  GeneralContentWrapper,
  GeneralTitlePage,
  NextButton,
  PlanCardContainer,
  Tab,
  TabContainer,
} from "./style";
import { CardContentWrapper, PageTabs } from "../../../../../util/theme";
import { CancelButton } from "../../../../../components/Forms/Common/style";
import CheckEntryCard from "../../../../../components/CheckEntryCard";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import ReviewCommentsSection from "../../../../../components/ReviewCommentsSection";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../../hooks/useUserDetails";
import { useForm } from "react-hook-form";
import SpecialProcurementNoticeForm from "./SpecialProcurementNoticeForm";
import addHours from "date-fns/addHours";
import addMinutes from "date-fns/addMinutes";
import format from "date-fns/format";
import procurementAPI from "../../../../../redux/api/procurementAPI";

const SpecialProcurementNotice = () => {
  const { role } = useRoles();
  const isDisabled = role !== Roles.PROCUREMENT_OFFICER;
  const isMaker = role === Roles.PROCUREMENT_OFFICER;

  const [value, setValue] = useState(0);
  const [next, setNext] = useState(0);
  const [editorError, setEditorError] = useState(null);
  const [wordCount, setWordCount] = useState(0);
  const [completedForm, setCompletedForm] = useState([]);
  const [uploadBidDoc, setUploadBidDoc] = useState([]);
  const [documentFee, setDocumentFee] = useState("");
  const [minutes, setMinutes] = useState("");
  const [hours, setHours] = useState("");
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [selectedVendor, setSelectedVendor] = useState([]);
  const [expiryDate, setExpiryDate] = useState(moment(new Date().setHours(0, 0, 0, 0)).format());
  const [showSelectVendorOption, setShowSelectVendorOption] = useState("");

  const { showAlert, Toast } = useAlert();

  useEffect(() => {
    const noticeFormIsComplete = completedForm.includes(0);
    if (editorState.getCurrentContent().getPlainText("\u0001").length >= 1) {
      if (!noticeFormIsComplete) {
        setCompletedForm((state) => [...state, 0]);
      }
    } else {
      if (noticeFormIsComplete) {
        setCompletedForm((state) => state.filter((el) => el !== 0));
      }
    }
  }, [editorState.getCurrentContent().getPlainText("\u0001")]);

  useEffect(() => {
    const bidDocumentUploaded = completedForm.includes(1);
    if (uploadBidDoc.length >= 1) {
      if (!bidDocumentUploaded) {
        setCompletedForm((state) => [...state, 1]);
      }
    } else {
      if (bidDocumentUploaded) {
        setCompletedForm((state) => state.filter((el) => el !== 1));
      }
    }
  }, [uploadBidDoc]);

  useEffect(() => {
    const documentFeeIsComplete = completedForm.includes(2);
    if (documentFee?.toString()?.length >= 1) {
      if (!documentFeeIsComplete) {
        setCompletedForm((state) => [...state, 2]);
      }
    } else {
      if (documentFeeIsComplete) {
        setCompletedForm((state) => state.filter((el) => el !== 2));
      }
    }
  }, [documentFee]);

  useEffect(() => {
    if (editorError) {
      window.scroll({
        top: 300,
        behavior: "smooth",
      });
    }
  }, [editorError]);

  const [createTenderDocPrep, createTenderDocPrepResp] = useMutation(procurementApi.createTenderDocument);

  const { activityId = "", procurementId = "" } = useParams();

  const activityDetails = useQuery({
    queryKey: [
      "activityDetails",
      {
        activityId,
      },
    ],
    queryFn: procurementAPI.getActivityById,
    config: {
      enabled: !!activityId,
    },
  });

  const { data: tenderDetails = {} } = useQuery({
    queryKey: ["generalPlanDetails", procurementId],
    queryFn: procurementAPI.getPlanDetails,
    config: {
      enabled: !!procurementId,
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100,
    },
  });

  const { data: tenderDocument } = useQuery({
    queryKey: ["tenderDocument", { procurementId }],
    queryFn: procurementAPI.getTenderDocument,
    config: {
      enabled: !!procurementId,
      onSuccess: (data) => {
        const { description, documents, fee, expiryDate } = data;
        setEditorState(EditorState.createWithContent(ContentState.createFromText(description)));
        setDocumentFee(fee);
        setMinutes(moment(expiryDate).minutes());
        setHours(moment(expiryDate).hours());
        setExpiryDate(moment(expiryDate).format());
        setUploadBidDoc(documents);
      },
      onError: (error) => {
        if (error.statusCode !== 404)
          showAlert({
            severity: "error",
            message: error.message,
          });
      },
    },
  });

  useQuery({
    queryKey: ["tenderSelectedVendor", { procurementId }],
    queryFn: procurementAPI.getSelectedVendorForTender,
    config: {
      enabled: tenderDetails?.tenderingStage?.name === "Single Stage tendering",
      onSuccess: (data) => {
        setSelectedVendor(data);
        if (data.length > 0) {
          setShowSelectVendorOption("yes");
        }
      },
      onError: (error) => {
        showAlert({
          severity: "error",
          message: error.message,
        });
      },
    },
  });

  const SpecialProcurementNoticeLinks = [
    {
      url: "/admin/procurement/plans",
      title: "Home",
      forwardSlash: true,
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Plan",
    },
  ];

  const handleAddFile = (file) => {
    setUploadBidDoc([...uploadBidDoc, ...file]);
  };

  const onEditorStateChange = (editorStateEvent) => {
    setEditorError(null);
    setEditorState(editorStateEvent);
    setWordCount(editorStateEvent.getCurrentContent().getPlainText().length);
  };

  const handleRemoveFile = (file) => {
    const filteredFile = uploadBidDoc.filter((x) => x.name !== file.name);
    setUploadBidDoc(filteredFile);
  };

  const handleMoveNext = () => {
    next < 1 && setNext(next + 1);
    next < 1 && setValue(next + 1);
    next <= 1 && !completedForm.includes(next) && setCompletedForm([...completedForm, next]);
  };

  const { register, errors, setValue: reactHookSetValue, handleSubmit, control } = useForm();

  useEffect(() => {
    if (createTenderDocPrepResp?.isSuccess) {
      showAlert({
        severity: "success",
        durationInMs: 3000,
        message: "Successfully created plan",
      });
      queryCache.removeQueries(["generalPlanDetails", procurementId]);
      handleMoveNext();
    }

    if (createTenderDocPrepResp?.isError) {
      showAlert({
        severity: "error",
        durationInMs: 10000,
        message: renderError({
          message: createTenderDocPrepResp.error.message,
          errors: createTenderDocPrepResp.error.errors,
        }),
      });
    }
  }, [createTenderDocPrepResp?.isSuccess, createTenderDocPrepResp?.isError]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const activityIsApproved = activityDetails.data?.procurementPlanActivityStatus === "Approved";

  const renderError = ({ message, errors }) => {
    return (
      <div>
        <div style={{ fontWeight: "bold" }}>{message}</div>
        <div>
          {Object.keys(errors).map((errorPath) => (
            <div style={{ marginTop: 10, fontSize: "0.8em" }} key={errorPath}>
              <span style={{ textTransform: "capitalize" }}>{errorPath}</span> - {errors[errorPath]}
            </div>
          ))}
        </div>
      </div>
    );
  };

  console.log("activityIsApproved ", activityIsApproved, activityDetails.data);

  const handleMovePrev = () => {
    next > 0 && setNext(next - 1);
    next > 0 && setValue(next - 1);
  };

  const submitForm = (formValues) => {
    const formData = new FormData();

    if (wordCount === 0) {
      setEditorError(true);
      return;
    }

    let deadlinePlusHours = addHours(new Date(formValues?.submissionDeadline).setHours(0, 0, 0, 0), formValues?.hours);
    let _totalTime = addMinutes(deadlinePlusHours, formValues?.minutes);

    let totalTime = format(_totalTime, "yyyy-MM-dd HH:mm:ss");
    const description = editorState.getCurrentContent().getPlainText("\u0001");

    formData.append("SubmissionDeadline", totalTime);
    formData.append("Description", description);
    formData.append("Fee", formValues?.amount);
    selectedVendor.map((vendor) => formData.append("VendorIds", vendor.id));
    formData.append("ManualSelection", showSelectVendorOption === "yes");
    uploadBidDoc?.map((eachDoc) => formData.append("File", eachDoc));

    createTenderDocPrep({ procurementId, formData });
  };

  const Layout = {
    0: (
      <SpecialProcurementNoticeForm
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
        isDisabled={isDisabled}
        register={register}
        errors={errors}
        addFiles={handleAddFile}
        files={uploadBidDoc}
        editorError={editorError}
        wordCount={wordCount}
        removeFile={handleRemoveFile}
        tenderDocument={tenderDocument}
        documentFee={documentFee}
        minutes={minutes}
        hours={hours}
        expiryDate={expiryDate}
        setExpiryDate={setExpiryDate}
        tenderDetails={tenderDetails}
        control={control}
        reactHookSetValue={reactHookSetValue}
        selectedVendor={selectedVendor}
        setSelectedVendor={setSelectedVendor}
        showSelectVendorOption={showSelectVendorOption}
        setShowSelectVendorOption={setShowSelectVendorOption}
        onChangeDocumentFee={(fee) => setDocumentFee(fee)}
        onChangeMinutes={(minutes) => setMinutes(minutes)}
        onChangeHours={(hours) => setHours(hours)}
        activityIsApproved={activityIsApproved}
      />
    ),
    1: <ReviewCommentsSection objectId={activityId} />,
  };

  return (
    <GeneralContentWrapper>
      <Toast float />
      <Box marginBottom="35px">
        <GeneralTitlePage>Preparation of Tender Documents</GeneralTitlePage>
        <Breadcrumb values={SpecialProcurementNoticeLinks} />
      </Box>

      <CardContentWrapper>
        <PlanCardContainer>
          <TabContainer>
            <PageTabs value={value} onChange={handleChange}>
              <Tab label="Notice Info" activetab={(value === 0).toString()} />
              <Tab label="Review" activetab={(value === 1).toString()} />
            </PageTabs>
          </TabContainer>
          {Layout[value]}
          <GeneralButtonContainer>
            <CardContentWrapper>
              <CancelButton disabled={!value} onClick={() => handleMovePrev()}>
                Back
              </CancelButton>
              {isMaker && value !== 1 && (
                <NextButton
                  onClick={value === 0 ? handleSubmit(submitForm) : handleMoveNext}
                  loading={createTenderDocPrepResp?.isLoading}
                  disabled={activityIsApproved}
                >
                  Publish
                </NextButton>
              )}
            </CardContentWrapper>
          </GeneralButtonContainer>
        </PlanCardContainer>
        <CheckEntryCard
          cardTitle="Check Entries"
          textValues={["Text of Notice", "Mandatory Document", "Document Fee"]}
          completedForm={completedForm}
        />
      </CardContentWrapper>
    </GeneralContentWrapper>
  );
};

export default withAdminDashboard(React.memo(SpecialProcurementNotice));
