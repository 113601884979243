import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import ErrorSharpIcon from "@material-ui/icons/ErrorSharp";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";

export const CreateProcurementTitle = styled(Typography)`
  color: #212b36 !important;
  font-size: 28px !important;
  letter-spacing: 0 !important;
  line-height: 32px !important;
  margin: 24px 0 5px 0 !important;
`;

export const BreadcrumbLabel = styled(Typography)`
  color: #2AA96D !important;
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 16px !important;
  margin-bottom: 35px !important;
`;

export const SectionFormContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #cbd4da;
  padding: 20.5px 25.5px 22.5px 25.5px;
`;

export const CheckEntryContainer = styled.div`
  box-sizing: border-box !important;
  height: 194px !important;
  width: 269px !important;
  border: 1px solid #cbd4da !important;
  border-radius: 3px !important;
  background-color: #ffffff !important;
  margin-left: 22px !important;
  padding: 20.5px 25.5px !important;
`;

export const CheckEntryHeader = styled(Typography)`
  color: #212b36 !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0 !important;
  line-height: 24px !important;
  margin-bottom: 15px !important;
`;

export const CheckEntryErrorIcon = styled(ErrorSharpIcon)`
  color: #de3618;
  margin-right: 10px;
`;

export const CheckEntryCheckCircleSharpIcon = styled(CheckCircleSharpIcon)`
  color: rgba(11, 176, 45, 0.6);
  margin-right: 10px;
`;

export const CheckEntryText = styled(Typography)`
  color: #212b36 !important;
  font-size: 14px !important;
  letter-spacing: 0 !important;
  line-height: 20px !important;
`;

export const CheckEntryContentWrapper = styled.div`
  display: flex;
  margin-bottom: 15px !important;
`;

export const ReviewWrapper = styled.div`
  padding: 20px;
  background: #FFEAD9;
  border-radius: 3px;
  margin-bottom: 30px;
`

export const TenderId = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2AA96D;
`

export const ReviewTitle = styled.p`
  font-size: 16px;
  line-height: 24px;
  color: #1E2B37;
  margin-bottom: 15px;
  ${props => props.rightAligned && 'text-align: right'}
`

export const ReviewLabel = styled.p`
  font-size: 13px;
  line-height: 16px;  
  color: #5F7483;
  ${props => props.rightAligned && 'text-align: right'}
`

export const ReviewText = styled.p`
  font-weight: bold;
  font-size: 14px;
  line-height: 19px;
  color: #1C2125;
  ${props => props.rightAligned && 'text-align: right'}
`

export const ReviewBudget = styled.h3`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  text-align: right;
  color: #1C2125;
`

export const ReviewDivider = styled.div`
  height: 1px;
  background-color: #CBD4DA;
  margin: 20px 0;
`

export const ReviewDetails = styled.div`
  display: grid;
  grid-template-columns: 160px auto;
  grid-column-gap: 60px;
  grid-row-gap: 30px;
`

export const ApproveButton = styled(LoadingButton)`
    &.MuiButtonBase-root {
      
    }
`

export const ModalBody = styled.div`
  position: absolute;
  top: 140px;
  left: calc(50% - 200px);
  width: 100%;
  max-width: 400px;
  min-height: 200px;
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
`

export const ModalTitle = styled.h1`
  font-weight: bold;
  font-size: 24px;
  line-height: 33px;
  color: #3F5673;
  margin-bottom: 30px;
`

export const ModalDescription = styled.p`
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;
  color: #3F5673;
  margin-bottom: 20px;
`

export const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 0.7fr;
  grid-column-gap: 10px;
  margin-top: auto;
`

export const ApprovalBanner = styled.div`
  background: rgba(3, 173, 235, 0.12);
  border-radius: 3px;
  padding: 8px 22px;
  color: #2AA96D;
  font-size: 14px;
  margin-bottom: 20px;
`

export const NeedsAmendmentText = styled.p`
  padding-top: 20px;
  color: #F2994A;
  font-size: 14px;
  font-weight: 600;
`

export const ApprovedTag = styled.div`
  width: 166px;
  height: 100%;
  background: #3BD278;
  border-radius: 3px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-weight: 600;
  font-size: 14px;
  color: white;
`
