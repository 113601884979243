import React from "react";
import { ResponseStats, TotalResponse } from "./style";
import Grid from "@material-ui/core/Grid";
import ApproveStatus from "../ApproveStatus";
import PropTypes from "prop-types";
import Box from "@material-ui/core/Box";
import Skeleton from "@material-ui/lab/Skeleton";

const TopStatisticsBar = ({ gridSpacing, leftContent, centerContent, status, hasApproveStatus = true, isLoading, hasMargin = true, ...props}) => {
  if(isLoading){
    return (
      <Skeleton
        variant='rect'
        height='102px'
        width='100%'
      />
    )
  }

  return (
    <ResponseStats hasApproveStatus={hasApproveStatus} {...props} hasMargin={hasMargin}>
      <TotalResponse>
        {leftContent}
      </TotalResponse>
      <Box px={2}>
        <Grid
          container
          alignItems='center'
          style={{height: '100%'}}
          spacing={gridSpacing}
        >
          {centerContent}
        </Grid>
      </Box>
      {
        hasApproveStatus && (
          <ApproveStatus status={status}/>
        )
      }
    </ResponseStats>
  );
};

TopStatisticsBar.propTypes = {
  leftContent: PropTypes.node.isRequired,
  centerContent: PropTypes.node.isRequired,
  status: PropTypes.string,
  hasApproveStatus: PropTypes.bool,
  isLoading: PropTypes.bool
}

export default TopStatisticsBar;
