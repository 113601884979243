import React from "react";
import ReceiptOfResponse from "./index";
import { Route, Switch } from "react-router-dom";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import VendorsCategorized from "./VendorsCategorized/VendorsCategorized";
import SpecificVendor from "./SpecificVendor/SpecificVendor";
import TechnicalReviewDetails from "./TechnicalReviewDetails";
import CreateTechnicalEvaluation from "./CreateTechnicalEvaluation/Index";
import UpdateTechnicalEvaluation from "./CreateTechnicalEvaluation/UpdateTechEvaluation";

const basePath = '/admin/procurement/activity/receiptofresponses/:activityId/:procurementId'

const ReceiptOfResponseWrapper = () => {
  return (
    <Switch>
      <Route path={basePath} component={ReceiptOfResponse} exact />
      <Route path={`${basePath}/vendors/:type`} component={VendorsCategorized} exact />
      <Route
        path={`${basePath}/vendorsDetails/:vendorId/:vendorName`}
        component={SpecificVendor}
        exact
      />
      <Route
        path={`${basePath}/vendorsDetails/:vendorId/:vendorName/vendor-evaluation`}
        component={TechnicalReviewDetails}
        exact
      />
      <Route
        path={`${basePath}/create-technical-evaluation`}
        component={CreateTechnicalEvaluation}
        exact
      />
      <Route
        path={`${basePath}/edit-technical-evaluation`}
        component={UpdateTechnicalEvaluation}
        exact
      />
    </Switch>
  );
};


export default withAdminDashboard(ReceiptOfResponseWrapper);
