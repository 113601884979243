import React from "react";
import { Container, Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import { Footer, Navbar } from "../../../components";
import {
  AuthSubtext,
  AuthText,
  ContentWrapper,
  Wrapper,
} from "../style";
import { CheckJunk, CheckLinkWrapper } from "./style";

const PasswordResetComponent = ({ passwordPath }) => {
  const textAlign = "center";
  const textDecoration = "none";
  const color = "#2AA96D";
  const fontWeight = "bold";
  const cursor = "pointer";

  const { user } = useSelector((state) => state.user);
  const email = (user && user.email) || localStorage.getItem("email");

  return (
    <div>
      <Navbar />

      <Container>
        <ContentWrapper>
          <Wrapper>
            <AuthText>Reset link sent!</AuthText>
            <AuthSubtext
              style={{
                textAlign,
                marginBottom: "16px",
              }}
            >
              Done. Check your email <b>{email}</b> and follow the
              instructions.
            </AuthSubtext>
            <Grid item xs={12}>
              <CheckLinkWrapper>
                <CheckJunk>
                  If you are yet to receive the email, ensure you have checked
                  your junk folder and if it is not there,
                  <p
                    onClick={() => {}}
                    style={{
                      textDecoration,
                      color,
                      fontWeight,
                      cursor,
                    }}
                  >
                    Resend link
                  </p>
                </CheckJunk>
              </CheckLinkWrapper>
            </Grid>
          </Wrapper>
        </ContentWrapper>
      </Container>
      <Footer />
    </div>
  );
};

export default PasswordResetComponent;
