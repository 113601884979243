import React, { useState, useEffect, useContext } from 'react'
import { useQuery, useMutation, queryCache } from 'react-query';
import { useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { Alert } from "@material-ui/lab";
import { useDropzone } from "react-dropzone";
import { saveAs } from 'file-saver';
import { Box, Button } from "@material-ui/core";

import AdminSearchFilter from '../../../components/AdminSearchFilter';
import StatisticsCards from '../../../components/StatisticsCards';
import withLoader from '../../Common/HOC/withLoader';
import CustomSearchTable from "../../../components/SearchTable/SupplierSearchTable";
import Loader from "../../../components/Common/Loader";
import withAdminDashboard from '../HOC/withAdminDashboard'
import VendorUpgradeRequestTable from './UploadedVendorTable';
import { RequestInputField } from './style';
import {
  ContentWrapper,
  TitleContainer
} from "../Administration/Staff/style";
import {
  CustomDialog,
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogTitleWrapper,
  SubmitButton,
  PrevBtn
} from "../../../components/Forms/ConfirmVerificationModal/style";
import { DropzoneArea } from "../../../components/DocumentUploadField/style";
import FileError from "../../../components/FileUploadField/FileError";
import { MaxFileSize } from "../../../util/constants";
import FileProgress from "../../../components/FileProgress/FileProgress";
// context
import ToastContext from "../../../util/toastContext"
import { getVendorsSummary } from "../../../redux/reducer/adminReducer";
import vendorsAPI from "../../../redux/api/vendorsAPI"
import { PageTitle } from '../../Common/style';
import Breadcrumb from '../../Common/PageElements/Breadcrumb';
import AccessControl from '../../../components/AccessControl';
import { Roles } from '../../../components/AccessControl/UserRoles';
import { AddButton } from '../Procurement/style';
import contractsAPI from '../../../redux/api/contractAPI';

const VENDOR_BULK_UPLOAD_FORMAT = `Tender Package Number,MinistryCode,Procuring Entity,Project Title/Description,Year,Procurement Category,Selection Method,Contractor Name,Contract Sum,Date of Award,Contract Duration`;

const createCardStats = (title, count, subTitle, type) => {
  return {
    title,
    count,
    subTitle,
    type
  }
}
const RenderStatCards = () => {
  const { vendorsSummary } = useSelector((state) => state.admin);
  const cards = [
    createCardStats("Total Uploaded", vendorsSummary?.total, "All Vendors", null),
    createCardStats("Onboarded Vendors", vendorsSummary?.activeTotal, "", "active"),
    createCardStats("Not Onboarded Vendors", vendorsSummary?.pendingTotal, "", "pending"),
  ]
  return <StatisticsCards cards={cards} />
}
const createHeader = (id, label) => {
  return {
    id,
    label
  }
}
const headLabel = [
  createHeader("email", "EMAIL ADDRESS"),
  createHeader("category", "CATEGORY"),
  createHeader("uploadedDate", "UPLOADED DATE"),
  createHeader("regPaymentDate", "REG. PAYMENT DATE"),
  createHeader("status", "STATUS"),
  // createHeader("CompanyName", "COMPANY NAME"),
  // createHeader("NewCategory", "NEW CATEGORY"),
  // createHeader("Status", "STATUS"),
  createHeader("action", "ACTION"),
]

const UploadedVendor = () => {
  const [openUpload, setOpenUpload] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const { pathname } = useLocation()
  const [filters, setFilters] = useState({
    Search: "",
    Sort: "",
    Status: "",
    Filter: "",
    FilterBy: "",
  });
  const [file, setFile] = useState(null);
  const fileMaxSize = MaxFileSize;

  const { showAlert } = useContext(ToastContext);
  const linkStyle = { textDecoration: "none", color: "inherit" };
  const marginTop = "40px";
  const status = "loading";

  const handleCloseModal = () => {
    setFile(null);
    setOpenUpload(false);
  };

  const { data: apiData, isLoading, error = {}, isError } = useQuery(
    ["getVendorUpgradeList",
      {
        PageNumber: pageNumber + 1,
        PageSize: rowsPerPage,
        ...filters
      }
    ],
    vendorsAPI.getVendorUpgradeList
  )

  const [uploadOCDS, uploadOCDSQuery] = useMutation(
    contractsAPI.uploadOCDS,
    {
      onSuccess: () => {
        showAlert({
          message: "Upload was successful",
          severity: "success",
        });
        queryCache.invalidateQueries("tenderAndAwardRequest", {
          exact: false,
          throwOnError: true,
          refetchActive: true,
          refetchInactive: false
        })
        handleCloseModal();
        // refetch();
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const vendorListData = apiData?.data || []
  const meta = apiData?.meta || {}

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error?.message,
        durationInMs: 3000,
      })
    }
  }, [showAlert])

  const dropzone = useDropzone({
    onDrop: (files) => {
      setFile(files[0]);
    },
    multiple: false,
    validator: fileSizeValidator
  });

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };
  const emptyRows =
    pageNumber > 0 ? Math.max(0, rowsPerPage - vendorListData?.length) : 0

  const handleChangePage = (event, pageNumber) => {
    setPageNumber(pageNumber);
  };

  function fileSizeValidator(file) {
    if (file.size > fileMaxSize) {
      return {
        code: "tooLarge",
        message: `File size is larger than ${fileMaxSize / 1000}kb`
      };
    }

    return null
  };

  const saveFile = () => {
    const data = [VENDOR_BULK_UPLOAD_FORMAT];
    const name = "Vendor_Upload_Format";

    const csvFile = new File(data, `${name}.csv`, { type: 'text/csv;charset=utf-8' });
    saveAs(csvFile);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPageNumber(0);
  };

  const handleUpload = async () => {
    const fd = new FormData();
    fd.set("File", file);

    try {
      uploadOCDS({
        file: fd,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const renderVendorRequestTable = (
    <div id='request'>
      <CustomSearchTable
        rows={vendorListData}
        pageNumber={pageNumber}
        rowsPerPage={rowsPerPage}
        loading={isLoading}
        columns={headLabel}
        showPadding={false}
        totalNumberOfPages={meta?.pagination?.totalPages}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        handleSortRequest={updateFilters("Sort")}
      >
        <VendorUpgradeRequestTable
          emptyRows={emptyRows}
          requests={vendorListData}
          column={headLabel}
        />
      </CustomSearchTable>
    </div>
  );


  const GeneralPlanLinks = [
    { url: "/admin/vendorsList/vendors", title: "Vendors" },
    { title: "Uploaded Vendors" },
  ];

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Uploaded Vendor</PageTitle>
          <Breadcrumb values={GeneralPlanLinks} />
        </div>
        <AccessControl allowedRoles={[Roles.KWPPA]}>
          <AddButton onClick={() => setOpenUpload(true)}>
            Upload Vendor
          </AddButton>
        </AccessControl>
      </TitleContainer>
      {
        withLoader(RenderStatCards)({
          stateName: "admin",
          conditioners: ["vendorsSummary"],
          apis: [getVendorsSummary()]
        })
      }
      <React.Fragment>
        <AdminSearchFilter normalSearch={true} title="Filter">
          <div style={{ display: "flex" }}>
            <RequestInputField
              type="text"
              label="Search Name"
              style={{ width: 300 }}
              setValue={updateFilters("Search")}
            />
            {/* <DropdownField
              name="approval"
              label="Approval Status"
              style={{ width: 150 }}
              onChange={updateFilters("Status")}
              required
              values={[
                { label: "All", value: null },
                { label: "Pending", value: 1 },
                { label: "Approved", value: 2 },
                { label: "Rejected", value: 3 },
              ]}
            /> */}
          </div>
        </AdminSearchFilter>
        <div style={{ marginTop }}>
          {status === "loadig" ?
            (
              <div style={{ width: "100%" }}>
                <Loader feedback="Fetching Vendor Upgrade Request Lists" />
              </div>
            ) : renderVendorRequestTable
          }

        </div>
      </React.Fragment>
      <CustomDialog scroll="body" onClose={handleCloseModal} open={openUpload}>
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>Upload Vendor</CustomDialogTitle>
        </CustomDialogTitleWrapper>
        <CustomDialogContent>
          <Box>
            <Button
              variant="text"
              color="primary"
              style={{ textTransform: "none" }}
              onClick={saveFile}
            ><b>Download Tender/Contracts sample file here</b></Button>
          </Box>
          <Alert severity="info">
            <b>Info:</b> You agree to have thoroughly gone through the draft and agree that the information are valid and correct?
          </Alert>
          <Box>
            {file ? (
              <FileProgress
                progress={100}
                fileName={file.name}
                fileSize={file.size}
                hasCancel
                onCancel={() => setFile(null)}
              />
            ) : (
              <DropzoneArea {...dropzone.getRootProps()}>
                <input {...dropzone.getInputProps()} />
                <div>
                  <p>Upload or drop file here</p>
                  <span style={{ fontSize: ".9em" }}>{"File size should not exceed 5,000kb (5mb)"}</span>
                  <FileError fileRejections={dropzone.fileRejections} />
                </div>
              </DropzoneArea>
            )}
          </Box>

        </CustomDialogContent>

        <AccessControl allowedRoles={[Roles.KWPPA]}>
          <Box>
            <SubmitButton
              onClick={handleUpload}
              style={{ marginRight: 16 }}
              disabled={uploadOCDSQuery.isLoading || !file}
            >{uploadOCDSQuery.isLoading ? "Uploading..." : "Upload New"}</SubmitButton>
            <PrevBtn onClick={() => {
              setOpenUpload(false);
              setFile(null);
            }}>Cancel</PrevBtn>
          </Box>
        </AccessControl>
      </CustomDialog>
    </ContentWrapper>
  )
}

export default withAdminDashboard(UploadedVendor)
