import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { useForm } from "react-hook-form";
import { useHistory, useParams } from "react-router-dom";
import Dropdown from "../../../DropdownField";
import InputField from "../../../InputField";
import { BottomGroupWrapper, FormTitle, IdInputField, PrimaryButton } from "../style";
import { ErrorContainer } from "../../Common/style";
import useAlert from "../../../../hooks/useAlert";
import FormContext from "../../../../util/context";
import ReviewCommentsSection from "../../../ReviewCommentsSection";
import { ButtonGroup } from "../../../NavigationButtons/ButtonGroup";
import { Roles } from "../../../AccessControl/UserRoles";
import { queryCache, useMutation, useQuery } from "react-query";
import procurementAPI from "../../../../redux/api/procurementAPI";
import useUserDetails from "../../../../hooks/useUserDetails";
import Loader from "../../../Common/Loader";
import adminAPI from "../../../../redux/api/adminAPI";

export const convertArrayToOptions = (array) => {
  return (
    array?.map(({ name, id }) => ({
      label: name,
      value: id,
    })) || []
  );
};

const KeyDetails = ({ completedForm, addCompletedForm }) => {
  const width = "100%";
  const backgroundColor = "#FFFFFF";

  const { isReviewMode, setActiveForm, userRole } = useContext(FormContext);

  const isMaker = userRole === Roles.PROCUREMENT_OFFICER;
  const isChecker = [Roles.COMMISSIONER, Roles.PERMANENT_SECRETARY].includes(userRole);

  const [submitting, setSubmitting] = useState(false);
  const [gettingPackageNumber, setGettingPackageNumber] = useState(false);
  // const [budgetValue, setbudgetValue] = useState(null);

  const { data: ministries } = useQuery({
    queryFn: adminAPI.getMinistriesAxios,
    queryKey: [
      "ministries",
      {
        pageSize: 0,
        pageNumber: null,
      },
    ],
  });

  const { register, handleSubmit, errors, setValue, getValues, reset } = useForm();
  const { id: generalPlanId, procurementId } = useParams();
  const history = useHistory();

  const { data: formOptions, isLoading } = useQuery({
    queryKey: "keyDetailsFormOptions",
    queryFn: procurementAPI.getKeyDetailsFormOptions,
  });

  const generalPlanDetails = useQuery({
    queryKey: ["generalPlanDetails", procurementId],
    queryFn: procurementAPI.getPlanDetails,
    config: {
      enabled: procurementId !== "new",
      staleTime: 900 * 1000,
    },
  });

  const [createPlan] = useMutation(procurementAPI.createProcurementPlanAxios, {
    throwOnError: true,
  });

  useEffect(() => {
    setActiveForm(0);
  }, [setActiveForm]);

  useEffect(() => {
    if (procurementId === "new") {
      reset();
    } else {
      let ministryId;

      if (generalPlanDetails.data && ministries?.data) {
        if (generalPlanDetails.data?.ministryId) {
          ministryId = generalPlanDetails.data?.ministryId;
        } else {
          ministryId = ministries?.data?.find(({ code }) => code === generalPlanDetails.data.ministryCode)?.id;
        }
      }

      const procurementPlanDefault = Object.assign({}, generalPlanDetails.data, { ministryId, reviewMethodId: generalPlanDetails.data.reviewMethod.id });
      // setbudgetValue(generalPlanDetails.data.budget);

      reset(procurementPlanDefault);
    }
  }, [ministries, reset, procurementId, generalPlanDetails.data]);

  const { showAlert, Toast } = useAlert();

  const exitReview = () => {
    history.push(`/admin/procurement/plans/${generalPlanId}`);
  };

  const { user } = useUserDetails();

  const handleGeneratePackageNumber = async () => {
    const values = getValues();

    const { name } = formOptions.procurementCategories.find((p) => p.id === values.procurementCategoryId);
    const { name: methodName } = formOptions.procurementMethods.find((p) => p.id === values.procurementMethodId);

    const data = {
      ministry: user?.ministry?.code,
      procurementCategory: name,
      procurementMethod: methodName,
    };

    setGettingPackageNumber(true);

    try {
      const planNumber = await procurementAPI.getPackageNumberAxios(data);
      setGettingPackageNumber(false);
      setValue("packageNumber", planNumber, {
        shouldValidate: true,
        shouldDirty: true,
      });
    } catch (e) {
      //TODO: Show error
    }
  };

  const onSubmit = (data) => {
    setSubmitting(true);

    let isEditMode = procurementId !== "new";

    if (isEditMode) {
      data.id = procurementId;
      data.generalPlanId = generalPlanId;
    }

    createPlan({ data, generalPlanId })
      .then(({ id }) => {
        showAlert({
          severity: "success",
          message: `Successfulled ${isEditMode ? "updated" : "created"} plan`,
        });

        if (!completedForm.includes(0)) {
          addCompletedForm((state) => [...state, 0]);
        }

        queryCache.removeQueries(["generalPlanDetails", procurementId]);

        history.push(`/admin/procurement/plans/${generalPlanId}/create/planning/${id}`);
      })
      .catch((err) => {
        showAlert({
          severity: "error",
          message: renderError({
            message: err?.message,
            errors: err?.errors,
          }),
        });
      })
      .finally(() => {
        setSubmitting(false);
      });
  };

  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  const renderError = ({ message, errors }) => {
    return (
      <div>
        <div style={{ fontWeight: "bold" }}>{message}</div>
        <div>
          {Object.keys(errors).map((errorPath) => (
            <div style={{ marginTop: 10, fontSize: "0.8em" }} key={errorPath}>
              <span style={{ textTransform: "capitalize" }}>{errorPath}</span> - {errors[errorPath]}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderMakerButtons = () => {
    if (!isMaker) {
      return null;
    }

    if (isReviewMode) {
      return (
        <ButtonGroup
          firstBtnText="Exit"
          thirdBtnText={submitting ? "Please wait..." : "Continue"}
          handleFirstBtn={exitReview}
          handleThirdBtn={handleSubmit(onSubmit)}
          isReviewMode={isReviewMode}
          disabled={submitting}
        />
      );
    }

    return (
      <ButtonGroup
        firstBtnText="Previous"
        thirdBtnText={submitting ? "Please wait..." : "Continue"}
        handleFirstBtn={exitReview}
        handleThirdBtn={handleSubmit(onSubmit)}
        isReviewMode={isReviewMode}
        disabled={submitting}
      />
    );
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <FormTitle>Basic Information</FormTitle>
      <form>
        <Toast float />
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Dropdown
              name="procurementCategoryId"
              label="Procurement Category"
              values={convertArrayToOptions(formOptions.procurementCategories)}
              initialState={generalPlanDetails.data?.procurementCategory?.id}
              style={{
                backgroundColor,
                width,
                margin: "0",
                marginTop: "20px",
              }}
              inputRef={register({
                required: "Please choose a Procurement Category",
              })}
              disabled={!isMaker}
            />
            {onRenderError(errors.procurementCategoryId)}
          </Grid>
          <Grid item xs={6} />
        </Grid>
        <BottomGroupWrapper>
          <Grid container spacing={3} style={{ marginTop: "20px" }}>
            <Grid item xs={12}>
              <InputField
                name="name"
                label="Tender Title"
                style={{ backgroundColor, width, margin: 0, padding: 0 }}
                inputRef={register({
                  required: "Enter Tender Title",
                })}
                disabled={!isMaker}
              />
              {onRenderError(errors.name)}
            </Grid>
            <Grid item xs={12}>
              <InputField
                type="text"
                label="Description"
                name="description"
                style={{
                  width,
                  margin: "0",
                  marginTop: "20px",
                  padding: 0,
                  backgroundColor,
                }}
                multiline
                inputRef={register({
                  required: "Please enter a description",
                })}
                disabled={!isMaker}
              />
              {onRenderError(errors.description)}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <Dropdown
                name="procurementMethodId"
                label="Procurement Method"
                values={convertArrayToOptions(formOptions.procurementMethods)}
                initialState={generalPlanDetails.data?.procurementMethod?.id}
                style={{ backgroundColor, width, margin: 0 }}
                inputRef={register({
                  required: "Please choose a procurement method",
                })}
                disabled={!isMaker}
              />
              {onRenderError(errors.procurementMethodId)}
            </Grid>
            <Grid item xs={6}>
              <Dropdown
                name="tenderingStageId"
                label="Tendering Stage"
                values={convertArrayToOptions(formOptions.tenderStages)}
                initialState={generalPlanDetails.data?.tenderingStage?.id}
                style={{ backgroundColor, width, margin: 0 }}
                inputRef={register({
                  required: "Please choose a Tendering Stage",
                })}
                disabled={!isMaker}
              />
              {onRenderError(errors.tenderingStageId)}
            </Grid>
          </Grid>
          <Grid container spacing={3} style={{ marginTop: "10px" }} />
          <Grid container spacing={3} style={{ marginTop: "10px" }}>
            <Grid item xs={6}>
              <Dropdown
                name="reviewMethodId"
                label="Review Method"
                values={convertArrayToOptions(formOptions.reviewMethods)}
                style={{ backgroundColor, width, margin: 0 }}
                inputRef={register({
                  required: "Please choose a review method",
                })}
                disabled={!isMaker}
              />
              {onRenderError(errors.reviewMethodId)}
            </Grid>
            <Grid item xs={6}>
              <InputField
                name="budget"
                label="Budget"
                style={{ backgroundColor, width, margin: 0, padding: 0 }}
                inputRef={register({
                  required: "Budget is required",
                  isNumber: (value) => !isNaN(parseFloat(value)) || "Budget should be in Numbers",
                })}
                disabled={!isMaker}
              />
              {onRenderError(errors.budget)}
            </Grid>
            <Grid item xs={6} />
          </Grid>

          <Grid container spacing={2} alignItems="center" style={{ marginBottom: "40px" }}>
            <Grid item xs={6}>
              <IdInputField
                type="text"
                name="packageNumber"
                style={{ width, margin: "0", marginTop: "20px" }}
                disabled={true}
                inputRef={register({
                  required: "Please generate a package number",
                })}
              />
              {onRenderError(errors.packageNumber)}
            </Grid>
            {isMaker ? (
              <Grid item>
                <PrimaryButton
                  disabled={gettingPackageNumber || (isReviewMode && isChecker)}
                  onClick={handleGeneratePackageNumber}
                  type="button"
                  style={{ marginTop: "20px" }}
                >
                  {gettingPackageNumber ? "Generating..." : "Generate Package Number"}
                </PrimaryButton>
              </Grid>
            ) : null}
          </Grid>
        </BottomGroupWrapper>

        {/* {isReviewMode ?  */}
        <ReviewCommentsSection objectId={procurementId} />
        {/* : null} */}

        {renderMakerButtons()}
      </form>
    </>
  );
};

export default KeyDetails;
