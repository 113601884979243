import styled from "styled-components";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import LoadingButton from "../LoadingButton/LoadingButton";
import { theme } from "../../util/theme";

export const Wrapper = styled(Grid)`
  height: 40px;
  box-sizing: border-box !important;
  margin-top: 28px;
  margin-bottom: 50px;

  button {
    border-radius: 3px !important;
    letter-spacing: 0 !important;
    line-height: 20px;
    font-weight: 600 !important;
    font-size: 14px !important;
  }
`;

export const BackButton = styled(Button)`
  width: 91px;
  border: 1px solid #919eab !important;
  text-transform: none !important;
  color: #8e9fac !important;
`;

export const SaveButton = styled(LoadingButton)`
  margin-left: auto;
  width: 171px;
  border: 1px solid #ebebeb !important;
  background-color: ${(props) => (props.disabled ? "#c9d4db !important" : `${theme.color.primary} !important`)};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15) !important;
  span {
    color: #ffffff !important;
  }
  text-transform: none !important;
`;

export const DoubleWrapper = styled.div`
  box-sizing: border-box !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between !important;
`;

export const SaveAndExit = styled(Button)`
  width: 136px;
  color: #8e9fac !important;
  border: 1px solid #919eab !important;
  text-transform: none !important;
  margin-right: 20px !important;
`;

export const GenButton = styled.button`
  box-sizing: border-box;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  color: white;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: ${({ backgroundColor }) => backgroundColor || "#2AA96D"};
  margin-right: ${({ marginRight }) => marginRight || "0"};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  cursor: pointer;
`;
