import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { FaTwitter, FaFacebookSquare } from "react-icons/fa";

export const FooterSection = styled.section`
  background-color: #274472;
  padding: 65px 0;
  margin-top: 70px !important;
  display: flex;
  align-items: center;
`;

export const FooterTitleText = styled(Typography)`
  color: #ffffff;
  font-size: 20px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 27px !important;
  margin-bottom: 18px !important;
`;

export const FooterDescriptionText = styled(Typography)`
  color: #ffffff;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
  margin-bottom: 16px;
  max-width: 461px;
`;

export const FooterGroupText = styled(Typography)`
  color: #ffffff;
  font-size: 16px !important;
  letter-spacing: 0 !important;
  line-height: 22px !important;
`;

export const FooterCopyRightContainer = styled.div`
  text-align: center;
  margin-top: 48px !important;
`;

export const TwitterIcon = styled(FaTwitter)`
  color: #fff;
`;

export const FacebookIcon = styled(FaFacebookSquare)`
  color: #fff;
`;

export const GroupedIcon = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 15px;

  svg {
    width: 20px;
    height: 20px;
    margin: 0 15px;
  }
`;
