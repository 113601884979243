import React, { useState, useContext } from "react";
import { useParams, Link, useLocation } from "react-router-dom";
import PropTypes from 'prop-types';
import { Box, Tabs, Tab, Typography, makeStyles } from "@material-ui/core";

import { GeneralTitlePage } from "../SpecificProcurementNotice/style";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import { ResponseStatsLabel, ResponseStatsValue } from "./style";
import TopStatisticsBar from "../../../../../components/TopStatisticsBar";
import { useQuery, useMutation } from "react-query";
import Loader from "../../../../../components/Common/Loader";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton";
import receiptOfResponseApi from "../../../../../redux/api/receiptOfResponseApi";
import VendorsCategorized from "./VendorsCategorized/VendorsCategorized";
import receiptOfBiddersResponseApi from "../../../../../redux/api/receiptOfBiddersResponseApi";
import ToastContext from "../../../../../util/toastContext";
import AccessControl from "../../../../../components/AccessControl";
import { Roles } from "../../../../../components/AccessControl/UserRoles";
import procurementAPI from "../../../../../redux/api/procurementAPI";

export const convertVendors = (vendors = [], totalCount) => {
  return vendors.map(vendor => ({
    name: vendor.companyName,
    email: vendor.email,
    documents: {
      count: vendor.documentsUploaded,
      total: totalCount
    },
    REGID: vendor.registrationPlan.grade,
    id: vendor.userId
  }))
}

const ReceiptOfResponse = () => {
  const { procurementId = "", activityId } = useParams();
  const [value, setValue] = useState(0);
  const [hasBenchmark, setHasBenchmark] = useState(null);
  const classes = useStyles();
  const { pathname } = useLocation();
  const { showAlert } = useContext(ToastContext);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const baseUrl = `/admin/procurement/activity/receiptofresponses/${activityId}/${procurementId}`;

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10
  });

  const vendors = useQuery({
    queryKey: [
      "interestedVendors",
      {
        procurementId,
        Status: 7,
        PageNumber: pagination.pageNumber,
        PageSize: pagination.pageSize
      }
    ],
    queryFn: receiptOfResponseApi.getInterestedVendors
  });

  const { data: technicalReviewsStat = {} } = useQuery({
    queryFn: receiptOfBiddersResponseApi.getEvaluatedVendorsStat,
    queryKey: [
      "technical-evaluation-stat",
      { procurementId },
    ],
    config: {
      onError: (error) => showAlert({
        severity: "error",
        message: error?.message,
      })
    }
  });

  const { data: activity = {}, ...activityQueryFeedback } = useQuery({
    queryFn: procurementAPI.getActivityById,
    queryKey: ["activity", { activityId }],
    config: {
      onError: (error) => showAlert({
        severity: "error",
        message: error?.message,
      })
    }
  });

  const benchmarkQuery = useQuery({
    queryFn: receiptOfBiddersResponseApi.getTechnicalEvaluation,
    queryKey: [
      "technical-evaluation",
      { id: procurementId },
    ],
    config: {
      onSuccess: (data) => {
        setHasBenchmark(data?.hasEvaluation);
      }
    }
  });

  const [mutate, { isLoading }] = useMutation(receiptOfResponseApi.technicalEvaluationApproval, {
    onSuccess: () => {
      showAlert({
        message: "Approval was successful, you can proceed to the next stage",
        severity: "success",
        durationInMs: 3000,
      });
      activityQueryFeedback.refetch();
    },
    onError: error => {
      showAlert({
        message: error.message,
        severity: "error",
        durationInMs: 2000,
      });
    }
  });

  const breadcrumbLinks = [
    {
      url: "/admin/procurement/plans",
      title: "Home",
      forwardSlash: true
    },
    {
      url: `/admin/procurement/plan-details/${procurementId}`,
      title: "Activities List"
    },
    {
      url: baseUrl,
      title: "Receipt of Response"
    }
  ];

  const stats = vendors.data?.interestedVendorsData?.totalResponse || {};
  const total = technicalReviewsStat.totalPassed + technicalReviewsStat.totalPending + technicalReviewsStat.totalFailed;

  const renderLeftContent = () => {

    return (
      <>
        <ResponseStatsLabel color='#5F7483'>
          Total Vendors
        </ResponseStatsLabel>
        <ResponseStatsValue>
          {isNaN(total) ? 0 : total}
        </ResponseStatsValue>
      </>
    )
  };

  const renderCenterContent = () => {
    return (
      <>
        <Box mr={6}>
          <ResponseStatsLabel color='#FF8D26'>
            Pending
          </ResponseStatsLabel>
          <ResponseStatsValue>
            {technicalReviewsStat.totalPending || 0}
          </ResponseStatsValue>
        </Box>
        <Box mr={6}>
          <ResponseStatsLabel color='#219653'>
            Passed
          </ResponseStatsLabel>
          <ResponseStatsValue>
            {technicalReviewsStat.totalPassed || 0}
          </ResponseStatsValue>
        </Box>
        <Box mr='auto'>
          <ResponseStatsLabel color='#EB5757'>
            Failed
          </ResponseStatsLabel>
          <ResponseStatsValue>
            {technicalReviewsStat.totalFailed || 0}
          </ResponseStatsValue>
        </Box>
        <AccessControl allowedRoles={[Roles.MINISTERIAL_TENDERS_BOARD]}>
          <LoadingButton
            color='#219653'
            component={Link}
            disabled={benchmarkQuery.isError  || benchmarkQuery.isLoading || hasBenchmark === true || hasBenchmark === null}
            to={`${pathname}/create-technical-evaluation`}
          >
            Set Benchmark
          </LoadingButton>
          <Box ml={3}>
            <LoadingButton
              color='#219653'
              disabled={activityQueryFeedback.isError || activityQueryFeedback.isLoading || activity.procurementPlanActivityStatus === "Approved" || total === 0 || total > 0 && technicalReviewsStat.totalPending > 0}
              onClick={() => mutate({ activityId })}
              loading={isLoading}
            >
              Proceed
            </LoadingButton>
          </Box>
        </AccessControl>
      </>
    )
  };

  return (
    <Box pb={10}>
      <Box mb={4} mt={2}>
        <GeneralTitlePage>Receipt of Response</GeneralTitlePage>
        <Breadcrumb values={breadcrumbLinks} />
      </Box>
      {
        vendors.isLoading ? (
          <Loader feedback='Fetching details' />
        ) : (
          <>
            <TopStatisticsBar
              leftContent={renderLeftContent()}
              centerContent={renderCenterContent()}
              status={stats.pending > 0 ? 'pending' : 'approved'}
            />

            <Box style={{ width: '100%' }}>
              <Box style={{ borderBottom: "1px solid #CDCED9" }}>
                <Tabs value={value} onChange={handleChange} classes={{ indicator: classes.tabIndicator }} aria-label="basic tabs example">
                  <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="Pending Review" {...a11yProps(0)} />
                  <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="Passed Review" {...a11yProps(1)} />
                  <Tab classes={{ root: classes.tabRoot, selected: classes.selected }} label="Failed Review" {...a11yProps(2)} />
                </Tabs>
              </Box>
              <Box mt={3}>
                <TabPanel value={value} index={0}>
                  <VendorsCategorized
                    type="pending"
                    evaluate
                    hasBenchmark={hasBenchmark}
                    status="Pending"
                    activityId={activityId}
                    procurementId={procurementId}
                  />
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <VendorsCategorized
                    type="accepted"
                    status="Passed"
                    activityId={activityId}
                    procurementId={procurementId}
                  />
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <VendorsCategorized
                    type="rejected"
                    status="Failed"
                    activityId={activityId}
                    procurementId={procurementId}
                  />
                </TabPanel>
              </Box>
            </Box>
          </>
        )
      }
    </Box>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}

      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const useStyles = makeStyles({
  tabRoot: {
    minWidth: "min-content",
    padding: 0,
    marginRight: 20,
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 400
  },
  selected: {
    color: "#2AA96D",
    fontWeight: 700,
  },
  tabIndicator: {
    width: "80px",
    color: "#2AA96D",
    backgroundColor: "#2AA96D"
  }
});

export default ReceiptOfResponse;
