import React, { useEffect, useRef } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useMutation } from "react-query";
import { ReactComponent as SuccessIcon } from "../../../assets/payment_success.svg"
import { ReactComponent as Info } from "../../../assets/round_caution.svg"
import paymentAPI from "../../../redux/api/paymentAPI";
import { Center, Detail, TextMsg, Title, CustomDialog, InfoText, InfoPlace, ContinueBtn, PrintBtn, Horizontal } from "./style";
import { Grid } from "@material-ui/core";
import { useReactToPrint } from 'react-to-print';
import moment from 'moment'
import Loader from "../../../components/Common/Loader";

const PaymentStatus = () => {
 
  const toPrint = useRef()
  const location = useLocation();
  const paymentStatusCode = new URLSearchParams(location.search).get("status-code");
  const transactionId = new URLSearchParams(location.search).get("transaction-id");
  const [updatePayment, {isSuccess, isError, data}] = useMutation(paymentAPI.updatePayment);

  const history = useHistory();

  const today = moment().format("DD/MM/YYYY")

 //TODO: GET PAYMENT DETAILS FROM PAYMENT API RESPONSE
  const paymentDetails = [
    {
      title: "Payer name:",
      value: `${localStorage.getItem("userName")}`
    },
    {
      title: "Payer email:",
      value: ""
    },
    {
      title: "Payment date:",
      value: today
    },
    {
      title: "Service fee:",
      value: ""
    },
  ]

  const handleContinue = () => {
    history.push("/vendor/overview");
  }

  const handlePrint = useReactToPrint({
    content: () => toPrint.current,
  })


  useEffect(() => {
    if (!paymentStatusCode) {
      setTimeout(() => history.goBack(), 10000)      
      return;
    }

    updatePayment({
      paymentResponseCode: paymentStatusCode,
      transactionId,
    });
  }, [paymentStatusCode, transactionId, updatePayment, history]);

  useEffect(() => {
    if (isSuccess) {
      if (data?.paymentStatus === "PAID") {
        setTimeout(() =>history.replace("/vendor/overview", {}),20000  )
          
      } else {
        setTimeout(() => history.goBack(), 20000)
      }
    }

    if (isError) {
      setTimeout(() => history.goBack(), 20000)
    }
  }, [isSuccess, isError, data, history]);


  if(isSuccess) {
  return (
      <CustomDialog open={true} scroll="body" ref={toPrint}>
        <Center>
          <SuccessIcon/>
          <TextMsg>Payment is Successful</TextMsg>
          {paymentDetails.map(detail => (
          <>
          <Grid container justify="space-between">
            <Title>{detail.title}</Title>
            <Detail>{detail.value}</Detail>
          </Grid>
          <Horizontal/>
          </>
        ))}
        <InfoPlace>
          <Grid container justify="flex-start" alignItems="center">
            <Info style={{marginRight:10}}/>
            <InfoText>You will be redirected in 20 seconds....</InfoText>
          </Grid>
        </InfoPlace>
        <Grid container justify="space-between">
          <ContinueBtn onClick={handleContinue}>
            Continue
          </ContinueBtn>
          <PrintBtn onClick={handlePrint}>
            Print
          </PrintBtn>
        </Grid>
        </Center>
      </CustomDialog>
  )
}


if(!paymentStatusCode || isError){
  return (
  <CustomDialog open={true} scroll="body">
      <Center>
        <TextMsg danger>Payment verification failed!</TextMsg>
        <Detail>Please retry transaction</Detail>
      <InfoPlace>
        <Grid container justify="flex-start" alignItems="center">
          <Info style={{marginRight:10}}/>
          <InfoText>You will be redirected in 10 seconds....</InfoText>
        </Grid>
      </InfoPlace>
      </Center>
    </CustomDialog>

  )
}

  return <Center><Loader/></Center>
 
};

export default PaymentStatus;

