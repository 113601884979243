import { TableCell, TableRow, IconButton, CircularProgress } from "@material-ui/core";
import React, { useState } from "react";
import { useLocation, Link, useParams } from 'react-router-dom';
import { useQuery } from 'react-query';
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import VisibilityOffOutlinedIcon from '@material-ui/icons/VisibilityOffOutlined';

import {
    CardWrapper,
    StatusCircle,
    StatusCirclePending,
    StatusCircleSuccess,
    TableWrapper,
    UnstyledLink,
    StatusCircleAwaiting
} from "../../../../PlanDetails/style";
import { Overlay, Title, Subtitle } from "../../../../PlanDetails/ApprovalMemo/style";
import { UploadButton } from "../../CertificateOfNoObjection/style";
import ConfirmWithoutEvaluation from './ConfirmWithoutEvaluation';
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import AccessControl from "../../../../../../components/AccessControl";
import { Roles } from "../../../../../../components/AccessControl/UserRoles";

const statusCircles = {
    Pending: <StatusCirclePending marginRight={30} />,
    Awaiting: <StatusCircleAwaiting marginRight={30} />,
    Approved: <StatusCircleSuccess marginRight={30} />,
    InActive: <StatusCircle marginRight={30} />,
};

// const tableCellStyle = { padding: "8px 16px" };



const EvaluationTable = ({ evaluationStat = {} }) => {
    const { procurementId } = useParams();
    const { pathname } = useLocation();
    // const isFinancialReviewActive = evaluationStat?.financial?.total > 0 ? evaluationStat?.technical?.totalPending === 0 : false;

    // const { data: technicalEvaluation = {}, isLoading, refetch } = useQuery({
    //     queryKey: ["technical-evaluation", { id: procurementId }],
    //     queryFn: receiptOfBiddersResponseApi.getTechnicalEvaluation,
    // });

    // if (isLoading) {
    //     return (
    //         <CardWrapper
    //             style={{
    //                 minHeight: 200,
    //                 display: "flex",
    //                 alignItems: "center",
    //                 justifyContent: "center"
    //             }}
    //             noPadding
    //             gray
    //         >
    //             <CircularProgress />
    //         </CardWrapper>
    //     );
    // }

    return (
        <CardWrapper noPadding gray>
            {/* {(!technicalEvaluation?.id || technicalEvaluation?.hasEvaluation === null) && (
                <CertificateOfNoObjectionOverlay
                    technicalEvaluation={technicalEvaluation}
                    procurementId={procurementId}
                    onUpdateEvalSuccess={() => {
                        refetch()
                    }}
                />
            )} */}


            <TableWrapper>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>
                                <b>Stage</b>

                            </TableCell>
                            {/* <TableCell style={{ width: 50 }}></TableCell> */}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {/* {technicalEvaluation?.hasEvaluation === true && (
                            <TableRow>
                                <TableCell style={tableCellStyle}>
                                    {evaluationStat?.technical?.totalPending === 0
                                        ? statusCircles.Approved : statusCircles.Pending
                                    }
                                    <UnstyledLink to={`${pathname}/technical-reviews`}>
                                        Technical review
                                    </UnstyledLink>
                                </TableCell>
                                <TableCell style={tableCellStyle}>
                                    <AccessControl allowedRoles={[Roles.TENDER_BOARD]}>
                                        <IconButton component={Link} to={`${pathname}/edit-technical-evaluation`}>
                                            <EditOutlinedIcon style={{ fontSize: 20 }} />
                                        </IconButton>
                                    </AccessControl>
                                </TableCell>
                            </TableRow>
                        )} */}
                        <TableRow>
                            <TableCell style={{ padding: "24px 16px" }}>
                                {evaluationStat?.financial?.totalPending === 0 &&
                                    evaluationStat?.technical?.totalPending === 0
                                    ? statusCircles.Approved : evaluationStat?.technical?.totalPending > 0
                                        ? statusCircles.Awaiting : statusCircles.Pending}
                                {/* {isFinancialReviewActive ? ( */}
                                <UnstyledLink to={`${pathname}/financial-reviews`}>
                                    Financial review
                                </UnstyledLink>
                                {/* ) : "Financial review"} */}
                            </TableCell>
                            {/* <TableCell style={{ padding: "32px 16px" }} /> */}
                        </TableRow>
                    </TableBody>
                </Table>
            </TableWrapper>
        </CardWrapper>
    );
};

function CertificateOfNoObjectionOverlay({
    technicalEvaluation = {},
    procurementId,
    onUpdateEvalSuccess
}) {
    const { pathname } = useLocation();
    const [openWithoutTechnicalModal, setOpenWithoutTechnicalModal] = useState(false);

    return (
        <>
            <Overlay>
                <VisibilityOffOutlinedIcon style={{ fontSize: 36, marginBottom: 8 }} />
                <AccessControl allowedRoles={[Roles.KWPPA, Roles.COMMISSIONER, Roles.MINISTERIAL_TENDERS_BOARD, Roles.PARASTATALS_TENDERS_BOARD, Roles.GOVERNOR, Roles.PERMANENT_SECRETARY, Roles.ACCOUNTING_OFFICERS]}>
                    <Title style={{ marginBottom: 8 }}>Evaluation type has not been set by appropriate personels</Title>
                </AccessControl>
                <AccessControl allowedRoles={[Roles.TENDER_BOARD]}>
                    <Title style={{ marginBottom: 8 }}>Select Evaluation Type</Title>
                    <Subtitle>To proceed to the milestone phases, click the</Subtitle>
                    <div style={{ display: "flex", alignItems: "center", marginTop: 16 }}>
                        <UploadButton
                            variant="contained"
                            style={{ marginRight: 8 }}
                            component={Link}
                            to={`${pathname}/create-technical-evaluation`}
                        >
                            With technical evaluation
                        </UploadButton>
                        <UploadButton
                            variant="outlined"
                            style={{
                                backgroundColor: "transparent",
                                borderColor: "white"
                            }}
                            onClick={() => setOpenWithoutTechnicalModal(true)}
                        >
                            Without technical evaluation
                        </UploadButton>
                    </div>
                </AccessControl>
            </Overlay>
            <ConfirmWithoutEvaluation
                isOpen={openWithoutTechnicalModal}
                procurementId={procurementId}
                technicalEvaluation={technicalEvaluation}
                onUpdateEvalSuccess={onUpdateEvalSuccess}
                onClose={() => setOpenWithoutTechnicalModal(false)}
            />
        </>
    );
}

export default EvaluationTable;
