import React from "react";
import { Box, IconButton } from "@material-ui/core";
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';
import { Link, useLocation } from 'react-router-dom';

import { ProfilePicWrapper } from "../../../../../../../components/AdminDashboardNav/style";
import { StyledTableCell, StyledTableRow } from "../../../../../../../components/SearchTable";
import { theme } from "../../../../../../../util/theme";
import { formatCurrency } from "../../../../../../../util/formatCurrency";

const StaffTable = ({ staff = [] }) => {
  const { pathname } = useLocation();

  return (
    <>
      {staff.map((row) => (
        <StyledTableRow key={row.userId}>
          <StyledTableCell>
            <Box display="flex" alignItems="center">
              <ProfilePicWrapper
                style={{
                  backgroundColor: theme.color.primary,
                  color: theme.color.white
                }}>
                {row.companyName[0] + row.companyName[1]}
              </ProfilePicWrapper>
              <Box ml={2}>
                <p>{row.companyName}</p>
                <span>{row.email}</span>
              </Box>
            </Box>
          </StyledTableCell>
          <StyledTableCell>{formatCurrency(row.quotation, true)}</StyledTableCell>
          <StyledTableCell>{row.score?.toFixed(2)}%</StyledTableCell>
          <StyledTableCell>{row.remark}</StyledTableCell>
          <StyledTableCell>
            <IconButton
              component={Link} 
              to={`${pathname}/${row.userId}`}>
              <KeyboardArrowRightRoundedIcon />
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

export default StaffTable;
