import React, { useState, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from "react-query";
// @mui 
import { TextField } from '@material-ui/core';
// components
import {
  CustomDialog,
  CustomDialogActions,
  CustomDialogTitle,
  CustomDialogContent,
  CustomDialogTitleWrapper,
  PrevBtn,
  SubmitButton
} from "./style"
// api services
import vendorsAPI from '../../redux/api/vendorsAPI';
// context
import ToastContext from '../../util/toastContext';

const RejectUpgradeModal = ({
  isOpen,
  toggleModal,
  userId,
}) => {
  const [reasonForRejection, setReasonForRejection] = useState("");
  const history = useHistory()
  const { showAlert } = useContext(ToastContext)
  const [rejectUpgrade, rejectUpgradeResp] = useMutation(vendorsAPI.upgradeRejection, {
    throwOnError: true,
    onSuccess: () => {
      setReasonForRejection("")
      showAlert({
        severity: "success",
        message: "Vendor's upgrade request has been rejected"
      })
      toggleModal(false)
      history.push("/admin/vendorsList/vendors/vendor-upgrade-request");
    },
    onError: (e) => {
      showAlert({
        severity: "error",
        message: e.message
      })
      toggleModal(false)
    }
  })
  const handleUpgradeRejection = async () => {
    try {
      !!userId ?
        await rejectUpgrade({
          userId,
          description: reasonForRejection,
        }) : showAlert({
          severity: "error",
          message: "User Id Not Provided"
        })
    } catch (e) {
      showAlert({
        severity: "error",
        message: "Rejection not successful"
      })
      toggleModal(false)
    }
  }

  const closeRejection = () => {
    toggleModal && toggleModal(false)
  }
  return (
    <>
      <CustomDialog open={isOpen} onClose={closeRejection}>
        <CustomDialogTitleWrapper>
          <CustomDialogTitle disableTypography>
            Kindly state  your reasons for rejecting class upgrade
          </CustomDialogTitle>
        </CustomDialogTitleWrapper>
        <CustomDialogContent style={{
          "& .MuiTextField-root": {
            width: "100%"
          }
        }}>
          <TextField
            multiline
            placeholder='Type reason here'
            label="Reason"
            rows={15}
            variant="outlined"
            InputProps={{
              disableUnderline: true,
            }}
            value={reasonForRejection}
            onChange={(e) => setReasonForRejection(e.target.value)}
          />
        </CustomDialogContent>
        <CustomDialogActions>
          <PrevBtn onClick={closeRejection}>Cancel</PrevBtn>
          <SubmitButton
            disabled={!reasonForRejection}
            loading={rejectUpgradeResp.isLoading}
            onClick={handleUpgradeRejection}
          >
            Blacklist
          </SubmitButton>
        </CustomDialogActions>

      </CustomDialog>
    </>
  )
}

export default RejectUpgradeModal
