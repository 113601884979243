import styled from "styled-components";
import { Button, Typography, Paper, Box } from "@material-ui/core";
import { Delete } from '@material-ui/icons';
import { theme } from "../../../../../../util/theme";


export const FormWrapper = styled.div`
    background-color: white;
    border: 1px solid #C9D4DB;
    box-shadow: 0px 2px 4px #C9D4DB;
    border-radius: 3px;
     margin-right: 25px;
    width: 70%;
`

export const FormTitle = styled.p`
    color: #1E2B37;
    font-weight: 600;
    line-height: 24px;
`

export const FormSubTitle = styled.p`
    color: #637381;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 20px;
`

export const DocumentsWrapper = styled.div`
    background: #FCFCFD;
    border: 1px solid #C9D4DB;
    padding: 1rem;
    border-radius: 8px;
`

export const SectionLabel = styled.p`
    color: #1E2B37;
    margin-bottom: 1rem;
    color: #1E2B37;
    font-weight: 600;
`

export const CriteriaWrapper = styled.div`
    /* background-color: white; */
    margin-top: 1rem;
`

export const Criteria = styled.div`
    width: 60%;
    margin-right: 1rem;
`

export const MaxScore = styled.div`
   width: 30%;
   margin-right: 1rem;
`

export const FormFieldWrapper = styled.div`
    display: flex;
    margin-bottom: 1rem;
`

export const BtnWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 3rem;
`

export const DeleteIcon = styled(Delete)`
    && {
       align-self: center;
       color: #DA1414;
       width: 5%;
        cursor: pointer;
  }
`;

export const AddNewButton = styled(Button)`
    && {
    color: ${theme.color.primary};
    margin-top: 2rem;
  }
`;

export const BackButton = styled(Button)`
    && {
        background-color: #EEFBF5;
    border-radius: 8px;
    color: ${theme.color.primary};
    &:hover {
      background-color: #EEFBF5;
      opacity: 0.7;
    }
  }
`;

export const ProceedButton = styled(Button)`
    && {
        background-color:  ${theme.color.primary};
    border-radius: 8px;
    color: white;
    &:hover {
      background-color:  ${theme.color.primary};
      opacity: 0.8;
    }
    &:disabled {
      background-color: ${theme.color.primary};
      cursor: not-allowed !important;
      opacity: 0.5;
      color: white;
    }
  }
`;

export const FormFieldTitle = styled.div`
    color: #1C2125;
    font-size: 16px;
    margin-bottom: 8px;
`

export const ErrorContainer = styled(Typography)`
  margin: 0;
  padding-top: 5px;
  font-size: 10px !important;
  color: red;
`;

export const FlexContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin-top: 2rem;
`;

export const StatsContainer = styled.div`
  border: 1px solid #CDCED9;
  box-shadow: 0px 2px 4px #C9D4DB;
  border-radius: 3px;
  width: 25%;
  height: max-content;
`;

export const Text = styled(Box)`
font-size: 13px;
color: #6B6C7E;
`
export const Score = styled(Box)`
font-size: 18px;
font-weight: 600;
`

export const CircularWrapper = styled(Box)`
  
    .MuiCircularProgress-colorPrimary {
      text-align: center;
  }
  .MuiCircularProgress-root { 
    left: 60%; 
    position: absolute; 
    top: 20%; 
}
`;