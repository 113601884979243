import React, { useEffect } from "react";
import { Box } from "@material-ui/core";
import { Caption, SideNavSection } from "./style";
import Navbar from "../../../components/AdminDashboardNav";
import Sidebar from "../../../components/Sidebar";
import useAlert from "../../../hooks/useAlert";
import ToastContext from "../../../util/toastContext";
import { useRoles } from "../../../hooks/useUserDetails";
import Logo from "../../../assets/kwara_logo.svg";

import CircularProgress from "@material-ui/core/CircularProgress";
import { LandingNavbarStatic } from "../../../components/HomepageHeader/style";

const withAdminDashboard = (WrappedComponent) => (props) => {
  const { showAlert, Toast, hideAlert } = useAlert();
  const { isSuccess, isError } = useRoles();

  const leftMargin = "250px";

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: "Network Error. Kindly Refresh",
      });
    }
  }, [isError, showAlert]);

  return (
    <ToastContext.Provider value={{ showAlert, Toast, hideAlert }}>
      <SideNavSection>
        <Caption>
          <img src={Logo} alt="Kwara state logo" />
        </Caption>
        <Sidebar />
      </SideNavSection>
      <div style={{ marginLeft: leftMargin, background: "#FAFBFC" }}>
        <LandingNavbarStatic >
          <Navbar />
        </LandingNavbarStatic>
        <Toast float />
        <Box paddingLeft="33.5px" paddingRight="33.5px" minHeight="94vh">
          {isSuccess ? (
            <WrappedComponent {...props} />
          ) : (
            <Box padding={10} width={"100%"} display={"flex"} justifyContent={"center"}>
              <CircularProgress size={20} />
            </Box>
          )}
        </Box>
      </div>
    </ToastContext.Provider>
  );
};

export default withAdminDashboard;
