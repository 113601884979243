import React from 'react';
import { StyledRadioLabel } from './style';
import { formatNumber, separateNumByComma } from '../../util/truncateValue';

const CustomUpgradeLabel = ({ registrationPlan }) => {
  const {
    name,
    fee,
    contractMaxValue,
    contractMinValue,
    renewalFee
  } = registrationPlan
  return (
    <StyledRadioLabel>
      <div className="class">
        <p className="value">{name}</p>
      </div>

      <div className="contract-fee">
        <p className="fee">N{separateNumByComma(fee)}</p>
        <p className="title">Registration Fee</p>
      </div>
      <div className="separator" />
      <div className="renewal">Renew at N{separateNumByComma(renewalFee)} annually</div>
      <div className="long-separator" />
      <div className="contract-value">
        {formatNumber(contractMinValue) === "1B" ? (
          <p className="value">1B and Above</p>
        ) : (
          <p className="value">
            {formatNumber(contractMinValue)} - {formatNumber(contractMaxValue)}
          </p>
        )}
        <p className="title">Contract sum</p>
      </div>

    </StyledRadioLabel>
  )
}

export default CustomUpgradeLabel
