import baseAxiosMethod from "./baseAxiosMethod";
import handleApiError, { handleAxiosError } from "./handleApiError";

const getProcurementPlans = async (key, id, params) => {
  try {
    const response = await baseAxiosMethod(`/generalPlans/${id}/procurementPlans`, { params });
    const {
      data,
      meta: { pagination },
    } = response.data;

    return { data, pagination };
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

const getPlansSummary = async (key, planId) => {
  try {
    const response = await baseAxiosMethod(`generalPlans/${planId}/procurementPlanSummary`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

const tenderSummaryQuery = async (key, planId) => {
  try {
    const response = await baseAxiosMethod(`generalPlans/${planId}/tenderSummary`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

const getGeneralPlansDetails = async (key, planId) => {
  try {
    const response = await baseAxiosMethod(`generalPlans/${planId}`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

const sendForApproval = async (id) => {
  try {
    const response = await baseAxiosMethod.post(`generalPlans/${id}/sendForApproval`);
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

const approvePlan = async ({ id }) => {
  try {
    await baseAxiosMethod.post(`generalPlans/${id}/approve`);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

const needsAmendment = async ({ id }) => {
  try {
    await baseAxiosMethod.post(`generalPlans/${id}/needAmendment`);
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

const getAnnualProcurementPlansSummary = async () => {
  try {
    const response = await baseAxiosMethod("generalPlans/summary");
    return response.data.data;
  } catch (e) {
    throw new Error(handleAxiosError(e));
  }
};

const deleteGeneralPlansTender = async (tenderId) => {
  try {
    const response = await baseAxiosMethod.delete(`procurements/${tenderId}/delete`);
    return response.data.data;
  } catch (e) {
    throw handleApiError(e.response?.data, e.response?.statusCode);
  }
};

const generalPlansAPI = {
  getProcurementPlans,
  getPlansSummary,
  getGeneralPlansDetails,
  sendForApproval,
  needsAmendment,
  approvePlan,
  getAnnualProcurementPlansSummary,
  deleteGeneralPlansTender,
  tenderSummaryQuery,
};

export default generalPlansAPI;
