import { createMuiTheme } from '@material-ui/core/styles';

const Theme = createMuiTheme({
  overrides: {
    MuiFormHelperText: {
        root: {
          margin: 0,
        },
        contained: {
          margin: `0 !important`,
        },
    },
},
});

export default Theme;