/* eslint-disable no-shadow */
import React, { useState } from "react";
import InputLabel from "@material-ui/core/InputLabel";
import NativeSelect from "@material-ui/core/NativeSelect";
import { DropwdownWrapper } from "./styled";

const Dropdown = ({ values, name, label, style, onChange, initialState, ...restProps }) => {
  // eslint-disable-next-line
  const [selected, setValue] = useState(initialState || values[0]?.value);

  const _onChange = (event) => {
    setValue(event.target.value);

    if (onChange) {
      onChange(event.target.value);
    }
  };

  return (
    <DropwdownWrapper disabled={restProps?.disabled} style={style}>
      <div>
        <InputLabel shrink>{label}</InputLabel>
        <NativeSelect
          defaultValue={selected}
          onChange={_onChange}
          inputProps={{
            id: name,
            name,
            ...restProps,
            style: {
              textTransform: "none",
            },
          }}
        >
          {values?.map(({ label, value }, i) => (
            <option value={value} key={`${value}-${i}`}>
              {label}
            </option>
          ))}
        </NativeSelect>
      </div>
    </DropwdownWrapper>
  );
};

export default Dropdown;
