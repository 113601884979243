const chooseColorScheme = (status) => {
  switch (status) {
    case "pending":
      return "#F49342";
    case "inprogress":
      return "#6CCF81";
    default:
      return "#2AA96D";
  }
};

const transformStatusColor = (status) => {
  switch (status) {
    case "done":
      return "done";
    default:
      return "notDone";
  }
};

const transformMileStonesToCalendarTime = ({ data }) => {
  const groups = [];
  const items = [];

  if (data?.length > 0) {
    data.forEach((milestone) => {
      groups.push({
        id: milestone.id,
        title: milestone.title,
        height: 100,
        status: transformStatusColor(milestone?.status?.toLowerCase()),
      });
      items.push({
        id: milestone.id,
        group: milestone.id,
        title: milestone.status,
        start_time: new Date(milestone.startDate).getTime(),
        end_time: new Date(milestone.endDate).getTime(),
        itemProps: {
          style: {
            backgroundColor: chooseColorScheme(milestone?.status?.toLowerCase()),
          },
        },
      });
    });
  }

  return { groups, items };
};

export default transformMileStonesToCalendarTime;
