import React, { useContext, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";

import { useParams } from "react-router-dom";
import { PageContainer, PageTitle } from "../../Common/style";

import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { InfoContainer } from "../BidDetails/BidInfo/style";
import { ItemsGrid } from "../ProjectInfo/style";
import withVendorDashboard from "../HOC/withVendorDashboard";
import { useQuery } from "react-query";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import Loader from "../../../components/Common/Loader";
import ToastContext from "../../../util/toastContext";
import { HL, InfoCardWrapper, MainText, MainTitle } from "../Procurement/style";
import moment from "moment";
import ProjectProgressBar from "../../Admin/Procurement/Activitiies/ContractSupervision/ProjectProgressBar";
import ProjectReportSection from "../ProjectInfo/ProjectReportSection";

const ProjectReport = () => {
  const { projectId: id } = useParams();
  const { showAlert } = useContext(ToastContext);
  const { status: projectStatus, error: projectError, isLoading, data: project } = useQuery(
    ["project", id],
    vendorsAPI.getProjectDetails
  );

  useEffect(() => {
    if (projectStatus === "error") {
      showAlert({
        severity: "error",
        message: projectError.message,
      });
    }
  }, [projectStatus, projectError, showAlert]);

  const renderBreadcrumbLinks = () => [
    { url: "/vendor/projects", title: "Projects", forwardSlash: true },
    { url: `/vendor/projects/${id}`, title: `${project?.code}`, forwardSlash: false },
    { url: `#`, title: 'Report' },
  ];

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" marginBottom="35px">
        {!isLoading && (
          <Box>
            <PageTitle>{project?.code}</PageTitle>
            <Breadcrumb values={renderBreadcrumbLinks()} />
          </Box>
        )}
      </Box>
      <InfoContainer>
        <Grid container layout={"row"}>
          <Grid item xs={8}>
            <div>
              <InfoCardWrapper>
                <MainTitle>Procurement Information</MainTitle>
                {isLoading && <Loader />}
                {!isLoading && (
                  <>
                    <MainText mt="5px" fontSize="14px">
                      {project?.ministry?.name}
                    </MainText>
                    <MainTitle mt="43px"> {project?.name}</MainTitle>
                    <MainText mt="20px" fontSize="13px">
                      Project Description
                    </MainText>
                    <MainText mt="10px" fontSize="14px">
                      {project?.description}
                    </MainText>
                    <HL mt="20px" />

                    <ItemsGrid>
                      <div>
                        <MainText mt="20px" fontSize="13px">
                          Budget
                        </MainText>
                        <MainTitle>N{project?.budget?.toLocaleString()}</MainTitle>
                      </div>
                      <div>
                        <MainText mt="20px" fontSize="13px">
                          Procurement ID
                        </MainText>
                        <MainTitle>{project?.code}</MainTitle>
                      </div>
                    </ItemsGrid>

                    <HL mt="20px"/>
                    <ItemsGrid>
                      <div>
                        <MainText mt="20px" fontSize="13px">
                          Procurement Category
                        </MainText>
                        <MainText fw="600" fontSize="14px">
                          {project?.procurementCategory}
                        </MainText>
                      </div>
                      <div>
                        <MainText mt="20px" fontSize="13px">
                          Procurement Method
                        </MainText>
                        <MainText fw="600" fontSize="14px">
                          {project?.procurementMethod}
                        </MainText>
                      </div>
                    </ItemsGrid>

                    <MainText mt="20px" fontSize="15px" color="#2AA96D" fw="bold">
                      View more
                    </MainText>
                  </>
                )}
                <div className="topRightCard oneCard">
                  <div>
                    <MainText mt="20px" mr="10px" fontSize="13px">
                      Date Published
                    </MainText>
                    <MainText fw="600" fontSize="14px">
                      {moment(project?.startDate).format("DD/MM/YYYY")}
                    </MainText>
                  </div>
                </div>
              </InfoCardWrapper>
            </div>
          </Grid>
          <Grid item xs={4}>
            <ProjectProgressBar
              marginSet="20px"
              textTitle="Total Tasks"
              totalTask={project?.totalMilestone}
              CompletedTask={project?.totalCompletedMilestone}
              pendingTask={project?.totalPendingMilestone}
              percentageCompleted={project?.percentageCompleted}
              fullWidth
            />
          </Grid>
        </Grid>
      </InfoContainer>
      <ProjectReportSection/>
    </PageContainer>
  );
};

export default withVendorDashboard(ProjectReport);
