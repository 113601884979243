import { Box, Button, SvgIcon } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { useQuery } from "react-query";
import { useParams, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";

import { TenderTitle } from "../../Homepage/ContractDetail/style";
import { getAdminContractDetails } from "../../../redux/reducer/adminReducer";

import { ReactComponent as CodeSvg } from "../../../assets/code.svg";
import { ReactComponent as PrintPdf } from "../../../assets/print.svg";
import contractsAPI from "../../../redux/api/contractAPI";
import useAlert from "../../../hooks/useAlert";
import withAdminDashboard from "../HOC/withAdminDashboard";
import ContractDetailsCardTable from "../../Homepage/ContractDetail/ContractDetailsCardTable";
import { BreadcrumbActive, BreadcrumbLabel } from "../../Homepage/TenderList/style";

const AdminOCDSDetails = () => {
  const classes = useStyles();
  const { id } = useParams();
  const { Toast, showAlert } = useAlert();
  const [downloadJSON, setDownloadJSON] = useState(false);
  const dispatch = useDispatch();
  const linkStyle = { textDecoration: "none", color: "inherit" };

  useEffect(() => {
    dispatch(getAdminContractDetails(id));
  }, [dispatch, id]);

  const handleDownloadJson = (data) => {
    var dataStr = "data:text/json;charset=utf-8," + encodeURIComponent(JSON.stringify(data));
    var downloadAnchorNode = document.createElement("a");
    downloadAnchorNode.setAttribute("href", dataStr);
    downloadAnchorNode.setAttribute("download", `awarded-contract-detail-${Date.now()}.json`);
    document.body.appendChild(downloadAnchorNode); // required for firefox
    downloadAnchorNode.click();
    downloadAnchorNode.remove();
  };

  const contractsOCDSQuery = useQuery(
    ['contractOCDS', id],
    contractsAPI.getContractOCDS, {
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message || "Failed to fetch data from the server",
      });
    }
  });

  const { data: contractOCDS } = contractsOCDSQuery?.data || {};

  const { isLoading } = useQuery(
    ['downloadJSON', id],
    contractsAPI.downloadJSON, {
    enabled: downloadJSON,
    onSuccess: (data) => {
      handleDownloadJson(data);
      setDownloadJSON(false);
    },
    onError: (error) => {
      setDownloadJSON(false);
      showAlert({
        severity: "error",
        message: error.message || "Failed to fetch data from the server",
      });
    }
  });

  return (
    <div>
       <Toast float />
        <BreadcrumbLabel>
          <Link style={linkStyle} to="/admin/dashboard/overview">
            Overview
          </Link>
             /
            <Link style={linkStyle} to="/admin/ocdsList/ocds">
              Awarded Contracts
            </Link> 
            / 
          <BreadcrumbActive>{contractOCDS?.title}
          </BreadcrumbActive>
        </BreadcrumbLabel>


        <TenderTitle>{contractOCDS?.title}</TenderTitle>
        <Box my={3}>
          <Box display="flex">
            <Box mr={2}>
              <Button
                classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                startIcon={<SvgIcon component={CodeSvg} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />}
                onClick={() => setDownloadJSON(true)}
              >
                {isLoading ? "Downloading..." : "Download JSON"}
              </Button>
            </Box>
            <Box>
              <Button
                classes={{ startIcon: classes.btnStartIcon, root: classes.downLoadBtn }}
                startIcon={<SvgIcon component={PrintPdf} style={{ color: "#fff", height: 30, width: 30, margin: 0 }} />}
                onClick={() => window.print()}
              >
                Print
              </Button>
            </Box>
          </Box>
        </Box>
        <ContractDetailsCardTable vendorId={id} />
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  btnStartIcon: {
    margin: 0,
  },
  downLoadBtn: {
    border: "1px solid #E5E5EA",
    boxShadow: "0px 1px 3px rgba(0, 0, 0, 0.1)",
    borderRadius: "4px",
    textTransform: "none",
  },
}));

export default withAdminDashboard(AdminOCDSDetails);
