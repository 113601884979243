import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { SaveButton } from "../../../../../../components/Forms/Common/style";

export const ApprovalModalContainer = styled.div`
  min-width: 400px;
  min-height: 300px;
  background: #FFFFFF;
  border: 1px solid #EBEBEB;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 30px;
`;

export const ModalApprovalText = styled(Typography)`
  font-style: normal;
  font-weight: bold !important;
  font-size: 24px !important;
  line-height: 33px;
  margin-bottom: 30px !important;
`;

export const ApprovalModalDocInfo = styled(Typography)`
  background: rgba(3, 173, 235, 0.12);
  border-radius: 3px;
  font-style: normal;
  font-weight: normal;
  font-size: 14px !important;
  line-height: 19px !important;
  color: #2AA96D;
  padding: 8px 22px !important;
  margin-bottom: 30px !important;
`;

export const SendRecomModalBtn = styled(SaveButton)`
  background-color: #3BD278 !important;
  margin-left: 0 !important;
  flex: 2;
  margin-right: 10px !important;
`;

export const CancelModalBtn = styled(SaveButton)`
  background-color: #ffffff !important;
  color: #979797 !important;
  margin-left: 0 !important;
  flex: 1;
`;

export const ApprovalDocumentLink = styled.a`
  color: #2AA96D;
  font-size: 14px;
`
