

import styled from "styled-components";
import { Paper, Button } from "@material-ui/core";
import { SecondaryWrapper } from "../../../../components/Forms/Common/style";
import { theme } from "../../../../util/theme";

export const Wrapper = styled.div`
  margin-top:  39.9px;
  button {
  text-transform: capitalize !important;
  }

 > div:first-child > div > div > div:first-child {
    top: -25px !important;
    right: -500px !important;
  }
`;

export const FormSectionWrapper = styled(Paper)`
  border: none;
  box-shadow: none !important;
  display: flex;
`;

export const ProgressSectionWrapper = styled(SecondaryWrapper)`
  height: max-content;
  position: sticky;
  top: 80px;
`;

export const CloseButton = styled(Button)`
  text-transform: capitalize !important;
  color: ${theme.color.primary} !important;
  border: 1px solid ${theme.color.primary} !important;
`
export const NavMargin = styled.div`
margin-left: auto;
margin-right: 10px;
`;