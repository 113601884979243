import React from 'react';
import {
    TextField,
    Box,
    Typography,
    Divider,
    Grid,
    FormHelperText
} from "@material-ui/core";
import { Controller } from "react-hook-form";

const bgColor = "#FCFCFD";

function EvaluationScoreInputField({
    useFormUtils,
    title,
    evaluations,
    isEvaluated
}) {
    const { control, errors } = useFormUtils;

    return (
        <>
            <Box p={3}>
                <Typography variant="subtitle1">{title}</Typography>
                <Typography variant="body2" color="textSecondary">Please score the vendor</Typography>
            </Box>
            <Divider />
            <Box p={3} bgcolor={bgColor}>
                {evaluations.map((field, index) => {
                    const fieldError = errors[`${field.requirementEvaluationId}_score`]?.message;
                    return (
                        <Box key={field.requirementEvaluationId} mt={index !== 0 ? 3 : 0}>
                            <Typography variant="body2" color="text.secondary" gutterBottom>{field.name}</Typography>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={6} md={3}>
                                    <Controller
                                        name={`${field.requirementEvaluationId}_score`}
                                        control={control}
                                        rules={{
                                            required: "Field is required",
                                            min: {
                                                value: 0,
                                                message: `Minimum value is 0`
                                            },
                                            max: {
                                                value: field.score,
                                                message: `Maximum value is ${field.score}`
                                            }
                                        }}
                                        render={(props) =>
                                            <TextField
                                                {...props}
                                                id="outlined-basic"
                                                label='Score'
                                                variant="outlined"
                                                type="number"
                                                disabled={isEvaluated}
                                                fullWidth
                                                inputProps={{ min: 0, max: field.score }}
                                                InputProps={{
                                                    endAdornment: (<>/{field.score}</>)
                                                }}
                                            />
                                        }
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={9}>
                                    <Controller
                                        name={`${field.requirementEvaluationId}_remark`}
                                        control={control}
                                        render={(props) =>
                                            <TextField
                                                {...props}
                                                disabled={isEvaluated}
                                                id="outlined-basic"
                                                label='Remark'
                                                variant="outlined"
                                                fullWidth
                                            />
                                        }
                                    />
                                </Grid>
                            </Grid>
                            {!!fieldError && (
                                <FormHelperText error>{fieldError}</FormHelperText>
                            )}
                        </Box>
                    )
                })}
            </Box>

            <Divider />
        </>
    )
}

export default EvaluationScoreInputField;