import styled from "styled-components";
import { SecondaryWrapper, Description } from "../Common/style";
import { FormTitle, FormSubtitle, theme } from "../../../util/theme";

export const EmptyState = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  padding: 50px;

  p {
    color: #172B4D;
    font-size: 19px;
    letter-spacing: 0;
    line-height: 23px;
    text-align: center;
    margin-top: 20px;
  }

  span {
    width: 290px;
    margin-top: 5px;

    span {
      color: #2AA96D;
      font-weight: 600;
      cursor: pointer;
    }
  }
`;

export const DirectorDetailsContainer = styled(SecondaryWrapper)`
  height: 139px;
  margin-bottom: 10px;
  padding: 15px;
`;

export const DirectorDetailsContentWrapper = styled.div`
  display: flex;
`;

export const DirectorDescription = styled(Description)`
  margin-bottom: 20px;
`;

export const DirectorDetailsContent = styled.div`
  margin-bottom: 20px;
`;

export const AvatarContainer = styled.div`
  height: 50px;
  width: 50px;
  background-color: #E5E9EC;
  border-radius: 50%;
  margin-right: 15px;
`;

export const DirectorTitle = styled(FormTitle)`
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 22px;
  margin-bottom: 0 !important;
`;

export const DirectorAddress = styled(FormSubtitle)`
  font-size: 14px !important;
  width: 450px;
`;

export const AddControlContainer = styled.span`
  color: ${theme.color.primary};
  cursor: pointer;
  text-align: right;
  display: block;
  margin-top: 30px;
  font-size: 14px !important;
`;

export const ControlContainer = styled(FormSubtitle)`
  display: flex;

  span:nth-child(2){
    color: ${theme.color.danger};
    margin-left: 20px;
    cursor: pointer;
  }

  span:nth-child(1){
    color: ${theme.color.primary};
    cursor: pointer;
  }
`;
