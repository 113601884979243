import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Link } from "react-router-dom";
import {
  FooterSection,
  FooterTitleText,
  FooterDescriptionText,
  FooterGroupText,
  FooterCopyRightContainer,
  TwitterIcon,
  GroupedIcon,
  FacebookIcon,
} from "./style";

const HomepageFooter = () => {
  const linkStyle = { color: "#fff", textDecoration: "none" };
  return (
    <FooterSection>
      <Container>
        <Grid container justify="space-between">
          <Grid item>
            <FooterTitleText>About Kwara</FooterTitleText>
            <FooterDescriptionText>
              Kwara State is the 9th largest State of the Federal Republic of Nigeria, with approximately 3 million people on
              37,000KM2 of land, centrally located and as such a major trade route (rail, road and air) between the commercial
              centres of Northern and Southern Nigeria, bordered by the River Niger and Republic of Benin.
            </FooterDescriptionText>
          </Grid>
          <Grid item>
            <FooterTitleText>Important Links</FooterTitleText>
            <FooterDescriptionText style={{ marginBottom: 10 }}>
              <Link to="/login" style={linkStyle}>
                Vendor Registration
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText style={{ marginBottom: 10 }}>
              <Link to="/resources" style={linkStyle}>
                Resources
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText style={{ marginBottom: 10 }}>
              <Link to="/news-and-bulletins" style={linkStyle}>
                News & Bulletin
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>
              <Link to="/ocds-portal/awarded-contracts" style={linkStyle}>
                Awarded Contracts
              </Link>
            </FooterDescriptionText>
            <FooterDescriptionText>Frequently asked questions</FooterDescriptionText>
          </Grid>
          <Grid item>
            <FooterTitleText>Contact Us</FooterTitleText>
            <FooterGroupText> Kwara State Government</FooterGroupText>
            <FooterGroupText>Kwara State Government House,</FooterGroupText>
            <FooterGroupText style={{ marginBottom: 10 }}>Ahmadu Bello Way, G.R.A Ilorin</FooterGroupText>
            <div style={{ marginBottom: 10 }}>
              <FooterGroupText>Telephone</FooterGroupText>
              <FooterGroupText>(+234) 08001000100</FooterGroupText>
            </div>

            <FooterGroupText>Email</FooterGroupText>
            <FooterGroupText>contact@kwarastate.gov.ng</FooterGroupText>
          </Grid>
        </Grid>
        <FooterCopyRightContainer>
          <GroupedIcon>
            <TwitterIcon />
            <FacebookIcon />
          </GroupedIcon>
          <FooterGroupText>Kwara state government E-procurement</FooterGroupText>
          <FooterGroupText>All rights reserved | (C) Copyright {new Date().getFullYear()}</FooterGroupText>
        </FooterCopyRightContainer>
      </Container>
    </FooterSection>
  );
};

export default HomepageFooter;
