import styled from "styled-components";
import { Card } from "@material-ui/core";

export const SummaryCard = styled(Card)`
  border: 1px solid #CBD4DA;
  border-radius: 8px;

  .title {
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #637381;
  }

  .count {
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    color: #212B36;
  }

  .MuiPaper-elevation1 {
    box-shadow: none;
  }
`;

export const ContentWrapper = styled.div`
  padding: 0 0 60px;
  position: relative;

  .MuiTableCell-body:nth-child(2) {
    display: flex;
    align-items: center;

    div:first-child {
      margin: 0 10px 0 0;
      width: 30px;
      height: 30px;
      color: white;
      background-color: #50b83c;
    }
  }
`;
