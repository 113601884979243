import React from "react";
import PropTypes from "prop-types";
import { useDropzone } from "react-dropzone";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import ClearIcon from "@material-ui/icons/Clear";
import DownloadIcon from '@material-ui/icons/SystemUpdateAlt';
import { Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import {
    DropzoneArea,
    UploadDocumentContainer,
    UploadDocumentContent,
    UploadingContainer,
    UploadingDocumentContainer,
    UploadingDocumentLoadInfo,
    UploadingDocumentName,
    Wrapper
} from "./styles";
import { ReactComponent as FileIcon } from "../../../../../assets/file.svg";
import { FileStatus } from "..";
import { MaxFileSize } from "../../../../../util/constants";

const UploadedDoc = ({
    file: doc, onRemove, onDownload, readOnly
}) => {
    return (
        <UploadDocumentContainer>
            <UploadDocumentContent>
                <UploadingContainer>
                    <FileIcon style={{ marginRight: "10px" }} />
                    <UploadingDocumentContainer>
                        <UploadingDocumentName>
                            <Typography>{doc.file.name}</Typography>
                        </UploadingDocumentName>
                        <UploadingDocumentLoadInfo>
                            <Typography>{(doc.file.size / 1000)?.toFixed(2)}kb  {doc.status === FileStatus.UPLOADING && <Typography style={{ color: "#000" }}>Uploading...</Typography>}</Typography>
                        </UploadingDocumentLoadInfo>
                    </UploadingDocumentContainer>
                </UploadingContainer>
                <div style={{ display: "flex" }}>
                    {doc.status !== FileStatus.UPLOADING && <ClearIcon onClick={() => onRemove(doc)} />}
                    {doc.url !== null && <DownloadIcon onClick={() => onDownload(doc)} />}
                </div>
            </UploadDocumentContent>
            {doc.status !== FileStatus.IDLE && <LinearProgress className={doc.status === FileStatus.ERROR ? "error-check" : ""} variant="determinate" value={100} />}
        </UploadDocumentContainer>
    );
}

UploadedDoc.propTypes = {
    file: PropTypes.object,
    onRemove: PropTypes.func.isRequired,
    onDownload: PropTypes.func.isRequired,
    readOnly: PropTypes.bool
}

UploadedDoc.defaultProps = {
    readOnly: false
}


const FileUploadField = ({
    accept,
    files,
    onAddFiles,
    onRemoveFile,
    multiple = false,
    readOnly = false
}) => {
    const fileMaxSize = MaxFileSize;
    function fileSizeValidator(file) {
        if (file.size > fileMaxSize) {
            return {
                code: "tooLarge",
                message: `File size is larger than ${fileMaxSize / 1000}kb`
            };
        }

        return null
    };

    const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
        onDrop: files => onAddFiles(files),
        accept,
        validator: fileSizeValidator
    });

    const handleRemoveFile = (file) => {
        onRemoveFile(file);
    };

    const handleDownloadFile = (file) => {
        const link = document.createElement('a');
        link.href = file.url;
        link.setAttribute('download', file.name);
        document.body.appendChild(link);
        link.click();
        link.remove();
    }

    return (
        !multiple && files.length
            ? <UploadedDoc file={files[0]} onRemove={handleRemoveFile} readOnly={readOnly} onDownload={handleDownloadFile} />
            : (
                <>
                    <DropzoneArea {...getRootProps()}>
                        <input {...getInputProps()} />
                        <div>
                            {isDragActive ? (
                                <p>Release to drop the files here</p>
                            ) : (
                                <>
                                    <p>Drag and drop your files here, or click to select files</p>
                                    <span>File size should not exceed 5mb</span>
                                    <Collapse in={!!fileRejections?.length}>
                                        <Alert severity="error" style={{ marginTop: 20 }}>
                                            {fileRejections.map(({ file, errors }) => (
                                                <div key={file.path} style={{ textAlign: "left" }}>
                                                    {file.path} - {(file.size / 1000).toLocaleString()}kb
                                                    {errors.map(e => (
                                                        <li key={e.code}>{e.message}</li>
                                                    ))}

                                                </div>
                                            ))}
                                        </Alert>
                                    </Collapse>
                                </>
                            )}
                        </div>
                    </DropzoneArea>
                    {Boolean(files.length) && files.map((file, index) => (
                        <Wrapper key={`${file.id}_${index}`}>
                            <UploadedDoc file={file} readOnly={readOnly} onRemove={handleRemoveFile} onDownload={handleDownloadFile} />
                        </Wrapper>
                    ))}
                </>
            )
    );
};

export default React.memo(FileUploadField);