import React from "react";
import { Container } from "@material-ui/core";
import { Foot, RightContent, LeftContent } from "./style";

function Footer() {
  return (
    <Container>
      <Foot>
        <RightContent>&copy; Copyright {new Date().getFullYear()} Kwara state PPA. All rights reserved</RightContent>
        <LeftContent>Powered by: Kwara Public Procurement Agency</LeftContent>
      </Foot>
    </Container>
  );
}

export default Footer;
