import { Grid } from "@material-ui/core";
import React, { useState } from "react";
import AdminSearchFilter from "../../../../../components/AdminSearchFilter";
import { SearchButton } from "../../../../../components/Forms/Common/style";
import InputField from "../../../../../components/InputField";
import RolePageDrawer from "../../../../../components/RolePageDrawer";
import SimpleCard from "../../../../../components/SimpleCard";
import Breadcrumb from "../../../../Common/PageElements/Breadcrumb";
import { PageTitle, TitleContainer } from "../../../../Common/style";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import { ContentWrapper } from "../../../Procurement/style";
import { listData } from "../../Users/mock";
import { BottomContentWrapper, TopContent } from "./style";

const CheckerDetails = () => {
  const [openModal, setOpenModal] = useState(false);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  const breadCrumbValues = [
    { title: "Home", url: "#", forwardSlash: true },
    { title: "Settings", url: "#", forwardSlash: true },
    { title: "Checkers", url: "#" },
  ];

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Checker</PageTitle>
          <Breadcrumb values={breadCrumbValues} />
        </div>
      </TitleContainer>

      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} md={8}>
          <SimpleCard
            title={"Permanent Secretary"}
            description={`Prunedge’s Style Guide can be easily included in applications 
            or one-off projects. We recommend pulling the latest version of Style Guide's 
            CSS from our CDN. You may also clone this repository and pull in the component SCSS files 
            if you wish to only include a portion of Style Guide in your project.
            `}
            descriptionTitle={"Description"}
            showIcon={true}
          />

          <AdminSearchFilter normalSearch title="Set User" style={{ fontSize: "14px" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
              }}
            >
              <InputField type="text" label="User email" defaultValue="alan.smith@kwarastate.gov.ng" />
              <InputField type="text" label="Threshold" defaultValue="NGN 23,000,000.00" />
              <SearchButton style={{ width: "128px" }}>Send Invite</SearchButton>
            </div>
          </AdminSearchFilter>
          <br />
          <BottomContentWrapper>
            <TopContent>
              <p>Profile</p>
            </TopContent>
          </BottomContentWrapper>
        </Grid>
        <Grid item xs={12} lg={4} md={4}>
          <SimpleCard title={"Permissions"} listData={listData} />
        </Grid>
      </Grid>
      <RolePageDrawer toggleDrawer={toggleDrawer} openModal={openModal} />
    </ContentWrapper>
  );
};

export default withAdminDashboard(CheckerDetails);
