import React from "react";
import { Grid } from "@material-ui/core";
import { useSelector } from "react-redux";
import moment from "moment";

import { ContractDetailTabText } from "./style";
import { formatCurrency } from "../../../../util/formatCurrency";

const ContractDetailTab = () => {
  const {
    adminContractDetails: { contractDetails = {} },
  } = useSelector((state) => state.admin);

  const details = [
    { title: "Ministry Name", value: contractDetails.ministryName || "N/A" },
    { title: "Contract Name", value: contractDetails.contractName || "N/A" },
    { title: "Contract Ref no", value: contractDetails.referenceId },
    { title: "Tender package number", value: contractDetails.packageNumber || "N/A" },
    { title: "Procurement method", value: contractDetails.procurementMethod || "N/A" },
    { title: "Date of notification of award", value: moment(contractDetails.notificationDate).format("DD, MMMM, yyyy") || "N/A" },
    { title: "Date of signature", value: moment(contractDetails.signatureDate).format("DD, MMMM, yyyy") || "N/A" },
    { title: "Contract of amount", value: <>&#8358;{formatCurrency(contractDetails.contractAmount)}</> },
    { title: "Contract duration", value: contractDetails.contractDuration + " " + contractDetails.durationType || "N/A" },
    { title: "Start date", value: moment(contractDetails.startdate).format("DD, MMMM, yyyy") || "N/A" },
    { title: "End date", value: moment(contractDetails.endDate).format("DD, MMMM, yyyy") || "N/A" },
  ];

  return details.map((detail, i) => (
    <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
      <Grid item xs={5}>
        <ContractDetailTabText>{detail.title}</ContractDetailTabText>
      </Grid>
      <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
        <ContractDetailTabText>{detail.value}</ContractDetailTabText>
      </Grid>
    </Grid>
  ));
};

export default ContractDetailTab;
