import React, {useState, useContext} from 'react';
import { TextField, Box, InputAdornment, styled, CircularProgress, Backdrop } from "@material-ui/core";
import Breadcrumb from '../../../../../Common/PageElements/Breadcrumb';
import { ContentWrapper } from '../../../../Administration/Staff/style';
import { GeneralTitlePage } from '../../../../../../components/AddGeneralPlan/style';
import {FormWrapper, 
    FormTitle, 
    FormSubTitle, 
    BenchMarkWrapper, 
    BenmarkLabel, 
    CriteriaWrapper, 
    Criteria, 
    MaxScore, 
    BtnWrapper,
    BackButton,
    AddNewButton,
    ProceedButton,
    DeleteIcon,
    LoadingButton,
    CircularWrapper,
    FormFieldWrapper} from './style';
import { Add } from '@material-ui/icons';
import { useMutation, useQuery, useQueryCache } from "react-query";
import ToastContext from '../../../../../../util/toastContext';
import { useHistory, useParams} from "react-router-dom";
import procurementAPI from '../../../../../../redux/api/procurementAPI';

    const CustomTextField = styled(TextField)({
        '& label.Mui-focused': {
          color: '#666666',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#666666',
        },
        '& .MuiOutlinedInput-root': {
          '&.Mui-focused fieldset': {
            borderColor: '#666666',
          },
        },
      });  


function UpdateTechnicalEvaluation() {

    const [benchMark, setBenchMark ]= useState(0)

    const [existingEvaluationId, setExistingEvaluationId ]= useState('')

    const [existingEvaluationData, setExistingEvaluationData] = useState([]) 

    const [firstDataId, setFirstDataId] = useState('') 

    const queryCache = useQueryCache()
    
    const { showAlert } = useContext(ToastContext);

    const history = useHistory();

    const [isdelete, setIsDelete] = useState(false)

    const { procurementId, activityName, activityId } = useParams()

    const redirectUrl = `/admin/procurement/activity/${activityName}/${activityId}/${procurementId}`
    const pageUrl = `/admin/procurement/activity/${activityName}/${activityId}/${procurementId}/create-technical-evaluation`

    function handleNameChange(id, event) {

        const dataObj = existingEvaluationData.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {})


        dataObj[id] = {
            ...dataObj[id],
            name: event.target.value,
          }
        setExistingEvaluationData(Object.values(dataObj));
      }

      function handleScoreChange(id, event) {

        const dataObj = existingEvaluationData.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {})

        dataObj[id] = {
            ...dataObj[id],
            score: parseFloat(event.target.value),
          }

        setExistingEvaluationData(Object.values(dataObj));
      }


      function handleAddInputField() {
        let id = Date.now();
        const values = [...existingEvaluationData]
        values.push({
            name: '', 
            score: '', 
            id
        })
        setExistingEvaluationData(values);
      }


      function handleRemoveInputField(id) {
        const dataObj = existingEvaluationData.reduce((obj, cur) => ({...obj, [cur.id]: cur}), {})

        delete dataObj[id]

        setExistingEvaluationData(Object.values(dataObj));
      }


    const redirectBack = () => {
      history.push(redirectUrl)
    } 
    

    const { status, data, isFetching } = useQuery(
      ['technical evaluation data', { procurementId }],
      procurementAPI.getTechEvaluation,
      {
        onSuccess: (data) => {
          if(data.data) {
            setExistingEvaluationId(data.data.id)
            setFirstDataId(data.data.requirements[0].id)
            setExistingEvaluationData(data.data.requirements)
            setBenchMark(data.data.benchmark)
          }
        },
        onError: (error) => {
          showAlert({
            severity: "error",
            message: error.message,
            durationInMs: 3000,
          });
        },
      }
    )


    const handleSubmit = () => {
        setIsDelete(false)

        if(benchMark > 100 || benchMark < 0) {
          showAlert({
            message: 'Benchmark should be greater than 0 and not be more than 100',
            severity: "error",
            durationInMs: 2000,
          });
        } else{
          const updateRequirements = existingEvaluationData.filter(val => typeof (val.id) === 'string')
  
          const addRequirements = existingEvaluationData
          .filter(val => typeof (val.id) === 'number')
          .map(obj => {
              return {name: obj.name, score: obj.score}
          })
        let payload = {
          benchmark: parseFloat(benchMark),
          updateRequirements,
          addRequirements
        }
        mutate({existingEvaluationId, payload});
        }
      
    }

    const [ mutate, {  isLoading, isError, isSuccess, error }] = useMutation(isdelete ? procurementAPI.deleteTechEvaluationRequirement : procurementAPI.editTechEvaluation,
      {
        onSuccess: () => {
          queryCache.invalidateQueries('technical evaluation data')
        },
      }
    )

    const handleDeletTechEvaluationRequirement = (id) => {
      if(typeof id === 'string'){
        setIsDelete(true)
        mutate(id)
      }else{
        handleRemoveInputField(id)
      }
    }


    if(isError){
        showAlert({
          message: error.message,
          severity: "error",
          durationInMs: 2000,
        });
      }
  
      if(isSuccess){
        if(!isdelete){
          showAlert({
            message: "Technical evaluation updated successfully",
            severity: "success",
            durationInMs: 2000,
          });
          history.push(redirectUrl)
        }
      }

  return (
    <ContentWrapper>
      <Box mt={2}>
        <GeneralTitlePage>Evaluation</GeneralTitlePage>
        <Breadcrumb
          values={[
            {
              url: "/dashboard/overview",
              title: "Home",
            },
            {
              url: redirectUrl,
              title: "Evaluation",
            },
            {
              url: pageUrl,
              title: "Create Technical Evaluation",
            },
          ]}
        />
      </Box>
        <FormWrapper>
          <FormTitle>Edit Technical Evaluation Requirement</FormTitle>
          <FormSubTitle>Kindly fill in the fields</FormSubTitle>
          {
            (isdelete && isLoading) || isFetching ? 
            <CircularWrapper>
              <CircularProgress size={20}/>
            </CircularWrapper>

            :
            <div>
              <BenchMarkWrapper>
              <BenmarkLabel>BenchMark</BenmarkLabel>
              <Box
                component="form"
                noValidate
                autoComplete="off"
                >
                <CustomTextField 
                id="bench-mark" 
                label="Benchmark for vendors" 
                variant="outlined" 
                fullWidth
                min='0'
                value={benchMark}
                type='number'
                onChange={(e) => setBenchMark(e.target.value)}
                InputProps={{
                    endAdornment: <InputAdornment position="start">%</InputAdornment>,
                  }}
                />
              </Box>
          </BenchMarkWrapper>
          <CriteriaWrapper>
                <Box
                component="form"
                noValidate
                autoComplete="off"
                > 
                    {
                        existingEvaluationData?.map((field) => {
                            return (
                                <FormFieldWrapper key={field.id}>
                                    <Criteria>
                                        <CustomTextField 
                                        id="criteria-name" 
                                        label="Enter criteria name" 
                                        variant="outlined" 
                                        type='text'
                                        name='name'
                                        fullWidth 
                                        value={field.name}
                                        onChange={(e) => handleNameChange(field.id, e)}
                                        />
                                    </Criteria>
                                    <MaxScore>
                                        <CustomTextField 
                                        id="score" 
                                        label="Maximum Obtainable Score" 
                                        variant="outlined" 
                                        name='score'
                                        min='0'
                                        fullWidth  
                                        type='number'
                                        value={field.score}
                                        onChange={(e) => handleScoreChange(field.id, e)}
                                        />
                                    </MaxScore>
                                      {
                                        field.id !== firstDataId &&
                                        <DeleteIcon onClick={() => handleDeletTechEvaluationRequirement(field.id)}/>
                                      }
                                </FormFieldWrapper>
                            )
                        })
                    }
              </Box>
          </CriteriaWrapper>
          <AddNewButton variant="text" size="large" startIcon={<Add />} onClick={() => handleAddInputField()}>Add New</AddNewButton>
          <BtnWrapper>
                <BackButton size="large" onClick={redirectBack}>Back</BackButton>
                
                {
                  isLoading ?
                    <LoadingButton disabled>
                      Wait... {" "}<CircularProgress size={14} />
                    </LoadingButton>
                    :
                    <ProceedButton 
                      variant="contained" 
                      size="large" 
                      onClick={handleSubmit}
                    >
                      Update
                    </ProceedButton>
                }
                </BtnWrapper>
                  </div>
                }
        </FormWrapper>
      </ContentWrapper>
  )
}

export default UpdateTechnicalEvaluation;