import React, { useState } from "react";
import TextField from "@material-ui/core/TextField";
import InputAdornment from "@material-ui/core/InputAdornment";
import { InputWrapper } from "./style";
import { makeStyles } from "@material-ui/styles";

const InputField = ({ type, label, defaultValue, name, style, inputRef, value, setValue, inputProps = {}, ...restProps }) => {
  const [showPassword, setPasswordVisiblity] = useState(false);
  const classes = useStyles();

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const dateInputProps = {
    InputLabelProps: {
      shrink: true
    }
  }

  return (
    <InputWrapper style={style}>
      <TextField
        label={label}
        id={name}
        type={showPassword ? "text" : type}
        defaultValue={defaultValue}
        name={name}
        value={value}
        classes={{ root: classes.root }}
        onChange={({ target: { value } }) => setValue?.(value)}
        // InputLabelProps={{ shrink: type === "date" && true }}
        InputProps={{
          endAdornment: type === "password" && (
            <InputAdornment position="end">
              <span
                aria-label="toggle password visibility"
                onClick={() => setPasswordVisiblity(!showPassword)}
                onMouseDown={handleMouseDownPassword}
              >
                {!showPassword ? "Show" : "Hide"}
              </span>
            </InputAdornment>
          ),
          ...inputProps,
          inputRef,
        }}
        {...(type === "date" && (dateInputProps))}
        {...restProps}
        variant="filled"
      />
    </InputWrapper>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    "& .Mui-disabled": {
      pointerEvents: "none",
    },
  },
}))


export default InputField;
