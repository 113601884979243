import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TableRow from "@material-ui/core/TableRow";
import { withStyles } from "@material-ui/core/styles";

const FormTitle = styled(Typography)`
  color: #445571;
  font-size: 24px !important;
  font-weight: 800 !important;
  letter-spacing: 0;
  line-height: 32px;
  margin-bottom: 10px !important;
`;

const FormSubtitle = styled(Typography)`
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 22px;
  color: #637381;
`;

const CardWrapper = styled.div`
  min-width: ${(props) => (props.width ? props.width : "300px")};
  border-radius: 3px !important;
  background-color: #ffffff !important;
  border: 1px solid #C9D4DB;
box-shadow: 0px 2px 4px #C9D4DB !important;
  padding: ${(props) => props.padding};
  box-sizing: border-box;
  height: fit-content;
  display: inline-block;
`;

CardWrapper.defaultProps = {
  padding: "20px",
};

const CardContentWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
}
`;

const CardStatus = styled.div`
  display: flex;
  justify-content: left;

  p {
    margin-right: 16px;
  }
`;

const StatusContainer = styled(FormSubtitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor};
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 12px !important;
  border-radius: 3px !important;
`;

const BadgeContainer = styled(FormSubtitle)`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor};
  font-size: 12px !important;
  letter-spacing: 0 !important;
  line-height: 12px !important;
  border-radius: 50px !important;
  border: 2px solid #fff;
`;

const GeneralBreadCrumbLabel = styled(Typography)`
  color: #2aa96d;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px !important;
`;

const theme = {
  color: {
    primary: "#2AA96D",
    secondary: "#445571",
    success: " #57D9A3",
    danger: "#E82203",
    white: "#FFFFFF",
    warning: "#FFC48B",
    textColor1: "#637381",
    textSecondary: '#6B6C7E',
    textMain: '#393A4A',
    tags: {
      approved: { background: "#BBE5B3", text: "#108043" },
      pending: { background: "#FCF1CD", text: "#F49342" },
      rejected: { background: "#E82203", text: "#FFFFFF" },
      blacklist: { background: "#637381", text: "#FFFFFF" },
    },
  },
};

const PageTabs = styled(Tabs)`
  .MuiTabs-indicator {
    background-color: ${theme.color.primary};
  }
`;

export const PageTab = styled(Tab)`
  color: ${({ activetab = "false" }) => (activetab === "false" ? "#000" : theme.color.primary)};
  padding: 0;
`;

const DefaultWidthCardWrapper = styled.div`
  border-radius: 3px !important;
  background-color: #ffffff !important;
  box-shadow: 0 2px 4px 0 #cbd4da !important;
  padding: 20px;
`;

const StyledTableRow = withStyles((styleTheme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: styleTheme.palette.action.hover,
    },
    cursor: "pointer",
  },
}))(TableRow);

const HoverTableStyle = styled(StyledTableRow)`
  border-left: 5px solid #fff;
  &:hover {
    border-left: 5px solid ${theme.color.primary};
    cursor: pointer;
  }
`;

export const ErrorContainer = styled(Typography)`
  margin: 5px 0;
  font-size: 10px !important;
  color: red;
`;

export {
  theme,
  FormTitle,
  FormSubtitle,
  CardWrapper,
  CardContentWrapper,
  StatusContainer,
  CardStatus,
  BadgeContainer,
  PageTabs,
  DefaultWidthCardWrapper,
  HoverTableStyle,
  GeneralBreadCrumbLabel,
  StyledTableRow,
};
