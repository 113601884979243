import { Box, Drawer, Typography, Divider } from "@material-ui/core";
import React from "react";
import { useMutation } from "react-query";
import useAlert from "../../hooks/useAlert";
import vendorsAPI from "../../redux/api/vendorsAPI";
import { separateNumByComma } from "../../util/truncateValue";
import { DashboardCancelButton, ButtonContainer } from "../DashboardDrawer/style";
import FileDownload from "../FileDownload";
import { SaveButton } from "../Forms/Common/style";
import { DrawerWrapper, VendorDetails } from "./style";

const ReceiptDrawer = ({ openDrawer, toggleDrawer, vendor }) => {
  const { showAlert } = useAlert();

  const [approvePayment, { isLoading }] = useMutation(vendorsAPI.approveVendorRenewal, {
    onSuccess: () => {
      toggleDrawer();
    },
    onError: (data) => {
      showAlert({ severity: "error", message: data?.message });
    },
  });

  const handleApproval = (e, id) =>{
   e.preventDefault();
   approvePayment(id);
  }
  return (
    <Drawer anchor="right" open={openDrawer}>
      <DrawerWrapper>
        <div>
          <Typography className="bold" variant="h4">
            Payment Receipt
          </Typography>
          <VendorDetails>
            <Box className="avatar">{vendor?.name?.split(" ")?.map((chars) => chars.charAt(0))}</Box>
            <Box>
              <Typography className="bold" style={{ marginBottom: -10 }} variant="subtitle1">
                {vendor?.name}
              </Typography>
              <Typography variant="caption">{vendor?.email}</Typography>
            </Box>
          </VendorDetails>
          <Box my={3}>
            <FileDownload name={`${vendor?.name}_Receipt`} size={325} url={vendor?.url} />
          </Box>
          <Divider />
          <Box mt={2}>
            <Typography variant="caption">Amount to pay</Typography>
            <Typography className="bold" variant="subtitle2">
              NGN{separateNumByComma(Number(vendor?.amount))}
            </Typography>
          </Box>
        </div>

        <div>
          <ButtonContainer noPad>
            <DashboardCancelButton onClick={() => toggleDrawer()}>Close</DashboardCancelButton>
            <SaveButton loading={isLoading} type="submit" onClick={(e) => handleApproval(e,vendor?.id)} style={{ width: "200px" }}>
              Approve payment
            </SaveButton>
          </ButtonContainer>
        </div>
      </DrawerWrapper>
    </Drawer>
  );
};

export default ReceiptDrawer;
