import React from "react";
import { Box, IconButton } from "@material-ui/core";
import moment from "moment";
import { Link, useParams } from 'react-router-dom';
import KeyboardArrowRightRoundedIcon from '@material-ui/icons/KeyboardArrowRightRounded';

import { ProfilePicWrapper } from "../../../../../../../components/AdminDashboardNav/style";
import { StyledTableCell, StyledTableRow } from "../../../../../../../components/SearchTable";
import { theme } from "../../../../../../../util/theme";
import { formatCurrency } from "../../../../../../../util/formatCurrency";

const EvaluationTable = ({ staff = [] }) => {
  const { activityId, activityName, procurementId } = useParams();

  return (
    <>
      {staff.map((row) => (
        <StyledTableRow key={row.userId}>
          <StyledTableCell>
            <Box display="flex" alignItems="center">
              <ProfilePicWrapper
                style={{
                  backgroundColor: theme.color.primary,
                  color: theme.color.white
                }}>
                {row.companyName[0] + row.companyName[1]}
              </ProfilePicWrapper>
              <Box ml={2}>
                <p>{row.companyName}</p>
                <span>{row.email}</span>
              </Box>
            </Box>
          </StyledTableCell>
          <StyledTableCell>{formatCurrency(row.quotation, true)}</StyledTableCell>
          <StyledTableCell>{row.score?.toFixed(2)}%</StyledTableCell>
          <StyledTableCell>{row.remark}</StyledTableCell>
          <StyledTableCell>{!!row.evaluatedDate && moment(row.evaluatedDate).format("DD, MMMM, yyyy")}</StyledTableCell>
          <StyledTableCell>
            <IconButton
              component={Link}
              to={`/admin/procurement/activity/${activityName}/${activityId}/${procurementId}/evaluation/${row.userId}`}>
              <KeyboardArrowRightRoundedIcon />
            </IconButton>
          </StyledTableCell>
        </StyledTableRow>
      ))}
    </>
  );
};

export default EvaluationTable;
