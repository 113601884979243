import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { Menu, MenuItem } from "@material-ui/core";
import { useDispatch } from "react-redux";

import { ProfileInfoWrapper, ProfilePic, ProfilePicWrapper, UserArrowDropDownIcon, UserInfo, UserName } from "./style";
import { logoutFn } from "../../util/logout";
import ProfileDrawer from "../ProfileDrawer";

const UserProfileTab = () => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [isProfileDrawerVisible, setIsProfileDrawerVisible] = useState(false);
  const open = Boolean(anchorEl);
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const history = useHistory();
  const dispatch = useDispatch();

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <div>
        <ProfileInfoWrapper
          item
          container
          justify="space-evenly"
          alignItems="center"
          aria-controls="menu-appbar"
          onClick={(e) => handleMenu(e)}
        >
          <UserInfo>
            <UserName>{localStorage.getItem("userName")}</UserName>
          </UserInfo>

          <ProfilePicWrapper className="profile-pic">
            <ProfilePic>{localStorage.getItem("userName").match(/\b\w/g).join("")}</ProfilePic>
          </ProfilePicWrapper>

          <UserArrowDropDownIcon />
        </ProfileInfoWrapper>

        <Menu
          id="menu-appbar"
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          keepMounted
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          open={open}
          onClose={handleClose}
        >
          <MenuItem
            onClick={() => {
              setIsProfileDrawerVisible(true);
              handleClose();
            }}
          >
            Profile
          </MenuItem>
          {/* <MenuItem onClick={handleClose}>My account</MenuItem> */}
          <MenuItem
            onClick={() => {
              logoutFn(dispatch);
              return history.push("/login");
            }}
          >
            Logout
          </MenuItem>
        </Menu>
      </div>
      <ProfileDrawer open={isProfileDrawerVisible} onClose={() => setIsProfileDrawerVisible(false)} />
    </>
  );
};

export default UserProfileTab;
