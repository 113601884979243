import React from "react";
import { Grid } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";

import { ContractDetailTabText } from "./style";
import EmptyTableBody from "../../../components/EmptyTableBody";
import { EmptyTableSubTitle } from "../../../components/EmptyTableBody/style";

const useStyles = makeStyles({
  alignCenter: {
    textAlign: "center",
  },
});

const SecondaryTable = ({ tableBody, tableHeader, noDataText }) => {
  const classes = useStyles();

  return (
    <>
      <Grid direction="row" style={{ borderBottom: "1px solid #CBD4DA", width: "100%", backgroundColor: "#2AA96D" }} container>
        {tableHeader.map((detail, i) => (
          <Grid
            key={i}
            className={classes[detail.align]}
            item
            xs={detail.grid}
            style={{ backgroundColor: detail.color, width: detail.width, borderLeft: "1px solid #CBD4DA" }}
          >
            <ContractDetailTabText style={{ color: "#fff", paddingTop: 10 }}>{detail.title}</ContractDetailTabText>
          </Grid>
        ))}
      </Grid>
      {tableBody.map((el, i) => (
        <Grid key={i} direction="row" style={{ borderBottom: "1px solid #CBD4DA" }} container>
          {tableHeader.map((detail) => (
            <Grid
              key={detail.id + i}
              className={classes[detail.align]}
              item
              xs={detail.grid}
              style={{ width: detail.width, borderLeft: "1px solid #CBD4DA" }}
            >
              <ContractDetailTabText>{detail.indexed ? i + 1 : el[detail.id]}</ContractDetailTabText>
            </Grid>
          ))}
        </Grid>
      ))}
      {tableBody.length === 0 && (
        <EmptyTableBody>
          <EmptyTableSubTitle>{noDataText || "No data yet"}</EmptyTableSubTitle>
        </EmptyTableBody>
      )}
    </>
  );
};

export default SecondaryTable;
