import React, { useContext, useState } from "react";
import { Box, Modal, Button } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { useMutation } from "react-query";

import { ApprovalBanner, ModalBody, ModalButtons, ModalDescription, ModalTitle } from "../../CreateProcurement/style";
import procurementAPI from "../../../../redux/api/procurementAPI";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";
import { DropzoneArea } from "../../../../components/DocumentUploadField/style";
import FileProgress from "../../../../components/FileProgress/FileProgress";
import ToastContext from "../../../../util/toastContext";
import { useHistory} from "react-router-dom";

import { Overlay, Title, Subtitle } from "../ApprovalMemo/style";
import { ReactComponent as SaveIcon } from "../../../../assets/save.svg";
import { UploadButton } from "../../Procurement/Activitiies/CertificateOfNoObjection/style";
import FileError from "../../../../components/FileUploadField/FileError";
import { MaxFileSize } from "../../../../util/constants";

export default function CertificateOfNoObjectionOverlay({refetch, procurementId, isProcurementOfficer }) {
  const { showAlert } = useContext(ToastContext);
  const [showApprove, setShowApprove] = useState(false);
  const [file, setFile] = useState(null);
  const fileMaxSize = MaxFileSize;
  const history = useHistory();

  const [uploadCertificateOfNoObjectionTender, uploadCertificateOfNoObjectionTenderQuery] = useMutation(
    procurementAPI.uploadCertificateOfNoObjectionTender,
    {
      onSuccess: () => {
        showAlert({
          message: "Certificate of no objection has been uploaded successfully",
          severity: "success",
        });
        setShowApprove(false);
        refetch();
      },
      onError: (error) => {
        showAlert({
          message: error.message,
          severity: "error",
        });
      },
    }
  );

  const handleApprove = async () => {
    try {
      uploadCertificateOfNoObjectionTender({
        procurementPlanId: procurementId,
        file,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  function fileSizeValidator(file) {
    if (file.size > fileMaxSize) {
      return {
        code: "tooLarge",
        message: `File size is larger than ${fileMaxSize / 1000}kb`
      };
    }

    return null
  };

  const redirectToTemplate = () => {
    return history.push(`/admin/procurement/certificate-of-no-objection/${procurementId}`)
  }

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDrop: (files) => setFile(files[0]),
    multiple: false,
    validator: fileSizeValidator
  });

  const handleShowUploadFile = () => {
    setShowApprove(true);
  };

  return (
    <>
      <Overlay>
        <Title>Certificate of No Objection</Title>
        {!isProcurementOfficer && <Subtitle>To proceed to the milestone phases, click the</Subtitle>}
        <Subtitle>
          {isProcurementOfficer
            ? "Awaiting Certificate of No Objection (CNO) to commence tender from KWPPA"
            : " button below to generate a Certificate of No Objection"}
        </Subtitle>
        {!isProcurementOfficer && (
          <UploadButton
            variant="contained"
            style={{ marginTop: 20 }}
            onClick={redirectToTemplate}
            endIcon={
              <SaveIcon
                width="19px"
                fill="white"
                style={{
                  transform: "rotate(180deg)",
                }}
              />
            }
          >
            Generate Certificate
          </UploadButton>
        )}
      </Overlay>
      <Modal open={showApprove} onClose={() => setShowApprove(false)}>
        <ModalBody>
          <ModalTitle>Upload certificate of no objection?</ModalTitle>
          <ModalDescription>
            You agree to have thoroughly gone through the draft. Scan and upload the signed certificate of no objection for the Tender
          </ModalDescription>
          <ApprovalBanner>Upload Certificate of no Objection here.</ApprovalBanner>
          <Box mb={2.5}>
            {file ? (
              <FileProgress progress={100} fileName={file.name} fileSize={file.size} />
            ) : (
              <DropzoneArea {...getRootProps()}>
                <input {...getInputProps()} />
                <div>
                  <p>Upload or drop file here</p>
                  <span style={{ fontSize: ".9em" }}>{"File size should not exceed 5,000kb (5mb)"}</span>
                  <FileError fileRejections={fileRejections} />
                </div>
              </DropzoneArea>
            )}
          </Box>
          <ModalButtons>
            <LoadingButton
              style={{
                marginLeft: "0",
              }}
              color="#3BD278"
              disabled={!file}
              onClick={handleApprove}
              loading={uploadCertificateOfNoObjectionTenderQuery.isLoading}
            >
              Yes, I approve.
            </LoadingButton>
            <Button onClick={() => setShowApprove(false)} variant="outlined">
              Cancel
            </Button>
          </ModalButtons>
        </ModalBody>
      </Modal>
    </>
  );
}
