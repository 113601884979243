import styled from "styled-components";
import { styled as materialStyled } from "@material-ui/styles";
import { Button } from "@material-ui/core";
import { theme } from "../../../../../util/theme";

export const SectionWrapper = styled.div`
  background: #FCFCFD;
  border: 1px solid #C9D4DB;
  padding: 20px;
`;

export const SectionTitle = styled.h3`
  font-size: 17px;
  line-height: 24px;
  color: #1E2B37;
  padding: 20px
`;

export const Editor = styled.div`  
  .general-editor-container-style{
    background-color: white;
  }
  
  .general-editor-style{
    min-height: 274px
  }
`;

export const RequiredDocuments = styled.div`
  display: grid;
  grid-row-gap: 20px;
`

export const RequiredDocumentGrid = styled.div`
  display: grid;
  grid-column-gap: 24px;
  padding: 24px;
  border: 1px solid #C9D4DB;
  
  .iconButton{
    width: 16px;
    height: 16px
  }
  
  svg {
    flex-shrink: 0
  }
`

export const AddNewDocumentButton = materialStyled(Button)({
  color: `${theme.color.primary}`,
  margin: '20px 0 40px 20px',
  fontWeight: 'bold',
  textTransform: 'capitalize',
})

export const ActionButton = materialStyled(Button)({
  backgroundColor: `${theme.color.primary}`,
  border: '1px solid #EBEBEB',
  color: 'white',
})

export const ButtonsRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 40px 26px
`
