import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import EmptyTableBody from "../../../../components/EmptyTableBody";
import { StyledTableCell } from "../../../../components/SearchTable";
import { HoverTableStyle, theme } from "../../../../util/theme";
import { TableStatusContainer } from "../../../Common/style";
import { EmptyTableSubTitle } from "./style";
import { formatCurrency } from "../../../../util/formatCurrency";

const useStyles = makeStyles({
  contractNo: {
    fontSize: 14,
    color: `${theme.color.primary}`,
    textTransform: "uppercase",
  },
});

const SIGNATURE_BACKGROUND_COLORS = {
  UNSIGNED: "#FFE2C5",
  SIGNED: "#BBE5B3",
  AWARDED: "#10615a",
  PENDING: "#ff9efc",
  REJECTED: "#bf5278",
};

const SIGNATURE_TEXT_COLORS = {
  UNSIGNED: "#BF6712",
  SIGNED: "#108043",
  AWARDED: "#e1f0e9",
  PENDING: "#DE3618",
  REJECTED: "#ffcfdb",
};

const pickSignature = (signature) => (
  <TableStatusContainer bgcolor={SIGNATURE_BACKGROUND_COLORS[signature]} textcolor={SIGNATURE_TEXT_COLORS[signature]}>
    {signature}
  </TableStatusContainer>
);

const ContractTable = ({ contracts = [], urlLink }) => {
  const classes = useStyles();
  const history = useHistory();

  return contracts.length ? (
    contracts.map((eachContract) => {
      const link = !!urlLink ? `${urlLink}/${eachContract.id}` : `/admin/contracts/${eachContract.id}`;
      return (
        <HoverTableStyle key={eachContract.id} onClick={() => history.push(link)}>
          <StyledTableCell>
            <p className={classes.contractNo}>
              {eachContract?.referenceId}
            </p>
            <Tooltip title={eachContract?.description} aria-label="add">
              <p>
                {eachContract?.description?.length > 50 ? eachContract?.description.substring(0, 50) + "..." : eachContract?.description}
              </p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>{eachContract?.ministry}</StyledTableCell>
          <StyledTableCell>{eachContract?.contractor?.companyName || "N/A"}</StyledTableCell>
          <StyledTableCell>{pickSignature(eachContract.status)}</StyledTableCell>
          <StyledTableCell>{moment(eachContract.startDate || eachContract.dateAwarded).format("DD MMM, YYYY")}</StyledTableCell>
          <StyledTableCell>{moment(eachContract.endDate || eachContract.expiryDate).format("DD MMM, YYYY")}</StyledTableCell>
          <StyledTableCell style={{ textAlign: "right" }}>
            {formatCurrency(eachContract?.estimatedValue, true) || "N/A"}
          </StyledTableCell>
        </HoverTableStyle>
      );
    })
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>There are currently no contracts</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default ContractTable;
