import styled from "styled-components";
import { Typography } from "@material-ui/core";
import Avatar from "@material-ui/core/Avatar";
import { theme } from "../../../../../util/theme";
import LoadingButton from "../../../../../components/LoadingButton/LoadingButton"

export const TabContainerWrapper = styled.div`
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  width: 100%;
  * {
    box-sizing: border-box;
  }
`;

export const TabContainer = styled.div`
  width: 80%;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  position: relative;
  .MuiTabs-indicator {
    background-color: #2AA96D;
  }

  .MuiTab-wrapper {
    text-transform: none;
    color: #5f7483;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }

  .MuiTabs-root {
    height: 43px;
    border: 1px solid #c9d4db;
    border-radius: 3px 3px 0 0;
    background-color: #e4e9ec;
  }
`;

export const CheckEntriesCard = styled.div`
  height: 245px;
  width: 269px;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  color: #1e2b37;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding: 20px;
  margin-left: 25px;

  ul {
    list-style-type: none;
    color: #1e2b37;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
    margin-top: 10px;
    padding: 0;
  }
  li {
    display: flex;
    align-items: center;
    padding: 5px 0;
    span {
      margin-right: 7px;
      margin-bottom: -5px;
    }
  }
  .MuiSvgIcon-root.ok-entry {
    width: 20px;
    height: 20px;
    fill: rgba(0, 179, 0, 0.6);
  }
  .MuiSvgIcon-root.error-entry {
    width: 20px;
    height: 20px;
    fill: #f21500;
  }
`;

export const TabContent = styled.div`
  width: 100%;
  min-height: 400px;
`;

export const TabContentTitle = styled.div`
  color: #1e2b37;
  font-size: 17px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
  padding: 20px 20px;
  width: 100%;
  span:first-child {
    color: #2AA96D;
    cursor: pointer;
  }
`;

export const TabContentBox = styled.div`
  border: 1px solid #c9d4db;
  background-color: #fcfcfd;
  padding: 15px 20px;
`;

export const UploadedFileSection = styled.div`
  display: flex;
  flex-wrap: wrap;
  .file-card {
    width: 50%;
    padding: 8px;
  }
`;

export const DragDropSection = styled.div`
  width: 100%;
  margin-bottom: 15px;
`;

export const SearchSection = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`;

export const DatasheetTableContainer = styled.div`
    width: 100%;
    padding: 20px 0;
    table {
      min-width: 600px;
      
    }
    tr {
      border: 1px solid #E4E9EC;
      height: 52px;
      padding; 0;
    }
    td {
      text-align: left;
      background-color: #FCFCFD;
    }
    .is-ok {
      fill: #3BD278;
      width: 12px;
      height: 12px;
    }
    .not-ok {
      fill: #F21500;
      width: 12px;
      height: 12px;
    }
    .indicator-col {
      max-width: 30px;

    }
    .name-id-col {
      color: #1E2B37;
      font-size: 14px;
      letter-spacing: 0;
      line-height: 20px;
      padding: 0 30px;
      span {
        color: #5F7483;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
      }
    }
    .action-col {
      text-align: right;
      display: flex;
      justify-content: flex-end;
    }
`;

export const RenderOptionStyle = styled.div`
  color: #1e2b37;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border-bottom: 1px solid #e4e9ec;
  padding: 5px;
  width: 100%;
  span {
    color: #5f7483;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
`;

export const EmptyContractState = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  height: 400px;

  div.wrapper {
    width: 450px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .main-text {
    color: #1e2b37;
    font-size: 17px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
  .sub-text {
    color: #1e2b37;
    font-size: 17px;
    letter-spacing: 0;
    line-height: 24px;
    text-align: center;
    margin-bottom: 20px;
  }
`;

export const ContractListContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
`;

export const ContractListItem = styled.div`
    display: flex;
    width: 100%;
    height: 96px;
    border: 1px solid #E4E9EC;
    border-radius: 3px 3px 0 0;
    background-color: #FCFCFD;
    padding: 18px 15px;
    margin-bottom: 15px;
    cursor: pointer;
    
    .contract-indicator {
      fill: #F21500;
      width: 13px;
      height: 13px;
      margin-right: 13px;
    }
    .contract-box {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .contract-info {
        color: #1E2B37;
        font-size: 14px;
        letter-spacing: 0;
        line-height: 20px;
        .light-grey {
          color: #5F7483;
        }
      }
      .contract-meta {
        display: flex;
        align-items: center;
        &>div{
          display: flex;
          align-items: center;
          margin-right: 4px;
          span {
            margin-right: 5px;
          }
        }
        .duration-icon {
          height: 15px;
          width: 15px;
          fill: #919EAB;
          margin-top: -3px;
          margin-right: 5px;
        }
      }
      .arrow-forward {
        height: 20px;
        width: 20px;
        fill: #919EAB;
      }
    }
    .
`;

export const EvaluatedContractContainer = styled.div`
  width: 100%;
  .contract-meta-input {
    display: flex;
  }
`;

export const VendorCard = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #e4e9ec;
  border-radius: 3px;
  background-color: #ffffff;
  margin-top: 20px;
`;

export const VendorInfo = styled.div`
  box-sizing: border-box;
  height: 72px;
  width: 100%;
  border: 1px solid #e4e9ec;
  border-radius: 3px;
  background-color: #fcfcfd;
  display: flex;
  padding: 5px 15px;
  align-items: center;
  .vendor-detail {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .vendor-indicator {
    fill: #e4e9ec;
    width: 41px;
    height: 41px;
    margin-right: 13px;
  }
  .vendor-name {
    color: #3f5673;
    font-family: "Nunito Sans";
    font-size: 16px;
    font-weight: bold;
    letter-spacing: 0;
    line-height: 22px;
  }
  .vendor-registry-id {
    color: #3f5673;
    font-family: "Nunito Sans";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 19px;
  }
  .vendor-info-icons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 90px;
  }
  .delete-icon {
    fill: #919eab;
  }
  .arrow-downward-icon {
    fill: #637381;
  }

  hr {
    color: #e4e9ec;
    background: red;
    border: green;
  }
`;

export const VendorInput = styled.div`
  width: 100%;
  padding: 20px;
  .vendor-meta-input {
    display: flex;
    // margin-bottom: 20px;
  }
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  .MuiInputLabel-root {
    text-transform: none;
  }
`;

export const VendorOptions = styled.div`
  color: #212b36;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;

  .MuiFormGroup-root {
    flex-direction: row;
  }
  .MuiFormLabel-root {
    color: #212b36;
    font-family: "SF Pro Text";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
  .MuiRadio-root {
    color: #c4cdd5;
    &.Mui-checked {
      color: #5c6ac4;
    }
  }
`;

export const InputDivider = styled.div`
  height: 1px;
  width: calc(100% - 20px);
  border: 1px solid #e4e9ec;
  margin: 10px;
`;

export const GeneralCardContentWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #c9d4db !important;
  background-color: #fcfcfd !important;
  padding: 19.5px 20.5px 13.5px !important;

  .general-editor-style {
    height: 274px !important;
    background-color: #ffffff;
  }

  .general-editor-container-style {
    width: 100% !important;
  }
`;

export const ReviewContainer = styled(GeneralCardContentWrapper)`
  padding-top: 69px !important;
`;

export const AdminAvatar = styled(Avatar)`
  margin-right: 15px !important;
  background-color: ${theme.color.primary} !important;
`;

export const AdminText = styled(Typography)`
  font-size: 14px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 14px !important;
`;

export const CommentText = styled(Typography)`
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 14px !important;
  margin-top: 5px !important;
`;

export const AdminTextTime = styled(AdminText)`
  font-size: 10px !important;
  color: #919191;
  font-weight: normal !important;
`;

export const ReviewTitleLabel = styled(Typography)`
  color: #424f5c;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 22px !important;
  margin: 10px 0 !important;
`;

export const ApprovalButton = styled(LoadingButton)`
  border: 1px solid #3BD278 !important;
  background-color: #3BD278 !important;
  color: #fff !important;
  margin-left: 20px !important;
`;