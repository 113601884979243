import styled from "styled-components";

export const UserPlaceHolder = styled.div`
  border-radius: 50%;
  background-color: green;
  height: 20px;
  width: 20px;
  color: #ffffff;
  font-size: 14px !important;
  font-weight: 500 !important;
  letter-spacing: 0;
  line-height: 20px !important;
  text-align: center !important;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 15px !important;
`;

export const StyledLink = styled.a`
  font-weight: 600;
  font-size: 14px;
  line-height: 19px;
  color: #2AA96D;
`;
