export const accordionContent = [
  {
    title: "Are the Procurement officers under the Civil Service Commission?",
    description: "No comment",
  },
  {
    title: "Can the Kwara state Public Procurement Agency cancel a contract that has been awarded by an MDA?",
    description: "No comment",
  },
  {
    title: "Does the Law still have room for Direct Labour in the System?",
    description: "No comment",
  },
  {
    title: "Explain what is Lateral Conversion",
    description: "No comment",
  },
  {
    title: "Explain what you mean by Lowest Evaluated Responsive Bidder",
    description:
      "The bidder who offers the lowest priced bid among bidders who are qualified to perform the contract and whose bids meet the specifications in the bid document is the lowest evaluated responsive bidder.",
  },
  {
    title: "How can the Agency ensure that there is no conflict of interest in the award of contracts by the MDAs?",
    description: "No comment",
  },
  {
    title: "How do we ensure that there is value for Money under the new Public Procurement regime?",
    description: "No comment",
  },
  {
    title: "How do you intend to fill the gaps at the Directors Level?",
    description: "No comment",
  },
  {
    title: "How does the law take care of Emergencies?",
    description: "No comment",
  },
  {
    title: "How is the whole system expected to function under the new Procurement regime?",
    description: "No comment",
  },
];

export const relatedCircular = [
  {
    title: "PROCUREMENT REPORT",
    url: "https://res.cloudinary.com/prunedge/raw/upload/v1609236784/E-Procurement/Documents/procurement_report_2_f8lxdh.docx",
    date: "26 May, 2020",
  },
  {
    title: "KWARA STATE PUBLIC PROCUREMENT LAW",
    url:
      "https://res.cloudinary.com/prunedge/image/upload/v1609236781/E-Procurement/Documents/Kwara-State-Public-Procurement-Law-2020_jkpxzz.pdf",
    date: "26 May, 2020",
  },
  {
    title: "MEASURES TO INCREASE SMEs PARTICIPATION IN CONTRACTS",
    url:
      "https://res.cloudinary.com/prunedge/image/upload/v1609236780/E-Procurement/Documents/MEASURES_TO_INCREASE_SMEs_PARTICIPATION_IN_CONTRACTS_hv5w1j.pdf",
    date: "26 May, 2020",
  },
  {
    title: "PROCUREMENT GUIDELINES FOR COVID-19 PANDEMIC",
    url:
      "https://res.cloudinary.com/prunedge/image/upload/v1609236780/E-Procurement/Documents/PROCUREMENT_GUIDELINES_FOR_COVID-19_PANDEMIC_num2hc.pdf",
    date: "26 May, 2020",
  },
  {
    title: "PROCUREMENT REPORT 1",
    url: "https://res.cloudinary.com/prunedge/raw/upload/v1609236779/E-Procurement/Documents/procurement_report_1_us1lcm.docx",
    date: "26 May, 2020",
  },
];

export const solutionGuide = [
  {
    title: "Admin Guide",
    url:
      "https://res.cloudinary.com/prunedge/image/upload/v1643635108/E-Procurement/Documents/Kwara_State_e-Procurement_Manual-_Administrators_fhf7dl.pdf",
    date: "June 2021",
  },
  {
    title: "Vendors Guide",
    url:
      "https://res.cloudinary.com/prunedge/image/upload/v1643635094/E-Procurement/Documents/Kwara_State_e-Procurement_Vendor_manual_fhjqlg.pdf",
    date: "June 2021",
  },
];
