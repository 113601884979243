import { Typography } from "@material-ui/core";
import styled from "styled-components";
import { Link } from "react-router-dom";

export const BreadCrumblabel = styled(Typography)`
  height: 16px;
  margin-top: 35px !important;
  margin-bottom: 35px !important;
  color: #adb4bb;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  flex-direction: row;
`;

export const BreadcrumbActive = styled.span`
  height: 16px;
  color: #6d7278;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 8px !important;
`;

export const PaginationWrapper = styled.div`
  box-sizing: border-box;
  height: 51px;
  border: 1px solid #e5e9ec;
  background-color: #ffffff;
  margin-bottom: 40px;
`;

export const UnstyledLink = styled(Link)`
  text-decoration: none;
`;