import React from "react";

import {
  NewsCaptionSmall,
  NewsComment,
  NewsSmallCardContent,
  NewsUpdateImageContentSmall,
  NewsCard,
  NewsClickCard,
  NewsDateLabel,
} from "../../pages/Homepage/Landing/style";
import { Grid } from "@material-ui/core";

export const NewsHeadlinesClickListCard = ({ handleClick, cardImg, title, date, synopsis, id }) => (
  <NewsClickCard onClick={handleClick}>
    <NewsUpdateImageContentSmall bgimg={cardImg} />
    <NewsSmallCardContent>
      <NewsDateLabel>{date || "15 Jul, 2020"}</NewsDateLabel>
      <NewsCaptionSmall>{title || "Governor Of Kwara state, At The Presentation Of Year 2020 Budget Proposal"}</NewsCaptionSmall>
      <NewsComment>{synopsis || "To ensure a transparent, competitive, inclusive, sustainable..."}</NewsComment>
    </NewsSmallCardContent>
  </NewsClickCard>
);

const NewsHeadlinesListCard = ({ cardImg, title, date, synopsis, id, noContent }) => (
  <NewsCard to={`/news/${id}`}>
    <Grid container alignItems="stretch">
      <Grid xs={12} sm={4}>
        <NewsUpdateImageContentSmall style={{ height: "100%" }} bgimg={cardImg} />
      </Grid>
      <Grid xs={12} sm={8}>
        <NewsSmallCardContent>
          <NewsDateLabel>{date || "15 Jul, 2020"}</NewsDateLabel>
          <NewsCaptionSmall>
            {title || "Governor Of Kwara state, At The Presentation Of Year 2020 Budget Proposal"}
          </NewsCaptionSmall>
          {!noContent && (
            <NewsComment>{synopsis || "To ensure a transparent, competitive, inclusive, sustainable..."}</NewsComment>
          )}
        </NewsSmallCardContent>
      </Grid>
    </Grid>
  </NewsCard>
);

export default NewsHeadlinesListCard;
