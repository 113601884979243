import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";

import { NewsUpdateSection, NewsUpdateCardListContainer } from "./style";
import { FlexContainer, LandingTitleText as NewsBulletinText, SeparatorLine, NewsCardWrapper } from "../Landing/style";
import NewsHeadlinesListCard from "../../../components/NewsHeadlineListCard";
import NewsHeadlinesCard from "../../../components/NewsHeadlinesCard";
import { news } from "./mock";

const NewsUpdateSectionComponent = () => {
  return (
    <NewsUpdateSection>
      <Container>
        <FlexContainer>
          <div>
            <NewsBulletinText>News and updates</NewsBulletinText>
            <SeparatorLine />
          </div>
          {/* <DatePicker style={{ width, height }} label="Filter by Date" /> */}
        </FlexContainer>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <NewsHeadlinesCard />
            <NewsUpdateCardListContainer>
              {news.slice(1, 2).map((post) => (
                <NewsHeadlinesListCard
                  key={post.id}
                  id={post.id}
                  cardImg={post.imgUrl}
                  title={post.title}
                  date={post.date}
                  synopsis={post.synopsis}
                  content={post.content}
                />
              ))}
            </NewsUpdateCardListContainer>
          </Grid>
          <Grid item xs={12} sm={6}>
            <NewsCardWrapper>
              {news.slice(2).map((post) => (
                <NewsHeadlinesListCard
                  key={post.id}
                  id={post.id}
                  cardImg={post.imgUrl}
                  title={post.title}
                  date={post.date}
                  synopsis={post.synopsis}
                  content={post.content}
                />
              ))}
            </NewsCardWrapper>
          </Grid>
        </Grid>
      </Container>
    </NewsUpdateSection>
  );
};

export default NewsUpdateSectionComponent;
