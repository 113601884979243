import styled from "styled-components";
import { MenuItem } from "@material-ui/core";

export const NotificationCardWrapper = styled(MenuItem)`
    min-height: 82px;
    padding: 10px 20px !important;
    border-bottom: 1px solid #e6e6e6 !important;    
    width: 100%;
`;

export const NotificationCard = styled.div`
  width: 100%;
  white-space: normal
`

export const Avatar = styled.img`
  height: 30px;
  width: 30px;
  background: lightblue;
  border-radius: 50%;
  margin-right: 15px;
  margin-top: 14px;
`;

export const NotificationTime = styled.p`
  color: #637381;
  font-size: 12px;
  letter-spacing: 0;
  line-height: 16px;
  margin-top: 15px;
`;

export const NotificationHeader = styled.div`
  height: 55px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5px 0 14px;
  border-bottom: 1px solid #E5E9EC;
  margin-bottom: 10px;
  box-sizing: border-box;

  > div:first-child {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;
export const NotificationFooter = styled.p`
  height: 47px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #637381;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  border-top: 1px solid #E5E9EC;
  box-sizing: border-box;
`;

export const NotificationBody = styled.div`
  overflow: auto;
  max-height: 50vh;
  display: grid;
  .MuiListItem-gutters {
    padding: 0;
  }
`;

export const NotificationMessage = styled.div`
  color: #637381;
  font-size: 14px;
  letter-spacing: 0;
  line-height: 20px;
  padding-top: 10px;
`;

export const ContractNumber = styled.span`
  color: #2AA96D;
  text-decoration: none;
`;

export const NotificationHeaderTitle = styled.p`
  width: 101px;
  color: #212B36;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
`;

export const NotificationNumber = styled.p`
  width: 56px;
  height: 16px;
  border-radius: 100px;
  background-color: #DFE3E8;
  line-height: 16px;
  color: #003136;
  font-size: 12px;
  font-weight: 500;
  letter-spacing: 0;
  text-align: center;
`;
