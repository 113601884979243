/* eslint-disable no-nested-ternary */
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import Paper from "@material-ui/core/Paper";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import TableRow from "@material-ui/core/TableRow";
import Checkbox from "@material-ui/core/Checkbox";
import React, { useState } from "react";
import {
  ResultDetailContainer,
  ResultShowText,
  TableContainerWrapper,
  TableWrapper,
  PaginationWrapper
} from "./style";
import {
  EmptyState,
  EmptyStateDescription,
  EmptyStateHeader,
} from "../../pages/Admin/Procurement/Activitiies/ReceiptOfResponse/VendorsTable/style";

export const StyledTableCell = withStyles(() => ({
  head: {
    borderRadius: 0,
  },
  root: {
    "&.MuiTableHead-root": {
      textAlign: "left",
      paddingLeft: "40px",
      paddingRigth: "30px",
    },
  },
  ".MuiTableHead-root": {
    textAlign: "left"
  },
  span: {
    color: "#5F7483",
    fontWeight: 600
  }
}))(TableCell);

export const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  columnBold: {
    fontWeight: "bold",
    fontSize: "17px",
    color: "#1E2B37",
    textTransform: "capitalize",
  },
});

const getRowCount = (rows) => {
  let count = "";
  if (!!rows) {
    if (Array.isArray(rows)) {
      count = rows.length;
    }
    if (Array.isArray(rows.data)) {
      count = rows.data.length;
    }
    if (!isNaN(parseInt(rows))) {
      count = rows;
    }
  }

  return !!parseInt(count) ? (count > 1 ? `Showing ${count} results` : `Showing 1 result`) : `Showing result`;
};

const SupplierSearchTable = ({
  columns,
  children,
  variant,
  showPadding = true,
  columnBold,
  showTableHeader = true,
  loading = false,
  rowsPerPage,
  // setRowsPerPage,
  rows,
  handleOrder,
  handleOrderBy,
  handleSortRequest,
  pageNumber,
  // setPageNumber,
  tabs,
  totalNumberOfPages,
  handleChangePage,
  handleChangeRowsPerPage,
  tableHeaderText,
  containerStyle,
  enableSelection,
  selectionProps: { numRows, selectedRows, numSelected, renderSelectionAction, onToggleSelectAll } = {},
  empty = false,
  emptyMessage = "",
}) => {
  const classes = useStyles();
  const [order, setOrder] = useState("asc")
  const [orderBy, setOrderBy] = useState("companyName")
  tableHeaderText = getRowCount(rows);

  const onHandlerSortRequest = (property) => (event) => {
    const isAsc = orderBy === property && order === "asc"
    const sortSign = {
      desc: "-",
      asc: "+",
    }
    setOrder(isAsc ? "desc" : "asc")
    setOrderBy(property)
    handleSortRequest && handleSortRequest(`${sortSign[order]}${property}`)
  }

  return (
    <React.Fragment>
      <TableContainerWrapper nopadding={showPadding.toString()}>
        <TableWrapper style={containerStyle} component={Paper}>
          {loading ? (
            <Box pt={20} pb={20} display="flex" alignItems="center" flexDirection="column">
              <CircularProgress size={40} />
              <Box mt={2}>
                <p>Loading...</p>
              </Box>
            </Box>
          ) : (
            <>
              {tabs}
              {showTableHeader && (
                <ResultDetailContainer>
                  <ResultShowText>{tableHeaderText}</ResultShowText>
                  {enableSelection && numSelected > 0 && renderSelectionAction?.({ numRows, numSelected, selectedRows })}
                </ResultDetailContainer>
              )}
              {empty && !loading ? (
                <EmptyState>
                  <EmptyStateHeader>All caught up!</EmptyStateHeader>
                  <EmptyStateDescription>{emptyMessage}</EmptyStateDescription>
                </EmptyState>
              ) : (
                <Table className={classes.table} aria-label="customized table">
                  <TableHead>
                    <TableRow style={{ paddingLeft: "40px" }}>
                      {enableSelection && (
                        <StyledTableCell
                          padding="checkbox"
                          className={(!!columnBold && classes.columnBold).toString()}
                          style={{ backgroundColor: variant === "secondary" ? "transparent" : "#E5E9EC" }}
                        >
                          <Checkbox
                            indeterminate={numSelected > 0 && numSelected < numRows}
                            checked={numRows > 0 && numSelected === numRows}
                            onChange={(e) => onToggleSelectAll?.(e.target.checked)}
                            inputProps={{ "aria-label": "Select all" }}
                          />
                        </StyledTableCell>
                      )}
                      {columns.map((column, index) => (
                        <StyledTableCell
                          className={(!!columnBold && classes.columnBold).toString()}
                          style={{ backgroundColor: variant === "secondary" ? "transparent" : "#E5E9EC" }}
                          key={column.id}
                          align="left"
                          sortDirection={orderBy === column.id ? order : false}
                        >
                          <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={onHandlerSortRequest(column.id)}
                          >
                            {column.label}
                          </TableSortLabel>
                        </StyledTableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>{children}</TableBody>
                </Table>
              )}
            </>
          )}
        </TableWrapper>
      </TableContainerWrapper>
      <PaginationWrapper style={{ marginTop: "10px", }}>
        {totalNumberOfPages > pageNumber && (
          <TablePagination
            rowsPerPageOptions={[10, 20, 30]}
            component="div"
            count={totalNumberOfPages}
            rowsPerPage={rowsPerPage}
            page={(pageNumber)} //TablePagination is zero indexed by default from material-ui so we have subtract 1
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
      </PaginationWrapper>
    </React.Fragment>
  );
};

export default SupplierSearchTable;
