import { Typography } from "@material-ui/core";
import styled from "styled-components";

export const DocumentFrame = styled.div`
  background: #ffffff;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: 20px !important;
  text-align: center;
`;

export const FileText = styled(Typography)`
  font-size: 13px;
  line-height: 16px;
  color: #2AA96D;
  width: 200px;
  margin-top: 15px !important;
`;

export const FileTitle = styled(Typography)`
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5f7483;
  padding-top: 10px !important;
  padding-bottom: 38px !important;
`;
