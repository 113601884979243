import React from "react";
import { CircularProgress } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { GreenButton } from "./style";
import { theme } from "../../util/theme";

const LoadingIcon = () => (
  <Box ml={2}>
    <CircularProgress size={12} />
  </Box>
)

const LoadingButton = ({
  color = `${theme.color.primary}`, loading = false, disabled, children, endIcon, ...props
}) => (
  <GreenButton
    color={color}
    disabled={loading || disabled}
    variant={"contained"}
    endIcon={loading ? <LoadingIcon /> : endIcon}
    {...props}
  >
    {children}
  </GreenButton>
);

export default LoadingButton;
