import React, { useEffect } from "react";
import { Drawer, DrawerTitle, DrawerForm, DrawerFormRow, DrawerActions, CancelButton, SaveButton } from "./style";
import { useForm } from "react-hook-form";
import { Dropdown, InputField } from "../../../../../components";
import InputAdornment from "@material-ui/core/InputAdornment";
import { ErrorContainer } from "../../../../../util/theme";
import { queryCache, useMutation, useQuery } from "react-query";
import adminAPI from "../../../../../redux/api/adminAPI";
import ToastContext from "../../../../../util/toastContext";
import ThresholdModal from "../../../../../components/ThresholdModal";

export default function AddPermissionDrawer({ show, onClose, roles, categories }) {
  const { register, handleSubmit, errors } = useForm();
  const inputFieldProps = { style: { padding: 0, margin: 0 } };
  const { showAlert } = React.useContext(ToastContext);
  const [selectedRole, setSelectedRole] = React.useState(3);
  const [selectedCategory, setSelectedCategory] = React.useState("45644857-580e-4455-dce3-08d8589f65be");
  const [selectedRoleLowerLimitThreshold, setSelectedRoleLowerLimitThreshold] = React.useState(0);
  const [selectedRoleUpperLimitThreshold, setSelectedRoleUpperLimitThreshold] = React.useState(0);
  const [openModal, setOpenModal] = React.useState(false)
  const [existingCheck, setExistingCheck] = React.useState(false)

  // console.log("upperLimitThreshold ", upperLimitThreshold);


  const [createRoleThreshold, roleThresholdResp] = useMutation(adminAPI.createPermissionThreshold, {
    onSuccess() {
      queryCache.clear('getRolesThreshholds')
      showAlert({
        severity: "success",
        message: "Your threshold has been successfully created",
        durationInMs: 3000,
      });
      onClose();
    },
    onError(error) {
      showAlert({
        severity: "error",
        message: error.message,
        durationInMs: 3000,
      });
      onClose();
    },
  });

  const [updateRoleThreshold, updateRoleThresholdResp] = useMutation(adminAPI.updatePermissionThreshold, {
    onSuccess() {
      queryCache.clear('getRolesThreshholds')
      showAlert({
        severity: "success",
        message: "Your threshold has been successfully updated",
        durationInMs: 3000,
      });
      onClose();
    },
    onError(error) {
      showAlert({
        severity: "error",
        message: error.message,
        durationInMs: 3000,
      });
    },
  });

  const thresholds = useQuery(["rolesThreshold", { selectedRole, selectedCategory }], adminAPI.getRoleThreshold, {
    onSuccess(data) {
      queryCache.cancelQueries("rolesThreshold")
      if (data) {
        setExistingCheck(true)
        setSelectedRoleLowerLimitThreshold(data?.data[0]?.lowerLimitThreshold);
        setSelectedRoleUpperLimitThreshold(data?.data[0]?.upperLimitThreshold);
      }
    },
    onError(data) {
      if (!data.response) {
        setExistingCheck(false)
        setSelectedRoleLowerLimitThreshold(0)
        setSelectedRoleUpperLimitThreshold(0)
      }
    },
  });

  useEffect(() => {
    !!categories && setSelectedCategory(categories[0].id)
  }, [categories])

  const submitHandler = (values) => {
    console.log(thresholds.isSuccess);
    if (thresholds.isSuccess === false) {
      createRoleThreshold(values);
    } else {
      updateRoleThreshold({ lowerLimitThreshold: values.lowerLimitThreshold, upperLimitThreshold: values.upperLimitThreshold, roleId: thresholds?.data?.data[0]?.id });
    }
  };

  const handleOnChange = (e) => {
    setSelectedRole(e);
  };

  const maybeRenderError = (name) => {
    const error = errors[name];
    if (error) return <ErrorContainer>{error.message}</ErrorContainer>;
    else return null;
  };

  const isInvalid = (name) => !!errors[name];

  const handlefilterRoles = (role) => {
    let availaibleRoles = ["Commissioner", "SSG", "Governor", "Executive", "Ministerial Tenders Board", "Accounting Officers", "Parastatals Tenders Board", "Permanent Secretary"];
    if (availaibleRoles.includes(role.title)) {
      return role
    }
  }

  return (
    <React.Fragment>
      <Drawer anchor="right" open={show} onClose={onClose}>
        <DrawerTitle>Set Threshold</DrawerTitle>
        <DrawerForm onSubmit={existingCheck ? (e) => {
          e.preventDefault()
          setOpenModal(true)
        } : handleSubmit(submitHandler)}>
          <DrawerFormRow>
            <Dropdown
              label="Role"
              name="roleId"
              onChange={handleOnChange}
              initialState={selectedRole}
              values={
                roles &&
                [{ title: "Select Role", value: 0 }, ...roles]?.filter(handlefilterRoles)?.map((eachVal) => ({ label: eachVal.title, value: eachVal.id }))
              }
              inputRef={register({
                required: "Please select a role",
                validate: (value) => value > 0 || "Please select a role",
              })}
              {...inputFieldProps}
            />
            {maybeRenderError("roleId")}
          </DrawerFormRow>
          <DrawerFormRow>
            <Dropdown
              label="Category"
              name="categoryId"
              onChange={(e) => setSelectedCategory(e)}
              initialState={selectedCategory}
              values={
                categories &&
                [...categories]?.map((eachVal) => ({ label: eachVal.name, value: eachVal.id }))
              }
              inputRef={register({
                required: "Please select a Category",
              })}
              {...inputFieldProps}
            />
            {maybeRenderError("categoryId")}
          </DrawerFormRow>
          <DrawerFormRow>
            <InputField
              type="number"
              label="Lower Limit Threshold"
              name="lowerLimitThreshold"
              key="lowerLimitThreshold"
              defaultValue={0}
              value={selectedRoleLowerLimitThreshold}
              setValue={setSelectedRoleLowerLimitThreshold}
              error={isInvalid("lowerLimitThreshold")}
              disabled={thresholds.isFetching}
              inputRef={register({
                required: "Please provide a lower limit threshold for this role",
              })}
              inputProps={{
                startAdornment: (
                  <InputAdornment style={{ margin: "16px 0 0" }} position="start">
                    NGN
                  </InputAdornment>
                ),
              }}
              {...inputFieldProps}
            />
            {maybeRenderError("lowerLimitThreshold")}
          </DrawerFormRow>
          <DrawerFormRow>
            <InputField
              type="number"
              label="Upper Limit Threshold"
              name="upperLimitThreshold"
              key="upperLimitThreshold"
              defaultValue={0}
              setValue={setSelectedRoleUpperLimitThreshold}
              value={selectedRoleUpperLimitThreshold}
              error={isInvalid("upperLimitThreshold")}
              disabled={thresholds.isFetching}
              inputRef={register({
                required: "Please provide a upper limit threshold for this role",
                min: {
                  value: selectedRoleLowerLimitThreshold + 1,
                  message: `Upper limit should be greater than lower limit`
                }
              })}
              inputProps={{
                startAdornment: (
                  <InputAdornment style={{ margin: "16px 0 0" }} position="start">
                    NGN
                  </InputAdornment>
                )
              }}
              {...inputFieldProps}
            />
            {maybeRenderError("upperLimitThreshold")}
          </DrawerFormRow>

          <DrawerActions>
            <CancelButton disabled={thresholds.isLoading || roleThresholdResp.isLoading || updateRoleThresholdResp.isLoading} onClick={onClose}>
              Cancel
            </CancelButton>
            <SaveButton
              disabled={roleThresholdResp.isLoading || updateRoleThresholdResp.isLoading || thresholds.isLoading}
              type="submit"
            >
              {roleThresholdResp.isLoading || updateRoleThresholdResp.isLoading || thresholds.isLoading ? "Please wait..." : existingCheck ? 'Update' : "Save"}
            </SaveButton>
          </DrawerActions>
        </DrawerForm>
      </Drawer>
      <ThresholdModal open={openModal} onClose={() => setOpenModal(false)} onAction={handleSubmit(submitHandler)} />
    </React.Fragment>
  );
}
