import styled from "styled-components";
import InputField from "../../InputField";
import { ReactComponent as HamburgerIcon } from "../../../assets/hamburger_icon.svg";
import { ReactComponent as CancelIcon } from "../../../assets/grey_close.svg";

export const FormTitle = styled.h2`
  height: 24px;
  color: #1e2b37;
  font-size: 16px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 24px;
`;

export const IdInputField = styled(InputField)`
  .MuiFilledInput-input {
    box-sizing: border-box;
    height: 49px;
    padding-top: 10.5px;
    padding-bottom: 14.5px;
    color: #637381;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
    border-radius: 3px;
    background-color: #f8f8f9;
  }
`;

export const BottomGroupWrapper = styled.div`
  box-sizing: border-box;
  min-height: 513px;
  border-top: 1px solid #c9d4db;
  border-bottom: 1px solid #c9d4db;
  background-color: #fcfcfd;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 34.5px !important;
`;

export const PrimaryButton = styled.button`
  box-sizing: border-box;
  height: 41px;
  width: 225px;
  border-radius: 3px;
  background-color: #2AA96D;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  border: 1px solid #ebebeb !important;
  margin-top: 30px;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
`;

export const TaskGroupWrapper = styled.div`
  box-sizing: border-box;
  min-height: 200px;
  border-top: 1px solid #c9d4db;
  border-bottom: 1px solid #c9d4db;
  background-color: #fcfcfd;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  margin-bottom: 34.5px !important;
`;

export const TaskWrapper = styled.div`
  box-sizing: border-box;
  height: 63px;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  background-color: #f8f8f9;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  margin-bottom: 15px;
`;

export const MenuIcon = styled(HamburgerIcon)`
  height: 40px;
  width: 30px;
  margin: auto;
  fill: ${({ iconcolor }) => iconcolor};

  &:hover {
    cursor: move;
  }
`;

export const CloseIcon = styled(CancelIcon)`
  height: 20px;
  width: 20px;
  margin: auto;
  fill: ${({ iconcolor }) => iconcolor};
  cursor: pointer;
`;

export const Divider = styled.div`
  width: 7px;
  color: #637381;
  font-size: 20px;
  letter-spacing: 0;
  line-height: 28px;
  display: flex;
  align-items: center;
  margin: 3px;
`;
