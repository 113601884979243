import styled from "styled-components";
import { FormTitle } from "../../../../../../util/theme";
import CloseIcon from "@material-ui/icons/Close";
import { Typography } from "@material-ui/core";

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;

  svg {
    width: auto;
  }

  p {
    color: #212b36;
    font-weight: 600;
    margin: 10px 0 5px;
  }

  span {
    font-size: 17px;
    text-align: center;
  }
`;

export const EmptyTableSubTitle = styled(FormTitle)`
  font-size: 17px !important;
`;

export const ModalCancelButton = styled.div`
  padding: 11px 27px;
  border: 1px solid #8E9FAC;
  border-radius: 3px;
  background-color: #FFFFFF;
  color: #8E9FAC;
  border: 1px solid #8E9FAC;
  cursor: pointer;
`;

export const ModalActionButton = styled.div`
 padding: 11px 27px;
 color: #FFFFFF;
 border: 1px solid #EBEBEB;
 border-radius: 3px;
 background-color: #2AA96D;
 box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15);
 cursor:pointer;
`;

export const ModalBody = styled.div`
 padding: 30px;
 width: 610px;
 border: 1px solid #979797;
 border-radius: 3px;
 background-color: #FFFFFF;
`;

export const TitleText = styled(Typography)`
color: #5F7383;
font-size: 20px !important;
font-weight: bold !important;
letter-spacing: 0;
line-height: 27px !important;
`;

export const Close = styled(CloseIcon)`
cursor: pointer;
`;

export const FormWrapper = styled.div`
 margin: 40px 0 10px;

`;
export const InputWrapper = styled.div`
 margin-right: 20px;

`;


