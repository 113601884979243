import React from "react";
import { ReactComponent as AdministrationIcon } from "../../assets/building.svg";
import { ReactComponent as ProcurementIcon } from "../../assets/speaker.svg";
import { ReactComponent as ContractIcon } from "../../assets/contract.svg";
import { ReactComponent as VendorIcon } from "../../assets/people.svg";
import { ReactComponent as OverviewIcon } from "../../assets/dashboard.svg";
import { ReactComponent as OCDSIcon } from "../../assets/ocds.svg";
// import { ReactComponent as SettingsIcon } from "../../assets/settings.svg";

export const menus = [
  {
    title: "overview",
    to: "/admin/dashboard/overview",
    check: "/admin/dashboard",
    children: [],
    icon: <OverviewIcon />,
  },
  {
    title: "procurement",
    to: "/admin/procurement/plans",
    check: "/admin/procurement",
    children: [],
    icon: <ProcurementIcon />,
  },
  {
    title: "vendors",
    to: "/admin/vendorsList/vendors",
    check: "/admin/vendorsList",
    children: [],
    icon: <VendorIcon />,
  },
  {
    title: "contracts",
    to: "/admin/contractsList/contracts",
    check: "/admin/contractsList",
    children: [],
    icon: <ContractIcon />,
  },
  {
    title: "administration",
    to: "/admin/administration",
    check: "/admin/administration",
    children: [
      { name: "staff", route: "staffs" },
      { name: "MDAs", route: "MDAs" },
      { name: "permissions", route: "permissions" },
      { name: "Settings", route: "settings" }
    ],
    icon: <AdministrationIcon />,
  },
  {
    title: "OCDS",
    to: "/admin/ocdsList/ocds",
    check: "/admin/ocdsList",
    children: [],
    icon: <OCDSIcon />,
  },
  // {
  //   title: "settings",
  //   to: "/admin/settings",
  //   children: ["roles", "users", "checkers"],
  //   icon: <SettingsIcon />,
  // },
];
