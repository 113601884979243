import { Box, Typography } from "@material-ui/core";
import React from "react";
import { BorderFrame, ProjectDivider, ProjectLabel, ProjectProcureTitle } from "../style";
import { CircularProgressbarWithChildren } from "react-circular-progressbar";

const ProjectProgressBar = (
  {
    totalTask = "0",
    CompletedTask = "0",
    pendingTask = "0",
    textTitle = "Total Milestone",
    marginSet = "50px",
    percentageCompleted = 0,
    fullWidth
  }
) => {
  return (
    <Box width={!!fullWidth ? null : "40%"} marginLeft="23px">
      <BorderFrame>
        <Box p="20px">
          <ProjectProcureTitle>Project Progress</ProjectProcureTitle>
          <Box mt={marginSet}>
            <Box p="10px" width="200px" m="auto">
              <CircularProgressbarWithChildren value={parseInt(percentageCompleted)}>
                <Typography style={{ fontSize: "15px" }}>{Math.round(percentageCompleted)}%</Typography>
                <Typography style={{ fontSize: "12px" }}>Completed</Typography>
              </CircularProgressbarWithChildren>
            </Box>
          </Box>
          <Box pb="10px">
            <ProjectLabel>{textTitle}</ProjectLabel>
            <ProjectProcureTitle>{totalTask}</ProjectProcureTitle>
          </Box>
          <ProjectDivider />
          <Box display="flex" pt="11px">
            <Box>
              <ProjectLabel>Completed</ProjectLabel>
              <ProjectProcureTitle textcolor="#2D9CDB">{CompletedTask}</ProjectProcureTitle>
            </Box>
            <Box ml="100px">
              <ProjectLabel>Pending</ProjectLabel>
              <ProjectProcureTitle textcolor="#F2994A">{pendingTask}</ProjectProcureTitle>
            </Box>
          </Box>
        </Box>
      </BorderFrame>
    </Box>
  );
};

export default ProjectProgressBar;
