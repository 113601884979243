/* eslint-disable no-unused-expressions */
import { Box, Grid } from "@material-ui/core";
import moment from "moment";
import React, { useContext, useState } from "react";
import { queryCache, useMutation, useQuery } from "react-query";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { PageContainer, PageTitle } from "../../Common/style";
import {
  BidInfoTitle,
  InfoContainer,
  ProcurementInformationContainer,
  ProcurementInfoTitle,
  ProcurementInfoValue
} from "../BidDetails/BidInfo/style";
import withVendorDashboard from "../HOC/withVendorDashboard";
import {
  ActiveStatus,
  ContentText,
  ContentWrapper,
  InvoiceItemsHeading,
  InvoiceItemsHeadingText,
  InvoiceItemsText,
  InvoiceItemsWrapper,
  LeftWrapper,
  MinistryDetailsWrapper,
  RightWrapper
} from "./style";
import Loader from "../../../components/Common/Loader";
import { InvoiceFilesTitle, InvoiceFilesWrapper } from "../MilestoneDetails/InvoicePreview/style";
import FileDownload from "../../../components/FileDownload";
import GridFileUpload from "../Transactions/TransactionGridFileUpload";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";
import Button from "@material-ui/core/Button";
import ToastContext from "../../../util/toastContext";
import { Skeleton } from "@material-ui/lab";

const InvoiceDetail = () => {
  const { projectId, milestoneId, invoiceId } = useParams();
  const {
    user: { UserId: userId },
  } = useSelector((state) => state.user);

  const [fileNames, setFilenames] = useState([]);

  const projectQuery = useQuery(["project", projectId], vendorsAPI.getProjectDetails);

  // eslint-disable-next-line
  const { data: invoice = {}, isLoading: invoiceIsLoading } = useQuery(
    ["milestoneInvoice", milestoneId],
    vendorsAPI.getMilestoneInvoices
  );

  // eslint-disable-next-line
  const { data: milestoneDetails = {}, isLoading: milestoneIsLoading } = useQuery(
    ["milestoneInvoice", projectId, milestoneId],
    vendorsAPI.getMilestoneDetails
  );

  // eslint-disable-next-line
  const { data: vendorDetails = {}, isLoading: detailsIsLoading } = useQuery(
    ["vendorDetails", userId],
    vendorsAPI.getVendorDetails
  );

  const documents = useQuery(
    ['invoiceProofOfPayment', { invoiceId }],
    vendorsAPI.getInvoiceProofOfPayment
  )

  const { showAlert } = useContext(ToastContext)

  const [uploadDocuments, uploadDocumentsQuery] = useMutation(
    vendorsAPI.uploadInvoiceProofOfPayment,
    {
      onSuccess: () => {
        queryCache.removeQueries(['invoiceProofOfPayment', { invoiceId }])

        showAlert({
          severity: "success",
          message: "Successfully uploaded documents"
        })
      },
      onError: error => {
        showAlert({
          severity: "error",
          message: error.message
        })
      }
    }
  )

  const { projectMileStone } = invoice;

  const BreadcrumbLinks = [
    {
      url: "/vendor/projects",
      title: "Project",
      forwardSlash: true,
    },
    {
      url: `/vendor/projects/${projectId}`,
      title: `${projectQuery.data?.code}`,
      forwardSlash: false,
    },
    {
      url: `/vendor/projects/${projectId}/milestone/${milestoneId}`,
      title: `${milestoneDetails?.title}`,
      forwardSlash: false,
    },

    {
      url: `/vendor/projects/${projectId}/milestone/${milestoneId}/invoice/${invoiceId}`,
      title: `${invoice?.name}`,
      forwardSlash: false,
    },
  ];

  if(invoiceIsLoading || milestoneIsLoading || detailsIsLoading || projectQuery.isLoading){
    return <Loader feedback='Fetching details'/>
  }

  const onClickUploadDocuments = () => {
    uploadDocuments({
      fileNames,
      invoiceId
    })
  }

  return (
    <PageContainer>
      <Box marginBottom="24px">
        <PageTitle>{`${projectMileStone?.milestoneInvoice?.invoiceNumber}`}</PageTitle>
        <Breadcrumb values={BreadcrumbLinks} />
      </Box>

      <InfoContainer>
        <ProcurementInformationContainer style={{ height: "100%" }}>
          <BidInfoTitle>Invoice Details</BidInfoTitle>
          <div>
            <MinistryDetailsWrapper>
              <LeftWrapper>
                <ContentText>Ministry Address</ContentText>
                <ContentText style={{ width: "171px" }}>{vendorDetails?.vendorCorrespondence?.address1}</ContentText>
              </LeftWrapper>

              <RightWrapper>
                <ContentWrapper>
                  <ContentText>Reg no</ContentText>
                  <ContentText>{vendorDetails?.cacRegistrationNumber}</ContentText>
                </ContentWrapper>
                <ContentWrapper>
                  <ContentText>Phone</ContentText>
                  <ContentText>{vendorDetails?.vendorContact?.phoneNumber}</ContentText>
                </ContentWrapper>
                <ContentWrapper>
                  <ContentText>Email</ContentText>
                  <ContentText>{vendorDetails?.vendorContact?.email}</ContentText>
                </ContentWrapper>
              </RightWrapper>
            </MinistryDetailsWrapper>
          </div>
          <Grid container spacing={5}>
            <Grid item xs={3}>
              <ProcurementInfoTitle>Milestone Name</ProcurementInfoTitle>
              <Box>
                <ProcurementInfoValue>{projectMileStone?.title}</ProcurementInfoValue>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <ProcurementInfoTitle>Date Issued</ProcurementInfoTitle>
              <Box>
                <ProcurementInfoValue>{moment(invoice.createdAt).format("DD, MMMM, yyyy") || "N/A"}</ProcurementInfoValue>
              </Box>
            </Grid>
            <Grid item xs={3}>
              <ProcurementInfoTitle>Date Due</ProcurementInfoTitle>
              <ProcurementInfoValue>{moment(invoice.dueDate).format("DD, MMMM, yyyy")}</ProcurementInfoValue>
            </Grid>
            <Grid item xs={3}>
              <ProcurementInfoTitle>Milestone Invoice</ProcurementInfoTitle>
              <Box>
                <ProcurementInfoValue>{projectMileStone?.milestoneInvoice?.invoiceNumber}</ProcurementInfoValue>
              </Box>
            </Grid>
          </Grid>
          <Box marginTop="24px">
            <Grid container spacing={5}>
              <Grid item xs={3}>
                <ProcurementInfoTitle>Description</ProcurementInfoTitle>
                <Box>
                  <ProcurementInfoValue>{invoice.name}</ProcurementInfoValue>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <ProcurementInfoTitle>Invoice Status</ProcurementInfoTitle>
                <ActiveStatus isstatus={"false"} isstatusbg="false">
                  {invoice.paymentStatus}
                </ActiveStatus>
              </Grid>
              <Grid item xs={3}>
                <ProcurementInfoTitle>Approved By</ProcurementInfoTitle>
                <Box>
                  <ProcurementInfoValue>{projectMileStone?.title}</ProcurementInfoValue>
                </Box>
              </Grid>
              <Grid item xs={3}>
                <ProcurementInfoTitle>Payment Date</ProcurementInfoTitle>
                <Box>
                  <ProcurementInfoValue>{moment(invoice.paymentDate).format("DD, MMMM, yyyy") || "N/A"}</ProcurementInfoValue>
                </Box>
              </Grid>
            </Grid>
          </Box>
          <InvoiceFilesWrapper>
            <InvoiceFilesTitle>
              Invoice Supporting Document
            </InvoiceFilesTitle>
            <Box mt={2}>
              <Grid container spacing={2}>
                {
                  invoice?.milestoneInvoiceDocuments?.map(file => (
                    <Grid item xs={6}>
                      <FileDownload
                        name={file.name}
                        size={file?.file.bytes}
                        url={file?.file.url}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </Box>
          </InvoiceFilesWrapper>
          <Box>
            <InvoiceItemsHeading>
              <InvoiceItemsHeadingText>Item</InvoiceItemsHeadingText>
              <InvoiceItemsHeadingText>Amount</InvoiceItemsHeadingText>
            </InvoiceItemsHeading>

            {!milestoneIsLoading &&
            invoice?.projectMileStone?.milestoneTasks?.map((eachTask) => (
              <InvoiceItemsWrapper key={eachTask.id}>
                <InvoiceItemsText>{eachTask.description}</InvoiceItemsText>
                <InvoiceItemsText>{eachTask.estimatedValue}</InvoiceItemsText>
              </InvoiceItemsWrapper>
            ))}
          </Box>
        </ProcurementInformationContainer>
        {
          documents.isLoading ? (
            <Skeleton
              variant='rect'
              height='130px'
              width='100%'
            />
          ) : (
            (documents.data?.length === 0) ? (
              <>
                <Box mt={2.5}>
                  <ProcurementInformationContainer>
                    <BidInfoTitle>Upload Proof of Payment</BidInfoTitle>
                    <GridFileUpload filesNames={fileNames} setFilesNames={setFilenames} multiple />
                  </ProcurementInformationContainer>
                </Box>
                <Box height={50}>
                  <Grid
                    container
                    justify='flex-end'
                    spacing={2}
                  >
                    <Button
                      variant='outlined'
                      disabled={uploadDocumentsQuery.isLoading}
                      component={Link}
                      to={`/vendor/projects/${projectId}/milestone/${milestoneId}`}
                      style={{
                        height: '40px',
                        boxSizing: 'border-box',
                        color: '#8E9FAC',
                        textTransform: 'capitalize'
                      }}
                    >
                      Go Back
                    </Button>
                    <LoadingButton
                      marginLeft='10px'
                      color='#2AA96D'
                      loading={uploadDocumentsQuery.isLoading}
                      onClick={onClickUploadDocuments}
                    >
                      Upload Payment Proof
                    </LoadingButton>
                  </Grid>
                </Box>
              </>
            ) : (
              <InvoiceFilesWrapper>
                <InvoiceFilesTitle>
                  Proof of Payment
                </InvoiceFilesTitle>
                <Box mt={2}>
                  <Grid container spacing={2}>
                    {
                      documents?.data?.map(file => (
                        <Grid item xs={6}>
                          <FileDownload
                            name={file.name}
                            size={file?.file.bytes}
                            url={file?.file.url}
                          />
                        </Grid>
                      ))
                    }
                  </Grid>
                </Box>
              </InvoiceFilesWrapper>
            )
          )
        }
      </InfoContainer>
    </PageContainer>
  );
};

export default withVendorDashboard(InvoiceDetail);
