import React from "react";
import { Box, Tab, makeStyles, Typography } from "@material-ui/core";
import { PageTabs } from "../../../../../../util/theme";

const EvaluationTab = ({ tabs, value, handleChange }) => {
    const classes = useStyles();

    return (
        <Box style={{
            backgroundColor: "rgb(229, 233, 236)",
            border: "1px solid #CDCED9",
            borderBottom: "none"
        }}>
            <Box style={{ marginRight: "16px", marginLeft: "16px" }}>
                <PageTabs
                    value={value}
                    onChange={handleChange}
                >
                    {tabs.map(tab => (
                        <Tab
                            label={<Typography variant="subtitle2">{tab.label}</Typography>}
                            value={tab.value}
                            classes={{ root: classes.root, wrapper: classes.tabWrapper }}
                        />
                    ))}
                </PageTabs>
            </Box>
        </Box>
    );
};

const useStyles = makeStyles({
    tabWrapper: {
        textTransform: "none",
    },
    root: {
        minWidth: "auto",
    },
});


export default (EvaluationTab);
