/* eslint-disable */
import React, { useContext, useEffect, useState } from "react";
import { ContentState, convertToRaw, EditorState } from "draft-js";
import { Box } from "@material-ui/core";
import { useMutation, useQuery } from "react-query";
import { useForm } from "react-hook-form";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { Grid } from "@material-ui/core";
import CircularProgress from "@material-ui/core/CircularProgress";
import addGeneralPlanApi from "../../redux/api/addGeneralPlanApi";
import withAdminDashboard from "../../pages/Admin/HOC/withAdminDashboard";
import {
  GeneralButtonContainer,
  GeneralCardContentWrapper,
  GeneralContentWrapper,
  GeneralEditor,
  GeneralInputField,
  GeneralPlanTab,
  GeneralTabContainer,
  GeneralTitleLabel,
  GeneralTitlePage,
  NextButton,
  PlanCardContainer,
  WordCountText,
} from "./style";
import { CardContentWrapper, PageTabs } from "../../util/theme";
import { CancelButton, ErrorContainer } from "../Forms/Common/style";
import CircularProgressBar from "../CircularProgressBar";
import Breadcrumb from "../../pages/Common/PageElements/Breadcrumb";
import ReviewModeBanner from "../../components/ReviewModeBanner";
import { useRoles } from "../../hooks/useUserDetails";
import { Roles } from "../AccessControl/UserRoles";
import AccessControl from "../../components/AccessControl";
import ReviewCommentsSection from "../../components/ReviewCommentsSection";
import ToastContext from "../../util/toastContext";
import Dropdown from "../DropdownField";
import UploadDocumentTab from "../Common/HOC/withActivityLayout/Document";
import { convertDocumentsToObject } from "../../pages/Common/util";
import { formatCurrency, revertCurrencyValToNum } from "../../util/formatCurrency";

const generateYears = () => {
  let years = [];

  const year = new Date().getFullYear();

  //Generate years between current year + 10 to current year - 10
  Array.from({ length: 20 }).forEach((_, idx) => {
    years = years.concat({
      label: year + 20 - (idx + 1),
    });
  });

  Array.from({ length: 20 }).forEach((_, idx) => {
    years = years.concat({
      label: year - (idx + 1),
    });
  });

  return years;
};

const NoticeInfoContent = ({
  setFormValues,
  formValues,
  onEditorStateChange,
  editorState,
  loading = false,
  isDisabled,
  register,
  errors,
  wordCount = 0,
  editorError,
}) => {
  const marginRight = "0";
  const width = "100%";
  const onRenderError = (error) => <ErrorContainer style={{ paddingLeft: 8 }}>{error && error.message}</ErrorContainer>;



  const updateFormValue = (field) => (event) => {
    if (field === "budget") {

      setFormValues({
        ...formValues,
        [field]: formatCurrency(revertCurrencyValToNum(event.target.value)),
      });
      return;
    }
    setFormValues({
      ...formValues,
      [field]: event.target.value,
    });
  };

  if (loading) {
    return (
      <Box padding={8}>
        <Grid container justify="center">
          <CircularProgress size={40} />
        </Grid>
      </Box>
    );
  }

  return (
    <Box>
      <GeneralTitleLabel>Full Official Text of the Notice</GeneralTitleLabel>
      <Box mb={2} px={1}>
        <GeneralInputField
          label="Title"
          name="procurementTitle"
          style={{
            marginRight,
            width,
          }}
          value={formValues.title}
          onChange={updateFormValue("title")}
          disabled={isDisabled}
          inputRef={register({
            required: "Enter plan title",
          })}
        />
        {onRenderError(errors.procurementTitle)}
      </Box>
      <Box width={230} mb={2.5} px={1}>
        <Dropdown
          name="procurementYear"
          values={generateYears()}
          label={"Procurement Year"}
          initialState={new Date().getFullYear()}
          onChange={(year) => {
            setFormValues({ ...formValues, year });
          }}
          disabled={isDisabled}
          inputRef={register({
            required: "Please choose a procurement year",
          })}
        />
        {onRenderError(errors.procurementYear)}
      </Box>
      <GeneralCardContentWrapper>
        <GeneralEditor
          editorState={editorState}
          toolbarClassName="toolbarClassName"
          wrapperClassName="general-editor-container-style"
          wrapperStyle={{ border: editorError === true && "1px solid red" }}
          editorClassName="general-editor-style"
          onEditorStateChange={onEditorStateChange}
          readOnly={isDisabled}
        />

        <WordCountText>
          {editorError === true ? (
            onRenderError({
              message: "Description is required",
            })
          ) : (
            <> Word count: {`${wordCount}`}</>
          )}
        </WordCountText>
      </GeneralCardContentWrapper>
      <GeneralTitleLabel>Budget Sum</GeneralTitleLabel>
      <GeneralCardContentWrapper>
        <CardContentWrapper>
          <GeneralInputField
            label="Budget Sum (Naira)"
            name="procurementBudget"
            style={{ width }}
            value={(formValues.budget)}
            onChange={updateFormValue("budget")}
            disabled={isDisabled}
            inputRef={register({
              required: "Budget is required",
            })}
          />
        </CardContentWrapper>
        {onRenderError(errors.procurementBudget)}
      </GeneralCardContentWrapper>
      <GeneralTitleLabel>Contact Information</GeneralTitleLabel>
      <GeneralCardContentWrapper>
        <CardContentWrapper>
          <Grid item xs={6}>
            <GeneralInputField
              label="Name"
              name="contactName"
              style={{ width }}
              value={formValues.name}
              onChange={updateFormValue("name")}
              disabled={isDisabled}
              inputRef={register({
                required: "Name is required",
              })}
            />
            {onRenderError(errors.contactName)}
          </Grid>
          <Grid item xs={6}>
            <GeneralInputField
              label="Position"
              name="position"
              style={{
                marginRight,
                width,
              }}
              value={formValues.position}
              onChange={updateFormValue("position")}
              disabled={isDisabled}
              inputRef={register({
                required: "Position is required",
              })}
            />
            {onRenderError(errors.position)}
          </Grid>
        </CardContentWrapper>
        <CardContentWrapper>
          <Grid item xs={6}>
            <GeneralInputField
              label="Phone Number"
              type="number"
              name="phoneNumber"
              style={{ width }}
              value={formValues.phoneNumber}
              onChange={updateFormValue("phoneNumber")}
              disabled={isDisabled}
              inputRef={register({
                required: "Phone number is required",
              })}
            />
            {onRenderError(errors.phoneNumber)}
          </Grid>
          <Grid item xs={6}>
            <GeneralInputField
              label="Email Address"
              type="email"
              name="email"
              style={{
                marginRight,
                width,
              }}
              value={formValues.email}
              onChange={updateFormValue("email")}
              disabled={isDisabled}
              inputRef={register({
                required: "Email address is required",
              })}
            />
            {onRenderError(errors.email)}
          </Grid>
        </CardContentWrapper>
      </GeneralCardContentWrapper>
      <GeneralTitleLabel>Additional Information</GeneralTitleLabel>
      <GeneralCardContentWrapper>
        <Grid item xs={12}>
          <GeneralInputField
            style={{
              marginRight,
              width,
            }}
            name="address"
            label="Address"
            multiline={true}
            value={formValues.address}
            onChange={updateFormValue("address")}
            inputRef={register({
              required: "Address is required",
            })}
            disabled={isDisabled}
          />
          {onRenderError(errors.address)}
        </Grid>
      </GeneralCardContentWrapper>
    </Box>
  );
};

const GeneralPlanLinks = [
  {
    url: "/admin/procurement/plans",
    title: "Home",
    forwardSlash: true,
  },
  {
    url: "#",
    title: "Procurement",
  },
];

const GeneralPlan = () => {
  const [value, setValue] = useState(0);
  const [wordCount, setWordCount] = useState(0);
  const [editorError, setEditorError] = useState(null);
  const [completedForm, setCompletedForm] = useState([]);
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [formValues, setFormValues] = useState({
    name: "",
    title: "",
    year: new Date().getFullYear(),
    position: "",
    budget: "",
    phoneNumber: "",
    email: "",
    address: "",
  });

  const [documents, setDocuments] = useState({
    mandatory: [],
    supporting: [],
    removed: [],
  });

  const history = useHistory();
  const location = useLocation();
  const { showAlert } = useContext(ToastContext);

  const { handleSubmit, register, errors, formState } = useForm();

  useEffect(() => {
    let noticeInfoContentHasNoError = id.toLowerCase() !== "new" ? true : null;
    const noticeInfoInputName = [
      "procurementTitle",
      "procurementYear",
      "procurementBudget",
      "contactName",
      "position",
      "phoneNumber",
      "email",
      "address",
    ];

    noticeInfoInputName.forEach((name) => {
      noticeInfoContentHasNoError = noticeInfoContentHasNoError && !Object.keys(errors).includes(name);
    });

    if (noticeInfoContentHasNoError) {
      if (!completedForm.includes(0)) {
        setCompletedForm([...completedForm, 0]);
      }
    }
    if (!noticeInfoContentHasNoError) {
      if (completedForm.includes(0)) {
        const index = completedForm.findIndex((el) => el === 0);
        const newArray = [...completedForm];
        newArray.splice(index, 1);
        setCompletedForm(newArray);
      }
    }
  }, [formState]);

  useEffect(() => {
    let uploadDocumentsHasNoError = [...documents.mandatory, ...documents.supporting].length > 0;
    if (uploadDocumentsHasNoError) {
      if (!completedForm.includes(1)) {
        setCompletedForm([...completedForm, 1]);
      }
    }
    if (!uploadDocumentsHasNoError) {
      if (completedForm.includes(1)) {
        const index = completedForm.findIndex((el) => el === 1);
        const newArray = [...completedForm];
        newArray.splice(index, 1);
        setCompletedForm(newArray);
      }
    }
  }, [documents]);

  useEffect(() => {
    if (editorError) {
      window.scroll({
        top: 300,
        behavior: "smooth",
      });
    }
  }, [editorError]);

  const { id = "" } = useParams();
  const { role } = useRoles();
  const isDisabled = role !== Roles.PROCUREMENT_OFFICER;
  const isReviewTabDisabled = id.toLowerCase() === "new";

  const planDetailsQuery = useQuery(["generalPlan", id], addGeneralPlanApi.getPlan, {
    enabled: id.toLowerCase() !== "new",
  });

  const planDocumentsQuery = useQuery({
    queryKey: ["getDocuments", id],
    queryFn: addGeneralPlanApi.getPlanDocuments,
    config: {
      enabled: id.toLowerCase() !== "new",
    },
  });

  const [createPlan, createPlanQueryResponse] = useMutation(addGeneralPlanApi.postPlan, {
    throwOnError: true,
    onError: (error) => {
      showAlert({
        severity: "error",
        durationInMs: 10000,
        message: renderError({
          message: error.message,
          errors: error.errors,
        }),
      });
    },
  });

  const renderError = ({ message, errors }) => {
    return (
      <div>
        <div style={{ fontWeight: "bold" }}>{message}</div>
        <div>
          {Object.keys(errors).map((errorPath) => (
            <div style={{ marginTop: 10, fontSize: "0.8em" }} key={errorPath}>
              <span style={{ textTransform: "capitalize" }}>{errorPath}</span> - {errors[errorPath]}
            </div>
          ))}
        </div>
      </div>
    );
  };
  const [editPlan, editPlanQueryResponse] = useMutation(addGeneralPlanApi.editPlan);
  const [uploadDocuments, uploadDocumentsQueryResponse] = useMutation(addGeneralPlanApi.savePlanDocuments, {
    throwOnError: true,
  });

  useEffect(() => {
    if (location && location.state?.documents) {
      handleMoveNext();
    }
  }, [location]);

  useEffect(() => {
    if (planDetailsQuery.isSuccess) {
      setFormValues(planDetailsQuery.data);
      setEditorState(EditorState.createWithContent(ContentState.createFromText(planDetailsQuery.data.description)));
      setWordCount(planDetailsQuery.data.description.length);
    }

    if (planDetailsQuery.isError) {
      showAlert({
        severity: "error",
        message: `Something went wrong while fetching the plan details: ${planDetailsQuery.error}`,
      });
    }
  }, [planDetailsQuery.isSuccess]);

  useEffect(() => {
    if (editPlanQueryResponse.isSuccess) {
      showAlert({
        severity: "success",
        durationInMs: 3000,
        message: "Successfully edited plan",
      });
      handleMoveNext();
    }

    if (editPlanQueryResponse.isError) {
      showAlert({
        severity: "error",
        durationInMs: 3000,
        message: editPlanQueryResponse.error,
      });
    }
  }, [editPlanQueryResponse.isSuccess, editPlanQueryResponse.isError]);

  useEffect(() => {
    if (planDocumentsQuery.isSuccess) {
      setDocuments(convertDocumentsToObject(planDocumentsQuery.data));
    }

    if (planDocumentsQuery.isError) {
      showAlert({
        message: "Something went wrong while fetching documents",
        severity: "error",
        durationInMs: 3000,
      });
    }
  }, [planDocumentsQuery.isSuccess, planDocumentsQuery.isError]);

  const onEditorStateChange = (editorStateEvent) => {
    setEditorError(null);
    setEditorState(editorStateEvent);
    setWordCount(editorStateEvent.getCurrentContent().getPlainText().length);
  };

  const handleMoveNext = () => {
    setValue(Math.min(value + 1, 2));
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleMovePrev = () => {
    setValue(Math.max(0, value - 1));
  };

  const onNext = () => {
    switch (value) {
      case 0: {
        const descriptionBlocks = convertToRaw(editorState.getCurrentContent()).blocks;

        const values = {
          ...formValues,
          description: descriptionBlocks
            .map((block) => {
              return (!block.text.trim() && "\n") || block.text;
            })
            .join("\n"),
        };

        if (id.toLowerCase() === "new") {
          if (wordCount === 0) {
            setEditorError(true);
            return;
          }
          createPlan(values).then(({ id }) => {
            showAlert({
              severity: "success",
              durationInMs: 3000,
              message: "Successfully created plan",
            });

            history.replace(`/admin/procurement/plans/notice/${id}`, {
              documents: true,
            });
          });
        } else {
          editPlan(values);
        }
        break;
      }

      case 1: {
        uploadDocuments({ ...documents, planId: id })
          .then(() => {
            handleMoveNext();
          })
          .catch((err) => {
            showAlert({
              message: err.message,
              severity: "error",
              durationInMs: 3000,
            });
          });
        break;
      }

      default: {
        handleMoveNext();
      }
    }
  };

  const Layout = [
    <NoticeInfoContent
      editorState={editorState}
      formValues={formValues}
      onEditorStateChange={onEditorStateChange}
      wordCount={wordCount}
      setFormValues={setFormValues}
      loading={planDetailsQuery.isLoading}
      isDisabled={isDisabled}
      register={register}
      errors={errors}
      editorError={editorError}
    />,
    <UploadDocumentTab loading={planDocumentsQuery.isLoading} documents={documents} setDocuments={setDocuments} />,
    <ReviewCommentsSection objectId={id} />,
  ];

  return (
    <GeneralContentWrapper>
      <Box marginBottom="35px">
        <GeneralTitlePage>{id.toLowerCase() === "new" ? "Create" : "Edit"} Annual Procurement Plan</GeneralTitlePage>
        <Breadcrumb values={GeneralPlanLinks} />
      </Box>
      {id.toLowerCase() !== "new" && <ReviewModeBanner />}
      <CardContentWrapper>
        <PlanCardContainer>
          <GeneralTabContainer>
            <PageTabs value={value} onChange={handleChange}>
              <GeneralPlanTab label="Notice Info" activetab={(value === 0).toString()} />
              <GeneralPlanTab label="Document" activetab={(value === 1).toString()} />
              <GeneralPlanTab label="Comment" activetab={(value === 2).toString()} disabled={isReviewTabDisabled} />
            </PageTabs>
          </GeneralTabContainer>
          {Layout[value]}
          <GeneralButtonContainer>
            <CardContentWrapper>
              {value ? <CancelButton onClick={() => handleMovePrev()}>Back</CancelButton> : <div />}
              <AccessControl allowedRoles={[Roles.PROCUREMENT_OFFICER]}>
                {value < 2 && (
                  <NextButton
                    onClick={handleSubmit(onNext)}
                    loading={
                      createPlanQueryResponse.isLoading ||
                      editPlanQueryResponse.isLoading ||
                      uploadDocumentsQueryResponse.isLoading
                    }
                  >
                    {value === 1 ? "Upload Documents" : "Save"}
                  </NextButton>
                )}
              </AccessControl>
            </CardContentWrapper>
          </GeneralButtonContainer>
        </PlanCardContainer>
        <CircularProgressBar
          textValues={["Text of Notice", "Contact information"]}
          completedForm={completedForm}
          totalItems={2}
        />
      </CardContentWrapper>
    </GeneralContentWrapper>
  );
};

export default withAdminDashboard(React.memo(GeneralPlan));
