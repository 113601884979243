import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";
import moment from "moment";
import { withStyles } from "@material-ui/core/styles";
import { Box, Grid, CircularProgress, Radio, RadioGroup, FormControlLabel, FormControl } from "@material-ui/core";

import { GeneralCardContentWrapper, GeneralInputField, TitleLabel, AddNewVendorBtn } from "./style";
import { CardContentWrapper } from "../../../../../util/theme";
import DatePicker from "../../../../../components/DatePicker";
import DraftEditor from "../../../../../components/DraftEditor";
import ErrorRenderer from "../../../../../components/ErrorRenderer/ErrorRenderer";
import DocumentUploadField from "../../../../../components/DocumentUploadField";
import SelectedVendor from "./SelectedVendor";
import VendorListDrawer from "./VendorListDrawer";

const CustomRadio = withStyles({
  root: {
    color: "#C4CDD5",
    "&$checked": {
      color: "#2D9CDB",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

const SpecialProcurementNoticeForm = ({
  editorState,
  onEditorStateChange,
  removeFile,
  addFiles,
  files = [],
  loading = false,
  isDisabled,
  register,
  editorError,
  wordCount,
  errors,
  documentFee,
  minutes,
  hours,
  expiryDate,
  control,
  reactHookSetValue,
  tenderDocument = {},
  tenderDetails,
  onChangeDocumentFee,
  setExpiryDate,
  onChangeMinutes,
  onChangeHours,
  selectedVendor,
  activityIsApproved,
  setSelectedVendor,
  showSelectVendorOption,
  setShowSelectVendorOption,
}) => {
  const marginRight = "0";
  const width = "100%";
  const [isVendorListDrawerVisible, setIsVendorListDrawerVisible] = useState(false);

  useEffect(() => {
    reactHookSetValue("submissionDeadline", expiryDate);
    // eslint-disable-next-line
  }, [expiryDate]);

  const handleChange = (event) => {
    setShowSelectVendorOption(event.target.value);
  };

  useEffect(() => {
    if (Object.keys(errors).length > 0) console.log(errors);
  }, [errors]);

  const handleChangeSelectedVendor = (vendor) => {
    const vendorIndex = selectedVendor.findIndex((el) => el.id === vendor.id);
    if (vendorIndex === -1) {
      setSelectedVendor((state) => [
        {
          id: vendor.id,
          companyName: vendor?.vendorProfile?.companyName,
          registrationClass: vendor?.vendorProfile?.registrationPlan.title,
        },
        ...state,
      ]);
    } else {
      const newVendorList = [...selectedVendor];
      newVendorList.splice(vendorIndex, 1);
      setSelectedVendor(newVendorList);
    }
  };

  if (loading) {
    return (
      <Box padding={8}>
        <Grid container justify="center">
          <CircularProgress size={40} />
        </Grid>
      </Box>
    );
  }

  const renderSelectedVendors = () => {
    return (
      <>
        <TitleLabel>{activityIsApproved ? "Selected vendors" : "Select vendors"}</TitleLabel>
        <GeneralCardContentWrapper>
          {!activityIsApproved && (
            <>
              <h6 style={{ color: "#3F5673", fontSize: 14 }}>Manual Selection</h6>
              <FormControl component="fieldset">
                <RadioGroup
                  row
                  aria-label="select-vendors"
                  name="select-vendors"
                  value={showSelectVendorOption}
                  onChange={handleChange}
                >
                  <FormControlLabel
                    style={{ color: showSelectVendorOption === "yes" ? "#2D9CDB" : "#5F7483" }}
                    value="yes"
                    control={<CustomRadio />}
                    label="Yes"
                  />
                  <FormControlLabel
                    style={{ color: showSelectVendorOption === "no" ? "#2D9CDB" : "#5F7483" }}
                    value="no"
                    control={<CustomRadio />}
                    label="No"
                  />
                </RadioGroup>
              </FormControl>
            </>
          )}
          {showSelectVendorOption === "yes" && (
            <>
              <Grid container style={{ marginTop: 20, marginBottom: 20 }}>
                {selectedVendor.map((vendor) => (
                  <SelectedVendor
                    key={vendor.id}
                    vendor={vendor}
                    showCancelBtn={!activityIsApproved}
                    onChange={handleChangeSelectedVendor}
                  />
                ))}
              </Grid>
              {!activityIsApproved && (
                <AddNewVendorBtn onClick={() => setIsVendorListDrawerVisible(true)}>Add New Vendor</AddNewVendorBtn>
              )}
            </>
          )}
          {!!activityIsApproved && selectedVendor.length === 0 && (
            <h6 style={{ color: "#637381", fontSize: 14 }}>No vendor was selected</h6>
          )}
        </GeneralCardContentWrapper>
      </>
    );
  };

  return (
    <Box>
      <TitleLabel>Note</TitleLabel>
      <CardContentWrapper>
        <div style={{ width }}>
          <Controller
            name={"submissionDeadline"}
            control={control}
            render={({ onChange, value }) => (
              <DatePicker
                disabled={isDisabled || activityIsApproved}
                value={value}
                label="Submission Deadline"
                handleChange={onChange}
                style={{ width: "100%", marginTop: 0 }}
              />
            )}
            rules={{ required: true }}
          />
          <ErrorRenderer message={"Please enter a valid submission deadline"} name={"submissionDeadline"} errors={errors} />
        </div>
        <div style={{ width, marginRight }}>
          <GeneralInputField
            label="Hours"
            type="number"
            disabled={isDisabled || activityIsApproved}
            defaultValue={moment(expiryDate).hours() || 0}
            name={"hours"}
            value={hours}
            onChange={(e) => {
              onChangeHours(e.target.value);
            }}
            inputRef={register({ required: true })}
          />
          <ErrorRenderer message={"Please enter hours"} name={"hours"} errors={errors} />
        </div>
        <div style={{ width, marginRight }}>
          <GeneralInputField
            label="Minutes"
            type="number"
            disabled={isDisabled || activityIsApproved}
            value={minutes}
            onChange={(e) => {
              onChangeMinutes(e.target.value);
            }}
            defaultValue={moment(expiryDate).minutes() || 0}
            name={"minutes"}
            inputRef={register({ required: true })}
          />
          <ErrorRenderer message={"Please enter minutes"} name={"minutes"} errors={errors} />
        </div>
      </CardContentWrapper>
      <GeneralCardContentWrapper>
        <DraftEditor
          isDisabled={isDisabled || activityIsApproved}
          editorState={editorState}
          editorError={editorError}
          setEditorState={onEditorStateChange}
        />
      </GeneralCardContentWrapper>

      <TitleLabel>{activityIsApproved ? "Uploaded Document" : "Upload Bidding Documents"}</TitleLabel>
      <GeneralCardContentWrapper>
        <CardContentWrapper>
          <div style={{ width, marginRight }}>
            <DocumentUploadField
              addFiles={addFiles}
              files={files}
              disabled={activityIsApproved}
              removeFile={removeFile}
              uploadSubText="Use the link below to download sample file"
            />
          </div>
        </CardContentWrapper>
      </GeneralCardContentWrapper>

      <TitleLabel>Document Fee</TitleLabel>
      <GeneralCardContentWrapper>
        <CardContentWrapper>
          <GeneralInputField
            style={{
              marginRight,
              width,
              paddingLeft: 0,
              paddingRight: 0
            }}
            value={documentFee}
            onChange={(e) => {
              onChangeDocumentFee(e.target.value);
            }}
            label="Enter Amount"
            defaultValue={documentFee}
            name={"amount"}
            inputRef={register({
              required: true,
              validate: {
                isNumber: (value) => !isNaN(parseFloat(value)) || "Budget should be in Numbers",
              },
            })}
            disabled={isDisabled || activityIsApproved}
          />
        </CardContentWrapper>
        <ErrorRenderer message={"Enter amount in numeric form"} name={"amount"} errors={errors} />
      </GeneralCardContentWrapper>
      {tenderDetails?.tenderingStage?.name === "Single Stage tendering" && renderSelectedVendors()}
      <VendorListDrawer
        onClose={() => setIsVendorListDrawerVisible(false)}
        open={isVendorListDrawerVisible}
        selectedVendor={selectedVendor}
        setSelectedVendor={setSelectedVendor}
        onChange={handleChangeSelectedVendor}
      />
    </Box>
  );
};

export default SpecialProcurementNoticeForm;
