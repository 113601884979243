import React, { useContext, useEffect } from "react";
import { Box, Grid } from "@material-ui/core";
import { queryCache, useMutation, useQuery } from "react-query";
import moment from "moment";

import { useParams } from "react-router-dom";
import { PageContainer, PageTitle } from "../../Common/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { SearchButton } from "../../../components/Forms/Common/style";
import { BidInfoTitle, InfoContainer, ProcurementInformationContainer } from "../BidDetails/BidInfo/style";
import {
  DateGrid,
  MarkCompleteButton,
  SectionDivider,
  SectionLabel,
  TaskDescription,
  TaskTitle,
  UploadImageContainer,
  UnderReviewIcon,
} from "./style";
import EditTaskModal from "./EditModal/index";
import GridFileUpload, { ObjectType, Status } from "./GridFileUpload";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import ToastContext from "../../../util/toastContext";
import withVendorDashboard from "../HOC/withVendorDashboard";
import DoneIcon from "@material-ui/icons/Done";
import { formatCurrency } from "../../../util/formatCurrency";
import { CalendarToday } from "@material-ui/icons";
import Skeleton from "@material-ui/lab/Skeleton";

const TaskDetails = () => {
  const { taskId: id, milestoneId, projectId } = useParams();
  const [open, setOpen] = React.useState(false);
  const { showAlert } = useContext(ToastContext);
  const { data: project } = useQuery(["project", projectId], vendorsAPI.getProjectDetails);
  const { data: milestone } = useQuery(["milestone", projectId, milestoneId], vendorsAPI.getMilestoneDetails);
  const { isLoading, error, status, data: task = {} } = useQuery(["task", milestoneId, id], vendorsAPI.getTaskDetails);
  const [markTaskAsDone, markTaskAsDoneStatus] = useMutation(vendorsAPI.markTaskAsDone, {
    onSuccess: (data) => {
      queryCache.setQueryData(["task", milestoneId, id], data);
    },
  });

  useEffect(() => {
    if (status === "error") {
      showAlert({
        severity: "error",
        message: error.message,
      });
    }
  }, [status, error, showAlert]);

  useEffect(() => {
    if (markTaskAsDoneStatus.status === "error") {
      showAlert({
        severity: "error",
        message: markTaskAsDoneStatus.message,
      });
    } else if (markTaskAsDoneStatus.status === "success") {
      showAlert({
        severity: "success",
        message: "Task has been submitted successflly",
      });
    }
  }, [markTaskAsDoneStatus, showAlert]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleMarkTaskAsDone = () => {
    markTaskAsDone({
      milestoneId,
      taskId: id,
    });
  };

  const BreadcrumbLinks = [
    { url: "/vendor/projects", title: "Project", forwardSlash: true },
    { url: `/vendor/projects/${projectId}`, title: project?.code, forwardSlash: false },
    { url: `/vendor/projects/${projectId}/milestone/${milestoneId}`, title: milestone?.title, forwardSlash: false },
    { url: `/vendor/projects/${projectId}/milestone/${milestoneId}/task/${id}`, title: task?.title, forwardSlash: false },
  ];

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" marginBottom="35px">
        <Box>
          <PageTitle>{project?.code}</PageTitle>
          <Breadcrumb values={BreadcrumbLinks} />
        </Box>
        <SearchButton style={{ width: "114px" }} onClick={handleOpen} disabled={task.status !== "PENDING"}>
          Edit Task
        </SearchButton>
      </Box>
      <InfoContainer>
        <Box mb={2.5}>
          {isLoading ? (
            <Skeleton width="100%" height="270px" variant="rect" />
          ) : (
            <ProcurementInformationContainer>
              <Box>
                <Grid container justify="space-between">
                  <TaskTitle>{task.title}</TaskTitle>
                  {task?.status === "UNDERREVIEW" ? (
                    <UnderReviewIcon>
                      <DoneIcon />
                    </UnderReviewIcon>
                  ) : (
                    <MarkCompleteButton
                      startIcon={<DoneIcon color="#2AA96D" />}
                      loading={markTaskAsDoneStatus.isLoading}
                      color="#DCF6FF"
                      onClick={handleMarkTaskAsDone}
                    >
                      Mark as done
                    </MarkCompleteButton>
                  )}
                </Grid>
              </Box>
              <SectionLabel hasMargin>Tender Description</SectionLabel>
              <TaskDescription>{task.description}</TaskDescription>
              <SectionDivider />
              <SectionLabel>Value</SectionLabel>
              <TaskTitle>{formatCurrency(task.estimatedValue, true)}</TaskTitle>
              <SectionDivider />
              <Grid container>
                <DateGrid>
                  <CalendarToday />
                  <Box ml={1}>
                    <SectionLabel>Start Date</SectionLabel>
                    <TaskDescription>{moment(task.startDate).format("DD MMMM, YYYY")}</TaskDescription>
                  </Box>
                </DateGrid>
                <DateGrid>
                  <CalendarToday />
                  <Box ml={1}>
                    <SectionLabel>End Date</SectionLabel>
                    <TaskDescription>{moment(task.endDate).format("DD MMMM, YYYY")}</TaskDescription>
                  </Box>
                </DateGrid>
              </Grid>
            </ProcurementInformationContainer>
          )}
        </Box>
        <UploadImageContainer>
          <BidInfoTitle>Upload Media</BidInfoTitle>
          <GridFileUpload objectId={id} status={Status.MANDATORY} objectType={ObjectType.VENDOR} />
        </UploadImageContainer>
      </InfoContainer>

      <EditTaskModal open={open} handleClose={handleClose} task={task || {}} />
    </PageContainer>
  );
};

export default withVendorDashboard(TaskDetails);
