import { Divider, Grid } from "@material-ui/core";
import EditOutlinedIcon from "@material-ui/icons/EditOutlined";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import Skeleton from "@material-ui/lab/Skeleton";
import Box from "@material-ui/core/Box";

import { formatCurrency } from "../../../../util/formatCurrency";
import { transformGeneralPlanStatus } from "../../../../util/transforms/generalPlan";
import FullGeneralPlanDetails from "./FullGeneralPlanDetails";
import {
  Accordion,
  AccordionDetails,
  AccordionExpandMoreIcon,
  AccordionSummary,
  BudgetDetailTitle,
  BudgetValue,
  ContractDetail,
  ContractDetails,
  ContractDetailTitle,
  ContractDetailValue,
  EditButton,
  Notice,
  NoticeContent,
  NoticeDivider,
  NoticeInformation,
  NoticeTitle,
  StatusLabelWrapper,
  ViewMoreText,
} from "./style";

const InformationAccordion = ({ hasTenders, isLoading, planDetails }) => {
  const [isOpen, toggleModal] = useState(false);
  const history = useHistory();

  const pickStatus = (status) => {
    switch (status) {
      case "Completed":
        return (
          <StatusLabelWrapper textcolor="#414F3E" bgcolor="#BBE5B3">
            {status}
          </StatusLabelWrapper>
        );
      case "Under Review":
        return (
          <StatusLabelWrapper textcolor="#FF8D26" bgcolor="#FCEBDB">
            {status}
          </StatusLabelWrapper>
        );
      case "In Progress":
        return (
          <StatusLabelWrapper textcolor="#FF8D26" bgcolor="#FCEBDB">
            {status}
          </StatusLabelWrapper>
        );
      default:
        return (
          <StatusLabelWrapper textcolor="#454F5B" bgcolor="#ABB4BC">
            {status}
          </StatusLabelWrapper>
        );
    }
  };

  const editProcurementPlanInformation = (event) => {
    event.stopPropagation();
    history.push(`notice/${planDetails.id}`);
  };

  if (isLoading) {
    return (
      <Box mb={2}>
        <Skeleton variant="rect" width="100%" height="75px" />
      </Box>
    );
  }
  const procurementPlanStatus = transformGeneralPlanStatus(planDetails);
  return (
    <div>
      <Accordion>
        <AccordionSummary expandIcon={<AccordionExpandMoreIcon />} aria-controls="panel1a-content" id="panel1a-header">
          <Grid container alignItems="center">
            {pickStatus(procurementPlanStatus) || "N/A"}
            <NoticeInformation>Procurement Plan Information</NoticeInformation>
          </Grid>
          {!hasTenders && (
            <Grid container justify="flex-end">
              <EditButton aria-label="Edit" onClick={editProcurementPlanInformation} onFocus={(event) => event.stopPropagation()}>
                Edit
                <EditOutlinedIcon style={{ fontSize: "16px", marginLeft: "10px" }} />
              </EditButton>
            </Grid>
          )}
        </AccordionSummary>
        <AccordionDetails>
          <div style={{ width: "100%" }}>
            <Notice>
              <NoticeTitle>Note</NoticeTitle>
              <div style={{ display: "flex", flexDirection: "row" }}>
                <NoticeContent>{planDetails?.description || "N/A"}...</NoticeContent>
                <ViewMoreText onClick={toggleModal}>view more</ViewMoreText>
              </div>
            </Notice>
            <NoticeDivider />
            <Box my={2}>
              <Grid justifyContent="flex-start" container spacing={4}>
                <Grid item>
                  <Box>
                    <BudgetDetailTitle>Budget</BudgetDetailTitle>
                    <BudgetValue>&#8358;{formatCurrency(planDetails?.budget ?? 0)}</BudgetValue>
                  </Box>
                </Grid>
                <Grid item>
                  <Divider orientation="vertical" />
                </Grid>
                <Grid item>
                  <Box>
                    <BudgetDetailTitle>Expenditure</BudgetDetailTitle>
                    <ContractDetailValue>&#8358;{formatCurrency(planDetails?.expenditure ?? 0)}</ContractDetailValue>
                  </Box>
                </Grid>
                <Grid item>
                  <Box>
                    <BudgetDetailTitle>Current Balance</BudgetDetailTitle>
                    <ContractDetailValue>&#8358;{formatCurrency(planDetails?.currentBalance ?? 0)}</ContractDetailValue>
                  </Box>
                </Grid>
              </Grid>
            </Box>
            <NoticeDivider />
            <ContractDetails>
              <Grid container>
                <Grid item xs={12}>
                  <ContractDetailTitle style={{ marginBottom: "20px" }}>Contact Information</ContractDetailTitle>
                </Grid>
                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Title</ContractDetailTitle>
                    <ContractDetailValue>{planDetails?.title || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>

                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Position</ContractDetailTitle>
                    <ContractDetailValue>{planDetails?.name || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>

                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Country</ContractDetailTitle>
                    <ContractDetailValue>{planDetails?.country || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>

                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Phone number</ContractDetailTitle>
                    <ContractDetailValue>{planDetails?.phoneNumber || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>
                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Email address</ContractDetailTitle>
                    <ContractDetailValue>{planDetails?.email || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>

                <Grid item xs={4} />

                <Grid item xs={12}>
                  <ContractDetailTitle style={{ marginTop: "20px", marginBottom: "20px" }}>
                    Additional Information
                  </ContractDetailTitle>
                </Grid>

                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>Fax</ContractDetailTitle>
                    <ContractDetailValue>{planDetails?.fax || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>
                <Grid item xs={4}>
                  <ContractDetail>
                    <ContractDetailTitle>URL</ContractDetailTitle>
                    <ContractDetailValue>{planDetails?.website || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>
                <Grid item xs={4} />

                <Grid item>
                  <ContractDetail>
                    <ContractDetailTitle>Address</ContractDetailTitle>
                    <ContractDetailValue>{planDetails?.address || "N/A"}</ContractDetailValue>
                  </ContractDetail>
                </Grid>
              </Grid>
            </ContractDetails>
          </div>
        </AccordionDetails>
      </Accordion>
      <FullGeneralPlanDetails isOpen={isOpen} toggleModal={toggleModal} />
    </div>
  );
};

export default InformationAccordion;
