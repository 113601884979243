import React from 'react'
import Certificate from './Certificate'
import withAdminDashboard from "../../HOC/withAdminDashboard";
import procurementAPI from '../../../../redux/api/procurementAPI';
import {ContentWrapper} from "./style";
import Loader from "../../../../components/Common/Loader";
import {PageContainer, PageTitle, UpdateButton, BreadCrumbWrapper } from './style';
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import { useQuery } from 'react-query';
import Pdf from "react-to-pdf";

function CertificatePage() {
  const { procurementId } = useParams();
  const ref = React.createRef();


  const { data = {}, isLoading, isSuccess } = useQuery({
    queryKey: ["generalPlanDetails", procurementId],
    queryFn: procurementAPI.getPlanDetails,
    config: {
      enabled: !!procurementId, 
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100,
    },
  });

  if (isLoading) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }

  return (
    <PageContainer>
      <PageTitle>{data?.name}</PageTitle>
      <BreadCrumbWrapper>
        <Breadcrumbs>
          <Link to={"/admin/dashboard/overview"}>Home</Link>
          <Link to={"/admin/procurement/plans"}>Plans</Link>
          <Link to={`/admin/procurement/certificate-of-no-objection/${procurementId}`}>Certificate of No Objection</Link>
        </Breadcrumbs>
        <Pdf targetRef={ref} filename="certificate-of-no-objection.pdf">
        {({ toPdf }) => <UpdateButton onClick={toPdf}>Generate Certificate</UpdateButton>}
        </Pdf>
        
      </BreadCrumbWrapper>
      <div style={{width: '70%'}}>
      <Certificate CertRef={ref}/>
      </div>
    </PageContainer>
  )
}

export default withAdminDashboard(CertificatePage);