import styled from "styled-components";
import Typography from "@material-ui/core/Typography";

import { TitleContainer } from "../../Admin/Procurement/style";
import { SaveButton } from "../../../components/Forms/Common/style";
import { StyledTableRow } from "../../../components/SearchTable";

export const TenderSubHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  align-items: flex-end;
`;

export const TenderStyledTableRow = styled(StyledTableRow)`
  background-color: #fff !important;
`;

export const TenderText = styled(Typography)`
  color: #637381 !important;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px !important;
`;

export const TenderContainer = styled(TitleContainer)`
  margin-bottom: 5px;
`;

export const BidButton = styled(SaveButton)`
  box-sizing: border-box;
  height: 41px;
  width: 74px;
  border: 1px solid #EBEBEB;
  border-radius: 3px;
  background-color: #2AA96D;
  box-shadow: 0 1px 3px 0 rgba(0,0,0,0.15)
`;

export const TenderInfoContainer = styled.div`
  border-radius: 3px;
  background-color: #FFFFFF;
  box-shadow: 0 2px 4px 0 #CBD4DA;
  padding: 24px;
  margin-bottom: 30px;
`;

export const TenderInfoTitle = styled(Typography)`
  color: #637381;
  font-size: 13px !important;
  letter-spacing: 0;
  line-height: 16px !important;
`;

export const TenderInfoLabel = styled(Typography)`
  color: #637381;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 20px !important;
`;
