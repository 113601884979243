import { makeStyles } from "@material-ui/styles";
import moment from "moment";
import React from "react";
import { useHistory } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

import EmptyTableBody from "../../../../components/EmptyTableBody";
import { StyledTableCell } from "../../../../components/SearchTable";
import { StyledTableRow, theme } from "../../../../util/theme";
import { EmptyTableSubTitle } from "./style";

const useStyles = makeStyles({
  contractNo: {
    fontSize: 14,
  },
  ocid: {
    fontSize: 14,
    color: `${theme.color.primary}`,
    fontWeight: 600
  },
  stickyCell: {
    position: "sticky",
    left: 0,
    width: 200,
    backgroundColor: "white",
    paddingRight: 0,
    display: "flex",
    alignItems: "center",
    borderRight: "2px solid #E4E9EC"
  }
});

const textOverFlowStyle = { width: 200, textOverflow: "ellipsis", overflow: "hidden", whiteSpace: "nowrap" };

const CustomContractTable = ({ contracts = [], urlLink }) => {
  const classes = useStyles();
  const history = useHistory();

  return contracts.length ? (
    contracts.map((eachContract) => {
      const link = !!urlLink ? `${urlLink}/${eachContract.id}` : `/admin/contracts/${eachContract.id}`;
      return (
        <StyledTableRow key={eachContract.id} onClick={() => history.push(link)} style={{ backgroundColor: "#fff" }}>
          <StyledTableCell className={classes.stickyCell}>
            <p style={{ width: 200, }} className={classes.ocid}>
              {eachContract?.referenceId || "N/A"}
            </p>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.title || "N/A"} aria-label="add">
              <p className={classes.contractNo || "N/A"} style={textOverFlowStyle}>
                {eachContract.title}
              </p>
            </Tooltip>
          </StyledTableCell>
          {/* <StyledTableCell>
            <Tooltip title={eachContract.budgetDescription} aria-label="add">
              <p style={textOverFlowStyle}>{eachContract.budgetDescription || "N/A"}</p>
            </Tooltip>
          </StyledTableCell> */}
          <StyledTableCell>
            <Tooltip title={eachContract.ministry || ""} aria-label="add">
              <p style={textOverFlowStyle}> {eachContract?.ministryName || "N/A"}</p>
            </Tooltip>
          </StyledTableCell>
          <StyledTableCell>
            <p style={{ width: 100 }}> {eachContract?.categoryName || "N/A"}</p>
          </StyledTableCell>
          <StyledTableCell>
            <Tooltip title={eachContract.vendor || ""} aria-label="add">
              <p style={textOverFlowStyle}> {eachContract?.vendor || "N/A"}</p>
            </Tooltip>
          </StyledTableCell>
          {/* <StyledTableCell>
            <p style={{ width: 100 }}> {eachContract.startDate ? moment(eachContract.startDate).format("DD MMM, YYYY") : "N/A"}</p>
          </StyledTableCell> */}
          <StyledTableCell>
            <p style={{ width: 100 }}> {eachContract.awardedDate ? moment(eachContract.awardedDate).format("DD MMM, YYYY") : "N/A"}</p>
          </StyledTableCell>
          <StyledTableCell align="right">&#8358;{eachContract?.estimatedValue?.toLocaleString() || "N/A"}</StyledTableCell>
        </StyledTableRow>
      );
    })
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>There are currently no contracts</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default CustomContractTable;
