import React, { useContext } from "react";
import Box from "@material-ui/core/Box";
import { FormSectionTitle, FormSectionContent, TabActions, TabNextButton, ApproveButton } from "./style";
import FormContext from "../../../../../../../util/context";
import DocumentUploadField from "../../../../../../../components/DocumentUploadField";
import { Roles } from "../../../../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../../../../hooks/useUserDetails";

function DocumentUploadForm({ handleApproval, isLoadingForApproval }) {
  const {
    state: { documents },
    addDocuments,
    removeDocument,
    nextTab,
  } = useContext(FormContext);
  const disabledState = documents.mandatory.length === 0 || isLoadingForApproval;

  //Access control config
  const { role } = useRoles();
  const isMaker = role === Roles.PROCUREMENT_OFFICER;
  const isChecker = [Roles.COMMISSIONER, Roles.PERMANENT_SECRETARY].includes(role);

  const addFiles = (documentType) => (newDocuments) => {
    addDocuments(documentType, newDocuments);
  };

  const removeFile = (documentType) => (document) => {
    removeDocument(documentType, document);
  };

  const renderActionButtonByRoles = () => {
    if (isMaker) {
      return (
        <TabNextButton disabled={documents.mandatory.length === 0} onClick={nextTab}>
          Next
        </TabNextButton>
      )
    };
    if (isChecker) {
      return (
        <Box style={{ display: "flex", justifyContent: "space-between" }}>
          <TabNextButton disabled={disabledState} onClick={nextTab} >
            Next
          </TabNextButton>
          <ApproveButton disabled={disabledState} onClick={handleApproval} loading={isLoadingForApproval}  >
            Approve
          </ApproveButton>
        </Box>
      )
    }
  }

  return (
    <React.Fragment>
      <FormSectionTitle>Mandatory Documents</FormSectionTitle>
      <FormSectionContent>
        <DocumentUploadField files={documents.mandatory} addFiles={addFiles("mandatory")} removeFile={removeFile("mandatory")} />
      </FormSectionContent>
      <FormSectionTitle>Supporting Documents</FormSectionTitle>
      <FormSectionContent>
        <DocumentUploadField
          files={documents.supporting}
          addFiles={addFiles("supporting")}
          removeFile={removeFile("supporting")}
        />
      </FormSectionContent>
      <TabActions>
        <div aria-hidden={true} />
        {renderActionButtonByRoles()}
      </TabActions>
    </React.Fragment>
  );
}

export default React.memo(DocumentUploadForm);
