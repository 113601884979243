import styled from "styled-components";
import { Typography } from "@material-ui/core";
import { EmptyStyleCell } from "../../Common/style";
import { SearchButton } from "../../../components/Forms/Common/style";

export const TableTextStyle = styled(Typography)`
  color: #5F7383;
  font-size: 16px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 22px !important;
`;

export const TableSubTextStyle = styled(Typography)`
  color: #5F7383;
  font-size: 16px !important;
  letter-spacing: 0;
  line-height: 22px !important;
  text-align: center !important;
`;

export const ProjectEmptyTableCell = styled(EmptyStyleCell)`
  height: 579px !important;
`;

export const ProjectSearchButton = styled(SearchButton)`
  width: 151px !important;
  margin-top: 0 !important;
`;

export const CellStatus = styled(Typography)`
  border: 2px solid transparent;
  border-radius: 100px;
  background-color: ${({ flagcolor }) => (flagcolor === "true" ? "rgba(0,176,241,0.2);" : "#D6EFD2")};
  color: ${({ flagcolor }) => (flagcolor === "true" ? "#2AA96D" : "#118043")};
  padding: 1px;
  text-align: center;
`;

export const TagWrapper = styled.div`
  width: 100%;
  
  span {
    width: auto !important;
  }
`;
