export const team = [
    {
        id: 1,
        name: "Aremu Abosede Deborah",
        position: "Commissioner for Social Development",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236692/E-Procurement/Pictures/Aremu-Abosede-Deborah.-Commissioner-for-Social-Development-01_1_amkrue.png"
    },
    {
        id: 2,
        name: "Joana-Nnazua-Kolo",
        position: "Commissioner for Youth",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236689/E-Procurement/Pictures/Joana-Nnazua-Kolo-Commissioner-for-Youths-_-Sport-01_tzh5bz.png"
    },
    {
        id: 3,
        name: "Murtala-Olanrewaju",
        position: "Commissioner for Communications",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236689/E-Procurement/Pictures/Murtala-Olanrewaju.jpg-_-Commissioner-for-Communications_-01_dozoa8.png"
    },
    {
        id: 4,
        name: "Arinola-Fatimoh-Lawa",
        position: "Commissioner for Water Resources",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236689/E-Procurement/Pictures/Arinola-Fatimoh-Lawal.-Commissioner-for-Water-Resources-JPG-01_v1zyny.png"
    },
    {
        id: 5,
        name: "Ayinla-Kora-Sabi",
        position: "Commissioner for Energy",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236689/E-Procurement/Pictures/Ayinla-Kora-Sabi-Commissioner-for-Energy-01_h3kze0.png"
    },
    {
        id: 6,
        name: "Funke-Oyedun",
        position: "Commissioner for Special Duties",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236688/E-Procurement/Pictures/Funke-Oyedun.jpg-_-Commissioner-for-Special-Duties_-01_efzr3v.png"
    },
    {
        id: 7,
        name: "Oyeyemi-Florence",
        position: "Commissioner for Finance",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236687/E-Procurement/Pictures/Oyeyemi-Florence-Commissioner-for-Finance-01_mwpur8.png"
    },
    {
        id: 8,
        name: "Suleiman Rotimi",
        position: "Commissioner for Work",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236687/E-Procurement/Pictures/Suleiman-Rotimi-Iliasu.jpg-_-Commissioner-for-Work_-01_egmvjn.png"
    },
    {
        id: 9,
        name: "Sa_adatu Modibbo Kawu",
        position: "Commissioner for Tertiary Education",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236687/E-Procurement/Pictures/Sa_adatu-Modibbo-Kawu-_-Commissioner-for-Tertiary-Education-01_eiwdpg.png"
    },
    {
        id: 10,
        name: "Agbaje Wahab Femi",
        position: "Commissioner for Enterprise",
        image: "https://res.cloudinary.com/prunedge/image/upload/v1609236686/E-Procurement/Pictures/Agbaje-Wahab-Femi-Commissioner-for-Enterprise.JPG-01_jmmkat.png"
    },
      
]