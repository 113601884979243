import React, { useEffect, useState } from "react";
import { ContentState, convertFromHTML, convertToRaw, EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Box, IconButton, Switch, FormControlLabel, Grid, Collapse, Typography } from "@material-ui/core";
import { Edit as EditIcon } from "@material-ui/icons";
import { makeStyles } from '@material-ui/core/styles';

import {
  ActionButton,
  AddNewDocumentButton,
  ButtonsRow,
  Editor,
  RequiredDocumentGrid,
  RequiredDocuments,
  SectionTitle,
  SectionWrapper,
} from "../style";
import DraftEditor from "../../../../../../components/DraftEditor";
import InputField from "../../../../../../components/InputField";
import { ReactComponent as CancelIcon } from "../../../../../../assets/cancel.svg";
import ControlledDatePicker from "../../../../../../components/ControlledDatePicker";

const useStyles = makeStyles({
  root: {
    [`& .MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track`]: {
      backgroundColor: "#777"
    }
  },
  thumb: {
    backgroundColor: props => props.checked ? "#2AA96D" : "#777",
  },
});

const RequiredDocument = ({
  title,
  setTitle,
  description,
  isCompulsory,
  setDescription,
  activityIsApproved,
  onDelete,
  setIsCompulsory,
  isActive,
  onClickEdit,
  isCurrentEdit,
  activeEditIndex
}) => {
  const classes = useStyles({ checked: isCompulsory });
  const [showInputFields, setShowInputFields] = useState(true);

  useEffect(() => {
    if (!isActive) {
      setShowInputFields(false);
    }
  }, [isActive]);

  return (
    <RequiredDocumentGrid>

      <Grid container >
        <Grid item style={{ flex: 1 }}>
          <Collapse in={(!isActive)}>
            <Box mr={2}>

              <Box bgcolor="rgba(0,0,0, .05)" mb={showInputFields || isCurrentEdit ? 2 : 0} p={2} style={{ borderRadius: 3, minHeight: 50 }}>
                <Grid container >
                  <Grid item style={{ flex: 1 }}>
                    <Typography variant="subtitle2" style={{ fontWeight: "bold" }}>
                      {title}
                    </Typography>
                    <Box mt={1}>
                      <Typography variant="subtitle2" style={{ maxHeight: 100 }}>
                        {description}
                      </Typography>
                    </Box>
                  </Grid>
                  <IconButton onClick={onClickEdit} className="iconButton">
                    <EditIcon fill="#EB5757" style={{ fontSize: 12 }} />
                  </IconButton>
                </Grid>
              </Box>
            </Box>
          </Collapse>
          <Collapse in={(isActive || isCurrentEdit)}>
            <Box mr={2}>
              <Box mb={2}>
                <InputField disabled={activityIsApproved} value={title} setValue={setTitle} label="Document Title" style={{ padding: 0 }} />
              </Box>
              <Box mb={2}>
                <InputField disabled={activityIsApproved} value={description} setValue={setDescription} label="Description" style={{ padding: 0 }} />
              </Box>
              <FormControlLabel
                control={<Switch classes={{
                  root: classes.root,
                  thumb: classes.thumb,
                }} checked={isCompulsory} disabled={activityIsApproved} onChange={event => {
                  setIsCompulsory(event.target.checked)
                }} name="isCompulsory" />}
                label="Is document required?"
              />
            </Box>
          </Collapse>
        </Grid>
        {!activityIsApproved && (

          <IconButton onClick={onDelete} className="iconButton">
            <CancelIcon fill="#EB5757" width={12} height={12} />
          </IconButton>
        )}
      </Grid>
    </RequiredDocumentGrid>
  )
};

const Requirements = ({ saveState, activityIsApproved, onNext, initialState = {} }) => {
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [activeEditIndex, setActiveEditIndex] = useState(null);

  const [documents, setDocuments] = useState([
    {
      title: "",
      description: "",
      isCompulsory: false
    },
  ]);

  const [deadline, setDeadline] = useState(null);

  useEffect(() => {
    if (initialState?.requiredDocuments) {
      setDocuments(initialState.requiredDocuments);
    }

    if (initialState?.deadline) {
      setDeadline(initialState?.deadline);
    }

    if (initialState.editorState) {
      const blocks = convertFromHTML(initialState.editorState);
      const state = ContentState.createFromBlockArray(blocks.contentBlocks, blocks.entityMap);

      setEditorState(EditorState.createWithContent(state));
    }
  }, [initialState]);

  const updateDocument = (index, key) => (value) => {
    const newDocuments = [...documents];

    newDocuments[index][key] = value;

    setDocuments(newDocuments);
  };

  const onDeleteDocument = (index) => () => {
    setDocuments(documents.filter((_, idx) => idx !== index));
  };

  const addDocument = () => {
    setActiveEditIndex(null);
    setDocuments(
      documents.concat({
        title: "",
        description: "",
        isCompulsory: false
      })
    );
  };

  const onClickNext = () => {
    const editorStateHTML = draftToHtml(convertToRaw(editorState.getCurrentContent()));
    saveState({ requiredDocuments: documents, editorState: editorStateHTML, deadline });
    onNext();
  };

  return (
    <>
      <SectionTitle>Note</SectionTitle>
      <SectionWrapper>
        <Editor>
          <DraftEditor editorState={editorState} setEditorState={setEditorState} isDisabled={activityIsApproved} />
        </Editor>
      </SectionWrapper>

      <SectionTitle>Required Documents</SectionTitle>
      <SectionWrapper>
        <Box mb={3}>
          <ControlledDatePicker
            disabled={activityIsApproved}
            label="Deadline"
            value={deadline}
            onChange={setDeadline}
            style={{ margin: 0 }}
          />
        </Box>
        <RequiredDocuments>
          {documents.map(({ title, description, isCompulsory }, index) => (
            <RequiredDocument
              title={title}
              isActive={index + 1 === documents.length}
              activityIsApproved={activityIsApproved}
              setTitle={updateDocument(index, "title")}
              description={description}
              isCompulsory={isCompulsory}
              activeEditIndex={activeEditIndex}
              onClickEdit={() => setActiveEditIndex(index)}
              isCurrentEdit={activeEditIndex === index}
              setDescription={updateDocument(index, "description")}
              setIsCompulsory={updateDocument(index, "isCompulsory")}
              onDelete={onDeleteDocument(index)}
            />
          ))}
        </RequiredDocuments>
        {!activityIsApproved && <AddNewDocumentButton style={{ margin: 0, marginTop: 20 }} onClick={addDocument}>Add New Document</AddNewDocumentButton>}
      </SectionWrapper>
      <ButtonsRow>
        <div />
        <ActionButton disabled={activityIsApproved} variant="contained" onClick={onClickNext}>
          Next
        </ActionButton>
      </ButtonsRow>
    </>
  );
};

export default Requirements;
