import styled from "styled-components";
import { theme } from "../../util/theme";


export const CardContainer = styled.div`
display: flex;
align-items: center;
flex-wrap: wrap;
> div:not(:first-child) {
    margin-left: 24px;
  }
`

export const Card = styled.div`
border-radius: 8px;
min-width: 117px;
border: ${(props) => `1px solid ${props.color.border || '#CBD4DA'}`};
background: ${(props) => `${props.color.bg || '#FFF'}`};
color: ${(props) => `${props.color.text || '#CBD4DA'}`};
height: 78px;
padding: 24px;

div.title {
 font-size: 14px;
 font-weight: 600;
 margin-bottom: 10px;
}

div.stat {
 font-size: 26px;
 font-weight: 600;
}

div.subTitle {
 font-size: 12px;
 color: ${theme.color.textColor1};
}


`;