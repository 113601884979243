import baseAxiosMethod from "./baseAxiosMethod";
import { handleAxiosError } from "./handleApiError";

const getNotifications = async (_, { classId, pageNumber, pageSize, isRead = null }) => {
  try {
    let params = new URLSearchParams();
    params.append("pageNumber", pageNumber);
    params.append("pageSize", pageSize);
    params.append("Read", isRead);
    // classId ? classId.forEach(id => params.append("Class", id)) : params.append("Class", null)

    const response = await baseAxiosMethod('notifications', {
      params
    })

    return {
      notifications: response.data?.data,
      pagination: response?.data.meta?.pagination
    }
  } catch (e) {
    return new Error(handleAxiosError(e))
  }
}

const markAsRead = async (notifications) => {
  try {
    await baseAxiosMethod.patch('notifications/read', notifications)
  } catch (e) {
    return new Error(handleAxiosError(e))
  }
}

const notificationsApi = {
  getNotifications,
  markAsRead
}

export default notificationsApi

