import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import { theme } from "../../util/theme";

export const Wrapper = styled.div`
  margin-top: 20px;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #cbd4da;
`;

export const Header = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  background-color: #e5e9ec;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  align-items: center;
  color: ${theme.color.secondary};
  padding-right: 20px;
  justify-content: space-between;

  .MuiTypography-body1 {
    font-size: 12px;
    font-weight: 600;
  }
`;
export const AdvanceText = styled(Typography)`
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 12px !important;
  text-decoration: underline;
  color: ${theme.color.primary};
  cursor: pointer;
`;

export const Filters = styled.div`
  padding: ${({ noPadding }) => (noPadding === true ? 0 : 10)}px;
  display: flex;
  @media screen and (max-width: 760px) {
    flex-direction: column;
  }
`;

export const FilterTags = styled.div`
  display: flex;
  margin: 12px 0 0 10px;

  > div {
    margin-right: 12px;
  }

  .MuiChip-colorPrimary {
    height: 20px;
    border-radius: 3px;
    background-color: #dff5fc;
    color: #42526e;
  }

  .MuiChip-deleteIconColorPrimary {
    color: #42526e;
    width: 12px;
    margin-left: 5px;
  }

  .MuiChip-label {
    font-size: 14px;
  }
`;
