import React, { useContext } from "react";
import { Drawer } from "@material-ui/core";
import Box from "@material-ui/core/Box";
import { DrawerAmountText, DrawerHeader } from "./style";
import Grid from "@material-ui/core/Grid";
import { ModdedAvatar, TableCellEmail } from "../../ReceiptOfResponse/VendorsTable/style";
import FileProgress from "../../../../../../components/FileProgress/FileProgress";
import { CardLabel } from "../../../../PlanDetails/style";
import Button from "@material-ui/core/Button";
import LoadingButton from "../../../../../../components/LoadingButton/LoadingButton";
import { openFileUrlInNewWindow } from "../../../../../Common/util";
import { formatCurrency } from "../../../../../../util/formatCurrency";
import { useMutation } from "react-query";
import receiptOfBiddersResponseApi from "../../../../../../redux/api/receiptOfBiddersResponseApi";
import ToastContext from "../../../../../../util/toastContext";

const PaymentDrawer = ({ name, email, url, amount, open, handleClose, id, status, handleApprove }) => {
  const [approve, approveQuery] = useMutation(receiptOfBiddersResponseApi.approvePayment, {
    throwOnError: true
  })

  const { showAlert } = useContext(ToastContext);
  const hasBeenVerified = status === "APPROVED";
  

  const onClickApprove = async () => {
    try{
      await approve({id})

      showAlert({
        message: "Successfully approved payment",
        severity: "success"
      })

      handleApprove()
    }catch (e) {
      showAlert({
        message: e.message,
        severity: "error"
      })
    }
  }

  return (
    <Drawer
      anchor='right'
      open={open}
      onClose={handleClose}
    >
      <Box p={3.2} height='100%' minWidth='400px' display='flex'>
        <Grid container direction='column'>
          <DrawerHeader>
            Payment Receipt
          </DrawerHeader>
          <Box mb={3.75}>
            <Grid spacing={1} container wrap='nowrap'>
              <Box mr={2}>
                <ModdedAvatar size={34} color='#50B83C'>
                  {name?.slice(0, 2)}
                </ModdedAvatar>
              </Box>
              <Grid
                container
                direction='column'
                spacing={1}
              >
                  <span>
                    {name}
                  </span>
                <TableCellEmail>
                  {email}
                </TableCellEmail>
              </Grid>
            </Grid>
          </Box>
          <Box mb={3.75} maxWidth='330px'>
            <FileProgress
              progress={100}
              fileName={name}
              fileSize={null}
              hasEyes
              onClickEyes={() => openFileUrlInNewWindow(url)}
            />
          </Box>
          <Box mb='auto'>
            <CardLabel>
              Amount to pay
            </CardLabel>
            <DrawerAmountText>
              {formatCurrency(amount, true, true)}
            </DrawerAmountText>
          </Box>
          <Grid
            container
            justify='flex-end'
          >
            <Button variant='outlined' onClick={handleClose}>
              Cancel
            </Button>
            <LoadingButton
              color='#2AA96D'
              marginLeft='20px'
              onClick={onClickApprove}
              loading={approveQuery.isLoading}
              disabled ={hasBeenVerified}
            >
              {hasBeenVerified ? "Verified" : "Verify Payment"}
            </LoadingButton>
          </Grid>
        </Grid>
      </Box>
    </Drawer>
  );
};

export default PaymentDrawer;
