import React from "react";
import Grid from "@material-ui/core/Grid";
import Container from "@material-ui/core/Container";
import { Navbar } from "../../../components";
import { FormSectionWrapper, ProgressSectionWrapper, Wrapper } from "../Authenticated/Registration/style";
import FormHomepage from "../../../components/Forms/FormHomepage";
import { DocumentWrapper, FolderIcon, Text, CardIcon, ForwardIcon, WrapContent, WrapPaymentContent, InfoIconStyle } from "./style";
import { LandingNavbarStatic } from "../../../components/HomepageHeader/style";

const SignUp = () => {
  const HomeFormExtras = () => (
    <>
      <a
        href="https://res.cloudinary.com/prunedge/image/upload/v1631796002/E-Procurement/Kwara%20State/Registration_Requirement_for_Vendors_xswo5r.pdf"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <DocumentWrapper>
          <WrapContent>
            <InfoIconStyle />
            <Text style={{ marginLeft: 20 }}>Registration Requirement </Text>
          </WrapContent>
          <ForwardIcon iconcolor="#9c69de" />
        </DocumentWrapper>
      </a>
      <a
        href="https://res.cloudinary.com/prunedge/image/upload/v1624026583/E-Procurement/Kwara%20State/Kwara_State_eprocurement_Product_Manual-_Vendors_lcm0cu.pdf"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "inherit", textDecoration: "none" }}
      >
        <DocumentWrapper>
          <WrapContent>
            <FolderIcon />
            <Text style={{ marginLeft: 20 }}> Download User Guide </Text>
          </WrapContent>
          <ForwardIcon iconcolor="#9c69de" />
        </DocumentWrapper>
      </a>
      <DocumentWrapper>
        <WrapPaymentContent>
          <CardIcon />
          <Text style={{ marginLeft: 20 }}> Payment Details </Text>
        </WrapPaymentContent>
        <ForwardIcon iconcolor="#eec200" />
      </DocumentWrapper>
    </>
  );

  return (
    <>
      <div style={{ height: 90 }}>
        <LandingNavbarStatic>
          <Navbar />
        </LandingNavbarStatic>
      </div>
      <Container>
        <Wrapper>
          <Grid container justify="space-between">
            <Grid item xs={6} style={{ position: "relative" }}>
              <FormSectionWrapper>
                <FormHomepage />
              </FormSectionWrapper>
            </Grid>
            <Grid item xs={4}>
              <ProgressSectionWrapper>
                <HomeFormExtras />
              </ProgressSectionWrapper>
            </Grid>
          </Grid>
        </Wrapper>
      </Container>
    </>
  );
};

export default SignUp;
