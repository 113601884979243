import React from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import ClearIcon from "@material-ui/icons/Clear";
import Grid from "@material-ui/core/Grid";
import { Box, Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import PropTypes from "prop-types";
import Skeleton from "@material-ui/lab/Skeleton";
import {
  DropzoneArea,
  UploadDocumentContainer,
  UploadDocumentContent,
  UploadingContainer,
  UploadingDocumentContainer,
  UploadingDocumentLoadInfo,
  UploadingDocumentName,
  Wrapper
} from "./style";
import fileIcon from "../../assets/file.svg";
import { Roles } from "../AccessControl/UserRoles";
import { useRoles } from "../../hooks/useUserDetails";
import { toHumanReadableFileSize } from "../../util/toHumanReadableFileSize";
import { MaxFileSize } from "../../util/constants";

const UploadedDoc = ({ fileName, fileSize, removeFile, readOnly, error = false, downloadUrl }) => {
  const onDownloadFile = () => {
    if (downloadUrl) {
      const a = document.createElement("a");
      a.href = downloadUrl;
      a.target = "_blank";
      a.rel = "noreferrer noopener";
      a.download = fileName;
      document.body.append(a);
      a.click();
      a.remove();
    }
  };

  return (
    <UploadDocumentContainer error={error}>
      <UploadDocumentContent>
        <UploadingContainer>
          <img
            src={fileIcon}
            alt={"File Icon"}
            style={{
              marginRight: "10px",
              width: "20",
              color: "#8a8a8a7a",
            }}
          />
          <UploadingDocumentContainer onClick={onDownloadFile}>
            <UploadingDocumentName>
              <Typography>{fileName}</Typography>
            </UploadingDocumentName>
            {!readOnly && (
              <UploadingDocumentLoadInfo>
                <Typography>{fileSize ? toHumanReadableFileSize(fileSize) : ""}</Typography>
              </UploadingDocumentLoadInfo>
            )}
          </UploadingDocumentContainer>
        </UploadingContainer>
        {!readOnly && <ClearIcon onClick={() => removeFile(fileName)} />}
      </UploadDocumentContent>
      <LinearProgress className="linearProgress" variant="determinate" value={100} />
    </UploadDocumentContainer>
  );
};

const DocumentUploadField = ({
  accept,
  files,
  addFiles,
  uploadText,
  uploadSubText,
  removeFile,
  loading,
  multiple,
  showRemove = true,
  disabled
}) => {
  const fileMaxSize = MaxFileSize;
  const { role } = useRoles()
  const isMaker = (role === Roles.PROCUREMENT_OFFICER) || (role === Roles.VENDOR);

  const onDrop = (acceptedFiles) => {
    addFiles(acceptedFiles);
  };

  function fileSizeValidator(file) {
    if (file.size > fileMaxSize) {
      return {
        code: "tooLarge",
        message: `File size is larger than ${fileMaxSize / 1000}kb`
      };
    }

    return null
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept,
    multiple,
    validator: fileSizeValidator

  });


  let showDropZone;
  if (multiple) {
    showDropZone = multiple;
  } else {
    showDropZone = files.length === 0;
  }

  return (
    <>
      {(isMaker && showDropZone && !disabled) && (
        <DropzoneArea {...getRootProps()}>
          <input {...getInputProps()} disabled={!isMaker} />
          <div>
            {isDragActive ? (
              <p>Release to drop the files here</p>
            ) : (
              <>
                <p>{uploadText || "Drag and drop your  files here, or click to select files"}</p>
                <span>{uploadSubText || "File size should not exceed 5mb"}</span>
                <Collapse in={!!fileRejections?.length}>
                  <Alert severity="error" style={{ marginTop: 20 }}>
                    {fileRejections.map(({ file, errors }) => (
                      <div key={file.path} style={{ textAlign: "left" }}>
                        {file.path} - {(file.size / 1000).toLocaleString()}kb
                        {errors.map(e => (
                          <li key={e.code}>{e.message}</li>
                        ))}

                      </div>
                    ))}
                  </Alert>
                </Collapse>
              </>
            )}
          </div>
        </DropzoneArea>
      )}
      {loading
        ? Array.from({ length: 4 }).map((_, idx) => <Skeleton variant="rect" width="100%" height="50px" key={idx} />)
        : Boolean(files.length) && (
          <Box marginTop={2}>
            <Grid container spacing={1}>
              {files.map((file, index) => (
                <Grid item xs={6} key={`${file.name}_${index}`}>
                  <Wrapper>
                    <UploadedDoc
                      error={file.error}
                      fileName={file.name}
                      fileSize={file.size ?? file.file?.bytes}
                      downloadUrl={file.downloadUrl ?? file.file?.secure_url}
                      removeFile={() => removeFile(file)}
                      readOnly={!isMaker || !showRemove || disabled}
                    />
                  </Wrapper>
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
    </>
  );
};

DocumentUploadField.propTypes = {
  accept: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      error: PropTypes.bool,
    })
  ),
  addFiles: PropTypes.func.isRequired,
  uploadText: PropTypes.string,
  uploadSubText: PropTypes.string,
  removeFile: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

DocumentUploadField.defaultProps = {
  multiple: true,
}

export default DocumentUploadField;
