import React, { useContext, useState } from "react";
import { TextField, Modal, Button, Box } from "@material-ui/core";
import { useMutation, queryCache } from "react-query";

import { ModalBody, ModalTitle } from "../../CreateProcurement/style";
import LoadingButton from "../../../../components/LoadingButton/LoadingButton";
import ToastContext from "../../../../util/toastContext";
import vendorsAPI from "../../../../redux/api/vendorsAPI";

const RejectVendorModal = ({ showNeedForAmendment, vendorDetailsQueryKey, setShowNeedForAmendment, vendorDetails }) => {
  const { showAlert } = useContext(ToastContext);

  const [reasonForRejection, setReasonForRejection] = useState("");
  const [rejectVendor, rejectVendorResp] = useMutation(vendorsAPI.rejectVendorProfile, {
    onSuccess: () => {
      setShowNeedForAmendment(false);
      setReasonForRejection("");
      queryCache.removeQueries(vendorDetailsQueryKey);
      showAlert({
        severity: "success",
        message: "Vendor's profile has been rejected",
      });
    },
    throwOnError: true,
  });

  const rejectVendorProfile = async () => {
    try {
      rejectVendor({
        vendorId: vendorDetails.id,
        description: reasonForRejection,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  return (
    <Modal open={showNeedForAmendment} onClose={() => setShowNeedForAmendment(false)}>
      <ModalBody style={{ top: "50px" }}>
        <ModalTitle>Kindly state your reasons</ModalTitle>
        <TextField
          id="outlined-textarea"
          multiline
          InputProps={{
            disableUnderline: true,
          }}
          onChange={(e) => setReasonForRejection(e.target.value)}
          value={reasonForRejection}
          variant="outlined"
          placeholder="Type reason here"
          rows={15}
          style={{ marginBottom: 20 }}
        />
        <Box display="flex" alignItems="center">
          <Button style={{
            flex: 1,
          }} onClick={() => setShowNeedForAmendment(false)} variant="outlined">
            Cancel
          </Button>
          <Box style={{ width: 20 }} />
          <LoadingButton
            style={{
              flex: 1,
              background: "red",
              color: "#fff",
              opacity: !reasonForRejection ? 0.4 : 1
            }}
            disabled={!reasonForRejection}
            onClick={rejectVendorProfile}
            loading={rejectVendorResp.isLoading}

          >
            Reject
          </LoadingButton>

        </Box>
      </ModalBody>
    </Modal>
  );
};

export default RejectVendorModal;
