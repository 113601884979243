import React from "react";
import styled from "styled-components";
import Button from "@material-ui/core/Button";
import ButtonBase from "@material-ui/core/ButtonBase";
import withStyles from "@material-ui/styles/withStyles";

export const Banner = styled.div`
  padding: 20px;
  background: #fef1c9;
  color: #424f5c;
  border-radius: 3px;
  box-sizing: border-box;
  min-height: 220px;
  display: flex;
  flex-direction: column;
`;

export const Title = styled.p`
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  margin-bottom: 14px;
`;

export const Text = styled.p`
  font-size: 14px;
  line-height: 19px;
  margin-bottom: ${(props) => props.bottomSpacing ?? "10px"};
`;

export const UploadButton = withStyles({
  root: {
    display: "flex",
    marginTop: "auto",
    width: "100%",
    padding: 10,
    textAlign: "center",
    borderRadius: 3,
    border: "1px solid #614A0C",
    fontSize: 14,
    lineHeight: 1.43,
    fontWeight: 700,
  },
})(ButtonBase);

export const SubmitButton = withStyles({
  root: {
    backgroundColor: "#2AA96D",
    color: "#fff",
    textTransform: "none",
    borderRadius: 3,
    padding: "10px 25px",
  },
})((props) => <Button variant="contained" {...props} />);

export const FileContainer = styled.div`
  width: 334px;
  margin-bottom: ${(props) => (props.spaced ? "16px" : 0)};
`;
