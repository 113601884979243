import styled from "styled-components";
import Typography from "@material-ui/core/Typography"
import { theme } from "../../../util/theme";

export const StyledRadioLabel = styled("div")`
  margin-bottom: 20px;
  box-sizing: border-box;
  height: 240px;
  width: 100%;
  min-width: 220px;
  border: 1.5px solid #dfe3e8;
  border-radius: 5px;
  padding: 15px 26px 20px 26px;
  padding-top: 40px;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;

  &:hover {
    background: #f9fafb;
  }
  p {
    margin: 0;
    padding: 0;
  }
  .class {
    margin-bottom: 10px;
    height: 20px;
    width: 78px;
    border-radius: 3px;
    background-color: rgba(3, 173, 235, 0.07);
    .value,
    p {
      height: 20px;
      color: #2AA96D;
      font-family: "Nunito Sans";
      font-size: 12px;
      font-weight: 600;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
  }
  .contract-fee {
    .fee {
      height: 20px;
      width: 88px;
      color: #454f5b;
      font-family: "Nunito Sans";
      font-size: 16px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
    .title {
      height: 20px;
      width: 100px;
      color: #919eab;
      font-family: "Nunito Sans";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
  }

  .separator {
    height: 1px;
    width: 28px;
    border-bottom: 1px solid #c4cdd5;
    margin: 10px 10px 0;
  }

  .renewal {
    margin-top: 6px;
    font-style: Regular;
    font-size: 12px;
    line-height: 20px;
    color: #8e9fac;
  }

  .long-separator {
    height: 1px;
    width: 100%;
    border-bottom: 1px solid #c4cdd5;
    margin-top: 17px;
  }

  .contract-value {
    margin-top: 20px;
    .value {
      height: 20px;
      width: 115px;
      color: #424f5c;
      font-family: "Nunito Sans";
      font-size: 14px;
      font-weight: 800;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
    .title {
      height: 20px;
      width: 118px;
      color: #919eab;
      font-family: "Nunito Sans";
      font-size: 12px;
      letter-spacing: 0;
      line-height: 20px;
      margin: 0 auto;
    }
  }
`;

export const Wrapper = styled.div`
  margin-top:  39.9px;
  button {
  text-transform: capitalize !important;
  }

 > div:first-child > div > div > div:first-child {
    top: -25px !important;
    right: -500px !important;
  }
`;

export const TitleText = styled(Typography)`
  color: ${theme.color.primary} ;
  text-align: center;
  &.MuiTypography-root {
    font-weight: 700 ;
    font-size: 24px ;
    line-height: 33px ;
    margin-top: 30px;
  }
`;

export const BreadCrumblabel = styled(Typography)`
  height: 16px;
  margin-top: 35px !important;
  margin-bottom: 35px !important;
  color: #adb4bb;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  flex-direction: row;
`;

export const BreadcrumbActive = styled.span`
  height: 16px;
  color: #6d7278;
  font-size: 12px !important;
  font-weight: 500;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 8px !important;
`;
