import styled from "styled-components";

export const MainCardContainer = styled.div`
  box-sizing: border-box;
  width: 100%;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #f8f8f9;
`;

export const TenderOrContractStatus = styled.div`
  height: 20px;
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  margin-right: 15px;
  margin-left: 15px;
  align-items: center;

  p {
    color: #212b36;
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
`;

export const TenderOrContractDescription = styled.div`
  height: 72px;
  margin: 15px;

  p {
    color: #212b36;
    font-size: 16px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 24px;
  }

  span {
    color: #212b36;
    font-size: 14px;
  }
`;

export const TenderOrContractTimelineWrapper = styled.div`
  box-sizing: border-box;
  border: 1px solid #cbd4da;
  border-radius: 3px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin: 15px;
  padding: 10px;
`;

export const TenderOrContractTimeLineHeading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 10px;
  margin-left: 10px;

  span {
    height: 16px;
    color: #637381;
    font-family: "SF Pro Text";
    font-size: 12px;
    letter-spacing: 0;
    line-height: 16px;
    margin-top: 10px;
  }

  p {
    height: 20px;
    color: #212b36;
    font-family: "SF Pro Text";
    font-size: 14px;
    letter-spacing: 0;
    line-height: 20px;
  }
`;
