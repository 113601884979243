import React from "react";
import { Route, Switch } from "react-router-dom";
import CertificateOfNoObjection from "./index";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import VendorEvaluation from "../BidderResponseReceipt/VendorEvaluation";

const CertificateOfNoObjectionWrapper = () => {
  return (
    <Switch>
      <Route
        path='/admin/procurement/activity/certificateofnoobjection/:activityId/:procurementId'
        component={CertificateOfNoObjection}
        exact
      />
      <Route path='/admin/procurement/activity/certificateofnoobjection/:activityId/:procurementId/evaluation/:vendorId'>
        <VendorEvaluation previewMode />
      </Route>
    </Switch>
  );
};

export default withAdminDashboard(CertificateOfNoObjectionWrapper);
