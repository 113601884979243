import styled from "styled-components";
import { theme } from "../../../../../util/theme";
import { withStyles } from "@material-ui/styles";
import Button from "@material-ui/core/Button";

export const NameInitials = styled.span`
  border-radius: 50%;
  background: ${theme.color.success};
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  width: 40px;
  text-transform: uppercase;
  font-size: 14px;
  color: ${theme.color.white};
  margin-right: 10px;
`;

export const CertificateOfNoObjectionName = styled.span`
  display: flex;
  align-items: center;
  p {
    display: flex;
    flex-direction: column;
    justify-content: center;
    span:first-child {
      font-size: 14px;
      color: #1c2125;
    }
    span:last-child {
      font-size: 12px;
      color: #5f7483;
    }
  }
`;

export const CertificateOfNoObjectionWidgetLeft = styled.div`
  p:first-child {
    font-weight: 600;
    font-size: 14px;
    line-height: 19px;
    color: #5f7483;
  }
  p:last-child {
    font-weight: 600;
    font-size: 26px;
    line-height: 35px;
    color: #1e2b37;
  }
`;

export const CertificateOfNoObjectionWidgetMiddle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  > div:first-child {
    flex: 1;
    p:first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #219653;
    }
    p:last-child {
      font-weight: 600;
      font-size: 18px;
      line-height: 35px;
      color: #1e2b37;
    }
  }
  > div:nth-child(2) {
    flex: 1;
    p:first-child {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
      color: #eb5757;
    }
    p:last-child {
      font-weight: 600;
      font-size: 18px;
      line-height: 35px;
      color: #1e2b37;
    }
  }
  > div:last-child {
    flex: 2;
    width: 100%;
    margin-left: auto;
    display: flex;
    align-items: flex-end;
  }
`;

export const InstructionHeader = styled.p`
  font-size: 16px;
  margin-bottom: 10px;
  color: #1e2b37;
  font-weight: bold;
`;

export const InstructionDescription = styled.p`
  font-size: 14px;
  line-height: 20px;
  color: #637381;
  margin-bottom: 15px;
`;

export const UploadButton = withStyles({
  root: {
    backgroundColor: "#2AA96D",
    color: "white",
    textTransform: "capitalize",
  },
})(Button);
