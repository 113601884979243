import styled from "styled-components";
import { Box, Typography } from "@material-ui/core";
import CheckCircleSharpIcon from "@material-ui/icons/CheckCircleSharp";

export const OverviewWrapper = styled.div`
  padding-top: 10.5px;

  .MuiTableCell-body {
    padding: 0 20px !important;
  }

  .MuiTableCell-head {
    padding: 16px 20px !important;
  }
`;

export const OverviewFlexContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const OverviewCardTitle = styled(Typography)`
  color: #5f7483 !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px !important;
  margin-bottom: 10px !important;
`;

export const OverviewCardTextLoader = styled(Typography)`
  color: #5f7483 !important;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 19px !important;
  margin-bottom: 10px !important;
`;

export const OverviewCardValue = styled(Typography)`
  color: #1e2b37;
  font-size: 26px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 35px !important;
`;

export const OverviewCardFlag = styled(Typography)`
  padding: 3px 11px;
  background-color: ${({ bgcolor }) => bgcolor};
  color: ${({ textcolor }) => textcolor};
  font-size: 12px !important;
  font-weight: 600 !important;
  border-radius: 3px !important;
  letter-spacing: 0;
  line-height: 16px !important;
`;

export const OverviewCardDesc = styled(Typography)`
  color: #5f7483;
  font-size: 12px !important;
  letter-spacing: 0;
  line-height: 16px !important;
  margin-bottom: 7px !important;
`;

export const RecentBidText = styled(Typography)`
  color: #1e2b37;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 24px !important;
`;

export const ViewAllText = styled(Typography)`
  color: #2AA96D !important;
  font-size: 15px !important;
  font-weight: bold !important;
  letter-spacing: 0;
  line-height: 20px !important;
  cursor: pointer;
`;

export const OverviewCardContainer = styled(Box)`
  box-sizing: border-box;
  border: 1px solid #c9d4db !important;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db important;
  display: flex;
  justify-content: space-between;
  margin-top: 35px !important;
  padding: 20.5px;
  margin-bottom: 19.5px;
`;

export const OverviewTableHeaderContainer = styled(Box)`
  padding: 20.5px;
  background-color: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #c9d4db;
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #c9d4db;
`;

export const OverviewTableCellId = styled(Typography)`
  color: #2AA96D;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const OverviewTableCellDesc = styled(Typography)`
  color: #1d2125;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const ContractTableContainer = styled(Box)`
  .MuiTable-root {
    min-width: 400px !important;
  }
`;

export const VendorRegistrationStatusContainer = styled.div`
  border-radius: 3px;
  background-color: #fff;
  padding: 15px;

  border: 1px solid #CDCED9;
`;

export const VendorStatusTitle = styled(Typography)`
  color: #1e2b37;
  font-size: 20px !important;
  letter-spacing: 0;
  line-height: 32px !important;
`;

export const CompanyProfileInfo = styled(Typography)`
  color: #1e2b37;
  font-size: 16px !important;
  font-weight: 300 !important;
  letter-spacing: 0;
  line-height: 22px !important;
`;

export const SectionInfoCard = styled.div`
  width: 151px;
  border-bottom: ${({ isCompleted }) => (isCompleted ? "3px solid rgba(0,179,0,0.6)" : "none")};
  border-radius: 1px;
  background-color: #ffffff;
  padding: 6px 12px;
  cursor: pointer;
  position: relative;
`;

export const HoverVendorText = styled(Typography)`
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: transparent;
  font-size: 12px !important;
  background-color: ${(props) => (props.shouldClick ? `#2AA96D` : `transparent`)}; 
  color: ${(props) => (props.shouldClick ? `#fff` : `transparent`)};
  cursor: ${(props) => (props.shouldClick ? `pointer` : `none`)}

  &:hover {
    background-color: ${(props) => (props.locked ? `#F2F2F2` : `#2AA96D`)};   
    color: #ffffff;
    cursor: ${(props) => (props.locked ? `none` : `pointer`)}
    text-decoration: underline;
  }
`;

export const SectionMarkCompleteIcon = styled(CheckCircleSharpIcon)`
  color: ${({ completedsection }) => (completedsection === "true" ? "rgba(0,179,0,0.6)" : "transparent")};
  font-size: 14px !important;
`;

export const SectionInfoCardTitle = styled.div`
  color: #1e2b37;
  font-size: 11px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 15px !important;
`;
export const SectionInfoCardLabel = styled.div`
  color: #1e2b37;
  font-size: 11px !important;
  font-weight: 300 !important;
  letter-spacing: 0;
  line-height: 15px !important;
`;

export const ContractTableStatus = styled(Typography)`
  border: 2px solid transparent;
  border-radius: 100px;
  background-color: ${({ flagcolor }) => (flagcolor === 1 ? "#C1EEFF" : "#CBD4DA")};
  color: ${({ flagcolor }) => (flagcolor === 1 ? "#2AA96D" : "#637381")};
  padding: 1px;
  text-align: center;
`;
