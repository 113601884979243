import { Box } from "@material-ui/core";
import React from "react";
import { useQuery, useMutation } from "react-query";
import { useParams, useHistory } from "react-router-dom";
import { SearchButton } from "../../../../components/Forms/Common/style";
import { ContentWrapper, TitleContainer } from "../../../Admin/Administration/Staff/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import { BiddingDocumentContainer } from "../../BidDetails/BidInfo/style";
import { NewCancelButton } from "../../PlaceBid/style";
import ContractInfo from "../ContractInfo";
import withVendorDashboard from "../../HOC/withVendorDashboard";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import { useContext } from "react";
import ToastContext from "../../../../util/toastContext";
import {
  MainText,
  MainTitle,
  CardWrapper,
  Pad,
  FileBoard,
  CuteGrid,
  FileCard,
  FileText,
  InterestExpressedPane,
} from "../../Procurement/style";
import { ReactComponent as FolderIcon } from "../../../../assets/folder.svg";
import { ReactComponent as DisabledCheckIcon } from "../../../../assets/disabled_check_mark.svg";
import procurementAPI from "../../../../redux/api/procurementAPI";
import FileDownload from "../../../../components/FileDownload";
import moment from "moment";

const LetterOfAcceptance = () => {
  const { contractId } = useParams();
  const history = useHistory();

  const { data: contractInfo } = useQuery(["contractInfo", contractId], vendorsAPI.getVendorContractById);
  const procurementId = contractInfo?.procurementPlan?.id;

  const { data: letter = [] } = useQuery(["getIntentLetter", procurementId], vendorsAPI.getVendorLetterOfIntent);
  const file = letter[0]?.file;
  const datePublished = moment(letter[0]?.createdAt).format("DD/MM/YYYY");
  const deadline = moment(letter[0]?.deadline).format("DD/MM/YYYY");

  const { data: documents = [] } = useQuery(["exchangedDocuments", procurementId], procurementAPI.getExchangedDocuments);

  const { showAlert } = useContext(ToastContext);

  const [acceptLetter, acceptLetterProp] = useMutation(vendorsAPI.acceptOfferLetter, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Letter of Acceptance accepted",
      });
      setTimeout(() => {
        history.push("/vendor/contracts");
      }, 2000);
    },
  });

  const [rejectLetter, rejectLetterProp] = useMutation(vendorsAPI.rejectOfferLetter, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Letter of Acceptance rejected",
      });
      setTimeout(() => {
        history.push("/vendor/contracts");
      }, 2000);
    },
  });

  const disableStatus = acceptLetterProp?.isLoading || rejectLetterProp?.isLoading || contractInfo?.status !== "ASSIGNED";

  const handleAcceptContractOffer = async () => {
    await acceptLetter({ contractId });
  };

  const handleRejectContractOffer = async () => {
    await rejectLetter({ contractId });
  };

  const BreadcrumbLinks = [
    { url: "/vendor/contracts", title: "Contract", forwardSlash: true },
    { url: "/vendor/contracts", title: " Details", forwardSlash: true },
    { url: "/vendor/contracts", title: " Letter of Acceptance", forwardSlash: false },
  ];

  return (
    <div>
      <ContentWrapper>
        <TitleContainer>
          <div>
            <PageTitle>{contractInfo?.procurementPlan?.packageNumber}</PageTitle>
            <Breadcrumb values={BreadcrumbLinks} />
          </div>
        </TitleContainer>

        <InterestExpressedPane state="pending">
          <CuteGrid>
            <div>
              <MainText color="#212B36" fw="bold" fontSize="14px">
                Letter of Intent
              </MainText>
              <MainText color="#212B36" mt="10px" fontSize="14px">
                Pending acceptance
              </MainText>
            </div>
            <div>
              <DisabledCheckIcon />
            </div>
          </CuteGrid>
        </InterestExpressedPane>

        <CardWrapper>
          <Pad>
            <ContractInfo id={procurementId} publishDate={datePublished} deadline={deadline} />
            <MainTitle mt="40px">Documents</MainTitle>
            <MainText mt="15px" fontSize="14px">
              List of all documents exchanged
            </MainText>
          </Pad>
          {documents.length ? (
            <FileBoard>
              <CuteGrid start>
                {documents.map((doc) => (
                  <div key={doc.id}>
                    {/* <div key={doc.documents.id}> */}
                    <FileCard>
                      <FolderIcon />
                      <FileText mt="15px" color="#2AA96D" fontSize="13px">
                        {doc.count} files
                      </FileText>
                    </FileCard>
                    <FileText mt="20px" fontSize="14px" wd="77%">
                      {doc.title}
                    </FileText>
                  </div>
                ))}
              </CuteGrid>
            </FileBoard>
          ) : (
            <Pad>
              {" "}
              <MainText mt="10px">No documents yet</MainText>{" "}
            </Pad>
          )}
        </CardWrapper>
        <CardWrapper mt="20px">
          <Pad>
            <MainTitle>Letter of intent</MainTitle>
            <MainText mt="50px"></MainText>
            <BiddingDocumentContainer>
              <div style={{ width: "25%" }}>
                {file ? (
                  <FileDownload url={file?.url} lighttheme name={file?.original_filename} size={file?.bytes} />
                ) : (
                  <Pad>
                    {" "}
                    <MainText mt="10px">No document yet</MainText>{" "}
                  </Pad>
                )}
              </div>
            </BiddingDocumentContainer>
            <Box display="flex" justifyContent="flex-end" alignItems="center" marginTop="60px">
              <NewCancelButton disabled={disableStatus} onClick={handleRejectContractOffer} style={{ minWidth: "91px" }}>
                {rejectLetterProp?.isLoading ? "Please wait..." : "Reject"}
              </NewCancelButton>
              <SearchButton
                noBottom
                disabled={disableStatus}
                onClick={handleAcceptContractOffer}
                style={{ minWidth: "155px", marginLeft: "15px" }}
              >
                {acceptLetterProp.isLoading ? "Please wait..." : "Accept Letter"}
              </SearchButton>
            </Box>
          </Pad>
        </CardWrapper>
      </ContentWrapper>
    </div>
  );
};

export default withVendorDashboard(LetterOfAcceptance);
