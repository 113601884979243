import React, { useContext } from "react";
import withVendorDashboard from "../HOC/withVendorDashboard";
import ToastContext from "../../../util/toastContext";
import { useParams } from "react-router-dom";
import { useQuery } from "react-query";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import { PageContainer, PageTitle } from "../../Common/style";
import Box from "@material-ui/core/Box";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import DocumentCategories from "../BidDetails/NewBidInfo/DocumentCategories";
import Paper from "@material-ui/core/Paper";
import { HL, InfoCardWrapper, MainText, MainTitle } from "../Procurement/style";
import Loader from "../../../components/Common/Loader";
import { ItemsGrid } from "../ProjectInfo/style";
import moment from "moment";
import { Title } from "../BidDetails/style";
import { DetailItemText } from "../../../components/DetailItem/style";

const ProjectDetails = () => {
  const { showAlert } = useContext(ToastContext);
  const { projectId: id } = useParams();

  const onError = (error) => {
    showAlert({
      severity: "error",
      message: error.message,
      durationInMs: 3000,
    });
  };

  const { isLoading, data: project } = useQuery(
    {
      queryKey: ["project", id],
      queryFn: vendorsAPI.getProjectDetails,
      config: {
        onError
      }
    }

  );

  const renderBreadcrumbLinks = () => [
    { url: "/vendor/projects", title: "Projects", forwardSlash: true },
    { url: `/vendor/projects/${id}`, title: `${project?.code}`, forwardSlash: false },
    { url: `#`, title: 'Details' },
  ];

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" marginBottom="35px">
        {!isLoading && (
          <Box>
            <PageTitle>{project?.code}</PageTitle>
            <Breadcrumb values={renderBreadcrumbLinks()} />
          </Box>
        )}
      </Box>

      <Paper elevation={1}>
        <Box p={2.5}>
          <InfoCardWrapper>
            <MainTitle>Procurement Information</MainTitle>
            {isLoading && <Loader />}
            {!isLoading && (
              <>
                <MainText mt="5px" fontSize="14px">
                  {project?.ministry?.name}
                </MainText>
                <MainText mt="20px" fontSize="13px">
                  Project Title
                </MainText>
                <MainTitle mt="5px"> {project?.name}</MainTitle>
                <MainText mt="20px" fontSize="13px">
                  Project Description
                </MainText>
                <MainText mt="5px" fontSize="14px">
                  {project?.description}
                </MainText>
                <HL mt="20px" />

                <ItemsGrid>
                  <div>
                    <MainText mt="20px" fontSize="13px">
                      Budget
                    </MainText>
                    <MainTitle>N{project?.budget?.toLocaleString()}</MainTitle>
                  </div>
                  <div>
                    <MainText mt="20px" fontSize="13px">
                      Procurement ID
                    </MainText>
                    <MainTitle>{project?.code}</MainTitle>
                  </div>
                </ItemsGrid>

                <HL mt="20px" />
                <ItemsGrid>
                  <div>
                    <MainText mt="20px" fontSize="13px">
                      Procurement Category
                    </MainText>
                    <MainText fw="600" fontSize="14px">
                      {project?.procurementCategory}
                    </MainText>
                  </div>
                  <div>
                    <MainText mt="20px" fontSize="13px">
                      Procurement Method
                    </MainText>
                    <MainText fw="600" fontSize="14px">
                      {project?.procurementMethod}
                    </MainText>
                  </div>
                </ItemsGrid>

                <MainText mt="20px" fontSize="15px" color="#2AA96D" fw="bold">
                  View more
                </MainText>
              </>
            )}
            <div className="topRightCard oneCard">
              <div>
                <MainText mt="20px" mr="10px" fontSize="13px">
                  Date Published
                </MainText>
                <MainText fw="600" fontSize="14px">
                  {moment(project?.startDate).format("DD/MM/YYYY")}
                </MainText>
              </div>
            </div>
          </InfoCardWrapper>
          <Box mt={4} mb={2.5}>
            <Title bottomSpacing="15px">Documents</Title>
            <DetailItemText>List of all documents exchanged</DetailItemText>
          </Box>
          {
            isLoading ? (
              <Loader />
            ) : (
              <DocumentCategories tenderId={project?.tenderId} />
            )
          }
        </Box>
      </Paper>
    </PageContainer>
  );
};

export default withVendorDashboard(ProjectDetails);
