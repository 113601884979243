import React, { useMemo } from "react";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import { Link, useParams } from "react-router-dom";
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import { Tooltip } from "@material-ui/core";
import { useQuery } from "react-query";
import moment from "moment";
import {
  BoldCardContent,
  BreadCrumbWrapper,
  CardBody,
  CardContent,
  CardLabel,
  CardSection,
  CardWrapper,
  ContentWrapper,
  Divider,
  PageTitle,
  SerialText,
  StatusCircle,
  StatusCirclePending,
  StatusCircleSuccess,
  TableHeader,
  TableTop,
  TableWrapper,
  TopSection,
  UnstyledLink,
} from "./style";
import withAdminDashboard from "../HOC/withAdminDashboard";
import procurementAPI from "../../../redux/api/procurementAPI";
import Loader from "../../../components/Common/Loader";
import LinearProgressBar from "../../../components/LinearProgressBar";
import FileProgress from "../../../components/FileProgress/FileProgress";
import Tag from "../../../components/Tag";
import { formatCurrency } from "../../../util/formatCurrency";
import ApprovalMemo from "./ApprovalMemo";
import { useRoles } from "../../../hooks/useUserDetails";
import { Roles } from "../../../components/AccessControl/UserRoles";
import CertificateOfNoObjection from "./CertificateOfNoObjection";

const statusCircles = {
  Pending: <StatusCirclePending marginRight={30} />,
  Awaiting: <StatusCirclePending marginRight={30} />,
  Approved: <StatusCircleSuccess marginRight={30} />,
  InActive: <StatusCircle marginRight={30} />,
};

const PlanDetails = () => {
  const { role } = useRoles();
  const isProcurementOfficer = Roles.PROCUREMENT_OFFICER === role;
  const { procurementId } = useParams();
  const { data = {}, isLoading, isSuccess } = useQuery({
    queryKey: ["generalPlanDetails", procurementId],
    queryFn: procurementAPI.getPlanDetails,
    config: {
      enabled: !!procurementId,
      cacheTime: 3600 * 100,
      staleTime: 3600 * 100,
    },
  });

  const memoFileQueryKey = ["getMemoForApproval", { procurementId, params: { objectType: "4" } }];

  const memoFileQuery = useQuery({
    queryKey: memoFileQueryKey,
    queryFn: procurementAPI.getProcurementApprovalMemo,
    config: {
      enabled: !!procurementId,
    },
  });

  const { data: CNO, refetch } = useQuery(["getCNO", procurementId], procurementAPI.getCertificateOfNoObjectionTender);

  const planningStages = data?.procurementPlanActivities?.filter((x) => x.procurementPlanType === "PROCUREMENTPLANNING") || [];
  const executionStages = data?.procurementPlanActivities?.filter((x) => x.procurementPlanType === "PROCUREMENTEXECUTION") || [];

  const getActivityName = (title) =>
    title
      .replace(/[^\w\s]/gi, "")
      .split(" ")
      .join("")
      .toLowerCase();

  const percentageCompleted = useMemo(() => {
    if (!isSuccess) {
      return 0;
    }

    const done = data.procurementPlanActivities.filter(
      ({ procurementPlanActivityStatus }) => procurementPlanActivityStatus === "Approved"
    ).length;

    return (done / data.procurementPlanActivities.length) * 100;
  }, [data, isSuccess]);

  const renderStage = (stage = "") => {
    const textMapping = {
      INPROGRESS: "In Progress",
      COMPLETED: "Completed",
      NOTSTARTED: "Not Started",
    };

    switch (stage) {
      case "INPROGRESS":
        return <Tag text={textMapping[stage]} color="yellow" rounded={false} />;
      case "COMPLETED":
        return <Tag text={textMapping[stage]} color="blue" rounded={false} />;
      case "NOTSTARTED":
      default:
        return <Tag text={textMapping[stage]} color="green" rounded={false} />;
    }
  };

  if (isLoading) {
    return (
      <ContentWrapper>
        <Loader />
      </ContentWrapper>
    );
  }

  const redirectToFileUrl = (url) => {
    window.open(url, "_blank");
  };

  const showApprovalMemoOverlay = Array.isArray(memoFileQuery.data) && memoFileQuery.data.length === 0;
  const showCertificateOfNoObjectionOverlay = !showApprovalMemoOverlay && CNO?.length === 0;

  return (
    <ContentWrapper>
      <PageTitle>{data.name}</PageTitle>
      <BreadCrumbWrapper>
        <Breadcrumbs>
          <Link to={"/admin/dashboard/overview"}>Home</Link>
          <Link to={"/admin/procurement/plans"}>Plans</Link>
          <Link to={`/admin/procurement/plans/${data?.annualProcurementPlanId}`}>{data?.ministryCode}</Link>
        </Breadcrumbs>
      </BreadCrumbWrapper>
      <CardWrapper>
        <TopSection>
          {renderStage(data.stage)}
          <SerialText>{data.packageNumber}</SerialText>
        </TopSection>
        <LinearProgressBar percentageOfCompletion={percentageCompleted?.toFixed(2)} />
        <CardSection style={{ marginTop: 20 }}>
          <CardLabel>Description</CardLabel>
          <BoldCardContent>{data.description}</BoldCardContent>
        </CardSection>
        <Divider />
        <CardSection>
          <CardLabel>Budget</CardLabel>
          <BoldCardContent>{formatCurrency(data.budget, true, true)}</BoldCardContent>
        </CardSection>
        <Divider />
        <CardBody>
          <CardSection>
            <CardLabel>Procurement Category</CardLabel>
            <CardContent>{data?.procurementCategory?.name}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Procurement Method</CardLabel>
            <CardContent>{data?.procurementMethod?.name}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Tendering Method</CardLabel>
            <CardContent>{data?.tenderingStage?.name}</CardContent>
          </CardSection>
          <CardSection>
            <CardLabel>Review Method</CardLabel>
            <CardContent>{data?.reviewMethod?.name}</CardContent>
          </CardSection>
        <CardSection>
        {Array.isArray(memoFileQuery.data) && memoFileQuery.data.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <CardLabel>Approval Memo</CardLabel>
            <CardBody style={{ gridColumnGap: 15, marginTop: 10 }} spacing={4}>
              {memoFileQuery.data.map((memo) => {
                return (
                  <CardSection>
                    <FileProgress
                      progress={100}
                      fileName={memo?.file.original_filename}
                      fileSize={memo?.file.bytes}
                      hasEyes={true}
                      onClickEyes={() => redirectToFileUrl(memo?.file.url)}
                    />
                  </CardSection>
                );
              })}
            </CardBody>
          </div>
        )}
        </CardSection>
        <CardSection>
        {Array.isArray(CNO) && CNO?.length > 0 && (
          <div style={{ marginTop: 20 }}>
            <CardLabel>Certificate Of No Objection</CardLabel>
            <CardBody style={{ gridColumnGap: 15, marginTop: 10 }} spacing={4}>
              {CNO?.map((memo) => {
                return (
                  <CardSection>
                    <FileProgress
                      progress={100}
                      fileName={memo?.file.original_filename}
                      fileSize={memo?.file.bytes}
                      hasEyes={true}
                      onClickEyes={() => redirectToFileUrl(memo?.file.url)}
                    />
                  </CardSection>
                );
              })}
            </CardBody>
          </div>
        )}
        </CardSection>
        </CardBody>
      </CardWrapper>
      <CardWrapper noPadding gray>
        {showApprovalMemoOverlay && (
          <ApprovalMemo
            procurementId={procurementId}
            isProcurementOfficer={isProcurementOfficer}
            memoFileQueryKey={memoFileQueryKey}
          />
        )}
        {showCertificateOfNoObjectionOverlay && (
          <CertificateOfNoObjection refetch={refetch} procurementId={procurementId} isProcurementOfficer={isProcurementOfficer} />
        )}
        <TableWrapper>
          <TableTop>
            <TableHeader>Roadmap</TableHeader>
          </TableTop>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Procurement Planning</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                {/* <TableCell /> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {planningStages.map(({ title, startDate, endDate, id, procurementPlanActivityStatus, procurementPlanId }) => (
                <TableRow key={title}>
                  <TableCell>
                    {statusCircles[procurementPlanActivityStatus]}
                    {procurementPlanActivityStatus === "InActive" ? (
                      <Tooltip
                        title="Can't move to a activity when the preceding activity has not been completed"
                        aria-label="add"
                      >
                        <span> {title}</span>
                      </Tooltip>
                    ) : (
                      <>
                        {Array.isArray(memoFileQuery.data) && memoFileQuery.data.length > 0 ? (
                          <UnstyledLink to={`/admin/procurement/activity/${getActivityName(title)}/${id}/${procurementPlanId}`}>
                            {title}
                          </UnstyledLink>
                        ) : (
                          <span> {title}</span>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>{startDate ? moment(startDate).format("DD, MMMM, yyyy") : ""}</TableCell>
                  <TableCell>{endDate ? moment(endDate).format("DD, MMMM, yyyy") : " "}</TableCell>
                  {/* <TableCell className="cell-no-padding">
                    {procurementPlanActivityStatus === "Pending" && (
                      <IconButton>
                        <EditOutlinedIcon width={20} />
                      </IconButton>
                    )}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
            <TableHead>
              <TableRow>
                <TableCell>Procurement Execution</TableCell>
                <TableCell>Start Date</TableCell>
                <TableCell>End Date</TableCell>
                {/* <TableCell /> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {executionStages.map(({ title, startDate, endDate, id, procurementPlanActivityStatus, procurementPlanId }) => (
                <TableRow key={title}>
                  <TableCell>
                    {procurementPlanActivityStatus === "Pending" && <StatusCirclePending marginRight={30} />}
                    {procurementPlanActivityStatus === "Approved" && <StatusCircleSuccess marginRight={30} />}
                    {procurementPlanActivityStatus === "InActive" && <StatusCircle marginRight={30} />}
                    {procurementPlanActivityStatus === "InActive" ? (
                      <Tooltip
                        title="Can't move to a activity when the preceding activity has not been completed"
                        aria-label="add"
                      >
                        <span> {title}</span>
                      </Tooltip>
                    ) : (
                      <>
                        {Array.isArray(memoFileQuery.data) && memoFileQuery.data.length > 0 ? (
                          <UnstyledLink to={`/admin/procurement/activity/${getActivityName(title)}/${id}/${procurementPlanId}`}>
                            {title}
                          </UnstyledLink>
                        ) : (
                          <span> {title}</span>
                        )}
                      </>
                    )}
                  </TableCell>
                  <TableCell>{startDate ? moment(startDate).format("DD, MMMM, yyyy") : ""}</TableCell>
                  <TableCell>{endDate ? moment(endDate).format("DD, MMMM, yyyy") : " "}</TableCell>
                  {/* <TableCell className="cell-no-padding">
                    {procurementPlanActivityStatus === "Pending" && (
                      <IconButton>
                        <EditOutlinedIcon width={20} />
                      </IconButton>
                    )}
                  </TableCell> */}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableWrapper>
      </CardWrapper>
    </ContentWrapper>
  );
};

export default withAdminDashboard(PlanDetails);
