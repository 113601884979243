import React from "react";
import { BrowserRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import { ReactQueryDevtools } from "react-query-devtools";
import { ReactQueryConfigProvider } from "react-query";
import NotFound from "./pages/404";
import ConfirmEmail from "./pages/Common/ConfirmEmail";
import AdminAuthenticatedRoute from "./pages/Admin/index";
import withAuthentication from "./components/Common/withAuthentication";
import VendorAuthenticatedRoute from "./pages/Vendor";
import withVendorAuthentication from "./components/Common/withVendorAuthentication";
import LandingPageRoutes from "./pages/Homepage";

const queryConfig = {
  queries: {
    refetchOnWindowFocus: false,
    retry: 1,
  },
  mutations: {
    throwOnError: false,
  },
};

function App() {
  return (
    <ReactQueryConfigProvider config={queryConfig}>
      <Router>
        <div className="App">
          <Switch>
            {LandingPageRoutes.map((route, index) => (
              <Route key={index} exact={route.exact} path={route.path} component={route.component} />
            ))}
            <Route exact path="/ocds-portal">
              <Redirect to="/ocds-portal/awarded-contracts" />
            </Route>
            <Route exact path="/vendors/confirm-email" component={ConfirmEmail} />
            {AdminAuthenticatedRoute.map((route, index) => (
              <Route exact={route.exact} key={index} path={route.path} component={withAuthentication(route.component)} />
            ))}
            {VendorAuthenticatedRoute.map((route, index) => (
              <Route exact key={index} path={route.path} component={withVendorAuthentication(route.component)} />
            ))}
            <Route path="*">
              <NotFound />
            </Route>
          </Switch>
        </div>
      </Router>
      <ReactQueryDevtools initialIsOpen={false} />
    </ReactQueryConfigProvider>
  );
}

export default App;
