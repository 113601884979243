/*eslint-disable no-unused-vars*/

// The rule above was used because the offline payment feature was commented out,
// the product owner is not sure if it should stay or not.
// Commenting out the UI code left a lot of variables unused, hence the lint rule.

import React, { useState, useRef, useContext } from "react";
import Grid from "@material-ui/core/Grid";
import Box from "@material-ui/core/Box";
import Collapse from "@material-ui/core/Collapse";
import { useHistory } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { ReactComponent as Info } from "../../../assets/round_caution.svg";
import {
  CustomDialog,
  CustomDialogTitle,
  Caption,
  CustomDialogContent,
  CustomDialogTitleWrapper,
  RegistrationCategoryWrapper,
  Subtitle,
  ApplicationPeriod,
  ApplicationDates,
  PayText,
  RegistrationCategoryContent,
  RegistrationCategoryClass,
  RegistrationCategorySubtitle,
  RegistrationCategoryValue,
  SubmitButton,
  OfflinePayCard,
  PrevBtn,
  ProofBtn,
} from "./style";
import { formatNumber, separateNumByComma } from "../../../util/truncateValue";
import paymentAPI from "../../../redux/api/paymentAPI";
import { Radio } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import { withStyles } from "@material-ui/core/styles";
import FileProgress from "../../../components/FileProgress/FileProgress";
import { useSelector } from "react-redux";
import ToastContext from "../../../util/toastContext";
import moment from "moment";
import { CALLBACK_ROUTES } from "../../../util/constants";
import Iframe from "react-iframe";
import vendorsAPI from "../../../redux/api/vendorsAPI";

const ConfirmVerificationModal = ({
  refetchPayStatus,
  toggleModal,
  isOpen,
  vendorProfileData,
  isRenewal = false,
  variant,
  registerationPlanId,
  vendorClass,
  onPaymentSuccess,
  ammount
}) => {
  const [paymentType, setPaymentType] = useState("online");
  const [paymentIframe, setPaymentIframe] = useState({ display: false, url: "" });
  const [checkPayment, setCheckPayment] = useState(false);
  const [paymentGatewayFailure, setPaymentGatewayFailure] = useState(false);
  const history = useHistory();
  const [file, setFile] = useState(null);
  const inputRef = useRef();
  const { showAlert } = useContext(ToastContext);
  let hostURL = window.location.origin;
  const {
    user: { UserId },
  } = useSelector((state) => state.user);

  const [invokePaystack, { isLoading: loadingPayStack, error }] = useMutation(paymentAPI.initiatePayment, {
    onSuccess: (data) => {
      setPaymentIframe({ display: true, url: data.data });
    },
    onError: (error) => {
        setPaymentGatewayFailure(true);
      showAlert({
        severity: "error",
        message: error?.data?.message,
        durationInMs: 3000,
      });
    },
  });

  const closePayment = () => {
    !!refetchPayStatus && refetchPayStatus();
    toggleModal(false);
    setPaymentGatewayFailure(false);
    setPaymentIframe({ ...paymentIframe, display: false })
  }

  const checkPaymentStatus = () => {
    setCheckPayment(true)
  }

  const { isLoading: checkingPaymentStatus } = useQuery(
    ["verifyPayment", UserId],
    isRenewal ? vendorsAPI.getVendorRenewalPaymentStatus : vendorsAPI.getVendorRegistrationPaymentStatus,
    {
      enabled: checkPayment,
      onSuccess: (data) => {
        if (data.status === "PAID") {
          showAlert({
            severity: "success",
            message: "Payment verified successfully",
            durationInMs: 3000,
          });
          closePayment();
          !!onPaymentSuccess && onPaymentSuccess();
        } else {
          showAlert({
            severity: "error",
            message: "Payment couldn't be verified, please try again",
            durationInMs: 3000,
          });
        }
      },
      onError: (error) => {
        showAlert({
          severity: "error",
          message: error.message,
          durationInMs: 3000,
        });
      },
      onSettled: () => {
        setCheckPayment(false)
      }
    })

  const { contractMinValue, contractMaxValue, fee, grade, anualRenewalFee } = !!vendorClass ? vendorClass : vendorProfileData;

  // const handleSelectFile = (e) => {
  //   if (e.target.files && e.target.files[0]) setFile(e.target.files[0]);
  // };

  const handlePayment = () => {
    const payload = {
      callBack: isRenewal ? `${hostURL}/${CALLBACK_ROUTES.RENEWAL}` : `${hostURL}/${CALLBACK_ROUTES.REGISTRATION}`,
      paymentType: !!variant ? variant : isRenewal ? 3 : 1,
      RegistrationPlanId: registerationPlanId
    };
    invokePaystack(payload);
  };

  // const handleCancel = () => setFile(null);

  const [uploadPaymentProof, { isLoading, isSuccess }] = useMutation(
    isRenewal ? paymentAPI.vendorRegistrationRenewalPaymentProof : paymentAPI.vendorRegistrationPaymentProof,
    {
      throwOnError: true,
      onError: (error) => {
        showAlert({
          severity: "error",
          durationInMs: 5000,
          message: error.message,
        });
      },
      onSuccess: () => {
        showAlert({
          severity: "success",
          durationInMs: 5000,
          message: "Proof has been uploaded for verification successfully",
        });
        history.push("/vendor/overview");
        toggleModal(false);
      },
    }
  );

  const handleProof = async () => {
    await uploadPaymentProof({
      vendorId: UserId,
      file,
    });
  };

  return (
    <>
      <CustomDialog scroll="body" onClose={closePayment} open={isOpen}>
        {paymentIframe.display ? (
          <>
            <Iframe url={paymentIframe.url} width="100%" height="500px" id="payFrame" />
            <Grid style={{ marginTop: "20px" }} container justify="space-between">
              <PrevBtn onClick={closePayment}>Close</PrevBtn>
              <SubmitButton disabled={checkingPaymentStatus} onClick={checkPaymentStatus}>
                I have paid
              </SubmitButton>
            </Grid>
          </>
        ) : (
          <>
            <Collapse in={paymentGatewayFailure}>
              <CustomDialogTitleWrapper>
                <CustomDialogTitle disableTypography>An error occured</CustomDialogTitle>
              </CustomDialogTitleWrapper>
              <Box my={5}>
                <Alert severity="error">
                  <AlertTitle>Payment Gateway Error</AlertTitle>
                  {error?.data?.message}
                </Alert>
              </Box>
              <Grid container justify="space-between">
                <PrevBtn onClick={() => {
                  setPaymentGatewayFailure(false);
                  toggleModal(false);
                }}>Close</PrevBtn>
                <SubmitButton onClick={() => setPaymentGatewayFailure(false)}>
                  Retry
                </SubmitButton>
              </Grid>
            </Collapse>
            <Collapse in={!paymentGatewayFailure}>
              <CustomDialogTitleWrapper>
                <CustomDialogTitle disableTypography>Check before proceeding</CustomDialogTitle>
                {/* <Caption variant="caption">
                Ensure the information on this page is correct before clicking the “Submit” button.
              </Caption> */}
              </CustomDialogTitleWrapper>
              <CustomDialogContent>
                <RegistrationCategoryWrapper>
                  <Subtitle>Registration Category</Subtitle>
                  <RegistrationCategoryContent>
                    <RegistrationCategoryClass>{grade && grade[6]}</RegistrationCategoryClass>
                    <div>
                      <Grid container>
                        <RegistrationCategorySubtitle>Contract Value and Threshold:</RegistrationCategorySubtitle>
                        {formatNumber(contractMinValue) === "100M" ? (
                          <RegistrationCategoryValue>100M and Above</RegistrationCategoryValue>
                        ) : (
                          <RegistrationCategoryValue>
                            {formatNumber(contractMinValue)} - {formatNumber(contractMaxValue)}
                          </RegistrationCategoryValue>
                        )}
                      </Grid>
                      <Grid container>
                        <RegistrationCategorySubtitle>Service Fee:</RegistrationCategorySubtitle>
                        <RegistrationCategoryValue>
                          N{!!ammount
                            ? separateNumByComma(Number(ammount))
                            : isRenewal
                              ? separateNumByComma(Number(anualRenewalFee))
                              : separateNumByComma(Number(fee))
                          }
                        </RegistrationCategoryValue>
                      </Grid>
                    </div>
                  </RegistrationCategoryContent>
                </RegistrationCategoryWrapper>

                <ApplicationPeriod>
                  <Subtitle>Application Period</Subtitle>
                  <ApplicationDates>{`${moment().format(
                    "MMMM DD, YYYY"
                  )} - December 31, ${new Date().getFullYear()}`}</ApplicationDates>
                </ApplicationPeriod>
                <hr />
                {/* <Subtitle>Select Payment Type</Subtitle> */}
                <Alert severity="info">Payments are non refundable</Alert>
                {/* <Grid container direction="row" justify="flex-start" alignItems="center">
          <BlueRadio
            checked={paymentType === "online"}
            onChange={(e) => setPaymentType(e.target.value)}
            value="online"
            name="payment"
            inputProps={{ "aria-label": "online" }}
          />
          <PayText checked={paymentType === "online"}>Online Payment</PayText>
          <BlueRadio
            checked={paymentType === "offline"}
            onChange={(e) => setPaymentType(e.target.value)}
            value="offline"
            name="payment"
            inputProps={{ "aria-label": "offline" }}
          />
          <PayText checked={paymentType === "offline"}>Offline Payment</PayText>
        </Grid>

        {paymentType === "offline" && (
          <>
            <OfflinePayCard>
              <div className="icon">
                <Info />
              </div>
              <p className="title">Offline Payment Instruction</p>
              {isSuccess ? (
                <p className="instruction">
                  Your payment has been sent for verification, you will be notified after it has been reviewed
                </p>
              ) : (
                <p className="instruction">
                  {" "}
                  Make a payment of N{isRenewal ? separateNumByComma(Number(anualRenewalFee)) : separateNumByComma(Number(fee))} to Kwara state IGR account in any bank close to you
                </p>
              )}
              {file && (
                <FileProgress fileName={file.name} fileSize={file.size} progress={100} hasCancel onCancel={handleCancel} />
              )}
              <ProofBtn disabled={!!file} onClick={() => inputRef.current.click()}>
                Upload Proof of Payment
              </ProofBtn>
              <input ref={inputRef} type="file" style={{ display: "none" }} onChange={handleSelectFile} />
            </OfflinePayCard>
          </>
        )} */}
              </CustomDialogContent>

              <Grid container justify="space-between">
                <PrevBtn onClick={() => toggleModal(false)}>Previous</PrevBtn>
                {paymentType === "online" ? (
                  <SubmitButton disabled={loadingPayStack} onClick={handlePayment}>
                    {isLoading ? "Please wait..." : "Pay Now"}
                  </SubmitButton>
                ) : (
                  <SubmitButton disabled={!file || isLoading} onClick={handleProof}>
                    {isLoading ? "Uploading proof of payment..." : "Send for Verification"}
                  </SubmitButton>
                )}
              </Grid>
            </Collapse>
          </>
        )}
      </CustomDialog>
    </>
  );
};

export default ConfirmVerificationModal;

const BlueRadio = withStyles({
  root: {
    color: "#5F7483",
    "&$checked": {
      color: "#2AA96D",
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);
