import styled from "styled-components";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography"
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid"
import { theme } from "../../../util/theme";

export const StyledContainer = styled(Container)`
  box-sizing: border-box;
  background-color: #ffffff;
`;

export const BreadCrumblabel = styled(Typography)`
  height: 16px;
  margin-top: 35px !important;
  margin-bottom: 35px !important;
  color: #adb4bb;
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  flex-direction: row;
`;

export const BreadCrumbContainer = styled(Box)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const BreadcrumbActive = styled.span`
  height: 16px;
  color: #6d7278;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 16px;
  margin-left: 8px !important;
`;

export const TitleText = styled(Typography)`    
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;

  .active {
    background-color: #EDF9F0 !important; 
    border-radius: 16px;
    font-size: 14px !important;
    color: ${theme.color.primary} !important;
    text-transform: capitalize;
    height: 25px !important;
    padding: 2px 8px !important;
    margin-left: 3px;
  }
`;

export const ContactTitle = styled(Typography)`  
  text-transform: capitalize;
  font-weight: 600 !important;
  font-size: 18px !important;
  line-height: 24px !important;
  color: #1E2B37 !important;
`;

export const DetailContainer = styled(Box)`
  background-color: #F8F8F9;
  border: 1px solid #C9D4DB;
  box-sizing: border-box;
  border-radius: 3px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

export const GridContainer = styled(Grid)`  
  box-sizing: border-box;
  width: 100%;
`;

export const SupTitle = styled(Typography)`
  font-size: 13px !important;
  line-height: 16px !important;
  color: #5F7483 !important;
`;
export const SupBody = styled(Typography)`
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #5F7483 !important;
  text-transform: capitalize;
`;