import React from "react";
import DateFnsUtils from "@date-io/date-fns";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { DatePickerWrapper } from "./style";

const ControlledDatePicker = ({
  label, id, style, value, onChange, margin, ...rest
}) => (
  <DatePickerWrapper margin={margin}>
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disableToolbar
        variant="inline"
        format="dd/MM/yyyy"
        placeholder="DD/MM/YYYY"
        margin="normal"
        id={id}
        label={label}
        value={value}
        onChange={onChange}
        style={style}
        KeyboardButtonProps={{
          "aria-label": "select date",
        }}
        InputLabelProps={{
          shrink: true
        }}
        {...rest}
      />
    </MuiPickersUtilsProvider>
  </DatePickerWrapper>
);

export default ControlledDatePicker;
