import styled from "styled-components";
import Button from "@material-ui/core/Button";
import { theme } from "../../util/theme";

export const Wrapper = styled.div`
  .MuiFormControl-root {
    background-color: ${theme.color.white};
    color: #637381;
    width: 100%;
  }

  .MuiInputBase-multiline {
    padding: 24px 0 0 16px;
  }

  .MuiInputBase-inputMultiline {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 20px;
    text-transform: lowercase;
  }

  .MuiInput-underline:before,
  .MuiInput-underline:after {
    display: none;
  }

  .MuiFormLabel-root.Mui-focused {
    color: #2AA96D;
  }

  .MuiFormControl-root > label {
    margin: 8px 16px;
  }

  label + .MuiInput-formControl {
    margin: 0;
  }

  .MuiInput-root {
    border: 1px solid #cbd4da;
    border-radius: 3px;
  }

  .MuiInput-root.Mui-focused {
    border: 1px solid ${theme.color.primary};
  }
`;

export const DropzoneArea = styled.div`
  box-sizing: border-box;
  padding-top: 20px;
  padding-bottom: 20px;
  border: 2px dashed #dfe3e8;
  border-radius: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  cursor: pointer;
  margin-top: 10px;
  color: ${theme.color.primary};
  font-size: 14px;
  span {
    color: #637381;
    font-size: 12px;
  }
  &:focus {
    outline: 2px dashed ${theme.color.primary};
  }
`;

export const UploadDocumentContainer = styled.div`
  box-sizing: border-box;
  border: 1px solid #dfe1e5;
  border-radius: 3px;
  background-color: #fafbfc;

  .MuiLinearProgress-colorPrimary {
    background-color: #fafbfc;
  }

  .MuiLinearProgress-barColorPrimary {
    background-color: ${({ error }) => (error ? "#F21500" : `${theme.color.success}`)};
  }
`;

export const UploadDocumentContent = styled.div`
  padding: 10px;
  display: flex;
  justify-content: space-between;

  svg {
    cursor: pointer;
    width: 14px;
  }
`;

export const UploadingContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const UploadingDocumentContainer = styled.div`
  .MuiTypography-body1 {
    color: #151c2f;
    font-size: 10px;
    font-weight: 400;
    letter-spacing: 0;
    line-height: 14px;
    cursor: pointer;
  }
`;

export const UploadingDocumentName = styled.div`
  .MuiTypography-body1 {
    color: #151c2f;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0;
    line-height: 14px;
  }
`;

export const UploadingDocumentLoadInfo = styled.div`
  .MuiTypography-body1 {
    height: 10px;
    color: rgba(107, 119, 140, 0.59);
    font-size: 6px;
    letter-spacing: 0;
    line-height: 10px;
  }
`;

export const ButtonContainer = styled(Button)`
  text-transform: none !important;
  box-shadow: 0px 0px 6px #e4e4e4;
`;
