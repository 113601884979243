import React, { useState } from "react"
import { Box, Grid } from "@material-ui/core"
import {
   SecSubtitleText,
   SecTitleText,
   SectionCard,
   InputStyledContainer
} from "./style"
import InputField from "../../../../../components/InputField"
import ControlledDropdown from "../../../../../components/ControlledDropdownField"
import { Roles } from "../../../../../components/AccessControl/UserRoles"
import { useRoles } from "../../../../../hooks/useUserDetails"


const BidInfoText = ({ titleText, subtitleText }) => (
   <Box marginBottom={"10px"}>
      <SecTitleText>{titleText}</SecTitleText>
      <SecSubtitleText>{subtitleText}</SecSubtitleText>
   </Box>
)
const defaultEvalInfo = {
   quotation: "",
   completionNumber: "",
   completionPeriod: 1,
};



const NoMarginInputField = ({ disabled, ...props }) => (
   <InputField
      disabled={false}
      {...props}
      style={{ margin: 0, padding: 0, backgroundColor: "#ffffff" }}
   />
)
const BidQuotation = ({
   previewMode,
   handleCompletionPeriod,
   handleCompletionNumber,
   handleQuotation
}) => {
   const [bidInfoValue, setBidInfoValue] = useState(defaultEvalInfo)

   const updateEvalInfo = (key) => value => {
      setBidInfoValue((prev) => ({
         ...prev,
         [key]: value
      }))
      if (key === "completionPeriod") {
         handleCompletionPeriod && handleCompletionPeriod(value)
      } else if (key === "completionNumber") {
         handleCompletionNumber && handleCompletionNumber(value)
      } else if (key === "quotation") {
         handleQuotation && handleQuotation(value)
      }
   }

   const { role } = useRoles()
   const isMaker = [Roles.VENDOR].includes(role)
   return (
      <Box width="100%">
         <SectionCard>
            <BidInfoText
               titleText={"Quotation"}
               subtitleText={"Enter the amount vendor is willing to execute project"}
            />
            <Box mb={2.5}>
               <InputStyledContainer>
                  <NoMarginInputField
                     label="Quotation"
                     name="quotation"
                     type="number"
                     value={bidInfoValue?.quotation}
                     setValue={updateEvalInfo('quotation')}
                     disabled={!isMaker || previewMode}
                  />
               </InputStyledContainer>
            </Box>
            <BidInfoText
               titleText={"Completed Period"}
               subtitleText="Enter the duration vendor is willing to execute project"
            />
            <Box mb={2.5}>
               <InputStyledContainer>
                  <Grid container spacing={3}>
                     <Grid item xs={6}>
                        <NoMarginInputField
                           label="Enter Number"
                           name="duration"
                           type="number"
                           value={bidInfoValue?.completionNumber}
                           setValue={updateEvalInfo('completionNumber')}
                           disabled={!isMaker || previewMode}
                        />
                     </Grid>
                     <Grid item xs={6}>
                        <ControlledDropdown
                           label="Select"
                           name="completionPeriod"
                           style={{
                              margin: 0,
                              padding: 0,
                              width: "100%",
                              backgroundColor: isMaker ? "#ffffff" : "rgba(0, 0, 0, 0.12)"
                           }}
                           disabled={!isMaker || previewMode}
                           value={bidInfoValue?.completionPeriod}
                           onChange={({ target }) => updateEvalInfo("completionPeriod")(target.value)}
                           options={[
                              { label: "Weeks", value: 1 },
                              { label: "Months", value: 2 },
                              { label: "Years", value: 3 }
                           ]}
                        />
                     </Grid>
                  </Grid>
               </InputStyledContainer>
            </Box>
         </SectionCard>
      </Box>
   )
}

export default BidQuotation