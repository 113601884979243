import React from "react";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import { useRoles } from "../../hooks/useUserDetails";

const AccessControl = ({ allowedRoles = [], children, placeholder }) => {
  const { role: userRole, isSuccess } = useRoles();

  if (!isSuccess) {
    return (
      <Box
        padding={10}
        width={"100%"}
        display={"flex"}
        justifyContent={"center"}
      >
        <CircularProgress size={20} />
      </Box>
    );
  }

  if (allowedRoles.includes(userRole)) {
    return children;
  }

  return !!placeholder ? placeholder : null;
};

export default AccessControl;

