import React, { useContext, useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { Box, TextField } from "@material-ui/core";
import { matchPath, Route, Switch, useLocation, useParams, useHistory } from "react-router-dom";
import { queryCache, useMutation, useQuery } from "react-query";

import CircularProgressBar from "../../../components/CircularProgressBar";
import SectionTimeLine from "../../../components/SectionTimeLine";
import FormContext from "../../../util/context";
import { ContentWrapper } from "../Administration/Staff/style";
import withAdminDashboard from "../HOC/withAdminDashboard";
import {
  ApprovedTag,
  CreateProcurementTitle,
  ModalBody,
  ModalButtons,
  ModalDescription,
  ModalTitle,
  NeedsAmendmentText,
  ReviewBudget,
  ReviewDetails,
  ReviewDivider,
  ReviewLabel,
  ReviewText,
  ReviewTitle,
  ReviewWrapper,
  SectionFormContainer,
  TenderId,
} from "./style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import KeyDetails from "../../../components/Forms/CreateProcurementPlan/KeyDetails";
import Planning from "../../../components/Forms/CreateProcurementPlan/Planning";
import PROCUREMENT_PLAN_TYPES from "../../../util/procurementPlanType";
import procurementAPI from "../../../redux/api/procurementAPI";
import { useRoles } from "../../../hooks/useUserDetails";
import { PROCUREMENT_STATUS } from "../../../components/Forms/CreateProcurementPlan/constants";
import Paper from "@material-ui/core/Paper";
import LoadingButton from "../../../components/LoadingButton/LoadingButton";
import Loader from "../../../components/Common/Loader";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import FileProgress from "../../../components/FileProgress/FileProgress";
import ToastContext from "../../../util/toastContext";
import format from "date-fns/format";
import { formatCurrency } from "../../../util/formatCurrency";
import Skeleton from "@material-ui/lab/Skeleton";
import { Roles } from "../../../components/AccessControl/UserRoles";
import AccessControl from "../../../components/AccessControl";
import adminAPI from "../../../redux/api/adminAPI";

import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";

const GreenCheckbox = withStyles({
  root: {
    color: "#3BD278",
    "&$checked": {
      color: "#3BD278",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const CreateProcurement = () => {
  const [activeForm, setActiveForm] = useState(-1);
  const { id } = useParams();
  const { showAlert } = useContext(ToastContext);

  const history = useHistory();
  const [completedForm, addCompletedForm] = useState([]);
  const [reasonForAmendment, setReasonForAmendment] = useState("");
  const [isReviewMode, setIsReviewMode] = useState(false);
  const [showNeedForAmendment, setShowNeedForAmendment] = useState(false);
  const [showApprove, setShowApprove] = useState(false);
  const { role: userRole, user } = useRoles();
  const permissionRoles = [Roles.PERMANENT_SECRETARY, Roles.COMMISSIONER, Roles.EXECUTIVE, Roles.SSG, Roles.GOVERNOR, Roles.MINISTERIAL_TENDERS_BOARD, Roles.ACCOUNTING_OFFICERS, Roles.PARASTATALS_TENDERS_BOARD];

  const isChecker = permissionRoles.includes(userRole);
  const [roleThresholdUpperLimit, setRoleThresholdUpperLimit] = useState(0);
  const [roleThresholdLowerLimit, setRoleThresholdLowerLimit] = useState(0);
  const [acceptApprovePlan, setAcceptApprovePlan] = useState({
    acceptApprovePlan: false,
  });

  const handleChange = (event) => {
    setAcceptApprovePlan({ ...acceptApprovePlan, [event.target.name]: event.target.checked });
  };

  const [approveProcurementPlan, approveProcurementPlanQuery] = useMutation(procurementAPI.approveProcurementPlan, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Your plan has been approved",
      });
      //Refetch query
      queryCache.removeQueries(["generalPlanDetails", procurementId]);
      setShowApprove(false);
    },
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message,
      });
    },
  });

  const [sendForApproval] = useMutation(procurementAPI.sendForApproval, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Tender has been created successfully",
      });
      setTimeout(() => {
        history.push(planUrl);
      }, 2000);
    },
    onError: (error) => {
      showAlert({
        severity: "error",
        message: error.message,
      });
    },
  });

  const [needsAmendment, needsAmendmentQuery] = useMutation(procurementAPI.needsAmendment, {
    onSuccess: () => {
      setShowNeedForAmendment(false);
      showAlert({
        severity: "success",
        message: "Tender sent for amendmment successfully",
      });
    },
    throwOnError: true,
  });

  const [addNeedAssessmentReview, addNeedAssessmentReviewQuery] = useMutation(procurementAPI.addReview, {
    onSuccess() {
      needsAmendment({
        procurementPlanId: procurementId,
      });
    },
  });

  const location = useLocation();

  const {
    params: { procurementId },
  } = matchPath(location.pathname, {
    path: "/admin/procurement/plans/:id/create/:step/:procurementId",
  });

  const generalPlanDetails = useQuery({
    queryKey: ["generalPlanDetails", procurementId],
    queryFn: procurementAPI.getPlanDetails,
    config: {
      enabled: procurementId !== "new",
      staleTime: 900 * 1000,
    },
  });

  // Get threshold limits for current user's role
  useQuery(["rolesThreshold", { selectedRole: user?.roleId, selectedCategory: generalPlanDetails?.data?.procurementCategory?.id }], adminAPI.getRoleThreshold, {
    onSuccess(data) {
      queryCache.cancelQueries("rolesThreshold");
      if (data) {
        setRoleThresholdLowerLimit(data?.data[0]?.lowerLimitThreshold);
        setRoleThresholdUpperLimit(data?.data[0]?.upperLimitThreshold);
      }
    },
  });

  // Check if tender budget is within curent user's threshold
  const canApproveTender = () => {
    if (
      roleThresholdUpperLimit >= generalPlanDetails.data?.budget &&
      roleThresholdLowerLimit <= generalPlanDetails.data?.budget
    ) {
      return true;
    } else {
      return false;
    }
  };

  useEffect(() => {
    const plan = generalPlanDetails.data;
    if (plan?.id) {
      setIsReviewMode(plan.status === PROCUREMENT_STATUS.IN_REVIEW);
    }
    if (generalPlanDetails.data?.status === "UNDERREVIEW") {
      addCompletedForm([0, 1, 2]);
    }
  }, [generalPlanDetails.data]);

  const handleApprove = () => {
    try {
      approveProcurementPlan({
        procurementPlanId: procurementId,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const handleNeedsAmendment = async () => {
    try {
      addNeedAssessmentReview({
        id: reasonForAmendment.length + 1,
        objectId: procurementId,
        message: reasonForAmendment,
      });
    } catch (e) {
      showAlert({
        message: e.message,
        severity: "error",
      });
    }
  };

  const handleSendForApproval = async (procurementId, onSuccess) => {
    try {
      await sendForApproval({
        procurementPlanId: procurementId,
      });
      onSuccess();
      return true;
    } catch (e) {
      return false;
    }
  };

  const handleRedirect = (stage) => {
    const planId = generalPlanDetails.data?.id;
    history.push(`/admin/procurement/plans/${id}/create/${stage}/${planId}`);
  };

  const sections = [
    {
      title: "Section One",
      onClickFn: !!generalPlanDetails.data?.id ? () => handleRedirect("keydetails") : null,
      label: "Key Details",
    },
    {
      title: "Section Two",
      onClickFn: !!generalPlanDetails.data?.id ? () => handleRedirect("planning") : null,
      label: "Planning",
    },
    {
      title: "Section Three",
      onClickFn: !!generalPlanDetails.data?.id ? () => handleRedirect("execution") : null,
      label: "Monitoring",
    },
  ];

  const renderNeedForAmendmentModal = () => {
    const onChange = (text) => {
      setReasonForAmendment(text);
    };

    return (
      <Modal open={showNeedForAmendment} onClose={() => setShowNeedForAmendment(false)}>
        <ModalBody style={{ top: "50px" }}>
          <ModalTitle>Needs Amendment?</ModalTitle>
          <ModalDescription>Plan will be sent back to Procurement Officer for further reviews</ModalDescription>
          <TextField
            id="outlined-textarea"
            multiline
            InputProps={{
              disableUnderline: true,
            }}
            onChange={(e) => onChange(e.target.value)}
            value={reasonForAmendment}
            variant="outlined"
            placeholder="Type reason here"
            rows={15}
            style={{ marginBottom: 20 }}
          />
          <ModalButtons>
            <LoadingButton
              style={{
                marginLeft: "0",
              }}
              onClick={handleNeedsAmendment}
              loading={needsAmendmentQuery.isLoading || addNeedAssessmentReviewQuery.isLoading}
            >
              Proceed To Amendment
            </LoadingButton>
            <Button onClick={() => setShowNeedForAmendment(false)} variant="outlined">
              Cancel
            </Button>
          </ModalButtons>
        </ModalBody>
      </Modal>
    );
  };

  const renderApproveModal = () => {
    return (
      <Modal open={showApprove} onClose={() => setShowApprove(false)}>
        <ModalBody>
          <ModalTitle>Approve Plan?</ModalTitle>
          <ModalDescription style={{ marginBottom: 10 }}>
            I agree to take responsibility for the approval of the tender package
          </ModalDescription>

          <FormControlLabel
            style={{ marginBottom: 20 }}
            control={
              <GreenCheckbox checked={acceptApprovePlan.acceptApprovePlan} onChange={handleChange} name="acceptApprovePlan" />
            }
            label={<ModalDescription style={{ marginBottom: 0 }}>I agree</ModalDescription>} // "I agree"
          />
          <ModalButtons>
            <LoadingButton
              style={{
                marginLeft: "0",
              }}
              color="#3BD278"
              disabled={!acceptApprovePlan.acceptApprovePlan}
              onClick={handleApprove}
              loading={approveProcurementPlanQuery.isLoading}
            >
              Yes, I approve.
            </LoadingButton>
            <Button
              style={{ textTransform: "capitalize" }}
              onClick={() => setShowApprove(false)}
              variant="outlined"
            >
              Cancel
            </Button>
          </ModalButtons>
        </ModalBody>
      </Modal>
    );
  };

  const redirectToFileUrl = (url) => {
    window.open(url, "_blank");
  };

  const renderBottomLeftReview = () => {
    const file = generalPlanDetails.data?.approvalDocument?.file;
    if (generalPlanDetails.data?.status === "APPROVED") {
      return (
        <Grid container wrap="nowrap" alignItems="stretch">
          <Box mr={!!file ? 1.5 : 0}>
            <ApprovedTag style={{ minHeight: 50 }}> Approved</ApprovedTag>
          </Box>
          {!!file && (
            <FileProgress
              progress={100}
              fileName={file?.original_filename}
              fileSize={file?.bytes}
              hasEyes={true}
              onClickEyes={() => redirectToFileUrl(file?.url)}
            />
          )}
        </Grid>
      );
    }

    if (needsAmendmentQuery.isSuccess) {
      return <NeedsAmendmentText>Sent back for amedment</NeedsAmendmentText>;
    } else {
      return (
        <AccessControl allowedRoles={[Roles.GOVERNOR]}>
          <Grid container>
            <Box mr={1.5}>
              <LoadingButton onClick={() => setShowNeedForAmendment(true)}>Need Amendment</LoadingButton>
            </Box>
            {canApproveTender() ? (
              <LoadingButton
                style={{ textTransform: "none" }}
                color="#3BD278"
                onClick={() => setShowApprove(true)}
              >
                Approve Tender
              </LoadingButton>
            ) : (
              <LoadingButton
                style={{ textTransform: "none" }}
                color="#3BD278"
                onClick={() => null}
              >
                Endorse Tender
              </LoadingButton>
            )}
          </Grid>
        </AccessControl>
      );
    }
  };

  const renderReviewBanner = () => {
    if (generalPlanDetails.isLoading) {
      return (
        <Box mb={3.75}>
          <Skeleton variant="rect" height="200px" width="100%" />
        </Box>
      );
    }

    if (!generalPlanDetails.data?.id || procurementId === "new") {
      return null;
    }

    return (
      <ReviewWrapper>
        <ReviewTitle>You are in Review mode</ReviewTitle>
        <Paper>
          <Box p={2.5}>
            <Grid container justify="space-between">
              <div>
                <TenderId>{generalPlanDetails.data?.packageNumber}</TenderId>
                <ReviewText>{generalPlanDetails.data?.name}</ReviewText>
              </div>
              <div>
                <ReviewLabel>Budget</ReviewLabel>
                <ReviewBudget>{formatCurrency(generalPlanDetails.data?.budget, true, true)}</ReviewBudget>
              </div>
            </Grid>
            <ReviewDivider />
            <Grid container justify="space-between">
              <ReviewDetails>
                <div>
                  <ReviewLabel>Procurement Category</ReviewLabel>
                  <ReviewText>{generalPlanDetails.data?.procurementCategory?.name}</ReviewText>
                </div>
                <div>
                  <ReviewLabel>Procurement Method</ReviewLabel>
                  <ReviewText>{generalPlanDetails.data?.procurementMethod?.name}</ReviewText>
                </div>
                <div>
                  <ReviewLabel>Tendering Stage</ReviewLabel>
                  <ReviewText>{generalPlanDetails.data?.tenderingStage?.name}</ReviewText>
                </div>
                <div>
                  <ReviewLabel>Review Method</ReviewLabel>
                  <ReviewText>{generalPlanDetails.data?.reviewMethod?.name}</ReviewText>
                </div>
              </ReviewDetails>
              <div>
                <Box mb={2.5}>
                  <div>
                    <ReviewLabel rightAligned>Date Received</ReviewLabel>
                    <ReviewText rightAligned>
                      {generalPlanDetails.data?.createdAt &&
                        format(new Date(generalPlanDetails.data?.createdAt), "dd MMMM, yyyy")}
                    </ReviewText>
                  </div>
                </Box>
                {isChecker && renderBottomLeftReview()}
              </div>
            </Grid>
          </Box>
        </Paper>
      </ReviewWrapper>
    );
  };

  const planUrl = `/admin/procurement/plans/${id}`;

  return (
    <FormContext.Provider
      value={{
        activeForm,
        completedForm,
        setActiveForm,
        addCompletedForm,
        sectionSize: sections.length,
        isReviewMode,
        setIsReviewMode,
        handleApprove,
        handleNeedsAmendment,
        handleSendForApproval,
        userRole,
      }}
    >
      {renderNeedForAmendmentModal()}
      {renderApproveModal()}
      <ContentWrapper>
        <Box marginBottom="35px">
          <CreateProcurementTitle>{isReviewMode ? "Review Activity" : "Create Tender"}</CreateProcurementTitle>
          {!isReviewMode && (
            <Breadcrumb
              values={[
                { title: "Home", url: "/admin/procurement/plans" },
                { title: "Plan", url: planUrl },
              ]}
            />
          )}
        </Box>

        <AccessControl allowedRoles={[Roles.KWPPA, ...permissionRoles]}>{renderReviewBanner()}</AccessControl>
        <SectionTimeLine sectionCardDetails={sections} isReviewMode={isReviewMode} />
        <Grid container spacing={3}>
          <Grid item xs={9}>
            <SectionFormContainer>
              {generalPlanDetails.isLoading ? (
                <Box p={5}>
                  <Loader />
                </Box>
              ) : (
                <Switch>
                  <Route exact path="/admin/procurement/plans/:id/create/keydetails/:procurementId">
                    <KeyDetails addCompletedForm={addCompletedForm} completedForm={completedForm} />
                  </Route>
                  <Route exact path="/admin/procurement/plans/:id/create/planning/:procurementId">
                    <Planning
                      planUrl={planUrl}
                      section={{
                        type: PROCUREMENT_PLAN_TYPES.PLANNING,
                        title: "Planning",
                        submitButtonText: "Continue",
                      }}
                      completedFormIndex={1}
                      addCompletedForm={addCompletedForm}
                      completedForm={completedForm}
                    />
                  </Route>
                  <Route exact path="/admin/procurement/plans/:id/create/execution/:procurementId">
                    <Planning
                      planUrl={planUrl}
                      section={{
                        type: PROCUREMENT_PLAN_TYPES.EXECUTION,
                        title: "Execution",
                        submitButtonText: "Send For Approval",
                      }}
                      completedFormIndex={2}
                      addCompletedForm={addCompletedForm}
                      completedForm={completedForm}
                    />
                  </Route>
                </Switch>
              )}
            </SectionFormContainer>
          </Grid>
          <Grid item xs={3}>
            <CircularProgressBar
              textValues={["Key Details", "Planning", "Monitoring"]}
              completedForm={completedForm}
              totalItems={3}
            />
          </Grid>
        </Grid>
      </ContentWrapper>
    </FormContext.Provider>
  );
};

export default withAdminDashboard(CreateProcurement);
