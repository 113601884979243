import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import InputField from "../../../../components/InputField";
import Dropdown from "../../../../components/DropdownField";
import { ActionButton, AddButton, CirclePlusIconWrapper, ContentWrapper, TitleContainer } from "./style";
import StaffDrawer from "../../../../components/StaffDrawer";
import SearchTable from "../../../../components/SearchTable";
import { CardWrapper } from "../../../../util/theme";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";
import AdminCard from "../../../../components/AdminCard";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import withLoader from "../../../Common/HOC/withLoader";
import ToastContext from "../../../../util/toastContext";
import { PageTitle } from "../../../Common/style";
import MinistryDropdown from "../../../../components/MinistryDropdown";
import { useQuery } from "react-query";
import adminAPI from "../../../../redux/api/adminAPI";
import AccessControl from "../../../../components/AccessControl";
import { Roles } from "../../../../components/AccessControl/UserRoles";
import { useRoles } from "../../../../hooks/useUserDetails";
import StaffTable from "./StaffTable";
import { getStaffSummary, getStaff } from "../../../../redux/reducer/adminReducer";

const Staff = () => {
  const { role, user } = useRoles();
  const allowedRoles = [Roles.KWPPA, Roles.MODERATOR];
  const isAllowedRole = allowedRoles.includes(role);
  const rolesWithMinitries = [Roles.PROCUREMENT_OFFICER, Roles.PERMANENT_SECRETARY, Roles.COMMISSIONER, Roles.MODERATOR, Roles.MINISTERIAL_TENDERS_BOARD];
  const rolesWithoutMinitries = [Roles.GOVERNOR, Roles.KWPPA, Roles.EXECUTIVE, Roles.SSG, Roles.MINISTRY_OF_JUSTICE, Roles.ADMIN, Roles.CONTRACTORS_REG_BOARD, Roles.TENDER_BOARD, Roles.ACCOUNTING_OFFICERS, Roles.PARASTATALS_TENDERS_BOARD];


  const { showAlert } = useContext(ToastContext);
  const [openModal, setOpenModal] = useState(false);

  const toggleDrawer = () => {
    setOpenModal(!openModal);
  };

  const AdminCardSummary = () => {
    const { staffSummary } = useSelector((state) => state.admin);
    return (
      <AdminCard
        title={"Total Staff"}
        count={(staffSummary && staffSummary.total) || 0}
        statuses={[
          { text: `${(staffSummary && staffSummary.activeTotal) || 0} Enabled`, bgColor: "#BBE5B3", textColor: "#108043" },
          { text: `${(staffSummary && staffSummary.pendingTotal) || 0} Disabled`, bgColor: "#FCF1CD", textColor: "#F49342" },
        ]}
      />
    );
  };

  const [filters, setFilters] = useState({
    name: "",
    status: "",
    ministryId: "",
  });

  const updateFilters = (key) => (value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: value,
    }));
  };

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });


  const getMinistryId = () => {
    let ministryId;
    let showMinistryFilter = true;

    if (rolesWithMinitries.includes(role)) {
      ministryId = user?.ministry?.id;
      showMinistryFilter = false;
    } else {
      ministryId = filters.ministryId;
    }
    return {
      ministryId,
      showMinistryFilter,
    };

  };

  const { data: staffData = {}, isLoading, error = {}, isError, clear } = useQuery({
    queryFn: adminAPI.getStaff,
    queryKey: [
      "staffs",
      {
        pageSize: pagination.pageSize,
        pageNumber: pagination.pageNumber,
        name: filters.name,
        status: filters.status,
        ministryId: getMinistryId().ministryId,
      },
    ],
  });

  const [selectedRows, setSelectedRows] = useState([]);

  const rows = staffData?.data ?? [];

  const determineStatus = (status) => (status === "ENABLED" ? "Disable" : "Enable");

  const renderSelectionAction = () => {
    const status = selectedRows[0].status;
    // Mixed selection
    if (selectedRows.some((row) => row.status !== status)) return null;
    return <ActionButton>{determineStatus(status)}</ActionButton>;
  };

  const handleToggleSelectAll = (selectAll) => {
    if (selectAll) {
      setSelectedRows(rows);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSelectRow = (row) => {
    setSelectedRows((selectedRows) => {
      if (isRowSelected(row)) {
        return selectedRows.filter((selectedRow) => selectedRow !== row);
      } else {
        return [...selectedRows, row];
      }
    });
  };

  const isRowSelected = (row) => selectedRows.includes(row);

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (isError) {
      showAlert({
        severity: "error",
        message: error?.message,
      });
    }
    //eslint-disable-next-line
  }, [showAlert]);

  const onCloseDrawer = () => {
    setOpenModal(false);
    clear();
  };

  return (
    <ContentWrapper>
      <TitleContainer>
        <PageTitle>Staff</PageTitle>
        {isAllowedRole && (
          <AddButton onClick={() => toggleDrawer()}>
            <CirclePlusIconWrapper /> Add Staff
          </AddButton>
        )}
      </TitleContainer>
      <CardWrapper>
        <AccessControl allowedRoles={rolesWithMinitries}>
          {withLoader(AdminCardSummary)({
            apis: [getStaffSummary(getMinistryId().ministryId)],
            conditioners: ["staffSummary"],
            stateName: "admin",
            load: rolesWithMinitries.includes(role)
          })}
        </AccessControl>
        <AccessControl allowedRoles={rolesWithoutMinitries}>
          {withLoader(AdminCardSummary)({
            apis: [getStaffSummary(getMinistryId().ministryId)],
            conditioners: ["staffSummary"],
            stateName: "admin",
            load: rolesWithoutMinitries.includes(role)
          })}
        </AccessControl>
      </CardWrapper>
      <AdminSearchFilter>
        <div style={{ display: "flex", flex: 1 }}>
          <InputField type="text" label="Search name" value={filters.name} setValue={updateFilters("name")} />
          {getMinistryId().showMinistryFilter && <MinistryDropdown onChange={updateFilters("ministryId")} useReactQuery />}
          <Dropdown
            name="status"
            label="Status"
            values={[
              {
                value: "",
                label: "Select a Status",
              },
              {
                label: "Enabled",
                value: 2,
              },
              {
                label: "Disabled",
                value: 1,
              },
            ]}
            onChange={updateFilters("status")}
          />
        </div>
      </AdminSearchFilter>
      <SearchTable
        rows={rows}
        columns={["name", "ministry", "role", "status", isAllowedRole && "action"]}
        tableAPI={getStaff}
        page={pagination.pageNumber}
        setPage={updatePagination("pageNumber")}
        rowsPerPage={pagination.pageSize}
        setRowsPerPage={updatePagination("pageSize")}
        loading={isLoading}
        totalNumberOfPages={staffData?.pagination?.totalEntries}
        enableSelection
        selectionProps={{
          numRows: rows.length,
          numSelected: selectedRows.length,
          onToggleSelectAll: handleToggleSelectAll,
          renderSelectionAction,
        }}
      >
        <StaffTable
          staff={rows}
          showAlert={showAlert}
          toggleDrawer={toggleDrawer}
          filters={filters}
          pagination={pagination}
          isRowSelected={isRowSelected}
          onSelectRow={handleSelectRow}
          allowedRoles={allowedRoles}
        />
      </SearchTable>
      {openModal && <StaffDrawer toggleDrawer={onCloseDrawer} openModal={openModal} />}
    </ContentWrapper>
  );
};

export default withAdminDashboard(Staff);
