import Typography from "@material-ui/core/Typography";
import styled from "styled-components";
import TableCell from "@material-ui/core/TableCell"
import { StyledTableRow } from "../../../components/SearchTable";
import { PageTitle } from "../../Common/style";
import { theme } from "../../../util/theme";
import InputField from "../../../components/InputField";


export const VendorUpgradeRequestRowStyle = styled(StyledTableRow)`
border-left: 5px solid transparent;
transition: all .3s;
  &:hover {
    border-left-color: ${theme.color.primary};
    cursor: pointer;
  }
`;

export const BreadCrumblabel = styled(Typography)`
  height: 16px;
  margin-top: 35px !important;
  margin-bottom: 35px !important;
  color: ${theme.color.primary};
  font-size: 14px !important;
  letter-spacing: 0;
  line-height: 16px;
  display: flex;
  flex-direction: row;
`;

export const BreadcrumbActive = styled.span`
  height: 16px;
  color: #6d7278;
  font-size: 14px !important;
  font-weight: 600;
  letter-spacing: 0;
  margin-left: 8px !important;
`;

export const VendorRequestTitlePage = styled(PageTitle)`
  margin-bottom: 5px !important;
`;
export const RequestInputField = styled(InputField)`
  width: 100%;
`;
export const StyledTableCell = styled(TableCell)`
  text-transform: capitalize !important;
  text-align: left;
  border-radius: 0;
`;
