/* eslint-disable no-unused-expressions */
import React, { useContext } from "react";
import { BackButton, SaveButton, Wrapper, DoubleWrapper, SaveAndExit } from "./style";
import FormContext from "../../util/context";

const NavigationButtons = ({ nextActionHandler, previousActionHandler, value, backText, isProcurement, ...props }) => {
  const { loading, disabled, ...otherProps } = props;
  const { activeForm, completedForm, setActiveForm, addCompletedForm, sectionSize, updateStage, updateStageQuery } = useContext(
    FormContext
  );

  const handlePrevious = () => {
    setActiveForm(activeForm - 1);
    previousActionHandler && previousActionHandler();
  };

  const handleNext = (e) => {
    e.preventDefault();
    const afterSuccess = () => {
      const next = () => {
        !(activeForm + 1 > sectionSize - 1) && setActiveForm(activeForm + 1);
        !completedForm.includes(activeForm) && addCompletedForm((state) => [...state, activeForm]);
      };
      updateStage ? updateStage(activeForm + 1, next) : next();
    };

    nextActionHandler ? nextActionHandler(afterSuccess)() : afterSuccess();
  };

  return (
    <Wrapper container justify="space-between">
      {Boolean(activeForm) && <BackButton onClick={handlePrevious}>{backText || "Previous"}</BackButton>}
      {!isProcurement ? (
        <SaveButton type="submit" onClick={handleNext} {...props}>
          {value || "Save and Next"}
        </SaveButton>
      ) : (
        <DoubleWrapper>
          {Boolean(activeForm) && (
            <SaveAndExit
              type="submit"
              {...props}
              onClick={(event) => {
                event.preventDefault();
              }}
            >
              Save and Exit
            </SaveAndExit>
          )}
          <SaveButton type="submit" disabled={disabled} onClick={handleNext} loading={loading || updateStageQuery?.isLoading} {...otherProps}>
            {value || "Save and Next"}
          </SaveButton>
        </DoubleWrapper>
      )}
    </Wrapper>
  );
};

export default NavigationButtons;
