import React from "react";
import { Route, Switch } from "react-router-dom";
import withAdminDashboard from "../../../HOC/withAdminDashboard";
import BidderResponseReceipt from "./BidderResponseReceipt";
import EvaluatedVendors from "./EvaluatedVendors";
import VendorEvaluation from "./VendorEvaluation";
import ManualPayments from "./ManualPayments/ManualPayments";
// import CreateTechnicalEvaluation from "./CreateTechnicalEvaluation/Index";
import TechnicalReview from "./TechnicalReviews";
import FinancialReviews from "./FinancialReviews";
// import UpdateTechnicalEvaluation from "./CreateTechnicalEvaluation/UpdateTechEvaluation";
import TechnicalReviewDetails from "../ReceiptOfResponse/TechnicalReviewDetails";

const baseUrl = "/admin/procurement/activity/:activityName/:activityId/:procurementId";

const BidderResponseReceiptWrapper = () => {
  return <div>
    <Switch>
      <Route path={baseUrl} component={BidderResponseReceipt} exact />
      <Route path={`${baseUrl}/evaluation/:vendorId`} component={VendorEvaluation} exact />
      <Route path={`${baseUrl}/evaluated/vendor`} component={EvaluatedVendors} exact />

      <Route path={`${baseUrl}/technical-reviews`} component={TechnicalReview} exact />
      <Route path={`${baseUrl}/financial-reviews`} component={FinancialReviews} exact />

      <Route path={`${baseUrl}/technical-reviews/:vendorId`} component={TechnicalReviewDetails} exact />

      <Route path={`${baseUrl}/payments`} component={ManualPayments} exact />
      {/* <Route path={`${baseUrl}/create-technical-evaluation`} component={CreateTechnicalEvaluation} exact />

      <Route path={`${baseUrl}/edit-technical-evaluation`} component={UpdateTechnicalEvaluation} exact /> */}
    </Switch>
  </div>;
}

export default withAdminDashboard(BidderResponseReceiptWrapper);
