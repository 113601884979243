import React, { useState, useEffect, useContext } from "react";
import { Box, Grid } from "@material-ui/core";
import { useParams, useHistory } from "react-router-dom";
import { SearchButton } from "../../../../components/Forms/Common/style";
import { ContentWrapper, TitleContainer } from "../../../Admin/Administration/Staff/style";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";
import { PageTitle } from "../../../Common/style";
import { BiddingDocumentContainer } from "../../BidDetails/BidInfo/style";
import ProcurementDocSection from "../DocSection";
import ProcurementInfo from "../ProcurementInfo";
import { useMutation, useQuery, useQueryCache } from "react-query";
import vendorsAPI from "../../../../redux/api/vendorsAPI";
import procurementAPI from "../../../../redux/api/procurementAPI";
import { useSelector } from "react-redux";
import ToastContext from "../../../../util/toastContext";
import Loader from "../../../../components/Common/Loader";
import withVendorDashboard from "../../HOC/withVendorDashboard";
import Skeleton from "@material-ui/lab/Skeleton";
import { MainText, MainTitle, CardWrapper, Pad, DocumentSection, InterestExpressedPane, CuteGrid, ProgressWrap, Overlay } from "../style";
import DocumentUploadField from "../../../../components/DocumentUploadField";
import ExpressInterestModal from "./ExpressInterestModal";
import RejectModal from "./RejectModal";
import { ReactComponent as CheckIcon } from "../../../../assets/check_mark.svg";
import { ReactComponent as DisabledCheckIcon } from "../../../../assets/disabled_check_mark.svg";
import LinearProgress from "@material-ui/core/LinearProgress";
import { withStyles } from "@material-ui/core/styles";
import FileProgress from "../../../../components/FileProgress/FileProgress";
import { Title, Subtitle } from "../../../Admin/PlanDetails/ApprovalMemo/style";
import { EyeNotAllowedIcon } from "../../BidDetails/BidDocuments/PaymentRequiredNotice/style";

const SingleProcurement = () => {
  const cache = useQueryCache();
  const { id: procurementId } = useParams();
  const history = useHistory();
  const { UserId } = useSelector((state) => state.user.user);

  const [expressInterest, expressInterestQuery] = useMutation(vendorsAPI.expressInterest, {
    onSuccess() {
      setInterestModal(false);
    },
  });
  const [interest, setInterest] = useState("none");
  const [trackProgress, setTrackProgress] = useState([]);
  const [hasRequiredFileUpload, setHasRequiredFileUpload] = useState(false);
  const [uploadedDoc, setUploadedDoc] = useState({});
  const [interestModal, setInterestModal] = useState(false);
  const [rejectModal, setRejectModal] = useState(false);
  const [isInterested, setIsInterested] = useState(false);

  const { data: vendorClass } = useQuery(["vendorClass", { UserId }], vendorsAPI.getVendorClass);



  const addMandatoryFiles = (file, id) => {
    !trackProgress.includes(id) && setTrackProgress((prev) => [...prev, id]);
    setUploadedDoc((prev) => ({ ...prev, [id]: Array.isArray(prev[id]) ? prev[id].concat(...file) : file }));
  };

  const removeMandatoryFiles = (file, id) => {
    const filterFiles = uploadedDoc[id].filter((x) => x.name !== file.name);
    setUploadedDoc((prev) => ({ ...prev, [id]: filterFiles }));
  };

  const { showAlert } = useContext(ToastContext);

  const { error, data = {}, status, isLoading } = useQuery(
    ["getProcurementPlanInformation", procurementId],
    procurementAPI.getProcurementPlanInformation
  );


  const checkInterestQuery = useQuery(["hasVendorExpressedInterest", data?.tender?.id], vendorsAPI.hasVendorExpressedInterest, {
    enabled: data?.tender?.id,
  });

  const userDetails = cache.getQueryData("userDetails");

  const uploadedLoadedDocument = useQuery(
    ["uploadedDocument", { id: data?.tender?.id, vendorId: userDetails?.id }],
    vendorsAPI.fetchUploadedDoc
  );

  useEffect(() => {
    if (!!error) {
      if (error.statusCode === 404) {
        history.push(`/vendor/bids/${procurementId}`);
      } else {
        showAlert({ severity: "error", message: error.message });
      }
    }
    // eslint-disable-next-line
  }, [error]);

  const BreadcrumbLinks = [
    {
      url: "/vendor/procurement",
      title: "Procurements",
      forwardSlash: true,
    },
    {
      url: `/vendor/procurement/${procurementId}`,
      title: `${data?.tender?.name}`,
      forwardSlash: false,
    },
  ];

  const handleExpressClick = () => {
    if (checkInterestQuery.status === "error") return;
    const formData = new FormData();

    const allKeys = Object.keys(uploadedDoc);

    allKeys.map((eachKey, index) => {
      return formData.append(`RequiredDocuments[${index}].RequirementId`, eachKey);
    });

    allKeys.map((eachKey, index) => {
      return uploadedDoc[eachKey].map((eachDoc) => formData.append(`RequiredDocuments[${index}].Documents`, eachDoc));
    });
    expressInterest({
      procurementPlanId: data?.tender?.id,
      formData,
    });
  };

  useEffect(() => {
    if (expressInterestQuery.status === "error") {
      showAlert({
        severity: "error",
        message: expressInterestQuery.error.message,
      });
    }
    if (expressInterestQuery.status === "success") {
      showAlert({
        severity: "success",
        message: "Successfully expressed interest in this procurement notice",
      });
      setInterest("interested");
    }
    // eslint-disable-next-line
  }, [expressInterestQuery.status, expressInterestQuery.error]);

  useEffect(() => {
    if (checkInterestQuery.status === "error") {
      showAlert({
        severity: "error",
        message: checkInterestQuery.error.message,
      });
    }
    if (checkInterestQuery.status === "success") {
      setInterest(checkInterestQuery.data ? "interested" : "none");
    }
    // eslint-disable-next-line
  }, [checkInterestQuery.status, checkInterestQuery.error, checkInterestQuery.data]);

  useEffect(() => {
    const allKeys = Object.keys(uploadedDoc);
    allKeys.map((eachKey) => {
      const removeTrack = trackProgress.filter((x) => x !== eachKey);
      return uploadedDoc[eachKey].length === 0 && setTrackProgress(removeTrack);
    });
  }, [uploadedDoc, trackProgress]);



  useEffect(() => {
    const compulsoryFiles = data?.prequalificationRequirements?.filter(el => el.isCompulsory) || [];

    let isRequiredFilesUploaded = compulsoryFiles?.reduce((acc, file) => {
      return acc && trackProgress.includes(file.id);
    }, true);
    setHasRequiredFileUpload(isRequiredFilesUploaded);

  }, [trackProgress, data]);

  return (
    <ContentWrapper>
      {(vendorClass?.contractMaxValue) < (data?.tender?.budget) &&
        <Overlay>
          <EyeNotAllowedIcon />
          <Title>Access denied!</Title>
          <Subtitle>
            The budget of this procurement is higher than the maximum contract value you can bid for currently.
          </Subtitle>
        </Overlay>
      }
      <ExpressInterestModal
        isLoading={expressInterestQuery.isLoading}
        open={interestModal}
        handleOnClick={handleExpressClick}
        setModal={setInterestModal}
        setInterest={setIsInterested}
        numOfUploadedDocs={trackProgress.length}
        numOfRequiredDocs={data?.prequalificationRequirements?.length}
      />
      <RejectModal open={rejectModal} setModal={setRejectModal} />
      <TitleContainer>
        {!isLoading && (
          <div>
            <PageTitle>{data?.procurementPlan?.packageNumber}</PageTitle>
            <Breadcrumb values={BreadcrumbLinks} />
          </div>
        )}
        {isLoading && <Skeleton width="300px" height="100px" />}
      </TitleContainer>
      {(isInterested || interest !== "none") && (
        <InterestExpressedPane>
          <CuteGrid>
            <div>
              <MainText color="#212B36" fw="bold" fontSize="14px">
                You have expressed interest
              </MainText>
              <MainText color="#212B36" mt="10px" fontSize="14px">
                Kindly wait till you're notified for the next stage
              </MainText>
            </div>
            <div>
              <CheckIcon />
            </div>
          </CuteGrid>
        </InterestExpressedPane>
      )}
      <CardWrapper>
        <Pad>
          <ProcurementInfo info={data} isLoading={status === "loading"} />
          <BiddingDocumentContainer style={{ marginBottom: "10px" }}>
            <Box display="flex">
              <MainTitle style={{ marginBottom: "10px" }}>Attachments</MainTitle>
            </Box>
            {status === "loading" && <Loader />}
            {status !== "loading" && <ProcurementDocSection documents={data?.supportingDocuments || []} />}
          </BiddingDocumentContainer>
          <MainTitle mt="20px">Requirements</MainTitle>
          <MainText mt="15px" fontSize="14px">
            Below are the list of all required documents
          </MainText>
          <ProgressWrap style={{ display: interest === "none" ? "block" : "none" }}>
            <MainText fontSize="13px">{`You have uploaded ${trackProgress.length} of ${data?.prequalificationRequirements?.length} documents`}</MainText>
            <MainText mt="10px"></MainText>
            <BorderLinearProgress
              variant="determinate"
              value={(trackProgress.length / data?.prequalificationRequirements?.length) * 100}
              size={40}
              thickness={4}
            />
          </ProgressWrap>
        </Pad>
        {data?.prequalificationRequirements?.map((eachReq) => (
          <DocumentSection style={{ marginBottom: "30px" }} key={eachReq.id}>
            <Box display="flex" alignItems="center">
              <MainTitle>{eachReq.title}</MainTitle>
              {eachReq.isCompulsory && <MainText fontSize="14px" style={{ marginLeft: 10 }}>
                (Compulsory)
              </MainText>}
            </Box>
            <MainText mt="10px" fontSize="14px">
              {eachReq.description}
            </MainText>
            <MainText mt="20px"></MainText>

            <div className="icon">
              {trackProgress.includes(eachReq.id) || interest !== "none" ? <CheckIcon /> : <DisabledCheckIcon />}
            </div>

            {interest === "none" && (
              <DocumentUploadField
                files={uploadedDoc[eachReq.id] || []}
                addFiles={(file) => addMandatoryFiles(file, eachReq.id)}
                removeFile={(file) => removeMandatoryFiles(file, eachReq.id)}
                loading={isLoading}
              />
            )}

            {uploadedLoadedDocument.isLoading && <Loader />}

            {!uploadedLoadedDocument?.isLoading && (
              <Grid container>
                {uploadedLoadedDocument?.data?.requirements?.map(
                  (eachReqFile) =>
                    eachReqFile.id === eachReq.id &&
                    eachReqFile?.requiredDocuments?.map((eachDoc) => (
                      <Grid key={eachDoc.id} item xs={6}>
                        <FileProgress hasEyes={true} fileName={eachDoc?.name} fileSize={eachDoc?.file?.bytes} progress={100} />
                      </Grid>
                    ))
                )}
              </Grid>
            )}
          </DocumentSection>
        ))}
        <MainText mt="25px"></MainText>
        <Pad>
          <Box display="flex" alignItems="center" justifyContent="flex-end">
            <SearchButton
              noBottom
              style={{ minWidth: "155px" }}
              onClick={() => setInterestModal(true)}
              loading={expressInterestQuery.status === "loading" || checkInterestQuery.status === "loading"}
              disabled={!hasRequiredFileUpload || checkInterestQuery.status === "error" || interest !== "none" || isInterested}
            >
              Express Interest
            </SearchButton>
          </Box>
        </Pad>
      </CardWrapper>
    </ContentWrapper>
  );
};

export default withVendorDashboard(SingleProcurement);

const BorderLinearProgress = withStyles((theme) => ({
  root: {
    height: 6,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: theme.palette.grey[theme.palette.type === "light" ? 200 : 700],
  },
  bar: {
    backgroundColor: "#1a90ff",
  },
}))(LinearProgress);
