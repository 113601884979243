import React, { useState, useEffect } from "react";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";

import { DatePickerWrapper } from "./style";

const DatePicker = ({
  label,
  id,
  style,
  handleChange,
  datepickerName,
  value,
  datePickerWrapperStyle = {},
  defaultValue,
  ...rest
}) => {
  const [selectedDate, setSelectedDate] = useState(value);

  useEffect(() => {
    setSelectedDate(value);
  }, [value]);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    handleChange && handleChange(date);
  };

  return (
    <DatePickerWrapper style={datePickerWrapperStyle}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          disableToolbar
          openTo="year"
          views={["year", "month", "date"]}
          variant="inline"
          format="MM/dd/yyyy"
          placeholder="DD/MM/YYYY"
          margin="normal"
          id={id}
          defaultValue={moment()}
          label={label}
          value={selectedDate}
          onChange={handleDateChange}
          style={style}
          KeyboardButtonProps={{
            "aria-label": "change date",
          }}
          InputLabelProps={{
            shrink: true
          }}
          {...rest}
        />
      </MuiPickersUtilsProvider>
    </DatePickerWrapper>
  );
};

export default DatePicker;
