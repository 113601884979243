import React, { useState } from "react";
import { Grid, Popover, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import moment from "moment";

import {
  MainCardContainer,
  TenderOrContractStatus,
  TenderOrContractDescription,
  TenderOrContractTimeLineHeading,
  TenderOrContractTimelineWrapper,
} from "./style";
import { UnstyledLink } from "../../pages/Homepage/TenderList/style";

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: "none",
  },
  paper: {
    padding: "4px 8px",
    boxShadow: "0 1px 1px rgba(0,0,0,0.4)",
  },
}));

const TendersOrContractCard = ({
  service = "service",
  status,
  description = "Description",
  openOrAwardedDate,
  closeOrExpiryDate,
  vendor,
  contractAmount,
  urlLink,
  awardedContracts = true,
  category,
  ministry,
  referenceId
}) => {
  let serviceTextIsLong = service?.length > 40;
  let descriptionTextIsLong = description?.length > 114;
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const getTenderStatus = () => {
    // if today's date is greater than closeOrExpiryDate

    const daysDiffrence = moment().diff(moment(closeOrExpiryDate, "DD-MM-YYYY"), "days");
    return daysDiffrence > 0 ? "Closed" : "Opened";
  };

  const open = Boolean(anchorEl);

  return (
    <Grid item style={{ marginBottom: "15px" }} xs={12} sm={6} md={4}>
      <UnstyledLink to={urlLink}>
        <MainCardContainer>
          <TenderOrContractStatus>
            <p
              aria-owns={open ? "mouse-over-popover" : undefined}
              aria-haspopup="true"
              onMouseEnter={handlePopoverOpen}
              onMouseLeave={handlePopoverClose}
            >
              {serviceTextIsLong ? service.substring(0, 40) + "..." : service}
            </p>
            <p>{status || getTenderStatus()}</p>
          </TenderOrContractStatus>
          <TenderOrContractDescription>
            <p
              dangerouslySetInnerHTML={{ __html: descriptionTextIsLong ? description.substring(0, 110) + "..." : description }}
            ></p>
            <span>{referenceId || "N/A"}</span>
          </TenderOrContractDescription>

          <TenderOrContractTimelineWrapper>
            <TenderOrContractTimeLineHeading>
              <span>Open Date</span>
              <span>Close Date</span>
            </TenderOrContractTimeLineHeading>
            <TenderOrContractTimeLineHeading>
              <p>{openOrAwardedDate || "N/A"}</p>
              <p>{closeOrExpiryDate || "N/A"}</p>
            </TenderOrContractTimeLineHeading>
            <TenderOrContractTimeLineHeading>
              <span>Category</span>
              <span>Ministry</span>
            </TenderOrContractTimeLineHeading>{" "}
            <TenderOrContractTimeLineHeading>
              <p>{service || "N/A"}</p>
              <p>{ministry || "N/A"}</p>
            </TenderOrContractTimeLineHeading>
            {/* {awardedContracts && (
              <> */}
            <TenderOrContractTimeLineHeading>
              <span>Vendor</span>
              <span>Contract Amount</span>
            </TenderOrContractTimeLineHeading>
            <TenderOrContractTimeLineHeading style={{ marginBottom: "10px" }}>
              <p>{vendor || "N/A"}</p>
              <p>{contractAmount || "N/A"}</p>
            </TenderOrContractTimeLineHeading>
          </TenderOrContractTimelineWrapper>
        </MainCardContainer>
      </UnstyledLink>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <Typography style={{ fontSize: 12 }}>{service || "N/A"}</Typography>
      </Popover>
    </Grid>
  );
};

export default TendersOrContractCard;
