import styled from "styled-components";
import Typography from "@material-ui/core/Typography";
import TableContainer from "@material-ui/core/TableContainer";
import Button from "@material-ui/core/Button"
import { theme } from "../../util/theme";
import { Link } from "react-router-dom";

export const TableWrapper = styled(TableContainer)`
  border-radius: 3px;
  box-shadow: 0 2px 4px 0 #cbd4da !important;

  .MuiTableCell-body {
    color: ${theme.color.secondary} !important;
  }

  .MuiTableHead-root {
    border-top: 1px solid #cbd4da;

    th {
      text-transform: uppercase;
      padding: 16px 10px;
    }

    tr:first-child {
      outline: 1px solid #cbd4da;
      th {
        line-height: 11px;
      }
      th:last-child {
        text-align: left;
      }
    }
  }

  .MuiTable-stickyHeader {
    border-top: 1px solid #cbd4da;
  }

  th:first-child {
    border-left: 1px solid #cbd4da;
  }

  th,
  td {
    text-align: left;
  }

  th {
    font-size: 12px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 16px;
    color: #637381;
  }

  p,
  span {
    font-size: 14px;
  }

  span {
    font-size: 12px;
    font-weight: 400;
  }

  .MuiTableCell-body:last-child {
    span {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-evenly;
      width: 22px;
      text-align: center;
    }
  }

  .MuiTableCell-body:first-child {
    div:nth-child(2) {
      margin-left: 10px;
    }
  }

  .MuiTablePagination-root {
    border-left: 1px solid #cbd4da;
  }

  .MuiTableBody-root .MuiTableCell-root {
    padding: 0 10px;
    height: 59px;

    :last-child {
      text-align: right;
    }
  }

  .MuiChip-label {
    text-transform: capitalize;
  }

  .MuiTableBody-root > .MuiBox-root {
    position: absolute;
    margin-left: 25%;
    text-transform: capitalize;
  }

  .MuiCircularProgress-colorPrimary {
    color: #2AA96D;
  }
`;

export const ResultDetailContainer = styled.div`
  /* padding-top: 25px; */
  box-sizing: border-box;
  height: 61px;
  border: 1px solid #c9d4db;
  border-radius: 3px 3px 0 0;
  background-color: #ffffff;
  box-shadow: 0 2px 4px 0 #c9d4db;
  display: flex;
  align-items: center;
  padding: 0 19px;
  justify-content: space-between;
`;

export const ResultShowText = styled(Typography)`
  height: 19px;
  color: #5f7483;
  font-size: 14px !important;
  font-weight: 600 !important;
  letter-spacing: 0;
  line-height: 19px !important;
`;

export const TableContainerWrapper = styled.div`
  margin-top: ${({ nopadding }) => (nopadding === "true" ? "20px" : 0)};
`;

export const PaginationWrapper = styled.div`
  box-sizing: border-box;
  height: 51px;
  border: 1px solid #e5e9ec;
  background-color: #ffffff;
  margin-bottom: 40px;
`;


export const RequestButton = styled(Button)`
  height: 32px;
  color: ${theme.color.primary} !important;
  border-radius: 8px;
  font-size: 14px;

  &.MuiButton-outlined {
    text-transform: capitalize;

    &:hover {
      background-color: ${theme.color.primary} !important;
      color: #ffffff !important;
    }
  }
  & .request-link {
    color: ${theme.color.primary} !important;
    text-decoration: none;
    &:hover {
      color: #ffffff !important;
    }
  }
`;

export const ButtonLink = styled(Link)`
  padding: 7px 16px;
  color: ${theme.color.primary} !important;
  border-radius: 8px;
  text-decoration: none;
  font-size: 14px;
  background-color: #F0F5FF;
  &:hover {
    background-color: ${theme.color.primary} !important;
    color: #ffffff !important;
    text-decoration: none;
  }
  
`
