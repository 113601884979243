import styled from "styled-components";
import withStyles from "@material-ui/styles/withStyles";
import ButtonBase from "@material-ui/core/ButtonBase";

export const Wrapper = styled.div`
  padding: 40px 20px;
  background: #fcfcfc;
  margin: 0 -20px;
`;

export const CategoryItem = withStyles({
  root: {
    background: "#FFFFFF",
    border: "1px solid #C9D4DB",
    borderRadius: 3,
    marginBottom: 20,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    padding: 20,
    "& svg": {
      marginBottom: 15,
    },
    "& .file-count": {
      fontSize: 13,
      lineHeight: 1.23,
      color: "#2AA96D",
    },
  },
})(ButtonBase);

export const CategoryTitle = styled.p`
  font-weight: 600;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #5f7483;
`;

export const CloseButton = withStyles({
  root: {
    padding: "11px 27px",
    border: "1px solid #8E9FAC",
    borderRadius: 3,
    color: "#8E9FAC",
  },
})(ButtonBase);
