import React from "react";
import { Collapse } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

import PropTypes from "prop-types";

const FileError = ({ fileRejections }) => {
  return (
    <Collapse in={!!fileRejections?.length}>
      <Alert severity="error" style={{ marginTop: 20 }}>
        {fileRejections.map(({ file, errors }) => (
          <div key={file.path} style={{ textAlign: "left" }}>
            {file.path} - {(file.size / 1000).toLocaleString()}kb
            {errors.map(e => (
              <li key={e.code}>{e.message}</li>
            ))}

          </div>
        ))}
      </Alert>
    </Collapse>
  );
};

FileError.propTypes = {
  fileRejections: PropTypes.arrayOf(PropTypes.shape({
    errors: PropTypes.array,
    file: PropTypes.object
  })).isRequired
};

FileError.defaultProps = {}

export default FileError;
