import styled from "styled-components";
import Modal from "@material-ui/core/Modal"
import { theme } from "../../util/theme";
import LoadingButton from "../LoadingButton/LoadingButton";


export const StyledModal = styled(Modal)`
  border-radius: 3px;
  box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.08);
  border: 1px solid #EBEBEB;
  backdrop-filter: blur(3px);
  .MuiDialog-paper {
    padding: 40px, 24px, 40px, 24px;
    min-width: 600px;
  }
`;


export const ModalBody = styled.div`
  position: absolute;
  top: 140px;
  left: calc(50% - 200px);
  width: 100%;
  max-width: 400px;
  min-height: 200px;
  border: 1px solid #EBEBEB;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.08);
  border-radius: 3px;
  padding: 30px;
  background-color: white;
  display: flex;
  flex-direction: column;
`

export const ModalTitle = styled.div`
  font-family: 'Nunito Sans';
  font-weight: 700;  
  font-size: 24px;
  line-height: 33px;
  color:${theme.color.textMain};
  text-align: center;
  margin-bottom: 8px;
`;


export const BodyText = styled.div`  
font-family: 'Nunito Sans';
  font-weight: 400;
  font-size: 16px;
  line-height: 25px;
  text-align: center;
  text-transform: capitalize;
  color: #3F5673;
`;


export const ModalButtons = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-column-gap: 10px;
  margin-top: 20px;
`;


export const ConfirmButton = styled(LoadingButton)`
  color: #ffffff;
  box-sizing: border-box;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: ${theme.color.danger};
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;

  &:disabled,
  &[disabled]{
  background-color: #c9d4db;
  cursor: not-allowed;
}
`;


export const CancelBtn = styled.button`
  color: ${theme.color.primary};
  background: #fff;
  box-sizing: border-box;
  height: 41px;
  padding-left: 20px;
  padding-right: 20px;
  border: 1px solid #2AA96D;
  border-radius: 3px;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.15);
  font-size: 14px;
  font-weight: 600;
  letter-spacing: 0;
  line-height: 20px;
  text-align: center;
  cursor: pointer;
  margin-right: 10px;
  &:hover {
    background: ${theme.color.danger};
    color: ${theme.color.white}
  }
`;


export const StyledImageWrapper = styled.div`
  display: grid;
  place-items: center;
`;
