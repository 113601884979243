import React from "react";
import { useDropzone } from "react-dropzone";
import Typography from "@material-ui/core/Typography";
import LinearProgress from "@material-ui/core/LinearProgress";
import ClearIcon from "@material-ui/icons/Clear";
import TextField from "@material-ui/core/TextField";

import {
  DropzoneArea,
  UploadDocumentContainer,
  UploadDocumentContent,
  UploadingContainer,
  UploadingDocumentContainer,
  UploadingDocumentLoadInfo,
  UploadingDocumentName,
  Wrapper
} from "./style";
import { ReactComponent as FileIcon } from "../../assets/file.svg";
import FileProgress from "../FileProgress/FileProgress";
import { MaxFileSize } from "../../util/constants";
import FileError from "./FileError";

const UploadedDoc = ({
  fileName, removeFiles, readOnly, errorFile,
}) => (
  <UploadDocumentContainer>
    <UploadDocumentContent>
      <UploadingContainer>
        <FileIcon style={{ marginRight: "10px" }} />
        <UploadingDocumentContainer>
          <UploadingDocumentName>
            <Typography>{fileName}</Typography>
          </UploadingDocumentName>
          {!readOnly
            && <UploadingDocumentLoadInfo>
              <Typography>735kb - 1 seconds left</Typography>
            </UploadingDocumentLoadInfo>}
        </UploadingDocumentContainer>
      </UploadingContainer>
      {!readOnly && <ClearIcon onClick={() => removeFiles(fileName)} />}
    </UploadDocumentContent>
    <LinearProgress className={errorFile ? "error-check" : ""} variant="determinate" value={100} />
  </UploadDocumentContainer>
);

const FileUploadField = ({
  accept,
  filesNames,
  setFilesNames,
  multiple = false,
  description = false,
  setDescription,
  readOnly = false,
  uploadText = "Release to drop the files here",
  uploadSubText = "Drag \"n\" drop your files here, or click to select files",
}) => {
  const fileMaxSize = MaxFileSize;
  const onDrop = (acceptedFiles) => {
    setFilesNames([...filesNames, ...acceptedFiles]);
  };

  const removeFiles = (file) => {
    if (!multiple) return setFilesNames([]);
    const newFiles = filesNames.filter((item) => item.name !== file.name);
    return setFilesNames(newFiles);
  };

  function fileSizeValidator(file) {
    if (file.size > fileMaxSize) {
      return {
        code: "tooLarge",
        message: `File size is larger than ${fileMaxSize / 1000}kb`
      };
    }

    return null
  };

  const { getRootProps, getInputProps, isDragActive, fileRejections } = useDropzone({
    onDrop,
    accept,
    validator: fileSizeValidator
  });

  return (
    !multiple && filesNames.length
      ? <UploadedDoc
        errorFile={Boolean(filesNames[0].error)}
        fileName={filesNames[0].name} setFilesNames={setFilesNames} removeFiles={removeFiles} readOnly={readOnly} />
      : (
        <>
          <DropzoneArea {...getRootProps()}>
            <input {...getInputProps()} />
            <div>
              {isDragActive ? (
                <p>{uploadText}</p>
              ) : (
                <>
                  <p>{uploadSubText}</p>
                  <span style={{ fontSize: ".9em" }}>{"File size should not exceed 5,000kb (5mb)"}</span>
                  <FileError fileRejections={fileRejections} />
                </>
              )}
            </div>
          </DropzoneArea>
          {Boolean(filesNames.length) && filesNames.map((file, index) => (
            <Wrapper key={`${file.name}_${index}`}>
              <FileProgress
                fileSize={file.size}
                fileName={file.name}
                hasCancel
                onCancel={() => removeFiles(file)}
                progress={100}
              />
              {description && (<TextField
                id="document-description"
                label="Description"
                multiline
                rows={3}
                name={file.name}
                defaultValue="type document description"
                onChange={(e) => setDescription(e)}
              />)}
            </Wrapper>
          ))}
        </>
      )
  );
};

export default FileUploadField;
