import React from 'react'
import PropTypes from "prop-types";
import {
  Page,
  View,
  Text,
  Font,
  Document,
  StyleSheet
} from '@react-pdf/renderer';


Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' },
    { src: '/fonts/Roboto-Bold.ttf' }
  ]
});


const styles = StyleSheet.create({
  subtitle2: { fontSize: 9, fontWeight: 700 },
  alignRight: { textAlign: 'right' },
  page: {
    padding: '40px 24px 0 24px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#fff',
    textTransform: 'capitalize'
  },

  table: {
    display: 'flex', width: 'auto',
    boxShadow: "0 2px 4px 0 #cbd4da",
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE3E8',
    borderRadius: "3px"
  },
  tableHeader: {
    backgroundColor: "#cbd4da"
  },
  tableBody: {},
  tableRow: {
    padding: '8px 0',
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE3E8'
  },
  tableCell_1: { padding: 2, width: '10%' },
  tableCell_2: { padding: 2, width: '50%', paddingRight: 16 },
  tableCell_3: { padding: 2, width: '15%' }
});

const SupplierListPDF = ({ suppliers = [] }) => {
  return (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.page}>
        <View style={styles.table}>
          <View style={styles.tableHeader}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>COMPANY</Text>
              </View>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>CATEGORY</Text>
              </View>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>COMPETENCE</Text>
              </View>
              <View style={styles.tableCell_3}>
                <Text style={styles.subtitle2}>WEBSITE</Text>
              </View>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>YEAR OF INC</Text>
              </View>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>CAC REG NO</Text>
              </View>
              <View style={styles.tableCell_1}>
                <Text style={styles.subtitle2}>STATUS</Text>
              </View>
            </View>
          </View>
          <View style={styles.tableBody}>
            {suppliers?.map((eachSupplier) => {
              return (
                <View style={styles.tableRow} key={eachSupplier.id}>
                  <View style={styles.tableCell_3}>
                    <Text style={styles.subtitle2}>{eachSupplier?.companyName}</Text>
                  </View>
                  <View style={styles.tableCell_1}>
                    <Text style={styles.subtitle2}>{eachSupplier?.category}</Text>
                  </View>
                  <View style={styles.tableCell_1}>
                    <Text style={styles.subtitle2}>{eachSupplier?.competence}</Text>
                  </View>
                  <View style={styles.tableCell_3}>
                    <Text style={styles.subtitle2}>{eachSupplier?.website}</Text>
                  </View>
                  <View style={styles.tableCell_1}>
                    <Text style={styles.subtitle2}>{eachSupplier?.incorporationYear}</Text>
                  </View>
                  <View style={styles.tableCell_1}>
                    <Text style={styles.subtitle2}>{eachSupplier?.cacRegistrationNumber}</Text>
                  </View>
                  <View style={styles.tableCell_1}>
                    <Text style={styles.subtitle2}>{eachSupplier?.status}</Text>
                  </View>
                </View>
              )
            })}
            <View style={[styles.tableCell_2, styles.alignRight]}>
              <Text>Total Number of Supplier: {suppliers?.length}</Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  )
}

SupplierListPDF.propTypes = {
  suppliers: PropTypes.array
}

export default SupplierListPDF