import React, { useState } from "react";
import { EmptyTableSubTitle } from "../EmptyTableBody/style";
import { StyledTableCell } from "../SearchTable";
import { VendorTableStyle } from "./style";
import EmptyTableBody from "../EmptyTableBody";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ReceiptDrawer from "./ReceiptDrawer";
import { separateNumByComma } from "../../util/truncateValue";

const PaymentTable = ({ vendors, refetch }) => {
  const [openDrawer, setOpenDrawer] = useState(false);

  const toggleDrawer = () => {
    setOpenDrawer(!openDrawer);
    refetch();
  };

  return vendors?.length ? (
    vendors?.map((row) => (
      <React.Fragment>
        <VendorTableStyle key={`${row.id}`} onClick={() => toggleDrawer()}>
          <StyledTableCell component="th" scope="row">
            <p>{row?.name}</p>
          </StyledTableCell>
          <StyledTableCell>NGN{separateNumByComma(Number(row?.amount))}</StyledTableCell>
          <StyledTableCell style={{ paddingLeft: 0 }}>
            <ChevronRightIcon />
          </StyledTableCell>
        </VendorTableStyle>
        <ReceiptDrawer openDrawer={openDrawer} toggleDrawer={toggleDrawer} vendor={row}/>
      </React.Fragment>
    ))
  ) : (
    <EmptyTableBody>
      <EmptyTableSubTitle>No pending renewal at the moment</EmptyTableSubTitle>
    </EmptyTableBody>
  );
};

export default PaymentTable;
