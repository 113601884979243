import React, { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import {
  ButtonWrapper,
  ContactBox,
  ContactInfo,
  ContactInputField,
  ContactText,
  ContactTextAreaField,
  ContactWrapper,
  DropdownCon,
  ErrorContainer,
  FormWrapper,
  Section1,
  SectionTitle,
  SectionWrapper,
  SubmitButton,
  FormContainer,
} from "./style";
import Hero from "../../../components/Hero";
import withHeaderFooterHOC from "../HOC/withHeaderFooterHOC";
import HomeIcon from "../../../assets/home_icon.svg";
import LocationIcon from "../../../assets/location_icon.svg";
import PhoneIcon from "../../../assets/phone_icon.svg";
import homepageAPI from "../../../redux/api/homepageAPI";
import useAlert from "../../../hooks/useAlert";
import ControlledDropdown from "../../../components/ControlledDropdownField";

const Contact = () => {
  const [sendContactFormResponse, { status, error }] = useMutation(homepageAPI.addComment);
  const { register, handleSubmit, errors, control } = useForm();
  const { Toast, showAlert } = useAlert();

  // eslint-disable-next-line no-shadow
  const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

  const onSubmit = (data) => {
    if (status === "loading") return;
    sendContactFormResponse(data);
  };

  const reasonList = [
    { label: "Drop a suggestion", value: "suggestion" },
    { label: "File a complaint", value: "complaint" },
  ];

  useEffect(() => {
    if (status === "error") {
      showAlert({
        severity: "error",
        message: error.message,
      });
    }
    if (status === "success") {
      showAlert({
        severity: "success",
        message: "Thank you for reaching out!",
      });
    }
  }, [status, error, showAlert]);

  return (
    <div>
      <Hero
        title="Contact Us"
        desc="Are you interested in bidding for a contract for your organization or
      for an individual? Click the link below to register"
      />
      <SectionWrapper>
        <Section1 container>
          <ContactWrapper item xs={12} md={6} sm={6}>
            <ContactBox>
              <SectionTitle>Contact Us</SectionTitle>
              <hr />
              <ContactInfo>
                <img src={HomeIcon} alt="home" /> <ContactText>Kwara state Public Procurement Agency</ContactText>
              </ContactInfo>
              <ContactInfo>
                <img src={LocationIcon} alt="location" />
                <ContactText>
                  Kwara State Government House, <br />
                  Ahmadu Bello Way, GRA, Ilorin, Kwara state
                </ContactText>
              </ContactInfo>
              <ContactInfo>
                {" "}
                <img src={PhoneIcon} alt="phone" />
                <ContactText>+234 (0) 705 656 4902</ContactText>
              </ContactInfo>
            </ContactBox>
          </ContactWrapper>
          <FormContainer item xs={12} md={6} sm={6}>
            <FormWrapper onSubmit={handleSubmit(onSubmit)}>
              <h5>Send a message</h5>
              <DropdownCon>
                <Controller
                  name="type"
                  control={control}
                  rules={{ required: true }}
                  defaultValue={reasonList[0].value}
                  render={(props) => (
                    <ControlledDropdown
                      style={{ width: "auto" }}
                      name="type"
                      label="Select reason"
                      options={reasonList}
                      {...props}
                    />
                  )}
                />
              </DropdownCon>
              {onRenderError(errors.type)}
              <ContactInputField
                type="text"
                label="Full Name"
                name="fullName"
                inputRef={register({
                  required: "Please enter your full name",
                  validate: (value) => (value.length > 2 && value.length < 256) || "Please enter a valid full name",
                })}
              />
              {onRenderError(errors.fullName)}

              <ContactInputField
                type="email"
                label="Email Address"
                name="email"
                inputRef={register({
                  required: "Please enter an email address",
                  validate: (value) => (value.length > 2 && value.length < 256) || "Please enter an email address",
                })}
              />
              {onRenderError(errors.email)}

              <ContactInputField
                type="tel"
                label="Phone Number"
                name="phoneNumber"
                placeholder="080...."
                inputRef={register({
                  required: "Please enter a phone number",
                  validate: (value) => value.length === 11 || "Phone number must be 11 digits",
                })}
              />
              {onRenderError(errors.phoneNumber)}

              <ContactTextAreaField
                type="text"
                label="Message"
                name="body"
                inputRef={register({
                  required: "Please enter a message",
                  validate: (value) => (value.length > 2 && value.length < 256) || "Message must be between 2 to 256 characters",
                })}
                multiline
                variant="outlined"
              />
              {onRenderError(errors.body)}

              <ButtonWrapper style={{ marginBottom: 0 }}>
                <SubmitButton loading={status === "loading"} type="submit" style={{ width: "122px" }}>
                  {"message"}
                </SubmitButton>
              </ButtonWrapper>
            </FormWrapper>
          </FormContainer>
        </Section1>
      </SectionWrapper>
      <Toast float />
    </div>
  );
};

export default withHeaderFooterHOC(Contact);
