export const organizationTypes = [
  {
    label: "INCORPORATED COMPANY",
    value: "1",
  },
  {
    label: "LIMITED PARTNERSHIPS",
    value: "2",
  },
  {
    label: "BUSINESS NAME",
    value: "3",
  },
];

export const coreCompetencies = [
  {
    label: "Goods",
    value: "1",
  },
  {
    label: "Works",
    value: "2",
  },
  {
    label: "Consulting",
    value: "3",
  },
];

export const states = {
  1: "Kebbi",
  2: "Kogi",
  3: "Kwara",
  4: "Delta",
  5: "Nasarawa",
  6: "Niger",
  7: "Ogun",
  8: "Ondo",
  9: "Osun",
  10: "Oyo",
  11: "Plateau",
  12: "Rivers",
  13: "Sokoto",
  14: "Taraba",
  15: "Yobe",
  16: "Katsina",
  17: "Kano",
  18: "Kaduna",
  19: "Jigawa",
  20: "Abia",
  21: "Adamawa",
  22: "Akwa Ibom",
  23: "Anambra",
  24: "Bauchi",
  25: "Bayelsa",
  26: "Benue",
  27: "Zamfara",
  28: "Borno",
  29: "Delta",
  30: "Ebonyi",
  31: "Edo",
  32: "Ekiti",
  33: "Enugu",
  34: "Gombe",
  35: "Imo",
  36: "Cross River",
  37: "Abuja Federal Capital Territory",
};

export const countries = {
  1: "Nigeria",
};
