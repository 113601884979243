import baseAxiosMethod from "./baseAxiosMethod";
import handleApiError, { handleAxiosError } from "./handleApiError";

const paymentAPI = {
  initiatePayment: async (params) => {
    try {
      const response = await baseAxiosMethod.post(
        `payments/initialize`, params
      );
      return response.data;
    } catch (e) {
      throw e.response;
    }
  },

  updatePayment: async (params) => {
    try {
      const response = await baseAxiosMethod.post(
        `payments/update_payment`, params
      );
      return response.data.data;
    } catch (e) {
      throw handleApiError(e.response?.data, e.response?.statusCode);
    }
  },

  async vendorRegistrationPaymentProof({ vendorId, file }) { 
    try {
      const fd = new FormData();
      fd.append("PaymentType", 1);
      fd.append("File", file);
      fd.append("ObjectId", vendorId);
      const response = await baseAxiosMethod.post(`/vendors/paymentproof/upload`, fd);
      return response.data.data;
    } catch (e) {
      throw new Error(handleApiError(e.response?.data, e.response?.statusCode));
    }
  },

  async vendorRegistrationRenewalPaymentProof({ vendorId, file }) { 
    try {
      const fd = new FormData();
      fd.append("File", file);
      const response = await baseAxiosMethod.post(`/vendors/${vendorId}/renewal-payment-proof`, fd);
      return response.data.data;
    } catch (e) {
      throw new Error(handleApiError(e.response?.data, e.response?.statusCode));
    }
  },

  async initiateBidPayment({ tenderId, callbackUrl }) {
    try {
      const response = await baseAxiosMethod.post(`/vendors/InitiateVendorBidPayment/${tenderId}?CallbackUrl=${callbackUrl}`);
      return response.data.data;
    } catch (e) {
      throw new Error(handleAxiosError(e));
    }
  },

  async updateBidPayment(values) {
    try {
      const response = await baseAxiosMethod.post(`/vendors/UpdateVendorBidPayment`, values);
      return response.data.data;
    } catch (e) {
      throw new Error(handleAxiosError(e));
    }
  },

  async uploadBidPaymentProof({ tenderId, file }) {
    try {
      const fd = new FormData();
      fd.append("PaymentType", 2);
      fd.append("File", file);
      fd.append("ObjectId", tenderId);
      const response = await baseAxiosMethod.post(`/vendors/paymentproof/upload`, fd);
      return response.data.data;
    } catch (e) {
      throw new Error(handleAxiosError(e));
    }
  },

  async getBidPaymentStatus(_, tenderId) {
    try {
      const response = await baseAxiosMethod.get(`/vendors/CheckVendorBidPayment/${tenderId}`);
      const paymentInfo = { ...response.data.data };    
      return paymentInfo;
    } catch (e) {     
      throw new Error(handleAxiosError(e));
    }
  },
};

export default paymentAPI;
