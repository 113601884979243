import styled from "styled-components";

export const Quotation = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  > p {
    font-size: 14px;
    text-align: right;
    text-decoration-line: underline;
    color: #2AA96D;
  }
`;

export const ContractDocumentSection = styled.div`
  margin-right: -20px;
  margin-left: -20px;
  background: #fcfcfd;
  border: 1px solid #c9d4db;
  padding: 20px;
`;

export const ContractDocument = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

export const CheckedIconWrapper = styled.div`
  background: ${({ isSigned }) => (isSigned ? "#219653" : "#e0e0e0")};
  display: flex;
  justify-content: center;
  align-items: center;
  height: 50px;
  width: 50px;
  border-radius: 50%;
  margin-left: 20px;
`;
