import React, { useLayoutEffect, useMemo, useContext, Fragment } from "react";
import withVendorDashboard from "../HOC/withVendorDashboard";
import { PageContainer, PageTitle } from "../../Common/style";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useMutation } from "react-query";
import paymentAPI from "../../../redux/api/paymentAPI";
import ToastContext from "../../../util/toastContext";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";

function CompleteBidPayment() {
  const { search } = useLocation();
  const { replace } = useHistory();
  const { id: bidId } = useParams();
  const { showAlert } = useContext(ToastContext);

  const [paymentStatusCode, transactionId] = useMemo(() => {
    const params = new URLSearchParams(search);
    return [params.get("status-code"), params.get("transaction-id")];
  }, [search]);
  
  const [updatePayment, { isError }] = useMutation(paymentAPI.updateBidPayment, {
    onSuccess() {
      replace(`/vendor/bids/${bidId}`);
    },
    onError(error) {
      showAlert({
        severity: "error",
        message: error.message,
        durationInMs: 3000,
      });
    },
  });

  useLayoutEffect(() => {
    if (!paymentStatusCode || !transactionId) {
      replace(`/vendor/bids/${bidId}`);
    } else {
      updatePayment({
        paymentStatusCode,
        transactionId,
      });
    }
  }, [paymentStatusCode, transactionId, replace, updatePayment, bidId]);

  return (
    <PageContainer>
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" flex="1" height="90vh">
        {!isError ? (
          <Fragment>
            <Box mb={2}>
              <CircularProgress size={32} />
            </Box>
            <PageTitle>Please wait...</PageTitle>
            <p>
              Verifying your payment... Do <strong>not</strong> close this tab...
            </p>
          </Fragment>
        ) : (
          <Fragment>
            <PageTitle>Failed to verify payment</PageTitle>
            <p>Please check your network connection and try refreshing the page. </p>
            <p>
              Your transaction ID is <strong>{transactionId}</strong>.
            </p>
            <p>Kindly contact support if the problem persists.</p>
          </Fragment>
        )}
      </Box>
    </PageContainer>
  );
}

export default withVendorDashboard(CompleteBidPayment);
