import React from "react";
import InputField from "../../../../components/InputField";
import Dropdown from "../../../../components/DropdownField";
import {
  ContentWrapper,
  TitleContainer,
  AddButton,
  CirclePlusIconWrapper,
} from "../style";
import SearchTable, { StyledTableRow, StyledTableCell } from "../../../../components/SearchTable";
import AdminSearchFilter from "../../../../components/AdminSearchFilter";

import { tenderTableRows } from "../mock";
import AdminCard from "../../../../components/AdminCard";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { PageTitle } from "../../../Common/style";
import { CardWrapper } from "../../../../util/theme";
import Breadcrumb from "../../../Common/PageElements/Breadcrumb";

const TableBody = () => (
  tenderTableRows.map((row, index) => (
    <StyledTableRow key={`${row.id}_${index}`}>
      <StyledTableCell>
        <div>
          <p>{row.id}</p>
          <span>{row.desc}</span>
        </div>
      </StyledTableCell>
      <StyledTableCell>{row.status}</StyledTableCell>
      <StyledTableCell>{row.name}</StyledTableCell>
      <StyledTableCell>{row.process}</StyledTableCell>
      <StyledTableCell>{row.closing_date}</StyledTableCell>
    </StyledTableRow>
  ))
);
const Tender = () => {
  const BreadLinks = [
    { url: "/admin/procurement/tender", title: "Home" },
    { url: "/admin/procurement/tender", title: "Procurement" },
    { url: "/admin/procurement/tender", title: "Tender" },
  ];

  return (
    <ContentWrapper>
      <TitleContainer>
        <div>
          <PageTitle>Procurement Tender</PageTitle>
          <Breadcrumb values={BreadLinks} />
        </div>
        <AddButton onClick={() => {}}>
          <CirclePlusIconWrapper /> Add New
        </AddButton>
      </TitleContainer>
      <CardWrapper>
        <AdminCard
          title={"Total Procurements"}
          count={20}
          description={"All active and inactive"}
          statuses={[
            { text: "13 Approved", bgColor: "#BBE5B3", textColor: "#108043" },
            { text: "6 Pending", bgColor: "rgba(3,173,235,0.13)", textColor: "#2AA96D" },
            { text: "1 Rejected", bgColor: "#FBEAE5", textColor: "#DE3618" },
          ]}
        />
      </CardWrapper>
      <AdminSearchFilter normalSearch>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <InputField
            type="text"
            label="Search name"
            defaultValue="Completion of resettle scheme"
            required
          />
          <Dropdown
            name="ministry"
            label="Ministry"
            values={[{ label: "All", value: 1 }]}
          />
          <Dropdown
            name="status"
            label="Status"
            values={[{ label: "All", value: 1 }]}
          />
          <Dropdown
            name="role"
            label="Role"
            values={[{ label: "All", value: 1 }]}
          />
        </div>
      </AdminSearchFilter>
      <SearchTable
        rows={tenderTableRows}
        columns={["ref id/desc", "status", "name", "process", "closing date"]}
      >
        <TableBody />
      </SearchTable>
    </ContentWrapper>
  );
};

export default withAdminDashboard(Tender);
