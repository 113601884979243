/* eslint-disable no-unused-expressions */
import React, { useContext, useEffect, useState } from "react";
import { Box, CircularProgress, Grid } from "@material-ui/core";
import moment from "moment";
import ListAltIcon from "@material-ui/icons/ListAlt";
import { useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { Event as DateRangeIcon } from "@material-ui/icons";

import { PageContainer, PageTitle } from "../../Common/style";
import Breadcrumb from "../../Common/PageElements/Breadcrumb";
import { SearchButton } from "../../../components/Forms/Common/style";
import { InfoContainer } from "../BidDetails/BidInfo/style";
import { CommentHeaderContainer, EmptyProjectMilestone, MilestoneDate, TitleLink } from "./style";
import { TableSubTextStyle, TableTextStyle } from "../Projects/style";
import TaskModal from "./TaskModal/index";
import vendorsAPI from "../../../redux/api/vendorsAPI";
import ToastContext from "../../../util/toastContext";
import InvoiceTab from "./InvoiceTab";
import ReviewCommentsSection from "../../../components/ReviewCommentsSection";
import withVendorDashboard from "../HOC/withVendorDashboard";
import ProjectProgressBar from "../../Admin/Procurement/Activitiies/ContractSupervision/ProjectProgressBar";
import { CuteGrid, HL, InfoCardWrapper, MainText, MainTitle } from "../Procurement/style";
import Loader from "../../../components/Common/Loader";
import SearchTable from "../../../components/SearchTable";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { formatCurrency } from "../../../util/formatCurrency";
import format from "date-fns/format";
import { ReactComponent as PendingCheck } from "../../../assets/pending-check.svg";
import { ReactComponent as ApprovedCheck } from "../../../assets/approved-check.svg";
import { useHistory } from "react-router";

const MilestoneDetail = () => {
  const { projectId: id, milestoneId } = useParams();
  const [open, setOpen] = useState(false);
  const [openInvoice, setOpenInvoice] = useState(false);
  const { showAlert } = useContext(ToastContext);
  const [openInvoicePreview, setOpenInvoicePreview] = useState(false);
  const history = useHistory();

  const taskQueryKey = ["milestoneTasks", milestoneId];
  const milestoneTasks = useQuery(taskQueryKey, vendorsAPI.getMilestoneTasks);

  const { status, error, isLoading, data: milestone, } = useQuery(["milestone", id, milestoneId], vendorsAPI.getMilestoneDetails);
  const { data: project } = useQuery(["project", id], vendorsAPI.getProjectDetails);

  const [addMilestoneTask, addTaskStatus] = useMutation(vendorsAPI.createMilestoneTask, {
    onSuccess: () => {
      showAlert({
        severity: "success",
        message: "Task created successfully",
      });
      setOpen(false);
    },
    onError: (data) => {
      showAlert({
        severity: "error",
        message: data.message,
      });
    },
    onSettled: () => {
      milestoneTasks.refetch();
    }
  });

  const [pagination, setPagination] = useState({
    pageNumber: 1,
    pageSize: 10,
  });

  const updatePagination = (key) => (value) => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      [key]: value,
    }));
  };

  useEffect(() => {
    if (status === "error") {
      showAlert({
        severity: "error",
        message: error.message,
      });
    }
  }, [status, error, showAlert]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    open && setOpen(false);
    openInvoice && setOpenInvoice(false);
    openInvoicePreview && setOpenInvoicePreview(false);
  };

  const onSubmit = async (data) => {
    await addMilestoneTask({
      projectMileStoneId: milestoneId,
      data,
    });
  };

  const BreadcrumbLinks = [
    { url: "/vendor/projects", title: "Project", forwardSlash: true },
    { url: `/vendor/projects/${id}`, title: project?.code, forwardSlash: false },
    { url: `/vendor/projects/${id}/milestone/${milestone?.id}`, title: milestone?.title, forwardSlash: false },
  ];

  return (
    <PageContainer>
      <Box display="flex" justifyContent="space-between" marginBottom="35px">
        <Box>
          <PageTitle>{project?.code}</PageTitle>
          <Breadcrumb values={BreadcrumbLinks} />
        </Box>
        <Box display="flex" alignItems="center">
          {/* <CancelButton style={{
            minWidth: "155px",
            backgroundColor: "#ffffff",
          }} onClick={handleOpenInvoice}>Generate Invoice</CancelButton> */}
          <SearchButton style={{ minWidth: "155px" }} onClick={handleOpen}>
            Create task
          </SearchButton>
        </Box>
      </Box>
      <>
        {isLoading ? (
          <center>
            <CircularProgress />
          </center>
        ) : (
          <>
            <InfoContainer>
              <Grid container>
                <Grid item xs={8}>
                  <div>
                    <InfoCardWrapper>
                      <MainTitle>Milestone 1</MainTitle>
                      {isLoading && <Loader />}
                      {!isLoading && (
                        <>
                          <MainText mt="5px" fontSize="14px">
                            {project?.ministry?.name}
                          </MainText>
                          <MainTitle mt="43px">{milestone?.title}</MainTitle>
                          <MainText mt="20px" fontSize="13px">
                            Task Description
                          </MainText>
                          <MainText mt="10px" fontSize="14px">
                            {milestone?.description}
                          </MainText>
                          <HL mt="20px" />

                          <CuteGrid>
                            <div>
                              <MainText mt="20px" fontSize="13px">
                                Budget
                              </MainText>
                              <MainTitle>N{milestone?.estimatedValue?.toLocaleString()}</MainTitle>
                            </div>
                          </CuteGrid>
                          <HL mt="20px" />

                          <CuteGrid>
                            <MilestoneDate>
                              <DateRangeIcon />
                              <div>
                                <MainText mt="20px" fontSize="13px">
                                  Start Date
                                </MainText>
                                <MainText>{moment(milestone?.startDate).format("DD MMMM, YYYY")}</MainText>
                              </div>
                            </MilestoneDate>
                            <MilestoneDate>
                              <DateRangeIcon />
                              <div>
                                <MainText mt="20px" fontSize="13px">
                                  End Date
                                </MainText>
                                <MainText>{moment(milestone?.endDate).format("DD MMMM, YYYY")}</MainText>
                              </div>
                            </MilestoneDate>
                          </CuteGrid>
                        </>
                      )}
                      <div className="topRightCard oneCard">
                        <div>
                          <MainText mt="20px" mr="10px" fontSize="13px">
                            Date Published
                          </MainText>
                          <MainText fw="600" fontSize="14px">
                            {moment(milestone?.startDate).format("DD/MM/YYYY")}
                          </MainText>
                        </div>
                      </div>
                    </InfoCardWrapper>
                  </div>
                </Grid>
                <Grid item xs={4}>
                  <ProjectProgressBar
                    fullWidth
                    marginSet="20px"
                    textTitle="Total Tasks"
                    totalTask={milestone?.totalTask}
                    CompletedTask={milestone?.totalCompletedTask}
                    pendingTask={milestone?.totalPendingTask}
                    percentageCompleted={milestone?.percentageCompleted}
                  />
                </Grid>
              </Grid>
            </InfoContainer>
            <Box mb={3.75}>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <SearchTable
                    rows={milestoneTasks.data?.tasks?.length}
                    columns={["TASK NAME", "VALUE", "START DATE", "END DATE", ""]}
                    page={pagination?.pageNumber}
                    setPage={updatePagination("pageNumber")}
                    rowsPerPage={pagination?.pageSize}
                    setRowsPerPage={updatePagination("pageSize")}
                    loading={milestoneTasks.isLoading}
                    totalNumberOfPages={milestoneTasks.data?.pagination?.totalEntries}
                    showPadding={false}
                  >
                    {milestoneTasks.data?.tasks?.length > 0 ? (
                      milestoneTasks.data?.tasks?.map((task) => (
                        <TableRow style={{ cursor: 'pointer' }} onClick={() => history.push(`/vendor/projects/${id}/milestone/${milestoneId}/task/${task.id}`)}>
                          <TableCell>
                            <TitleLink>
                              {task.title}
                            </TitleLink>
                          </TableCell>
                          <TableCell>{formatCurrency(task.estimatedValue, true, true)}</TableCell>
                          <TableCell>{format(new Date(task.startDate), "dd/MM/yyyy")}</TableCell>
                          <TableCell>{format(new Date(task.endDate), "dd/MM/yyyy")}</TableCell>
                          <TableCell>{task.status === "PENDING" ? <PendingCheck /> : <ApprovedCheck />}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <EmptyProjectMilestone>
                        <ListAltIcon style={{ fontSize: "100px" }} />
                        <TableTextStyle>You have no task(s)</TableTextStyle>
                        <TableSubTextStyle style={{ width: "345px" }}>
                          You currently have no task(s) in your milestone, use the button below to create one.
                        </TableSubTextStyle>
                        <SearchButton
                          onClick={handleOpen}
                          style={{
                            width: "155px",
                            marginTop: "32.5px",
                          }}
                        >
                          Create a task
                        </SearchButton>
                      </EmptyProjectMilestone>
                    )}
                  </SearchTable>
                </Grid>
                <Grid item xs={4}>
                  <InvoiceTab milestone={milestone} milestoneTasks={milestoneTasks.data?.tasks} />
                </Grid>
              </Grid>
            </Box>
            <InfoContainer>
              <CommentHeaderContainer> Comment left by admin </CommentHeaderContainer>
              <ReviewCommentsSection objectId={milestoneId} />
            </InfoContainer>
            <TaskModal onSubmit={onSubmit} addTaskStatus={addTaskStatus} open={open} handleClose={handleClose} />
          </>
        )}
      </>
    </PageContainer>
  );
};

export default withVendorDashboard(MilestoneDetail);
