import React, { useState } from "react";
import { Badge, ClickAwayListener, Grow, IconButton, MenuList, Paper, Popper } from "@material-ui/core";
import { UserNotificationBell } from "../style";
import NotificationPanel from "../NotificationsPanel";
import useNotifications from "../../../hooks/useNotifications";

const AdminNavNotifications = () => {
  const [openNotificationPanel, setOpenNotificationPanel] = useState(false);

  const notificationAnchorRef = React.useRef(null);

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpenNotificationPanel(false);
    }
  }
  
  const [page, setPage] = useState(1);

  const { notificationsQuery, markNotificationsAsRead } = useNotifications({ pageNumber: 1, pageSize: page * 10, isRead: false });

  const { data, isLoading, isSuccess } = notificationsQuery;

  const { pagination, notifications = [] } = data || {};

  const toggleNotificationPanel = () => {
    //Mark notifications as read on close
    if (openNotificationPanel) {
      markNotificationsAsRead();
    }

    setOpenNotificationPanel((prevOpenNotificationPanel) => !prevOpenNotificationPanel);
  };

  const increasePage = () => {
    setPage(Math.min(page + 1, pagination.totalPages));
  };
  
  return (
    <div>
      <IconButton
        aria-label="show 17 new notifications"
        ref={notificationAnchorRef}
        aria-controls={openNotificationPanel ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={toggleNotificationPanel}
      >
        <Badge badgeContent={pagination?.totalEntries} color="secondary">
          <UserNotificationBell />
        </Badge>
      </IconButton>
      <Popper
        open={openNotificationPanel}
        anchorEl={notificationAnchorRef.current}
        role={undefined}
        transition
        disablePortal
        placement="top"
        style={{ zIndex: "100" }}
      >
        {({ TransitionProps, placement }) => (
          <Grow {...TransitionProps} style={{ transformOrigin: placement === "bottom" ? "center top" : "center bottom" }}>
            <Paper>
              <ClickAwayListener onClickAway={toggleNotificationPanel}>
                <MenuList autoFocusItem={openNotificationPanel} onKeyDown={handleListKeyDown}>
                  <NotificationPanel
                    handleNotificationPanelClose={toggleNotificationPanel}
                    notifications={notifications}
                    totalEntries={pagination?.totalEntries}
                    loading={isLoading}
                    isSuccess={isSuccess}
                    increasePage={increasePage}
                    hasMore={page < pagination?.totalPages}
                  />
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </div>
  );
};

export default AdminNavNotifications;
