import React, { useState, useContext, useEffect } from 'react'
import { useForm } from "react-hook-form";

import { FormDescrition, FormDescriptionBox, BtnBox, FormTitle, FormContainer, TitleContainer, InputFieldBox, CancelButton, UpdateButton } from "./style";
import { PageTitle } from "../../../Common/style";
import withAdminDashboard from "../../HOC/withAdminDashboard";
import { InputField } from '../../../../components';
import FileUploadField from '../../../../components/FileUploadField';
import FileProgress from '../../../../components/FileProgress/FileProgress';
import { Box } from '@material-ui/core';
import ToastContext from "../../../../util/toastContext";
import procurementAPI from '../../../../redux/api/procurementAPI';
import { useMutation, useQuery } from 'react-query';
import Loader from '../../../../components/Common/Loader';
import { ErrorContainer } from "../../../../components/Forms/Common/style";

function AdminSettings() {
    const [files, setFiles] = useState([]);
    const { showAlert } = useContext(ToastContext);
    const { register, handleSubmit, errors, setValue, getValues, reset } = useForm();
    const [signatureState, setSignatureState] = useState(false)

        const [postSignatureMutation, {isLoading}] = useMutation(
          procurementAPI.createAuthorizerSignature,
          {
            onSuccess: () => {
              showAlert({
                message: "Certificate of no objection has been uploaded successfully",
                severity: "success",
              });
            },
            onError: (error) => {
              showAlert({
                message: error.message,
                severity: "error",
              });
            },
          }
        );

        const { data: authorizersData, isFetching } = useQuery(["getAuthoriserSignature"], procurementAPI.getAuthorizerSignature, {
          enabled: true,
          onError: (error) => {
            showAlert({
              message: error.message,
              severity: "error",
            });
          },
        });

          const handleUpload = (data) => {
            if(files.length === 0 && !signatureState){
              showAlert({
                message: 'Please select a signature file',
                severity: "error",
              });
            }else{
              const formData = new FormData();
              formData.append("AuthorizerPosition", data.authorizerPosition);
              formData.append("AuthorizerFullName", data.authorizerFullName);
              files.map((file) => formData.append("SignatureImage", file));
              postSignatureMutation({ formData });
            }
          };

          const onRenderError = (error) => <ErrorContainer>{error && error.message}</ErrorContainer>;

   useEffect(() => {
    setSignatureState(!!authorizersData?.data?.signatureUrl)
    reset({
      authorizerFullName: authorizersData?.data?.authorizerFullName,
      authorizerPosition: authorizersData?.data?.authorizerPosition,
    })
   },[reset, authorizersData])
   
   if (isFetching) {
    return <Loader />;
  }

  return (
    <div>
        <TitleContainer>
        <PageTitle>Certificate of no objection</PageTitle>
      </TitleContainer>
      <FormContainer>
            <FormTitle>Certificate Authorization</FormTitle>
            <FormDescriptionBox>
                <FormDescrition>Logical group descriptions goes here explaining the purpose of this section...</FormDescrition>
            </FormDescriptionBox>
            <InputFieldBox>
            <div style={{background: "#ffffff", width: '48%' }}>
            <InputField
                label="Authorizer’s Position"
                name="authorizerPosition"
                style={{background: "#ffffff" }}
                inputRef={register({
                  required: "Enter Authorizer’s Position",
                })}
               />
               {onRenderError(errors.authorizerPosition)}
            </div>
            <div style={{background: "#ffffff", width: '48%' }}>
               <InputField
                label="Authorizer's Name"
                name="authorizerFullName"
                style={{background: "#ffffff" }}
                inputRef={register({
                  required: "Enter Authorizer's Name",
                })}
               />
               {onRenderError(errors.authorizerFullName)}
            </div>
            </InputFieldBox>
            <Box mb={9}></Box>
            {
              signatureState ? 
              <FileProgress 
              // fileSize={file.size}
              fileName='Signature'
              hasCancel
              onCancel={() => setSignatureState(false)}
              progress={100}
              />
              :
              <FileUploadField filesNames={files} setFilesNames={setFiles}/>
            }
        <BtnBox>
            <CancelButton>Cancel</CancelButton>
            <UpdateButton style={{marginLeft: '1rem'}} onClick={handleSubmit(handleUpload)} disabled={isLoading}>{isLoading ? 'Loading...' : 'Update'}</UpdateButton>
        </BtnBox>
      </FormContainer>
    </div>
  )
}

export default withAdminDashboard(AdminSettings);