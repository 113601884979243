import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import moment from "moment";

import { ContractDetailTabText } from "./style";

const TenderDetailsTab = ({ contractOCDS }) => {
  const details = [
    { title: "Tender Title	", value: contractOCDS.title },
    { title: "Tender Status	", value: contractOCDS.tenderStatus || "Not Applicable" },
    { title: "Evaluation Status	", value: contractOCDS.evaluationStatus || "Not Applicable" },
    { title: "Package Number	", value: contractOCDS.packageNumber || "Not Applicable" },
    { title: "Procurement Method	", value: contractOCDS.procurementMethod || "Not Applicable" },

    { title: "Number of Tenderers	", value: contractOCDS.bidders?.length },

    { title: "Tender Start Date	", value: contractOCDS.tenderPeriodStartDate ? moment(contractOCDS.tenderPeriodStartDate).format("DD MMMM, yyyy") : "Not Applicable" },
    { title: "Tender End Date		", value: contractOCDS.tenderPeriodEndDate ? moment(contractOCDS.tenderPeriodEndDate).format("DD MMMM, yyyy") : "Not Applicable" },

    // { title: "Tendering Stage	", value: contractOCDS.tenderingStage || "Not Applicable" },
    // { title: "Review Method	", value:  contractOCDS.bidOpeningDate ? moment(contractOCDS.bidOpeningDate).add(1, "days").format("DD, MMMM, yyyy") : "Not Applicable" },
  ];

  return (
    <>
      <Box style={{ background: "#e4e9ec", padding: 20 }}>
        <Typography variant="h6" style={{ color: "#5F7483" }}>
          {contractOCDS?.title}
        </Typography>
      </Box>
      <Box>
        {details.map((detail, i) => (
          <Grid key={i} style={{ borderBottom: "1px solid #CBD4DA" }} container>
            <Grid item xs={3}>
              <ContractDetailTabText style={{ background: "#e4e9ec", boxShadow: "0px -1px 0px #C9D4DB" }}>
                {detail.title}
              </ContractDetailTabText>
            </Grid>
            <Grid item sm style={{ borderLeft: "1px solid #CBD4DA" }}>
              <ContractDetailTabText>{detail.value}</ContractDetailTabText>
            </Grid>
          </Grid>
        ))}
      </Box>
    </>
  );
};

export default TenderDetailsTab;
