import styled from "styled-components";

export const Nav = styled.div`
  box-sizing: border-box;
  border: 1px solid #ebebeb;
  border-radius: 3px;
  background-color: #ffffff;
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.08);
  display: flex;
  align-items: center;

  img {
    height: 70px;
  }

  .MuiContainer-root {
    text-align: left;
  }

  .state_logo:hover {
    cursor: pointer;
  }
`;
